import * as React from "react";
import { connect } from "react-redux";
import { withRouter, RouteComponentProps } from "react-router";
import styled from "styled-components";
// import BackArrow from "react-icons/lib/md/chevron-left";
import { MdChevronLeft } from "react-icons/md";

import * as Yup from "yup";
import {
  Container,
  SecondaryButton,
  Button,
  HR,
  Spinner,
  AppText,
} from "../UI";
import { FieldDetail, ColorItem } from "../Dumb";
// import { Toggle } from "react-toggle-component";
import { PRIMARY_GRADIENT_FROM } from "../../utils";
import { Formik, FormikProps, FieldArray } from "formik";
import {
  InputField,
  SelectField,
  CheckboxField,
  OnboardCheckboxField,
} from "../form";
import Switch from "react-toggle-switch";
import "react-toggle-switch/dist/css/switch.min.css";
import { api } from "../../api/api";
import { OrderDetailType, OrderPackages } from "../../api/responseTypes";
import { AppState } from "../../store/configureStore";

import { Dispatch, bindActionCreators } from "redux";
import {
  AppActions,
  CustomFieldByEntity,
  CustomFields,
  CustomFieldsByEntityListValue,
} from "../../types";
import { states } from "../../static/states";
import { phones } from "../../static/phones";
import bwipjs from "bwip-js";
import {
  barcodeSampleArray,
  convertBarcodeTypesToLibraryTypes,
} from "../../static/barcodes";

interface RouterProps {
  id: string;
}

interface OrderBarcodeProps {}

interface OrderBarcodeState {
  order: OrderDetailType;
}

type Props = RouteComponentProps<RouterProps> &
  OrderBarcodeProps &
  LinkStateProps &
  LinkDispatchProps;

class OrderBarcode extends React.Component<Props, OrderBarcodeState> {
  constructor(props: Props) {
    super(props);

    this.state = {
      order: {
        id: 0,
        driver_id: null,
        customer_id: null,
        address_id: 0,
        status: null,
        num_boxes: null,
        amount_to_collect: null,
        description: null,
        notes: "",
        created_at: "",
        updated_at: "",
        deleted_at: null,
        reference: null,
        date_imported: null,
        address_relevance: 0,
        delivered: null,
        delivery_date: null,
        address: {
          id: 0,
          address_1: "",
          address_2: "",
          city: "",
          state: "",
          zip: "",
          created_at: "",
          updated_at: "",
          deleted_at: null,
          latitude: 0,
          longitude: 0,
          notes: null,
        },
        customer: {
          address_id: 0,
          created_at: "",
          deleted_at: "",
          email: "",
          first_name: "",
          id: 0,
          last_name: "",
          phone: "",
          phone_type: "",
          subscription_price: "",
          updated_at: "",
        },
        packages: [],
      },
    };
  }

  componentDidMount = async () => {
    const id = this.props.match.params.id;
    const order = await api.getOrder({ id }, this.props.token);
    console.log("order----", order);
    await this.setState({
      order: order ? order : this.state.order,
    });
    this.drawCanvas();
  };

  drawCanvas = () => {
    const { barcode_type } = this.props;
    const barcodeType = convertBarcodeTypesToLibraryTypes(barcode_type);
    this.state.order.packages.forEach((barcode) => {
      console.log("DREW CANVAS FOR: ---", `${barcode.order_id}`);
      try {
        let canvas = bwipjs.toCanvas(`${barcode.id}`, {
          bcid: barcodeType,
          text: `${barcode.barcode}`,
          scale: 3, // 3x scaling factor
          height: 10, // Bar height, in millimeters
          includetext: true, // Show human-readable text
          textxalign: "center",
        });
      } catch (error) {
        console.log("CANVAS ERROR", error);
      }
    });
  };

  render() {
    console.log("barcode state", this.state);
    return (
      <BarcodesContainer>
        <AppText>Order barcodes</AppText>
        {this.state.order.packages.map((barcode, index) => {
          return (
            <BarcodeBox>
              <StoreName>{this.props.store_name}</StoreName>
              <OrderNamePhone>
                <OrderName>{`${this.state.order.customer.last_name}, ${this.state.order.customer.first_name}`}</OrderName>
                <OrderPhone>{`${this.state.order.customer.phone}`}</OrderPhone>
              </OrderNamePhone>
              <Address>{`${this.state.order.address.address_1}`}</Address>
              <DateBoxNumberBarcode>
                <DateBoxNumber>
                  <Date>{`${this.state.order.created_at}`}</Date>
                  <BoxNumber>
                    {`Box ${index + 1} of ${this.state.order.packages.length}`}
                  </BoxNumber>
                  <Description>{this.state.order.notes}</Description>
                  {/* <Items>Item</Items> */}
                </DateBoxNumber>
                <Barcode>
                  <StyledBarcodes id={`${barcode.id}`}></StyledBarcodes>
                </Barcode>
              </DateBoxNumberBarcode>
            </BarcodeBox>
          );
        })}
      </BarcodesContainer>
    );
  }
}

const BarcodesContainer = styled.div`
  display: flex;
  flex-direction: column;
  /* justify-content: center; */
  align-items: center;
  margin-top: 20px;
`;

const BarcodeBox = styled.div`
  margin-left: auto;
  margin-right: auto;
  width: 4in;
  height: 2.5in;
  box-sizing: border-box;
  padding: 0 5px;
`;

const StoreName = styled.div`
  border-bottom: double 4px;
`;

const OrderNamePhone = styled.div`
  font-size: 18px;
  margin: 5px 0;
`;

const OrderName = styled.div`
  display: inline-block;
`;
const OrderPhone = styled.div`
  display: inline-block;
  float: right;
`;

const Address = styled.div`
  font-size: 18px;
  padding-left: 1em;
  background: #000;
  color: #fff;
`;

const DateBoxNumber = styled.div`
  display: inline-block;
  width: 60%;
  height: 118px;
`;

const DateBoxNumberBarcode = styled.div`
  display: flex;
  margin-top: 1em;
  box-sizing: inherit
  height: 118px;
`;

const Barcode = styled.div`
  display: inline-block;
  justify-content: center;
  width: 100%;
  margin-top: 1em;
  height: 118px;
`;

const Date = styled.div`
  font-size: 14px;
`;

const BoxNumber = styled.div`
  font-size: 18px;
`;

const StyledBarcodes = styled.canvas`
  width: 100%;
  max-height: 110px;
`;

const Description = styled.div`
  margin-top: 10px;
  margin-bottom: 10px;
  font-size: 12px;
`;
const Items = styled.div`
  margin-top: 10px;
  margin-bottom: 10px;
  font-size: 12px;
`;

interface LinkStateProps {
  token: string;
  barcode_type: string;
  store_name: string;
}

interface LinkDispatchProps {}

const mapDispatchToProps = (
  dispatch: Dispatch<AppActions>
): LinkDispatchProps => ({});
// isAuthenticated is true if user_token exists in redux
const mapStateToProps = (state: AppState): LinkStateProps => ({
  token: state.auth.token as string,
  barcode_type: state.store.barcode_type,
  store_name: state.store.name,
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(OrderBarcode));
