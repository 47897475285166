import * as React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators, Dispatch } from "redux";
import onClickOutside, {
  InjectedOnClickOutProps,
  HandleClickOutside,
} from "react-onclickoutside";
import { AppText } from "../UI";
import { AppActions } from "../../types";
import { logout } from "../../actions";
import { PRIMARY_GRADIENT_FROM } from "../../utils";
import { AppState } from "../../store/configureStore";

interface DropdownMenuProps {
  toggleMenu: () => void;
  handleClickOutside: () => void;
}

type Props = DropdownMenuProps &
  LinkDispatchProps &
  LinkStateProps &
  InjectedOnClickOutProps &
  HandleClickOutside<any>;

class DropdownMenu extends React.Component<Props, any> {
  logout = () => {
    this.props.logout();
    this.props.toggleMenu();
  };

  render() {
    const { toggleMenu, user_id } = this.props;
    return (
      <Container>
        <ArrowDiv>
          <UpArrow>
            <InnerArrow />
          </UpArrow>
        </ArrowDiv>
        <Div>
          {/* <Link
            to="/account"
            onClick={toggleMenu}
            style={{ textDecoration: "none" }}
          >
            <Item>
              <ItemText>Manage Account</ItemText>
            </Item>
          </Link> */}
          <Link
            to="/settings"
            onClick={toggleMenu}
            style={{ textDecoration: "none" }}
          >
            <Item>
              <ItemText>Settings</ItemText>
            </Item>
          </Link>
          {user_id === 1 && (
            <Link
              to="/setup"
              onClick={toggleMenu}
              style={{ textDecoration: "none" }}
            >
              <Item>
                <ItemText>Client Setup</ItemText>
              </Item>
            </Link>
          )}
          {/* <Link
            to="/billing"
            onClick={toggleMenu}
            style={{ textDecoration: "none" }}
          >
            <Item>
              <ItemText>Billing</ItemText>
            </Item>
          </Link> */}
          <Item onClick={this.logout}>
            <ItemText>Logout</ItemText>
          </Item>
        </Div>
      </Container>
    );
  }
}

const ArrowDiv = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  padding-right: 32px;
`;

const Container = styled.div`
  width: 176px;
  position: absolute;
  left: -121.55px;
  top: 25px;
  z-index: 1;
`;

const Div = styled.div`
  background-color: ${PRIMARY_GRADIENT_FROM};
  border-radius: 5px;
  padding: 20px 30px 12px 17px;
`;

const UpArrow = styled.div`
  /* border-color: #fff transparent;
  border-style: solid;
  border-width: 0px 10px 10px 10px; */
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-bottom: 10px solid ${PRIMARY_GRADIENT_FROM};
  height: 0px;
  width: 0px;
`;

const InnerArrow = styled.div`
  position: relative;
  top: 2px;
  left: -9px;
  height: 0px;
  width: 0px;
  border-left: 9px solid transparent;
  border-right: 9px solid transparent;
  border-bottom: 9px solid ${PRIMARY_GRADIENT_FROM};
`;

interface ItemProps {
  height?: string;
}

const Item = styled.div<ItemProps>`
  height: ${(props) => props.height};
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  :hover {
    cursor: pointer;
  }
`;

Item.defaultProps = {
  height: "35px",
};

const ItemText = styled(AppText)`
  text-decoration: none;
  font-size: 14px;
  font-weight: 400;
  line-height: 2.29;
  text-align: right;
  color: #f7f7f7;
`;

interface LinkDispatchProps {
  logout: () => void;
}

interface LinkStateProps {
  user_id: number;
}

const mapStateToProps = (state: AppState): LinkStateProps => ({
  user_id: state.user.id as number,
});

const mapDispatchToProps = (
  dispatch: Dispatch<AppActions>
): LinkDispatchProps => ({
  logout: bindActionCreators(logout, dispatch),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(onClickOutside(DropdownMenu));
