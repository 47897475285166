import * as React from "react";
import { connect } from "react-redux";
import { AppState } from "../../../store/configureStore";
import { AppActions } from "../../../types";
import { Formik, FormikProps, Form } from "formik";
import { Dispatch, bindActionCreators } from "redux";
import * as Yup from "yup";
import styled from "styled-components";
import { Container, AppText, HR, Button, Collapsible } from "../../UI";
import { TABLET_BREAKPOINT, PHONE_REG_EXP } from "../../../utils";
import { AuthButton } from "../../Pages/NewClientSetup";
import { OnboardInputField, SelectField } from "../../form";
// import { registerAdminThunk } from "../../../api/auth";
import { NavLink } from "react-router-dom";
import { api } from "../../../api/api";
import EditPassword from "../../modal/EditPassword";
import { barcode } from "../../../static/";
import { UserStore } from "../../../types/store";
import { getStore } from "../../../actions/store";

// const onboardSchema = Yup.object().shape({
//   store_name: Yup.string()
//     .required("* Store Name is required")
//     .max(100, "* name too long"),
//   admin_email: Yup.string()
//     .email("* Invalid email")
//     .required("* Email is required"),
//   store_address: Yup.string()
//     .required("* Store Address is required")
//     .max(250, "* Too Long!"),
//   store_phone_number: Yup.string()
//     .required("Store Phone # is required")
//     .matches(PHONE_REG_EXP, "* Phone number is not valid")
// });
const onboardSchema = Yup.object().shape({
  store_name: Yup.string()
    .required("* Store Name is required")
    .max(100, "* name too long"),
  store_address: Yup.string()
    .required("* Store Address is too long")
    .max(100, "* name too long"),
  store_city: Yup.string().required("* City is Required"),
  store_state: Yup.string()
    .required(" State is required")
    .max(2, "* Two lettered State code"),
  store_zip: Yup.string()
    .required("* zip code is required")
    .max(5, "* Insert 5 digit zip code"),
  store_phone_number: Yup.string()
    .required("* Store Phone # is required")
    .matches(PHONE_REG_EXP, "* Phone number is not valid"),
  admin_email: Yup.string().email("* Invalid email"),
  // .required("* Email is required"),
  // nexmo_number: Yup.string()
  //   .required("* Nexmo number is required")
  //   .max(10, "* number exceeded")
  // ach_number: Yup.string()
  //   .required("* ACH number is required")
  //   .max(10, "* number exceeded"),
  // subscription_price: Yup.string()
  //   .required("* Subscription Price required")
  //   .max(100, "* name too long"),
  // subscription_notes: Yup.string().max(100, "* name too long"),
  // name_on_credit_card: Yup.string()
  //   .required("* Credit Card name is required")
  //   .max(15, "* name  too long"),
  // credit_card_number: Yup.string()
  //   .required("* Credit Card is required")
  //   .max(8, "* Number exceeded"),
  // security_code: Yup.string()
  //   .required("* Security Code is required")
  //   .max(3, "* Number exceeded"),
  // expiration_date: Yup.string().required("* Date is required"),
  // billing_zip: Yup.string().required("* Zip code is required"),
  // subscription_start_date: Yup.string().required("* Security Code is required")
});
interface EditGeneralInfoProps {}

interface EditGeneralInfoState {
  editPassword: boolean;
  edit: boolean;
}
interface MyFormikProps {
  store_name: string;
  store_address: string;
  store_city: string;
  store_state: string;
  store_zip: string;
  store_phone_number: string;
  admin_email: string;
  barcode_type: string;
}

type Props = EditGeneralInfoProps & LinkStateProps & LinkDispatchProps;

class EditGeneralInfo extends React.Component<Props, EditGeneralInfoState> {
  constructor(props: Props) {
    super(props);

    this.state = {
      editPassword: false,
      edit: false,
    };
  }
  toggleNewPassword = () => {
    this.setState((prev) => ({ editPassword: !prev.editPassword }));
  };
  toggleEdit = () => this.setState((prev) => ({ edit: !prev.edit }));

  render() {
    const { edit } = this.state;
    const {
      store_name,
      admin_email,
      store_address,
      store_city,
      store_state,
      store_zip,
      store_phone,
      barcode_type,
    } = this.props;
    return (
      <Formik
        initialValues={{
          store_name: store_name ? store_name : "",
          store_address: store_address ? store_address : "",
          store_city: store_city ? store_city : "",
          store_state: store_state ? store_state : "",
          store_zip: store_zip ? store_zip : "",
          store_phone_number: store_phone ? store_phone : "",
          admin_email: admin_email ? admin_email : "",
          barcode_type: barcode_type ? barcode_type : "",
        }}
        validationSchema={onboardSchema}
        onSubmit={async (values, { setFieldError, setStatus }) => {
          setStatus({ error: "", loading: true });
          setStatus({ loading: false });
          const { user_id, store_id, token } = this.props;
          const {
            admin_email,
            store_address,
            store_zip,
            store_state,
            store_city,
            store_name,
            store_phone_number,
            barcode_type,
          } = values;
          // this.props.signup({
          //   first_name: values.admin_name.split(" ")["0"],
          //   last_name: values.admin_name.split(" ")["1"],
          //   email: values.admin_email,
          //   store_name: values.store_name,
          //   nexmo_num: values.nexmo_number
          // });
          try {
            if (this.props.admin_email !== admin_email) {
              await api.editUser(
                { user_id: user_id, email: admin_email },
                token as string
              );
            }
            const store = await api.editStore(
              {
                store_id: store_id,
                name: store_name,
                address_1: store_address,
                city:
                  store_city !== this.props.store_city ? store_city : undefined,
                state:
                  store_state !== this.props.store_city
                    ? store_state
                    : undefined,
                zip: store_zip !== this.props.store_zip ? store_zip : undefined,
                phone: store_phone_number,
                barcode_type: barcode_type,
              },
              token as string
            );
            this.props.getStore(store as UserStore);
          } catch (err) {
            console.log(err);
          }
        }}
      >
        {({
          status,
          setStatus,
          submitForm,
          touched,
          errors,
        }: FormikProps<MyFormikProps>) => {
          return (
            <ClientSetupContainer>
              <EditPassword
                showModal={this.state.editPassword}
                toggleModal={this.toggleNewPassword}
              />
              <AccountSetupText>GENERAL INFO</AccountSetupText>
              <Div>
                <Grid>
                  <LeftGrid>
                    <OnboardInputField
                      name="store_name"
                      placeholder=""
                      title="Store Name"
                      disabled={!this.state.edit}
                    />
                    <Div style={{ marginBottom: "40px" }} />
                    <div>
                      <OnboardInputField
                        name="store_address"
                        placeholder=""
                        title="Store Address"
                        disabled={!this.state.edit}
                      />
                      <OnboardInputField
                        name="store_city"
                        placeholder=""
                        title="City"
                        inputWidth="40"
                        disabled={!this.state.edit}
                      />
                      <OnboardInputField
                        name="store_state"
                        placeholder=""
                        title="State"
                        inputWidth="20"
                        disabled={!this.state.edit}
                      />
                      <OnboardInputField
                        name="store_zip"
                        placeholder=""
                        title="Zipcode"
                        inputWidth="20"
                        disabled={!this.state.edit}
                      />
                    </div>
                    <Div style={{ marginBottom: "40px" }} />
                    <OnboardInputField
                      name="store_phone_number"
                      placeholder=""
                      title="Store Phone Number"
                      disabled={!this.state.edit}
                    />
                    <Div style={{ marginBottom: "40px" }} />
                    {!edit ? (
                      <OnboardInputField
                        name="barcode_type"
                        placeholder=""
                        title="Barcode Type"
                        disabled={!this.state.edit}
                      />
                    ) : (
                      <SelectField
                        name="barcode_type"
                        placeholder=""
                        title="Barcode Types"
                        options={barcode}
                      />
                    )}
                  </LeftGrid>
                  <RightGrid>
                    <OnboardInputField
                      name="admin_email"
                      placeholder=""
                      title="Email"
                      disabled={!this.state.edit}
                    />
                    <Div style={{ marginBottom: "40px" }} />
                    <OnboardInputField
                      name="password"
                      placeholder=""
                      title="Password"
                      disabled={!this.state.edit}
                    />

                    <UpdatePassword onClick={this.toggleNewPassword}>
                      UPDATE PASSWORD
                    </UpdatePassword>
                    <Div style={{ marginBottom: "50px" }} />

                    <Div style={{ marginBottom: "50px" }} />
                  </RightGrid>
                </Grid>
              </Div>
              <ButtonDiv>
                {edit ? (
                  <>
                    <AuthButton
                      type="submit"
                      style={{ justifySelf: "right" }}
                      onClick={this.toggleEdit}
                      // disabled={
                      //   !(Object.keys(touched).length && !Object.keys(errors).length)
                      // }
                    >
                      Cancel
                    </AuthButton>
                    <AuthButton
                      // type="submit"
                      style={{ justifySelf: "right" }}
                      onClick={submitForm}
                      // disabled={
                      //   !(Object.keys(touched).length && !Object.keys(errors).length)
                      // }
                    >
                      SAVE
                    </AuthButton>
                  </>
                ) : (
                  <>
                    <AuthButton
                      type="submit"
                      style={{ justifySelf: "right" }}
                      onClick={this.toggleEdit}
                      // disabled={
                      //   !(Object.keys(touched).length && !Object.keys(errors).length)
                      // }
                    >
                      EDIT
                    </AuthButton>
                    <NavLink to="/settings">
                      <AuthButton
                        // type="submit"
                        style={{ justifySelf: "right" }}
                        // onClick={submitForm}
                        // disabled={
                        //   !(Object.keys(touched).length && !Object.keys(errors).length)
                        // }
                      >
                        RETURN TO SETTINGS
                      </AuthButton>
                    </NavLink>
                  </>
                )}
              </ButtonDiv>
            </ClientSetupContainer>
          );
        }}
      </Formik>
    );
  }
}

const ClientSetupContainer = styled(Container)`
  background-image: linear-gradient(to left, #4ba5eb, #60999e);
  padding: 53px 50px 45px 75px;
  display: grid;
  grid-template-rows: 130px 1fr 1fr;
  background-size: cover;
  background-attachment: fixed;
  background-position: center center;
  overflow-x: auto;
  height: 100vh;
`;
const AccountSetupText = styled(AppText)`
  font-size: 27px;
  color: #fff;
`;

const StyledOnboardInputField = styled(OnboardInputField)`
  border-bottom: transparent;
`;
const TitleSetupText = styled(AppText)`
  font-size: 15px;
  color: #fff;
`;
const LeftGrid = styled.div`
  display: grid;
  grid-column: 1;
  /* align-content: center; */
  justify-content: flex-start;
  padding-bottom: 60px;
  gap: 10px;
`;

const RightGrid = styled.div`
  display: grid;
  grid-column: 2;
  /* align-content: center; */
  justify-content: flex-start;
  padding-bottom: 60px;
  gap: 10px;
`;

const Grid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  padding-bottom: 0px;
`;
const Div = styled.div`
  display: grid;
  grid-template-rows: auto min-content;
`;

const SubscriptionGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column: 1;
  /* margin-bottom: 165px; */
`;

const SubLeft = styled.div`
  padding-right: 50px;
`;

const ButtonDiv = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
`;
const SubRight = styled.div``;

const UpdatePassword = styled.button`
  background-color: transparent;
  border: solid 1px white;
  height: 40px;
  width: 163px;
  border-radius: 2px;
  font-size: 13px;
  font-weight: 500;
  font-family: Avenir, Lato, sans-serif;
  color: white;
  :focus {
    outline: none;
  }
  :active {
    box-shadow: 0 3px 0 #dedfe2;
    top: 3px;
    transform: translateY(2px);
    background-color: #ffff;
    border: solid 1px white;
    font-size: 13px;
    font-weight: 500;
    font-family: Avenir, Lato, sans-serif;
    color: gray;
    /* opacity: 0.1; */
  }
`;

interface LinkStateProps {
  token: string;
  admin_email: string;
  user_id: number;
  store_name: string;
  store_phone: string;
  store_address: string;
  store_city: string;
  store_state: string;
  store_zip: string;
  store_id: number;
  barcode_type: string;
}

interface LinkDispatchProps {
  // signup: ({
  //   first_name,
  //   last_name,
  //   email,
  //   store_name,
  //   nexmo_num
  // }: {
  //   first_name: string;
  //   last_name: string;
  //   email: string;
  //   store_name: string;
  //   nexmo_num: string;
  // }) => void;
  getStore: (store: UserStore) => void;
}

const mapDispatchToProps = (
  dispatch: Dispatch<AppActions>
): LinkDispatchProps => ({
  getStore: bindActionCreators(getStore, dispatch),
  // signup: bindActionCreators(registerAdminThunk, dispatch)
});

const mapStateToProps = (state: AppState): LinkStateProps => ({
  token: state.auth.token as string,
  admin_email: state.user.email as string,
  user_id: state.user.id as number,
  store_name: state.store.name as string,
  store_phone: state.store.phone as string,
  store_address: state.store.address.address_1 as string,
  store_city: state.store.address.city,
  store_state: state.store.address.state,
  store_zip: state.store.address.zip,
  store_id: state.user.store_id as number,
  barcode_type: state.store.barcode_type as string,
});

export default connect(mapStateToProps, mapDispatchToProps)(EditGeneralInfo);
