import * as React from "react";
import styled from "styled-components";

interface DynamicTableContainerProps {}

interface DynamicTableContainerState {}

class DynamicTableContainer extends React.Component<
  DynamicTableContainerProps,
  DynamicTableContainerState
> {
  constructor(props: DynamicTableContainerProps) {
    super(props);
    this.state = {};
  }
  render(): any {
    return;
  }
}

export { DynamicTableContainer };
