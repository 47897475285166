import * as React from "react";
import styled from "styled-components";

interface ColorBoxProps {
  height?: string;
  width?: string;
  backgroundColor;
}

const ColorBox = styled.div<ColorBoxProps>`
  width: ${props => props.width};
  height: ${props => props.height};
  background-color: ${props => props.backgroundColor};
`;

ColorBox.defaultProps = {
  width: "28px",
  height: "28px",
  backgroundColor: "#ffffff"
};

export { ColorBox };
