import styled from "styled-components";

interface CardProps {
  cardWidth?: string;
  padding?: string;
  minHeight?: string;
}

export const Card = styled.div<CardProps>`
  color: ${props => props.theme.TITLE_COLOR};
  font-family: ${props => props.theme.PRIMARY_FONT};
  width: ${(props: CardProps) => props.cardWidth};
  height: auto;
  min-height: ${props => props.minHeight};
  background-color: #ffffff;
  padding: ${(props: CardProps) => props.padding};
  border-radius: 3.6px;
  box-sizing: border-box;
`;

Card.defaultProps = {
  cardWidth: "auto",
  padding: "26px",
  minHeight: "auto"
};
