// * This is the about page

import * as React from "react";
import ReactModal from "react-modal";
import styled from "styled-components";

export interface ModalProps {
  showModal: boolean;
  label: string;
  toggleModal: () => void;
  children: React.ReactNode;
  height?: string;
  width?: string;
  zIndex?: string;
}

export interface ModalState {}

class Modal extends React.Component<ModalProps, ModalState> {
  public render(): JSX.Element {
    const {
      showModal,
      label,
      toggleModal,
      children,
      height,
      width,
      zIndex,
      ...props
    } = this.props;

    return (
      <StyledModal
        shouldCloseOnOverlayClick={true}
        isOpen={showModal}
        contentLabel={label}
        onRequestClose={toggleModal}
        ariaHideApp={false}
        height={height}
        width={width}
        zIndex={zIndex}
        {...props}
      >
        {children}
      </StyledModal>
    );
  }
}

function ReactModalAdapter({ className, children, ...props }: any) {
  const contentClassName = `${className}__content`;
  const overlayClassName = `${className}__overlay`;
  return (
    <ReactModal
      portalClassName={className}
      className={contentClassName}
      overlayClassName={overlayClassName}
      {...props}
      children={children}
    />
  );
}

interface AdditionalModalProps {
  width?: "903px";
  height?: "475px";
  zIndex?: "1";
}

const StyledModal = styled(ReactModalAdapter)<AdditionalModalProps>`
  &__overlay {
    position: fixed;
    top: 0px;
    left: 0px;
    right: 0px;
    bottom: 0px;
    background-color: rgba(0, 0, 0, 0.15);
    z-index: ${props => props.zIndex};
  }
  &__content {
    width: ${props => props.width};
    height: ${props => props.height};
    margin: auto;
    background: #fff;
    overflow: auto;
    -webkit-overflow-scrolling: touch;
    box-shadow: inset 0 4px 0 0 #60999e;
    border-radius: 2px;
    margin-top: 10%;
    margin-bottom: 10%;
    :focus {
      outline: none;
    }
    @media (max-height: 789px) {
      max-height: 600px;
      overflow: auto;
    }
  }
`;

StyledModal.defaultProps = {
  width: "fit-content",
  height: "fit-content"
};

export { Modal };
