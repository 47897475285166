import * as React from "react";
import styled from "styled-components";
import { Container, AppText } from "../../UI";
import { AuthButton } from "../NewClientSetup";
import { Link, NavLink } from "react-router-dom";
import { connect } from "react-redux";
import { Dispatch, bindActionCreators } from "redux";
import { AppActions } from "../../../types";
import { logout } from "../../../actions";

interface ProfileProps {
  logout: () => void;
}

interface ProfileState {}

class Profile extends React.Component<ProfileProps, ProfileState> {
  render(): JSX.Element {
    return (
      <SettingsContainer>
        <AccountSetupText>SETTINGS</AccountSetupText>
        <Div>
          <Grid>
            <LeftGrid>
              <Link to="/settings/general_info">
                <TitleSetupText>GENERAL INFO</TitleSetupText>
              </Link>
              <Div style={{ marginBottom: "40px" }} />
              <Link to="/settings/my_communication">
                <TitleSetupText>MY COMMUNICATION PREFERENCES</TitleSetupText>
              </Link>
              <Div style={{ marginBottom: "40px" }} />

              <Link to="/settings/customer_communication">
                <TitleSetupText>CUSTOMER COMMUNICATION SETTINGS</TitleSetupText>
              </Link>
              <Div style={{ marginBottom: "40px" }} />
              <Link to="/settings/mobile_app">
                <TitleSetupText>MOBILE APP SETTINGS</TitleSetupText>
              </Link>
              <Div style={{ marginBottom: "40px" }} />
              <Link to="/settings/custom_properties">
                <TitleSetupText>MANAGE CUSTOM PROPERTIES</TitleSetupText>
              </Link>
              <Div style={{ marginBottom: "40px" }} />
              <TitleSetupText
                onClick={this.props.logout}
                style={{ cursor: "pointer" }}
              >
                LOGOUT
              </TitleSetupText>

              {/* <OnboardInputField
                      name="ach_number"
                      placeholder=""
                      title="ACH Number"
                    /> */}
              {/* <Collapsible title={"POS"} /> */}
            </LeftGrid>
            <RightGrid />
          </Grid>
        </Div>
        <ButtonDiv>
          <NavLink to="/dashboard">
            <AuthButton type="submit" style={{ justifySelf: "left" }}>
              Return to Dashboard
            </AuthButton>
          </NavLink>
          <Div style={{ marginBottom: "40px" }} />
        </ButtonDiv>
      </SettingsContainer>
    );
  }
}

const SettingsContainer = styled(Container)`
  background-image: linear-gradient(to left, #4ba5eb, #60999e);
  padding: 53px 50px 45px 75px;
  display: grid;
  grid-template-rows: 120px 1fr 1fr;
  background-size: cover;
  background-attachment: fixed;
  background-position: center center;
  overflow-x: auto;
  height: 100vh;
  width: 100vw;
`;
const AccountSetupText = styled(AppText)`
  font-size: 27px;
  color: #fff;
  margin-bottom: 50px;
`;

const TitleSetupText = styled(AppText)`
  font-size: 20px;
  font-weight: 200px;
  color: #fff;
  :active {
    transform: translateY(2px);
  }
`;
const LeftGrid = styled.div`
  display: grid;
  grid-column: 1;
  align-content: center;
  justify-content: start;
  padding-bottom: 60px;
  gap: 10px;
`;

const RightGrid = styled.div`
  display: grid;
  align-content: flex-start;
  justify-content: left;
  padding-bottom: 60px;
`;

const Grid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  padding-bottom: 0px;
`;

const ButtonDiv = styled.div`
  display: flex;
  align-items: flex-end;
`;
const Div = styled.div`
  display: grid;
  grid-template-rows: auto min-content;
`;

interface LinkDispatchProps {
  logout: () => void;
}

const mapDispatchToProps = (
  dispatch: Dispatch<AppActions>
): LinkDispatchProps => ({
  logout: bindActionCreators(logout, dispatch)
});
export default connect(
  null,
  mapDispatchToProps
)(Profile);
