import { GET_STORE, UPDATE_STORE } from "../types";
import { UserStore } from "../types/store";

export const getStore = (store: UserStore) => ({
  type: GET_STORE,
  store
});

export const updateStore = (store: UserStore) => ({
  type: UPDATE_STORE,
  store
});
