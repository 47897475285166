import * as React from "react";
import { connect } from "react-redux";
import { Spinner, AppText, Button, BasicButton } from "../../UI";
import { AppState } from "../../../store/configureStore";
import { withRouter, RouteComponentProps } from "react-router";
import { toast } from "react-toastify";
import {
  AppActions,
  LoggedUser,
  ItemList,
  OrderType,
  Driver,
  DeliveryRuns,
  DeliveryDriver,
  ImportedDeliveryRun,
} from "../../../types";
import { Dispatch, bindActionCreators } from "redux";
import HereMaps from "../../Dumb/HereMap/HereMaps";
import { api } from "../../../api/api";
import styled from "styled-components";
import {
  updateMapViewState,
  clearDeliveryRunOrders,
  addCreateDeliveryRunDrivers,
  updateSelectedDeliveryRunOrder,
  addToSelectedOrders,
  deleteFromSelectedOrders,
  updateSelectState,
  addNewDeliveryRun,
  addToDeliveryRun,
  updateExistingDeliveryRun,
  addCreateDeliveryRunOrders,
} from "../../../actions/createDeliveryRun";
import {
  avgLocation,
  formatCurrentTime,
  formatGivenTime,
  formatCurrentDate,
  formatGivenDate,
  PRIMARY_COLOR,
} from "../../../utils";
import moment from "moment";
import CreateDeliveryRunTable from "../../Dumb/HereMap/CreateDeliveryRunTable";
import DeliveryRunModal from "../../modal/DeliveryRunModal";
import ExitOrderModal from "../../modal/ExitOrderModal";
import { clearDashboardState } from "../../../actions";
import { OrderList } from "../../../api/responseTypes";
import { EmptyCard } from "../../Dumb";
import OrderCard from "../../Segments/OrderCard";
import DriverCard from "../../Segments/DriverCard";
interface CreateDeliveryRunProps {
  changeMapState: () => void;
  lat: number;
  lng: number;
  viewAllOrders: boolean;
}

interface CreateDeliveryRunState {
  toggleDeliveryRunModal: boolean;
  toggleExitOrderModal: boolean;
  modal_route_selection_method: string;
  modal_startTime_day: string;
  modal_startTime_date: string;
  modal_orders: OrderType[];
  modal_delivery: DeliveryRuns;
  modal_driver_id: number;
  modal_travel_time: string;
  confirmationPage: boolean;
  averageLat: number;
  averageLng: number;
  ordersSelected: number[];
  ordersLoading: boolean;
}

type Props = RouteComponentProps<{}> &
  CreateDeliveryRunProps &
  LinkStateProps &
  LinkDispatchProps;

class CreateDeliveryRun extends React.Component<Props, CreateDeliveryRunState> {
  constructor(props: Props) {
    super(props);
    this.state = {
      toggleDeliveryRunModal: false,
      toggleExitOrderModal: false,
      modal_route_selection_method: "",
      modal_startTime_day: "",
      modal_startTime_date: "",
      modal_orders: [],
      modal_driver_id: 0,
      modal_delivery: {} as DeliveryRuns,
      modal_travel_time: "",
      confirmationPage: false,
      averageLat: 0,
      averageLng: 0,
      ordersSelected: [],
      ordersLoading: false,
    };
  }
  componentDidMount = async () => {
    const {
      updateMapViewState,
      orders,
      viewAllOrders,
      store_id,
      token,
      addCreateDeliveryRunDrivers,
      mapOrders,
      addCreateDeliveryRunOrders,
    } = this.props;
    this.setState({
      averageLat: avgLocation(mapOrders)[0],
      averageLng: avgLocation(mapOrders)[1],
    });
    const drivers = await api.getDrivers(
      { store_id, page: 1, limit: "1000" },
      token
    );
    const active_drivers = drivers.data
      .filter((drivers) => drivers.is_active === 1)
      .map((drivers) => {
        return {
          id: drivers.id,
          first_name: drivers.first_name,
          last_name: drivers.last_name,
          deliveryRuns: [] as DeliveryRuns[],
        };
      }) as DeliveryDriver[];
    addCreateDeliveryRunDrivers(active_drivers);
    if (!!viewAllOrders) {
      this.setState({
        ordersLoading: true,
      });
      const orders = (await api.getOrders(
        { store_id, page: 1, limit: "5000" },
        token
      )) as OrderList;
      addCreateDeliveryRunOrders(orders.data);
      this.setState({
        ordersLoading: false,
      });
    }
  };
  exitCreateDeliveryRun = () => {
    const { updateMapViewState, clearDeliveryRunOrders } = this.props;
    updateMapViewState();
    clearDeliveryRunOrders();
  };
  onOrderSelect = (orderID: number) => {
    const {
      updateSelectedDeliveryRunOrder,
      addToSelectedOrders,
      deleteFromSelectedOrders,
      orders,
      selectMode,
    } = this.props;
    if (!selectMode) {
      updateSelectedDeliveryRunOrder(orderID);
      const orderSelectionCheck = orders.filter(
        (order) => order.id === orderID
      )[0];
      if (!orderSelectionCheck.selected) {
        addToSelectedOrders(orderID);
      } else {
        deleteFromSelectedOrders(orderID);
      }
    }
  };
  toggleDeliveryRunModal = () => {
    this.setState((prev) => ({
      toggleDeliveryRunModal: !prev.toggleDeliveryRunModal,
    }));
  };
  toggleExitOrderModal = () => {
    this.setState((prev) => ({
      toggleExitOrderModal: !prev.toggleExitOrderModal,
    }));
  };
  driverSelectToast = () => {
    return toast.success("Added New Delivery Run!", {
      position: "top-right",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: false,
      draggable: true,
    });
  };
  deliveryRunSelectToast = (deliveryRunID) => {
    return toast.success(`"Added To ${deliveryRunID}!"`, {
      position: "top-right",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: false,
      draggable: true,
    });
  };
  onDriverSelect = (driverID: number) => {
    const {
      selectedOrders,
      addNewDeliveryRun,
      selectMode,
      updateSelectState,
    } = this.props;
    if (selectMode) {
      if (selectedOrders.length > 0) {
        addNewDeliveryRun(driverID, selectedOrders);
        updateSelectState();
        this.driverSelectToast();
      } else {
        // set up alert toast
        console.log("NO ORDERS SELECTED!");
      }
    }
    if (!selectMode) {
      console.log("Go to driver!");
    }
  };
  onDeliveryRunSelect = async (
    driverID: number,
    deliveryRunID: string,
    deliveryRunLabel: string,
    delivery: DeliveryRuns
  ) => {
    const {
      selectedOrders,
      addToDeliveryRun,
      selectMode,
      updateSelectState,
      token,
    } = this.props;

    if (selectMode) {
      if (selectedOrders.length > 0) {
        addToDeliveryRun(driverID, deliveryRunID, selectedOrders);
        updateSelectState();
        this.deliveryRunSelectToast(deliveryRunLabel);
      }
    } else {
      console.log("DELIVERY RUN SELECT RAN??");
      //dispatch selected delivery
      let order_ids = [] as number[];
      delivery.deliveries.forEach((order) => order_ids.push(order.id));
      const data =
        order_ids.length > 0
          ? ((await api.calculateRoute(
              order_ids as number[],
              token as string
            )) as any)
          : "No orders to check";
      this.setState({
        modal_driver_id: delivery.driver_id,
        modal_route_selection_method: delivery.route_selection_method,
        modal_startTime_date: delivery.schedule_date,
        modal_startTime_day: delivery.schedule_day,
        modal_delivery: delivery,
        modal_travel_time: data.summary ? data.summary.trafficTime : data,
      });
      //show delivery run modal
      this.toggleDeliveryRunModal();
    }
  };

  submitDeliveryRuns = async () => {
    const { drivers, token, clearDashboardState } = this.props;
    try {
      drivers.forEach((driver) => {
        if (driver.deliveryRuns.length > 0) {
          driver.deliveryRuns.forEach(async (delivery) => {
            const currentTime =
              formatGivenDate(delivery.schedule_date) +
              " " +
              formatGivenTime(delivery.schedule_day);
            const endTime = `${formatCurrentDate()} ${formatCurrentTime()}`;
            let totalCases = 0;
            let order_ids = "";
            delivery.deliveries.forEach(async (order) => {
              totalCases += order.num_boxes as number;
              order_ids += `&order_ids[]=${order.id}`;
            });
            if (delivery.new_delivery) {
              console.log("currentTime", currentTime);
              await api.createDeliveryRuns(
                {
                  driver_id: driver.id,
                  totalCases: totalCases,
                  route_selection_method: "optimized",
                  startTime: currentTime,
                  status: delivery.inactive ? "scheduled" : "active",
                  order_ids: order_ids,
                  endTime: endTime,
                },
                token
              );
            } else {
              await api.updateDeliveryRun(
                {
                  order_ids: order_ids,
                  delivery_run_id: delivery.delivery_id as number,
                },
                token
              );
            }
          });
        }
      });
      clearDashboardState();
      this.props.history.push("/dashboard");
      this.exitCreateDeliveryRun();
    } catch (error) {
      console.log(error);
    }
  };
  render() {
    const {
      updateMapViewState,
      mapOrders,
      orders,
      avgLocation,
      drivers,
      updateSelectedDeliveryRunOrder,
      selectedOrders,
      selectMode,
      updateSelectState,
      addNewDeliveryRun,
      updateExistingDeliveryRun,
    } = this.props;
    console.log("createdelivery run state----", this.state);

    return (
      <CreateDeliveryRunContainer>
        <div>
          {this.state.ordersLoading && (
            <ShadowDiv>
              <LoadingDiv>
                <LoadingText>Orders Loading</LoadingText>
                <Spinner />
              </LoadingDiv>
            </ShadowDiv>
          )}
        </div>
        <DeliveryRunModal
          driver_id={this.state.modal_driver_id}
          route_selection_method={this.state.modal_route_selection_method}
          start_day={this.state.modal_startTime_day}
          start_date={this.state.modal_startTime_date}
          orders={this.state.modal_orders}
          delivery={this.state.modal_delivery}
          showModal={this.state.toggleDeliveryRunModal}
          toggleModal={this.toggleDeliveryRunModal}
          travel_time={this.state.modal_travel_time}
        />
        <ExitOrderModal
          showModal={this.state.toggleExitOrderModal}
          toggleModal={this.toggleExitOrderModal}
          exitCreateDeliveryRun={this.exitCreateDeliveryRun}
        />
        {selectMode ? (
          <BackButton width="130px" onClick={updateSelectState}>
            Cancel Assign
          </BackButton>
        ) : (
          <BackButton width="130px" onClick={() => this.toggleExitOrderModal()}>
            Back to Order List
          </BackButton>
        )}
        <OrderBox>
          {selectedOrders.length > 0 && (
            <AssignButton
              width="236px"
              onClick={selectMode ? () => {} : updateSelectState}
            >
              Assign Orders to Delivery
            </AssignButton>
          )}
          <CreateDeliveryRunTable title={"ORDERS"} emptyState={!orders.length}>
            {orders && !!orders.length ? (
              orders.map((order) => {
                return (
                  <div
                    key={order.id}
                    onClick={() => this.onOrderSelect(order.id)}
                  >
                    <OrderCard
                      key={order.id}
                      recieved={
                        order.date_imported ? order.date_imported : "Manual"
                      }
                      cases={order.num_boxes ? order.num_boxes : 0}
                      {...order.customer}
                      {...order.address}
                      {...order}
                    />
                  </div>
                );
              })
            ) : (
              <EmptyCard
                primaryText="All Orders Have Been Assigned To a Delivery Run"
                secondaryText="Click Save Delivery Runs to Proceed"
              />
            )}
          </CreateDeliveryRunTable>
        </OrderBox>
        <MapContainer>
          <StyledHereMaps
            lat={avgLocation[0]}
            lng={avgLocation[1]}
            zoom={12}
            orders={orders}
          />
        </MapContainer>
        <DriverBox selectMode={selectMode}>
          <CreateDeliveryRunTable
            title={"DRIVERS"}
            selectMode={selectMode}
            emptyState={drivers.length > 0 ? false : true}
          >
            {drivers &&
              drivers.map((driver) => {
                return (
                  <div key={driver.id}>
                    <DriverCard
                      key={driver.id}
                      delivery_runs={driver.deliveryRuns}
                      onDriverSelect={() => this.onDriverSelect(driver.id)}
                      {...driver}
                      onDeliveryRunSelect={this.onDeliveryRunSelect}
                      updateDeliveryRun={
                        updateExistingDeliveryRun as (
                          deliveryRun: ImportedDeliveryRun
                        ) => void
                      }
                      selectMode={selectMode}
                      fromDashboard={false}
                    />
                  </div>
                );
              })}
          </CreateDeliveryRunTable>
          <SubmitButton onClick={this.submitDeliveryRuns}>
            Save Delivery Runs
          </SubmitButton>
        </DriverBox>
      </CreateDeliveryRunContainer>
    );
  }
}

interface DriverBox {
  selectMode: boolean;
}
const CreateDeliveryRunContainer = styled.div`
  display: flex;
`;

const LoadingText = styled(AppText)`
  font-size: 20;
  font-weight: 600;
  color: ${PRIMARY_COLOR};
  padding-top: 20px;
`;

const LoadingDiv = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: white;
  height: 150px;
  width: 300px;
  border-radius: 5px;
  box-shadow: 2px 1px 4px 0 rgba(59, 58, 58, 0.5);
`;

const ShadowDiv = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 70;
  position: absolute;
  width: 100%;
  height: 100%;
  box-shadow: inset 0 0 600px black;
`;

const MapContainer = styled.div`
  /* position: absolute; */
  padding-top: 60px;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
`;

const StyledHereMaps = styled(HereMaps)`
  /* position: absolute; */
  z-index: 0;
  top: 80px;
`;
const OrderBox = styled.div`
  /* position: absolute; */
  z-index: 2;
  padding-top: 60px;
  height: calc(100vh);
`;
const DriverBox = styled.div<DriverBox>`
  padding-top: 60px;
  height: calc(100vh);
  z-index: 50;
  transition: box-shadow 0.1s ease;
  box-shadow: ${(props) =>
    props.selectMode ? "0 0 0 2000px rgba(0, 0, 0, 0.65)" : ""};
`;

const BackButton = styled(BasicButton)`
  position: absolute;
  top: 90%;
  left: 50%;
  z-index: 60;
  margin-left: -65px;
  /* :active {
    box-shadow: 0 3px 0 #7bc1c7;
    top: 90.1%;
    transform: translateY(2px);
  } */
`;

const AssignButton = styled(BasicButton)`
  position: absolute;
  bottom: 0;
  z-index: 50;
  /* :active {
    box-shadow: 0 3px 0 #7bc1c7;
    top: 95.8%;
    transform: translateY(2px);
  } */
`;
const SubmitButton = styled(BasicButton)`
  position: absolute;
  bottom: 0;
  right: 0;
  z-index: 50;
  /* :active {
    box-shadow: 0 3px 0 #7bc1c7;
    top: 95.2%;
    transform: translateY(2px);
  } */
`;

interface LinkStateProps {
  token: string;
  store_id: number;
  orders: OrderType[];
  avgLocation: number[];
  drivers: DeliveryDriver[];
  selectedOrders: number[];
  selectMode: boolean;
  mapOrders: OrderType[];
}

interface LinkDispatchProps {
  updateMapViewState: () => void;
  clearDeliveryRunOrders: () => void;
  addCreateDeliveryRunDrivers: (drivers: DeliveryDriver[]) => void;
  updateSelectedDeliveryRunOrder: (orderID: number) => void;
  addToSelectedOrders: (orderID: number) => void;
  deleteFromSelectedOrders: (orderID: number) => void;
  updateSelectState: () => void;
  addNewDeliveryRun: (driverID: number, orderList: number[]) => void;
  addToDeliveryRun: (
    driverID: number,
    deliveryRunID: string,
    orderList: number[]
  ) => void;
  updateExistingDeliveryRun: (delivery_runs: ImportedDeliveryRun) => void;
  clearDashboardState: () => void;
  addCreateDeliveryRunOrders: (orders: OrderType[]) => void;
}

const mapDispatchToProps = (
  dispatch: Dispatch<AppActions>
): LinkDispatchProps => ({
  updateMapViewState: bindActionCreators(updateMapViewState, dispatch),
  clearDeliveryRunOrders: bindActionCreators(clearDeliveryRunOrders, dispatch),
  addCreateDeliveryRunDrivers: bindActionCreators(
    addCreateDeliveryRunDrivers,
    dispatch
  ),
  updateSelectedDeliveryRunOrder: bindActionCreators(
    updateSelectedDeliveryRunOrder,
    dispatch
  ),
  addToSelectedOrders: bindActionCreators(addToSelectedOrders, dispatch),
  deleteFromSelectedOrders: bindActionCreators(
    deleteFromSelectedOrders,
    dispatch
  ),
  updateSelectState: bindActionCreators(updateSelectState, dispatch),
  addNewDeliveryRun: bindActionCreators(addNewDeliveryRun, dispatch),
  addToDeliveryRun: bindActionCreators(addToDeliveryRun, dispatch),
  updateExistingDeliveryRun: bindActionCreators(
    updateExistingDeliveryRun,
    dispatch
  ),
  clearDashboardState: bindActionCreators(clearDashboardState, dispatch),
  addCreateDeliveryRunOrders: bindActionCreators(
    addCreateDeliveryRunOrders,
    dispatch
  ),
});

const mapStateToProps = (state: AppState): LinkStateProps => ({
  store_id: state.user.store_id as number,
  token: state.auth.token as string,
  orders: state.createDeliveryRun.orders,
  avgLocation: state.createDeliveryRun.avgLoc,
  drivers: state.createDeliveryRun.drivers,
  selectedOrders: state.createDeliveryRun.orderList,
  selectMode: state.createDeliveryRun.selectMode,
  mapOrders: state.createDeliveryRun.mapOrders,
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(CreateDeliveryRun));
