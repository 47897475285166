import * as React from "react";
import { connect } from "react-redux";
import { Container, Spinner, AppText, PageLoadSpinner } from "../UI";
import {
  DynamicTable,
  ColumnType,
  SelectedMenuOption,
  ColumnColors,
  RowData,
} from "../Dumb";
import { AppState } from "../../store/configureStore";
import {
  AppActions,
  LoggedUser,
  ItemList,
  CustomFieldsList,
  CustomFields,
  CustomFilters,
  FilterField,
  MenuItem,
} from "../../types";
import { Dispatch, bindActionCreators } from "redux";
import {
  fetchDeliveryProperties,
  updateDeliveryInitialLoad,
  addDeliveryCustomFilters,
  updateDeliveryCustomListLoad,
  addDeliveryCustomFiltersField,
  updateDefaultDeliveryID,
  setDeliveryCustomFieldFilters,
} from "../../actions";
import { api } from "../../api/api";
import { DeliveryList, DeliveryResponse } from "../../api/responseTypes";
import styled from "styled-components";
import { getFilterType, debouncer, toastError } from "../../utils";

const EmptyGridDiv = styled.div`
  width: 100%;
  height: 200px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  border-width: 1px;
  border-style: solid;
  border-color: #000;
`;

const EmptyGrid = (
  <EmptyGridDiv>
    <AppText>You don't have any deliveries on this page</AppText>
  </EmptyGridDiv>
);

interface DeliveriesProps {
  store_id: number;
  token: string;
}

interface DeliveriesState {
  deliveries: DeliveryResponse[];
  loading: boolean;
  searchKeyword: string;
  currentPage: number;
  limit: string;
  from: number;
  to: number;
  total: number;
  per_page: number;
  list_id: number | null;
  item_list: [] | ItemList[];
  last_page: number | null;
  customFilters: CustomFilters[];
  changeListLoad: boolean;
  exporting: boolean;
}

export const delivery_field = [
  {
    label: "Notes",
    value: "notes",
    operator_type: "text",
    listValues: [] as any,
  },
  {
    label: "Minutes In Store",
    value: "minutes_in_store",
    operator_type: "numeric",
    listValues: [] as any,
  },
  {
    label: "Minutes in Transit",
    value: "minutes_in_transit",
    operator_type: "numeric",
    listValues: [] as any,
  },
  {
    label: "Date Added to Delivery Run",
    value: "date_added_to_delivery_run",
    operator_type: "text",
    listValues: [] as any,
  },
  {
    label: "Completed",
    value: "completed",
    operator_type: "dropdown",
    //TODO need to know what values this can be used for
    listValues: [] as any,
  },
  {
    label: "Status",
    value: "status",
    operator_type: "text",
    listValues: [] as any,
  },
];

type Props = DeliveriesProps &
  DeliveriesState &
  LinkDispatchProps &
  LinkStateProps;

class Deliveries extends React.Component<Props, DeliveriesState> {
  constructor(props: Props) {
    super(props);
    this.state = {
      deliveries: [],
      loading: true,
      searchKeyword: "",
      currentPage: 1,
      from: 1,
      to: 10,
      total: 0,
      per_page: 10,
      list_id: null,
      item_list: [],
      limit: "10",
      last_page: null,
      customFilters: [],
      changeListLoad: false,
      exporting: false,
    };
  }

  componentDidMount = async () => {
    const {
      store_id,
      token,
      fetchDeliveryProperties,
      addDeliveryCustomFiltersField,
      setDeliveryCustomFieldFilters,
      deliveryListLoaded,
      deliveryFilters,
    } = this.props;

    const propFilter = deliveryFilters
      ? deliveryFilters.map((field) => field.display)
      : [];

    this.setState({ loading: true });

    const deliveryColumnFilters = await api.getColumnFilters({
      entity: "Delivery",
      store_id: store_id,
      token: token,
    });
    console.log("deliveryColumnFilters", deliveryColumnFilters);

    setDeliveryCustomFieldFilters(deliveryColumnFilters.fields);

    //todo check fetch properties
    const initialCustomFields = (await api.getCustomField(
      { store_id: store_id, entity_name: "Delivery" },
      token
    )) as CustomFieldsList;

    fetchDeliveryProperties(initialCustomFields.data);

    let customFilters = initialCustomFields.data
      ? initialCustomFields.data.map((obj) => {
          return {
            display: obj.display,
            field: obj.slug,
            visible: false,
          };
        })
      : [];

    this.setState({
      customFilters: customFilters,
    });

    // const filtersToUse = this.state.customFilters.filter(
    //   (field) => !propFilter.includes(field.display)
    // );
    // this.addCustomList(filtersToUse, deliveryFilters);

    // append initialcustom fields to filter list
    let mappedDeliveryCustomFieldsFilter = initialCustomFields.data
      ? initialCustomFields.data.map((obj) => {
          return {
            label: obj.display,
            value: obj.slug,
            operator_type: getFilterType(obj.field_type_id) as string,
            listValues: obj.listValues,
          };
        })
      : [];
    const mappedDeliveryFieldsFilter = delivery_field.concat(
      mappedDeliveryCustomFieldsFilter
    );
    addDeliveryCustomFiltersField(mappedDeliveryFieldsFilter);

    //initial get all deliveries
    if (deliveryListLoaded) {
      const delivery = (await api.getDeliveries(
        { store_id, page: 1, limit: this.state.limit },
        token
      )) as DeliveryList;
      const itemList = (await api.getItemLists(
        { store_id: store_id, entity: "Delivery" },
        token
      )) as ItemList[];

      this.setState({
        deliveries: delivery.data,
        currentPage: delivery.current_page,
        loading: false,
        from: delivery.from,
        to: delivery.to,
        total: delivery.total,
        per_page: delivery.per_page,
        last_page: delivery.last_page,
        item_list: itemList,
      });
    }
  };

  componentDidUpdate = async (prevProps, prevState) => {
    const { store_id, token, deliveryListID, deliveryFilters } = this.props;

    if (this.props !== prevProps) {
      const delivery = (await api.getDeliveries(
        {
          store_id,
          page: 1,
          item_list: deliveryListID,
          limit: this.state.limit,
        },
        token
      )) as DeliveryList;
      const itemList = (await api.getItemLists(
        { store_id: store_id, entity: "Delivery" },
        token
      )) as ItemList[];
      this.setState({
        deliveries: delivery.data,
        currentPage: delivery.current_page,
        loading: false,
        from: delivery.from,
        to: delivery.to,
        total: delivery.total,
        per_page: delivery.per_page,
        last_page: delivery.last_page,
        item_list: itemList,
        changeListLoad: false,
      });
    }
    if (this.props.deliveryListID !== prevProps.deliveryListID) {
      //update delivery on deliveryListID change
      const delivery = (await api.getDeliveries(
        {
          store_id,
          page: 1,
          item_list: deliveryListID,
          limit: this.state.limit,
        },
        token
      )) as DeliveryList;
      const itemList = (await api.getItemLists(
        { store_id: store_id, entity: "Delivery" },
        token
      )) as ItemList[];
      this.setState({
        deliveries: delivery.data,
        currentPage: delivery.current_page,
        loading: false,
        from: delivery.from,
        to: delivery.to,
        total: delivery.total,
        per_page: delivery.per_page,
        last_page: delivery.last_page,
        item_list: itemList,
      });
    }
    if (this.props.custom_properties !== prevProps.custom_properties) {
      const intialCustomFields = (await api.getCustomField(
        { store_id: store_id, entity_name: "Delivery" },
        token
      )) as CustomFieldsList;
      let customFilters = intialCustomFields.data.map((obj) => {
        return {
          display: obj.display,
          field: obj.slug,
          visible: false,
        };
      });
      this.addCustomList(customFilters, deliveryFilters);
    }
    if (this.state.limit !== prevState.limit) {
      const delivery = (await api.getDeliveries(
        {
          store_id,
          page: 1,
          limit: this.state.limit,
          item_list: deliveryListID,
        },
        token
      )) as DeliveryList;
      // const itemList = (await api.getItemLists(
      //   { store_id: store_id, entity: "Delivery" },
      //   token
      // )) as ItemList[];
      this.setState({
        deliveries: delivery.data,
        currentPage: delivery.current_page,
        loading: false,
        from: delivery.from,
        to: delivery.to,
        total: delivery.total,
        per_page: delivery.per_page,
        last_page: delivery.last_page,

        // item_list: itemList
      });
    }
  };

  onColumnSave = async (filters: CustomFilters[]) => {
    const { store_id, token, setDeliveryCustomFieldFilters } = this.props;
    const deliveryColumnFilters = await api.editColumns({
      entity: "Delivery",
      store_id: store_id,
      filters: filters,
      token: token,
    });

    setDeliveryCustomFieldFilters(deliveryColumnFilters.fields);
  };

  changeListRenderState = () => {
    this.setState({
      changeListLoad: true,
    });
  };

  changeViewLimit = (limit: string) => {
    if (limit !== this.state.limit) {
      this.setState({
        limit: limit,
        changeListLoad: true,
      });
    }
  };

  changeSearchText = (searchKeyword: string) => {
    this.setState({ searchKeyword });
    debouncer(this.searchOnDebounce, 500);
  };

  searchOnDebounce = async () => {
    const { store_id, token, deliveryListID } = this.props;
    const { limit, searchKeyword } = this.state;
    this.setState({
      changeListLoad: true,
    });

    const deliveries = (await api.getDeliveries(
      {
        store_id: store_id,
        page: 1,
        limit: limit,
        item_list: deliveryListID,
        searched_text: searchKeyword,
      },
      token
    )) as DeliveryList;

    this.setState({
      deliveries: deliveries.data,
      currentPage: deliveries.current_page,
      loading: false,
      from: deliveries.from,
      to: deliveries.to,
      total: deliveries.total,
      last_page: deliveries.last_page,
      changeListLoad: false,
    });
  };

  getDeliveries = () => {
    const { deliveries, searchKeyword } = this.state;
    const { deliveryFilters } = this.props;

    const initialList = Array.isArray(deliveries) ? deliveries : [];
    // ? deliveries.filter(deliveries => {
    //     return Object.values(deliveries).filter(
    //       field =>
    //         !!field &&
    //         `${field}`.toLowerCase().includes(searchKeyword.toLowerCase())
    //     ).length;
    //   })
    // : [];

    let filter = [] as string[];

    deliveryFilters.forEach((obj) => {
      if (obj.visible === true) {
        filter.push(obj.field);
      }
      if (obj.field === "id") {
        filter.push(obj.field);
      }
    });
    const filteredDelivery = (obj) => {
      let result = {};

      for (let key in obj) {
        if (filter.includes(key)) {
          result[key] = obj[key];
        }
      }
      return result;
    };
    const finalList = initialList.map((obj) =>
      filteredDelivery(obj)
    ) as RowData[];
    return finalList;
  };

  updateDeliveries = async () => {
    const { store_id, token, deliveryListID } = this.props;
    //update delivery on deliveryListID change
    const delivery = (await api.getDeliveries(
      { store_id, page: 1, item_list: deliveryListID, limit: this.state.limit },
      token
    )) as DeliveryList;
    // const itemList = (await api.getItemLists(
    //   { store_id: store_id, entity: "Delivery" },
    //   token
    // )) as ItemList[];
    this.setState({
      deliveries: delivery.data,
      currentPage: delivery.current_page,
      loading: false,
      from: delivery.from,
      to: delivery.to,
      total: delivery.total,
      per_page: delivery.per_page,
      last_page: delivery.last_page,
    });
  };

  nextPage = async () => {
    const { store_id, token, deliveryListID } = this.props;
    const { to, total, currentPage, limit } = this.state;
    if (to < total) {
      this.setState({
        changeListLoad: true,
      });
      const delivery = (await api.getDeliveries(
        {
          store_id: store_id,
          page: currentPage + 1,
          limit: limit,
          item_list: deliveryListID,
        },
        token
      )) as DeliveryList;
      this.setState({
        deliveries: delivery.data,
        currentPage: delivery.current_page,
        loading: false,
        from: delivery.from,
        to: delivery.to,
        total: delivery.total,
        last_page: delivery.last_page,
        changeListLoad: false,
      });
    }
  };

  prevPage = async () => {
    const { store_id, token, deliveryListID } = this.props;
    const { to, per_page, currentPage, limit, last_page } = this.state;
    if (to === null) {
      this.setState({
        changeListLoad: true,
      });
      const delivery = (await api.getDeliveries(
        {
          store_id: store_id,
          page: last_page as number,
          limit: limit,
          item_list: deliveryListID,
        },
        token
      )) as DeliveryList;
      this.setState({
        deliveries: delivery.data,
        currentPage: delivery.current_page,
        loading: false,
        from: delivery.from,
        to: delivery.to,
        total: delivery.total,
        last_page: delivery.last_page,
        changeListLoad: false,
      });
    }
    if (to > per_page) {
      this.setState({
        changeListLoad: true,
      });
      const delivery = (await api.getDeliveries(
        {
          store_id: store_id,
          page: currentPage - 1,
          limit: this.state.limit,
          item_list: deliveryListID,
        },
        token
      )) as DeliveryList;
      this.setState({
        deliveries: delivery.data,
        currentPage: delivery.current_page,
        loading: false,
        from: delivery.from,
        to: delivery.to,
        total: delivery.total,
        last_page: delivery.last_page,
        changeListLoad: false,
      });
    }
  };

  firstPage = async () => {
    const { store_id, token, deliveryListID } = this.props;
    const { limit } = this.state;
    this.setState({
      changeListLoad: true,
    });
    const delivery = (await api.getDeliveries(
      {
        store_id: store_id,
        page: 1,
        limit: limit,
        item_list: deliveryListID,
      },
      token
    )) as DeliveryList;
    this.setState({
      deliveries: delivery.data,
      currentPage: delivery.current_page,
      loading: false,
      from: delivery.from,
      to: delivery.to,
      total: delivery.total,
      last_page: delivery.last_page,
      changeListLoad: false,
    });
  };

  lastPage = async () => {
    const { store_id, token, deliveryListID } = this.props;
    const { limit, last_page } = this.state;
    this.setState({
      changeListLoad: true,
    });
    const deliveries = (await api.getDeliveries(
      {
        store_id: store_id,
        page: last_page as number,
        limit: limit,
        item_list: deliveryListID,
      },
      token
    )) as DeliveryList;
    this.setState({
      deliveries: deliveries.data,
      currentPage: deliveries.current_page,
      loading: false,
      from: deliveries.from,
      to: deliveries.to,
      total: deliveries.total,
      last_page: deliveries.last_page,
      changeListLoad: false,
    });
  };

  addCustomList = (
    filters: CustomFilters[],
    defaultFilters: CustomFilters[]
  ) => {
    const {
      addDeliveryCustomFilters,
      updateDeliveryCustomListLoad,
      deliveryCustomListLoaded,
    } = this.props;
    // let newDefaultFilter = [] as CustomFilters[];
    let defaultFilterMap = defaultFilters.map(
      (defaultFilter) => defaultFilter.field
    );
    let filterMap = filters.map((filter) => filter.field);

    let newDefaultFilter = defaultFilters.map((defaultFilter) => {
      if (
        defaultFilterMap.includes(defaultFilter.field) &&
        !filterMap.includes(defaultFilter.field)
      ) {
        return defaultFilter;
      } else {
        // filters.forEach(filter => {
        //   if (defaultFilter.field === filter.field) {
        //     if (defaultFilter.display === filter.display) {
        //       newDefaultFilter.push(defaultFilter);
        //     } else {
        //       newDefaultFilter.push(filter);
        //     }
        //   }
        // });
      }
    });

    const newFilter = filters.filter((filter) => {
      return !defaultFilterMap.includes(filter.field);
    });

    const finalFilterList = newDefaultFilter.concat(newFilter);
    if (deliveryCustomListLoaded) {
      addDeliveryCustomFilters(finalFilterList as CustomFilters[]);
    }
    updateDeliveryCustomListLoad();
  };

  renderNewListDeliveries = async (id: number) => {
    const { store_id, token } = this.props;
    const delivery = (await api.getDeliveries(
      { store_id, page: 1, item_list: id, limit: this.state.limit },
      token
    )) as DeliveryList;
    this.setState({
      deliveries: delivery.data,
      currentPage: delivery.current_page,
      loading: false,
      from: delivery.from,
      to: delivery.to,
      total: delivery.total,
      per_page: delivery.per_page,
      last_page: delivery.last_page,
    });
  };

  deliveryList = () => {
    let standardList = [{ title: true, label: "STANDARD LISTS" }] as MenuItem[];
    let customList = [
      {
        title: true,
        label: "CUSTOM LISTS",
      },
    ] as MenuItem[];
    //go through Item lists and append based on label
    this.state.item_list.forEach((item) => {
      //label tbd
      if (item.label === "All Deliveries") {
        standardList.push({
          title: false,
          label: item.label,
          id: item.code,
          filter_ids: item.filter_ids,
        });
        this.props.updateDefaultDeliveryID(item.code as string);
      } else if (
        item.label === "Active Deliveries" ||
        item.label === "Past Deliveries" ||
        item.label === "Voided Deliveries"
      ) {
        standardList.push({
          title: false,
          label: item.label,
          id: item.code,
          filter_ids: item.filter_ids,
        });
      } else {
        customList.push({
          title: false,
          label: item.label,
          id: item.id,
          filter_ids: item.filter_ids,
        });
      }
    });
    return standardList.concat(customList);
  };

  isDefaultList = () => {
    const { deliveryListLabel } = this.props;
    if (
      deliveryListLabel === "" ||
      deliveryListLabel === "Past Deliveries" ||
      deliveryListLabel === "All Deliveries" ||
      deliveryListLabel === "Active Deliveries" ||
      deliveryListLabel === "Voided Deliveries"
    ) {
      return true;
    }
    return false;
  };

  exportDeliveries = async () => {
    const { store_id, deliveryListID, token } = this.props;

    this.setState({
      exporting: true,
    });
    const response = await api.exportDeliveries(
      store_id,
      deliveryListID,
      token
    );

    this.setState({
      exporting: false,
    });
  };

  orderColumns = () => {
    const { deliveryFilters } = this.props;

    const newDeliveryColumn = deliveryFilters
      .filter((field) => !!field.visible)
      .sort((a, b) => (a.ordinal && b.ordinal ? a.ordinal - b.ordinal : 0))
      .map((filter) => filter.field);

    return newDeliveryColumn;
  };

  render() {
    const { loading } = this.state;
    const {
      deliveryFilters,
      deliveryListLabel,
      deliveryListID,
      store_id,
      token,
    } = this.props;
    const massSelectionRequirement = "Need to select a delivery first";
    const deliveries = this.getDeliveries();
    const itemList = this.state.item_list.length > 1 ? this.deliveryList() : [];
    const selected_options_stub: SelectedMenuOption[] = [
      {
        text: "Delete",
        onClick: async (selected) => {
          console.log("selected: ", selected);
          const { store_id, token, deliveryListID } = this.props;
          const { currentPage } = this.state;
          if (selected.length > 0) {
            await api.massDeleteDeliveries(
              {
                deliveryIDs: selected as number[],
              },
              token
            );
            const deliveries = (await api.getDeliveries(
              {
                store_id,
                page: currentPage,
                limit: this.state.limit,
                item_list: deliveryListID,
              },
              token
            )) as DeliveryList;

            this.setState({
              deliveries: deliveries.data,
              currentPage: deliveries.current_page,
              loading: false,
              from: deliveries.from,
              to: deliveries.to,
              total: deliveries.total,
              per_page: deliveries.per_page,
              last_page: deliveries.last_page,
            });
          } else {
            toastError(massSelectionRequirement);
          }
        },
      },
    ];
    if (loading) {
      return (
        <Container>
          <Spinner />
        </Container>
      );
    }

    return (
      <Container style={{ marginTop: 1 }}>
        {this.state.changeListLoad && <PageLoadSpinner />}
        <DynamicTable
          columnTitles={!!deliveries.length ? this.orderColumns() : []}
          // columnTypes={rowType_stub}
          // columnColors={colors_stub}
          // rowData={dataRow_stub}
          rowData={deliveries}
          selectedMenuOptions={selected_options_stub}
          selectedMenuLabel="Deliveries"
          currentPageFirstItemNumber={this.state.currentPage + 1}
          onNextClick={this.nextPage}
          onPrevClick={this.prevPage}
          onFirstClick={this.firstPage}
          onLastClick={this.lastPage}
          renderEmptyGrid={EmptyGrid}
          excludedColumns={["deleted_at"]}
          searchOnChange={this.changeSearchText}
          searchValue={this.state.searchKeyword}
          totalItemCount={this.state.deliveries.length}
          renderNewList={this.renderNewListDeliveries}
          from={this.state.from}
          to={this.state.to}
          total={this.state.total}
          updateList={this.updateDeliveries}
          changeViewLimit={this.changeViewLimit}
          limit={this.state.limit}
          basic={true}
          currentPage={this.state.currentPage}
          list={itemList}
          fieldList={deliveryFilters}
          customFieldList={this.state.customFilters}
          currentList={deliveryListLabel}
          listID={deliveryListID}
          isDefaultList={this.isDefaultList()}
          exportCSVList={this.exportDeliveries}
          changeListRenderState={this.changeListRenderState}
          exporting={this.state.exporting}
          onColumnSave={this.onColumnSave}
        />
      </Container>
    );
  }
}

interface LinkStateProps {
  token: string;
  store_id: number;
  custom_properties: CustomFields[];
  deliveryListLabel: string;
  deliveryListLoaded: boolean;
  deliveryCustomListLoaded: boolean;
  deliveryListID: number;
  deliveryFilters: CustomFilters[];
}

interface LinkDispatchProps {
  fetchDeliveryProperties: (property: CustomFields[]) => void;
  updateDeliveryInitialLoad: () => void;
  addDeliveryCustomFilters: (filter: CustomFilters[]) => void;
  setDeliveryCustomFieldFilters: (filter: CustomFilters[]) => void;
  updateDeliveryCustomListLoad: () => void;
  addDeliveryCustomFiltersField: (filter: FilterField[]) => void;
  updateDefaultDeliveryID: (id: number | string) => void;
}

const mapDispatchToProps = (
  dispatch: Dispatch<AppActions>
): LinkDispatchProps => ({
  fetchDeliveryProperties: bindActionCreators(
    fetchDeliveryProperties,
    dispatch
  ),
  updateDeliveryInitialLoad: bindActionCreators(
    updateDeliveryInitialLoad,
    dispatch
  ),
  addDeliveryCustomFilters: bindActionCreators(
    addDeliveryCustomFilters,
    dispatch
  ),
  updateDeliveryCustomListLoad: bindActionCreators(
    updateDeliveryCustomListLoad,
    dispatch
  ),
  addDeliveryCustomFiltersField: bindActionCreators(
    addDeliveryCustomFiltersField,
    dispatch
  ),
  updateDefaultDeliveryID: bindActionCreators(
    updateDefaultDeliveryID,
    dispatch
  ),
  setDeliveryCustomFieldFilters: bindActionCreators(
    setDeliveryCustomFieldFilters,
    dispatch
  ),
});

const mapStateToProps = (state: AppState): LinkStateProps => ({
  store_id: state.user.store_id as number,
  token: state.auth.token as string,
  custom_properties: state.customDeliveryFields as CustomFields[],
  deliveryListLoaded: state.listViewIDs.deliveryListInitialLoad as boolean,
  deliveryListLabel: state.listViewIDs.deliveryLabel as string,
  deliveryListID: state.listViewIDs.deliveryListID as number,
  deliveryCustomListLoaded: state.customDeliveryFilterState
    .initialDeliveryCustomListLoad as boolean,
  deliveryFilters: state.customDeliveryFieldFilter as CustomFilters[],
});

export default connect(mapStateToProps, mapDispatchToProps)(Deliveries);
