import * as React from "react";
import { connect } from "react-redux";
import { withRouter, RouteComponentProps } from "react-router";
import styled from "styled-components";
import { MdChevronLeft } from "react-icons/md";
import * as Yup from "yup";
import {
  Container,
  SecondaryButton,
  Button,
  HR,
  Spinner,
  AppText,
} from "../UI";
import { FieldDetail, ColorItem } from "../Dumb";
import {
  PRIMARY_GRADIENT_FROM,
  formatFullDateToTime,
  formatFullDateToUSTime,
  PRIMARY_COLOR,
} from "../../utils";
import { Formik, FormikProps, FieldArray } from "formik";
import {
  InputField,
  SelectField,
  CheckboxField,
  OnboardCheckboxField,
} from "../form";
import { api } from "../../api/api";
import { DeliveryRunResponse } from "../../api/responseTypes";
import { AppState } from "../../store/configureStore";
import { Dispatch, bindActionCreators } from "redux";
import {
  AppActions,
  CustomFieldByEntity,
  CustomFieldsList,
  CustomFields,
  CustomFieldsByEntityListValue,
  DeliveryRuns,
} from "../../types";
import {
  updateFromDetailedDeliveryRun,
  updateDetailedDeliveryRun,
} from "../../actions";

const editDeliveryRynSchema = Yup.object().shape({});

interface FieldArray {
  custom_field_id: number;
  field: number | undefined;
  label: string;
  slug: string;
  value: string | null;
  filter_type: number;
  listValues: CustomFieldsByEntityListValue[];
}

interface MyFormikProps {
  custom_fields: FieldArray[];
}

interface RouterProps {
  id: string;
}

interface DeliveryRunDetailProps {}
interface DeliveryRunDetailState {
  edit: boolean;
  deliveryRun: DeliveryRunResponse;
  loading: boolean;
  custom_fields: FieldArray[];
}

type Props = RouteComponentProps<RouterProps> &
  DeliveryRunDetailProps &
  LinkStateProps &
  LinkDispatchProps;

class DeliveryRunDetail extends React.Component<Props, DeliveryRunDetailState> {
  constructor(props: Props) {
    super(props);
    this.state = {
      edit: false,
      loading: true,
      custom_fields: [],
      deliveryRun: {
        id: 0,
        driver_id: 0,
        num_deliveries: 0,
        status: "",
        scheduled_start_time: "",
        scheduled_end_time: "",
        route_selection_method: "",
        sequence_num: 0,
        created_at: "",
        updated_at: "",
        deleted_at: "",
        actual_start_time: "",
        revised_end_time: "",
        actual_end_time: "",
        current_position: "",
        orders: [],
        deliveries: [],
        driver_info: {
          color: "",
          created_at: "",
          deleted_at: null,
          email: "",
          external_driver_id: null,
          first_name: "",
          id: 0,
          is_active: 0,
          language: "",
          last_name: "",
          store_id: 0,
          updated_at: "",
        },
      },
    };
  }
  toggleEdit = () => this.setState((prev) => ({ edit: !prev.edit }));

  componentDidMount = async () => {
    const id = Number(this.props.match.params.id);
    const deliveryRun = await api.getDeliveryRun(id, this.props.token);
    const customValues = (await api.getAllCustomProperties(
      id,
      this.props.token,
      "DeliveryRun"
    )) as CustomFieldByEntity[];
    const finalList =
      this.props.allCustomFields && customValues
        ? customValues.map((field) => {
            const slug = this.props.allCustomFields
              .filter(
                (allcustomFields) =>
                  allcustomFields.id === field.custom_field.id
              )
              .map((obj) => {
                return {
                  display: obj.display,
                  slug: obj.slug,
                  filter_id: obj.field_type_id,
                };
              })[0];
            return {
              field: field.id,
              value: field.value,
              custom_field_id: field.custom_field.id,
              label: slug ? slug.display : "",
              slug: slug ? slug.slug : "",
              filter: slug ? slug.filter_id : "",
              listValues: field.custom_field.listValues,
              filter_type: field.custom_field.field_type_id,
            };
          })
        : [];
    this.setState({
      deliveryRun: deliveryRun as DeliveryRunResponse,
      loading: false,
      custom_fields: finalList,
    });
  };

  deleteDeliveryrun = async () => {
    const id = Number(this.props.match.params.id);
    await api.deleteDeliveryRun({ id }, this.props.token);
    this.props.history.goBack();
  };

  viewDeliveryRunOnDashBoard = () => {
    const {
      history,
      updateDetailedDeliveryRun,
      updateFromDetailedDeliveryRun,
    } = this.props;
    const id = Number(this.props.match.params.id);
    updateFromDetailedDeliveryRun();
    updateDetailedDeliveryRun(id);
    history.push("/dashboard");
  };
  render() {
    const {
      edit,
      loading,
      custom_fields,
      deliveryRun: {
        id,
        num_deliveries,
        current_position,
        status: deliveryRunStatus,
        scheduled_start_time,
        route_selection_method,
        sequence_num,
        created_at,
        updated_at,
        driver_info: { first_name, last_name },
      },
    } = this.state;
    const {
      history: { goBack },
    } = this.props;
    return (
      <Container>
        <Div>
          <Horizontal onClick={goBack}>
            <MdChevronLeft color={PRIMARY_GRADIENT_FROM} size={42} />
            {/* <img src={arrowLeft} color={PRIMARY_GRADIENT_FROM} /> */}
            <ReturnText>RETURN TO DELIVERY RUNS</ReturnText>
          </Horizontal>
          <Formik
            enableReinitialize={true}
            initialValues={{
              //driver needs to have name label value on get delivery run endpoint
              driver_first_name: first_name,
              driver_last_name: last_name,
              num_deliveries: num_deliveries,
              custom_fields: this.state.custom_fields,
            }}
            onSubmit={async (values, { setFieldError, setStatus }) => {
              const { token } = this.props;
              setStatus({ error: "", loading: true });

              values.custom_fields.forEach((field) => {
                if (!!field.field) {
                  api.updateCustomFieldValue(
                    { id: field.field, value: field.value as string },
                    token
                  );
                }
                if (!field.field && field.value) {
                  api.createCustomFieldValue(
                    {
                      custom_field_id: field.custom_field_id,
                      entity_id: parseInt(this.props.match.params.id),
                      value: field.value,
                    },
                    token
                  );
                }
              });
              this.setState({
                custom_fields: values.custom_fields,
              });
              this.toggleEdit();
            }}
          >
            {({
              status,
              setStatus,
              submitForm,
              values,
            }: FormikProps<MyFormikProps>) => {
              return (
                <Main>
                  <Spread>
                    <Horizontal>
                      <NameText>{`${
                        deliveryRunStatus.charAt(0).toUpperCase() +
                        deliveryRunStatus.slice(1)
                      } Delivery Run ${id}`}</NameText>
                    </Horizontal>
                    <Horizontal>
                      {edit ? (
                        <>
                          <CancelText clickable onClick={this.toggleEdit}>
                            Cancel
                          </CancelText>
                          <SaveButton type="submit" onClick={submitForm}>
                            Save
                          </SaveButton>
                        </>
                      ) : (
                        <>
                          <SecondaryButton
                            style={{
                              width: "130px",
                              backgroundColor: PRIMARY_COLOR,
                              color: "#fff",
                              fontSize: "12px",
                            }}
                            onClick={this.viewDeliveryRunOnDashBoard}
                            type="button"
                          >
                            View on Dashboard
                          </SecondaryButton>
                          <SecondaryButton
                            style={{ width: "130px", marginLeft: "12px" }}
                            onClick={this.toggleEdit}
                            type="button"
                          >
                            Edit Delivery Run
                          </SecondaryButton>
                          <DeleteButton
                            onClick={this.deleteDeliveryrun}
                            style={{ width: "130px", marginLeft: "12px" }}
                            type="button"
                          >
                            Delete Delivery Run
                          </DeleteButton>
                        </>
                      )}
                    </Horizontal>
                  </Spread>
                  <ThisHR />
                  <Grid>
                    <SectionTitle style={{ alignSelf: "center" }}>
                      SYSTEM PROPERTIES
                    </SectionTitle>
                    <>
                      <FieldDetail
                        style={{
                          gridRow: "2 / span 1",
                          gridColumn: "1 / span 1",
                        }}
                        title="Driver Name"
                        value={`${first_name} ${last_name}`}
                      />
                      <FieldDetail
                        style={{
                          gridRow: "2 / span 1",
                          gridColumn: "2 / span 1",
                        }}
                        title="Number of deliveries"
                        value={`${num_deliveries}`}
                      />
                      <FieldDetail
                        style={{
                          gridRow: "2 / span 1",
                          gridColumn: "3 / span 1",
                        }}
                        title="Status"
                        value={`${deliveryRunStatus}`}
                      />
                      <FieldDetail
                        style={{
                          gridRow: "3 / span 1",
                          gridColumn: "1 / span 1",
                        }}
                        title="Scheduled Start Time"
                        value={`${formatFullDateToTime(scheduled_start_time)}`}
                      />
                      <FieldDetail
                        style={{
                          gridRow: "3 / span 1",
                          gridColumn: "2 / span 1",
                        }}
                        title="Current Position"
                        value={`${current_position ? current_position : "N/A"}`}
                      />
                      <FieldDetail
                        style={{
                          gridRow: "3 / span 1",
                          gridColumn: "3 / span 1",
                        }}
                        title="Route Selection Method"
                        value={`${route_selection_method}`}
                      />
                      <FieldDetail
                        style={{
                          gridRow: "4 / span 1",
                          gridColumn: "1 / span 1",
                        }}
                        title="Date Created"
                        value={`${formatFullDateToUSTime(created_at)}`}
                      />
                      <FieldDetail
                        style={{
                          gridRow: "4 / span 1",
                          gridColumn: "2 / span 1",
                        }}
                        title="Last Modified Date"
                        value={`${formatFullDateToUSTime(updated_at)}`}
                      />
                    </>
                    <ThisHR
                      style={{
                        gridRow: "5 / span 1",
                        gridColumn: "1 / span 3",
                      }}
                    />
                  </Grid>
                  <Grid>
                    <SectionTitle
                      style={{
                        alignSelf: "center",
                        gridRow: "1 / span 1",
                        gridColumn: "1 / span 1",
                      }}
                    >
                      CUSTOM PROPERTIES
                    </SectionTitle>
                    <EmptyDiv
                      style={{
                        gridRow: "1 / span 1",
                        gridColumn: "2 / span 1",
                      }}
                    />
                    <EmptyDiv
                      style={{
                        gridRow: "1 / span 1",
                        gridColumn: "3 / span 1",
                      }}
                    />
                    {edit ? (
                      <>
                        <FieldArray
                          name="custom_fields"
                          render={(arrayHelpers) => (
                            <>
                              {values.custom_fields.map((fields, index) => {
                                if (fields.filter_type === 3) {
                                  const optionItems = fields.listValues.map(
                                    (values) => {
                                      return {
                                        label: values.value,
                                        data: values.value,
                                        value: values.value,
                                      };
                                    }
                                  );
                                  return (
                                    <div key={fields.custom_field_id}>
                                      <SelectField
                                        name={`custom_fields[${index}].value`}
                                        placeholder={
                                          fields.value ? fields.value : ""
                                        }
                                        options={optionItems}
                                        title={fields.label}
                                      />
                                    </div>
                                  );
                                }
                                if (fields.filter_type === 6) {
                                  return (
                                    <div key={fields.custom_field_id}>
                                      <ValueText>{fields.label}</ValueText>
                                      <CheckboxField
                                        title={``}
                                        name={`custom_fields[${index}].value`}
                                      />
                                    </div>
                                  );
                                }
                                return (
                                  <div key={fields.custom_field_id}>
                                    <InputField
                                      name={`custom_fields[${index}].value`}
                                      placeholder={
                                        fields.value ? fields.value : ""
                                      }
                                      title={fields.label}
                                    />
                                  </div>
                                );
                              })}
                            </>
                          )}
                        />
                      </>
                    ) : (
                      <>
                        {custom_fields.length > 0
                          ? custom_fields.map((values) => {
                              if (values.filter_type === 6) {
                                return (
                                  <FieldDetail
                                    title={values.label}
                                    value={values.value ? "true" : "false"}
                                  />
                                );
                              }
                              return (
                                <FieldDetail
                                  title={values.label}
                                  value={values.value ? values.value : ""}
                                />
                              );
                            })
                          : null}
                      </>
                    )}
                  </Grid>
                </Main>
              );
            }}
          </Formik>
        </Div>
      </Container>
    );
  }
}

const Div = styled.div`
  display: flex;
  flex-direction: column;
`;

const Main = styled(Div)`
  margin-left: 90px;
`;

const Spread = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-end;
`;

const Grid = styled.div`
  display: grid;
  grid-template-rows: min-content 50px 50px 50px min-content;
  grid-template-columns: 400px 400px 400px;
  row-gap: 25px;
`;
const EmptyDiv = styled.div``;

const ValueText = styled(AppText)`
  opacity: 0.5;
  font-size: 14px;
  font-weight: 500;
  color: #3b3a3a;
  margin-bottom: 7px;
`;

const Horizontal = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: min-content;
  white-space: nowrap;
  :hover {
    cursor: pointer;
  }
`;

const ReturnText = styled(AppText)`
  font-size: 15px;
  font-weight: 600;
  letter-spacing: 0.3px;
  color: ${(props) => props.theme.PRIMARY_GRADIENT_FROM};
  margin-left: 17px;
`;

const CancelText = styled(AppText)`
  font-size: 13px;
  font-weight: 500;
  color: #3b3a3a;
  margin-right: 65px;
`;

const DeleteButton = styled(Button)`
  background-color: #c4183c;
`;

const SaveButton = styled(Button)`
  width: 130px;
  height: 40px;
`;

const SectionTitle = styled(AppText)`
  font-size: 15px;
  font-weight: 900;
  color: #3b3a3a;
`;

const ThisHR = styled(HR)`
  margin: 15px 0 25px 0;
`;

const NameText = styled(AppText)`
  opacity: 0.9;
  font-size: 18px;
  font-weight: 500;
  letter-spacing: 0.2px;
  color: #3b3a3a;
  margin-left: 17px;
`;

interface LinkStateProps {
  token: string;
  allCustomFields: CustomFields[];
}

interface LinkDispatchProps {
  updateFromDetailedDeliveryRun: () => void;
  updateDetailedDeliveryRun: (id: number) => void;
}

const mapDispatchToProps = (
  dispatch: Dispatch<AppActions>
): LinkDispatchProps => ({
  updateFromDetailedDeliveryRun: bindActionCreators(
    updateFromDetailedDeliveryRun,
    dispatch
  ),
  updateDetailedDeliveryRun: bindActionCreators(
    updateDetailedDeliveryRun,
    dispatch
  ),
});
// isAuthenticated is true if user_token exists in redux
const mapStateToProps = (state: AppState): LinkStateProps => ({
  token: state.auth.token as string,
  allCustomFields: state.customDeliveryRunFields as CustomFields[],
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(DeliveryRunDetail));
