import * as React from "react";
import styled, { StyledComponent } from "styled-components";
// import SearchIcon from "react-icons/lib/go/search";
import { Input } from "../UI";
import { PRIMARY_COLOR } from "../../utils";
import { GoSearch } from "react-icons/go";

interface SearchInputProps {}

class SearchInput extends React.Component<any, any> {
  render() {
    return (
      <FakeInput>
        <GoSearch size={22} color={PRIMARY_COLOR} />
        <ThisInput {...this.props}>{this.props.children}</ThisInput>
      </FakeInput>
    );
  }
}

const ThisInput = styled(Input)`
  border-width: 0px;
  background-color: #fff;
  border-radius: 4px;
  padding-left: 12px;
  :focus {
    outline: none;
    box-shadow: none;
  }
`;

const FakeInput = styled.div`
  padding-left: 12px;
  border-radius: 4px;
  border: solid 1px rgba(71, 70, 71, 0.26);
  background-color: #ffffff;
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export { SearchInput };
