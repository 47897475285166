import { Driver, DriverActionTypes } from "../types";

const driverReducerDefaultState: Driver[] = [];

const driverReducer = (
  state = driverReducerDefaultState,
  action: DriverActionTypes
): Driver[] => {
  switch (action.type) {
    case "FETCH_DRIVERS":
      return [...action.drivers];
    case "FETCH_DRIVER":
      return [...state, action.driver];
    case "ADD_DRIVER":
      return [...state, action.driver];
    case "REMOVE_DRIVER":
      return state.filter(driver => action.driver_id === driver.driver_id);
    case "EDIT_DRIVER":
      return state.map((driver: Driver) => {
        if (driver.driver_id === action.driver_id) {
          return {
            ...driver,
            ...action.driver
          };
        }
        return driver;
      });
    case "LOGOUT":
      return driverReducerDefaultState;
    default:
      return state;
  }
};

export default driverReducer;
