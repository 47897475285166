import React from "react";
import { Field, FieldProps, ErrorMessage } from "formik";
import styled from "styled-components";
import { AppText } from "../UI";
import DayPicker, { DayModifiers } from "react-day-picker";
import "react-day-picker/lib/style.css";
import moment from "moment";
import {
  formatGivenTime,
  formatFieldToPayloadDate,
  PRIMARY_COLOR,
  formatFieldPayloadDateRange,
} from "../../utils";

export const DayPickerField = ({
  name,
  start_date,
}: // handleDayClick
{
  name: string;
  start_date: string;
  // handleDayClick: (day: Date, { selected }: DayModifiers) => void;
}) => (
  <div>
    <Field
      name={name}
      render={({ field, form, form: { errors, touched } }: FieldProps) => {
        const today = new Date();
        const yesterday = new Date(today.getTime() - 24 * 60 * 60 * 1000);
        return (
          <FormSegment>
            <StyledDayPicker
              onDayClick={(day) => {
                yesterday <= day &&
                  form.setFieldValue(
                    name,
                    formatFieldToPayloadDate(day.toLocaleDateString())
                  );
              }}
              disabledDays={[yesterday, { before: yesterday }]}
              selectedDays={formatFieldPayloadDateRange(start_date, 1)}
            />
          </FormSegment>
        );
      }}
    />
  </div>
);

const FormSegment = styled.div`
  display: flex;
  justify-content: center;
`;

const StyledDayPicker = styled(DayPicker)`


  /*MONTH CALENDAR CSS*/
  .DayPicker-Month {
    margin: 0 2.5em;
    margin-top: 1.5em;
    margin-bottom: 0.5em;
    font-family: ${(props) => props.theme.PRIMARY_FONT};
    font-size: 14px;
    font-weight: 700;
    font-stretch: condensed;
    letter-spacing: 0.2px;
    color: ${PRIMARY_COLOR};
  }

  .DayPicker-Weekday {
    opacity: 0.5;
    font-family: ${(props) => props.theme.PRIMARY_FONT};
    font-size: 15px;
    font-stretch: condensed;
    letter-spacing: 0.2px;
    color: rgba(31, 33, 37, 0.5);
  }

  /* .DayPicker-Day {
    padding: 0.5em 1em;
    font-size: 15px;
    color: #8f9092;
    font-family: ${(props) => props.theme.PRIMARY_FONT};
    font-stretch: condensed;
    letter-spacing: 0.2px;
  } */

  .DayPicker-Caption > div {
    text-align: center;
  }
`;
