import React, { ReactNode, SFC } from "react";
import styled from "styled-components";
import { AppText } from "../UI";

// * I have to have this component for Formik's ErrorField component

export interface ErrorTextProps {
  children?: ReactNode;
}

export const ErrorText: SFC<ErrorTextProps> = ({ children }) => (
  <AppTextError>{children}</AppTextError>
);

export const AppTextError = styled(AppText)`
  color: #f23c5a;
  font-size: 12px;
  line-height: 2;
`;
