import * as React from "react";

interface AccountProps {}

interface AccountState {}

class Account extends React.Component<AccountProps, AccountState> {
  render(): JSX.Element {
    return <span>Account</span>;
  }
}

export default Account;
