import React from "react";
import { Field, FieldProps, ErrorMessage } from "formik";
import { Option } from "react-select/lib/filters";
import styled from "styled-components";
import { AppText, Select } from "../UI";
import { ErrorText } from "../Dumb";

export const SelectField = ({
  name,
  title,
  placeholder,
  style,
  inputWidth,
  options,
  disabled = false,
  isMulti = false,
  additionalOnChange
}: {
  name: string;
  title: string;
  placeholder: string;
  style?: React.CSSProperties;
  inputWidth?: number;
  options: Option[];
  disabled?: boolean;
  isMulti?: boolean;
  additionalOnChange?: (option: Option) => void;
}) => (
  <div style={style}>
    <Field
      name={name}
      render={({ field, form, form: { errors, touched } }: FieldProps) => {
        const error = !!errors[name] && !!touched[name];
        return (
          <div>
            {title ? (
              <AppTextFormTitle>{title}</AppTextFormTitle>
            ) : (
              <AppTextFormTitle>&nbsp; </AppTextFormTitle>
            )}
            <Select
              {...field}
              inputWidth={inputWidth}
              options={options}
              isDisabled={disabled}
              placeholder={placeholder}
              form={form}
              field={field}
              error={error}
              isMulti={isMulti}
              closeMenuOnSelect={!isMulti}
              hideSelectedOptions={false}
              additionalOnChange={additionalOnChange}
            />
            <ErrorMessage name={field.name} component={ErrorText} />
          </div>
        );
      }}
    />
  </div>
);

const AppTextFormTitle = styled(AppText)`
  opacity: 0.5;
  font-size: 14px;
  font-weight: 500;
  color: #3b3a3a;
  margin-bottom: 7px;
`;
