import { CustomFilters, CustomDeliveryFieldFilterActionTypes } from "../types";

const customDeliveryFieldFilterReducerDefaultState: CustomFilters[] = [
  { display: "ID", field: "id", visible: false },
  // { display: "Order ID", field: "order_id", visible: true },
  // { display: "Customer ID", field: "customer_id", visible: true },
  { display: "Status", field: "status", visible: true },
  // { display: "Delivery Run ID", field: "delivery_run_id", visible: true },
  { display: "Notes", field: "notes", visible: true },
  { display: "Delivery Image", field: "delivery_image", visible: true },
  { display: "Minutes in Store", field: "minutes_in_store", visible: true },
  { display: "Minutes in Transit", field: "minutes_in_transit", visible: true },
  { display: "Created At", field: "created_at", visible: true },
  { display: "Updated At", field: "updated_at", visible: true },
  { display: "Deleted At", field: "deleted_at", visible: true },
  { display: "Completed", field: "completed", visible: true },
  {
    display: "Estimated delivery time",
    field: "estimated_delivery_time",
    visible: true,
  },
  {
    display: "Actual Delivery Time",
    field: "actual_delivery_time",
    visible: true,
  },
  { display: "Sequence Number", field: "sequence_number", visible: true },
];

const customDeliveryFieldFilterReducer = (
  state = customDeliveryFieldFilterReducerDefaultState,
  action: CustomDeliveryFieldFilterActionTypes
): CustomFilters[] => {
  switch (action.type) {
    case "SET_DELIVERY_FIELD_FILTERS":
      return action.filters;
    case "ADD_DELIVERY_CUSTOM_FILTERS":
      return action.filters;
    case "ADD_DELIVERY_FIELD_FILTER":
      const addDeliveryState = state.map((obj) => {
        if (obj.field === action.filter) {
          return {
            display: obj.display,
            field: obj.field,
            visible: true,
          };
        } else {
          return {
            display: obj.display,
            field: obj.field,
            visible: obj.visible,
          };
        }
      }) as any;
      return addDeliveryState;
    case "REMOVE_DELIVERY_FIELD_FILTER":
      const removeDeliveryState = state.map((obj) => {
        if (obj.field === action.filter) {
          return {
            display: obj.display,
            field: obj.field,
            visible: false,
          };
        } else {
          return {
            display: obj.display,
            field: obj.field,
            visible: obj.visible,
          };
        }
      }) as any;
      return removeDeliveryState;
    case "DELETE_DELIVERY_FIELD_FILTER":
      return state.filter((filter) => filter.field !== action.filter);
    case "LOGOUT":
      return customDeliveryFieldFilterReducerDefaultState;
    default:
      return state;
  }
};

export default customDeliveryFieldFilterReducer;
