import styled from "styled-components";

interface IconProps {
  clickable?: boolean;
}

export const Icon = styled.img<IconProps>`
  :hover {
    cursor: ${props => (props.clickable ? "pointer" : "default")};
  }
`;
