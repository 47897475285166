import * as React from "react";
import { connect } from "react-redux";
import { Formik, FormikProps, Form } from "formik";
import * as Yup from "yup";
import { PHONE_REG_EXP, formatPhoneNumber } from "../../../utils";
import { Dispatch, bindActionCreators } from "redux";
import { AppActions } from "../../../types";
import {
  startSetOnboardingPrefs,
  onboardNextPage,
  onboardPrevPage,
} from "../../../actions/onboarding";
import { AppState } from "../../../store/configureStore";
import {
  InputField,
  OnboardInputField,
  OnboardCheckboxField,
} from "../../form";
import styled from "styled-components";
import { AuthButton } from "../../Pages/NewClientSetup";
import { AppTextRoman, AppText } from "../../UI";
import MyNumberInput from "../../form/PhoneNumberField";
import { storeUserNotificationThunk } from "../../../api/notification";
import { api } from "../../../api/api";
import { editUserPhone } from "../../../actions";

const onboardSchema = Yup.object().shape({
  personal_phone_number: Yup.string()
    // .matches(PHONE_REG_EXP, "* Phone number is not valid")
    .notRequired(),
  driver_inactive_for_xx_minutes_email: Yup.boolean().required(),
  driver_inactive_for_xx_minutes_sms: Yup.boolean().required(),
  driver_inactive_for_xx_minutes_value: Yup.number().when(
    [
      "driver_inactive_for_xx_minutes_email",
      "driver_inactive_for_xx_minutes_sms",
    ],
    {
      is: (a, b) => a || b,
      then: Yup.number().required("* # Minutes required"),
    }
  ),
  when_driver_leaves_email: Yup.boolean().required(),
  when_driver_leaves_sms: Yup.boolean().required(),
  when_driver_is_returning_to_store_email: Yup.boolean().required(),
  when_driver_is_returning_to_store_sms: Yup.boolean().required(),
  when_a_package_is_delivered_email: Yup.boolean().required(),
  when_a_package_is_delivered_sms: Yup.boolean().required(),
  when_an_order_is_received_by_pos_email: Yup.boolean().required(),
  when_an_order_is_received_by_pos_sms: Yup.boolean().required(),
});

interface MyCommunicationPreferencesProps {}

interface MyCommunicationPreferencesState {}

interface MyFormikProps {
  when_driver_leaves_sms: boolean;
  when_driver_leaves_email: boolean;
  when_driver_is_returning_to_store_sms: boolean;
  when_driver_is_returning_to_store_email: boolean;
  driver_inactive_for_xx_minutes_sms: boolean;
  driver_inactive_for_xx_minutes_email: boolean;
  driver_inactive_for_xx_minutes_value: number;
  when_a_package_is_delivered_sms: boolean;
  when_a_package_is_delivered_email: boolean;
  when_an_order_is_received_by_pos_sms: boolean;
  when_an_order_is_received_by_pos_email: boolean;
  personal_phone_number: string | null;
}

type Props = MyCommunicationPreferencesProps &
  LinkDispatchProps &
  LinkStateProps;

class MyCommunicationPreferences extends React.Component<
  Props,
  MyCommunicationPreferencesState
> {
  async componentDidMount() {}
  render(): JSX.Element {
    return (
      <Formik
        initialValues={{
          driver_inactive_for_xx_minutes_email: this.props
            .driver_inactive_for_xx_minutes_email,
          driver_inactive_for_xx_minutes_sms: this.props
            .driver_inactive_for_xx_minutes_sms,
          driver_inactive_for_xx_minutes_value: this.props
            .driver_inactive_for_xx_minutes_value,
          personal_phone_number: this.props.personal_phone_number
            ? this.props.personal_phone_number
            : "",
          when_a_package_is_delivered_email: this.props
            .when_a_package_is_delivered_email,
          when_a_package_is_delivered_sms: this.props
            .when_a_package_is_delivered_sms,
          when_an_order_is_received_by_pos_email: this.props
            .when_an_order_is_received_by_pos_email,
          when_an_order_is_received_by_pos_sms: this.props
            .when_an_order_is_received_by_pos_sms,
          when_driver_is_returning_to_store_email: this.props
            .when_driver_is_returning_to_store_email,
          when_driver_is_returning_to_store_sms: this.props
            .when_driver_is_returning_to_store_sms,
          when_driver_leaves_email: this.props.when_driver_leaves_email,
          when_driver_leaves_sms: this.props.when_driver_leaves_sms,
        }}
        validationSchema={onboardSchema}
        onSubmit={async (values, { setFieldError, setStatus }) => {
          const smsArray: any = [];
          const emailArray: any = [];
          values.when_driver_leaves_sms ? smsArray.push(1) : null;
          values.when_driver_is_returning_to_store_email
            ? emailArray.push(1)
            : null;
          values.when_driver_is_returning_to_store_sms
            ? smsArray.push(2)
            : null;
          values.when_driver_is_returning_to_store_email
            ? emailArray.push(2)
            : null;
          values.driver_inactive_for_xx_minutes_sms ? smsArray.push(3) : null;
          values.driver_inactive_for_xx_minutes_email
            ? emailArray.push(3)
            : null;
          values.when_a_package_is_delivered_sms ? smsArray.push(4) : null;
          values.when_a_package_is_delivered_email ? emailArray.push(4) : null;
          values.when_an_order_is_received_by_pos_sms ? smsArray.push(5) : null;
          values.when_an_order_is_received_by_pos_email
            ? emailArray.push(5)
            : null;

          try {
            this.props.storeUserNotification(
              this.props.store_id as number,
              smsArray,
              "sms"
            );
            this.props.storeUserNotification(
              this.props.store_id as number,
              emailArray,
              "email"
            );
            api.editUser(
              {
                user_id: this.props.user_id as number,
                phone: values.personal_phone_number,
              },
              this.props.token
            );
            this.props.editUserPhone(values.personal_phone_number);
          } catch (err) {
            console.log(err);
          }
          setStatus({ error: "", loading: true });
          this.props.startSetOnboardPrefs(values);
          this.props.onboardNextPage();
          setStatus({ loading: false });
        }}
      >
        {({
          status,
          setStatus,
          submitForm,
          touched,
          errors,
        }: FormikProps<MyFormikProps>) => {
          return (
            <Div>
              <Grid>
                <DescriptionText>
                  One of the benefits of using Scanovator is the ability to
                  receive real-time notifications so that you’re always in the
                  know about what’s happening with your deliveries. Configure
                  what alerts you’d like to receive and how you’d like to
                  receive them.
                </DescriptionText>
                <TableContainer>
                  <Table>
                    <div />
                    <DescriptionText>SMS</DescriptionText>
                    <DescriptionText>EMAIL</DescriptionText>
                    <LabelText>When a Driver Leaves</LabelText>
                    <OnboardCheckboxField name="when_driver_leaves_sms" />
                    <OnboardCheckboxField name="when_driver_leaves_email" />
                    <LabelText>When a Driver is Returning to Store</LabelText>
                    <OnboardCheckboxField name="when_driver_is_returning_to_store_sms" />
                    <OnboardCheckboxField name="when_driver_is_returning_to_store_email" />
                    <LabelText>Driver Inactive for xx Minutes</LabelText>
                    <OnboardCheckboxField name="driver_inactive_for_xx_minutes_sms" />
                    <OnboardCheckboxField name="driver_inactive_for_xx_minutes_email" />
                    <LabelText>When a Package is Delivered</LabelText>
                    <OnboardCheckboxField name="when_a_package_is_delivered_sms" />
                    <OnboardCheckboxField name="when_a_package_is_delivered_email" />
                    <LabelText>When an Order is Received by POS</LabelText>
                    <OnboardCheckboxField name="when_an_order_is_received_by_pos_sms" />
                    <OnboardCheckboxField name="when_an_order_is_received_by_pos_email" />
                  </Table>
                  {/* <OnboardInputField
                    name="personal_phone_number"
                    placeholder=""
                    title="My Phone Number"
                    style={{ marginLeft: "100px", alignSelf: "center" }}
                    formatValue={formatPhoneNumber}
                  /> */}
                  <InputDiv>
                    <MyNumberInput
                      format="(###) ###-####"
                      title="My Phone Number"
                      name="personal_phone_number"
                      style={{ alignSelf: "center" }}
                      placeholder={""}
                    />
                  </InputDiv>
                </TableContainer>
                <div />
              </Grid>
              <Spread>
                <AuthButton type="submit" onClick={this.props.onboardPrevPage}>
                  BACK
                </AuthButton>
                <AuthButton
                  type="submit"
                  onClick={submitForm}
                  // disabled={
                  //   !(
                  //     Object.keys(touched).length && !Object.keys(errors).length
                  //   )
                  // }
                >
                  NEXT
                </AuthButton>
              </Spread>
            </Div>
          );
        }}
      </Formik>
    );
  }
}

const Grid = styled.div`
  display: grid;
  grid-template-rows: 80px min-content auto;
  gap: 20px;
  max-width: 1040px;
  box-sizing: border-box;
  padding-left: 120px;
  margin-top: 40px;
`;

const Div = styled.div`
  display: grid;
  grid-template-rows: auto min-content;
`;

const Table = styled.div`
  display: grid;
  grid-template-columns: min-content min-content min-content;
  white-space: nowrap;
  column-gap: 0px;
  row-gap: 25px;
  column-gap: 170px;
`;

const Spread = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

const InputDiv = styled.div`
  margin-top: 100px;
  margin-left: 100px;
`;

const TableContainer = styled.div`
  display: flex;
  flex-direction: row;
`;

const DescriptionText = styled(AppText)`
  font-size: 15px;
  line-height: 1.67;
  color: #ffffff;
  font-weight: 300;
`;

const LabelText = styled(AppText)`
  font-size: 18px;
  color: #f7f7f7;
  font-weight: 300;
`;

interface LinkStateProps extends MyFormikProps {
  user_id: number | null;
  store_id: number | null;
  token: string;
}

interface LinkDispatchProps {
  startSetOnboardPrefs: (onboardPrefs: Object) => void;
  onboardNextPage: () => void;
  onboardPrevPage: () => void;
  storeUserNotification: (
    user_id: number,
    notification_ids: [],
    send_method: string
  ) => void;
  editUserPhone: (phoneNumber: string) => void;
}

const mapStateToProps = (state: AppState): LinkStateProps => ({
  when_driver_leaves_sms: state.onboarding.when_driver_leaves_sms,
  when_driver_leaves_email: state.onboarding.when_driver_leaves_email,
  when_driver_is_returning_to_store_sms:
    state.onboarding.when_driver_is_returning_to_store_sms,
  when_driver_is_returning_to_store_email:
    state.onboarding.when_driver_is_returning_to_store_email,
  driver_inactive_for_xx_minutes_sms:
    state.onboarding.driver_inactive_for_xx_minutes_sms,
  driver_inactive_for_xx_minutes_email:
    state.onboarding.driver_inactive_for_xx_minutes_email,
  driver_inactive_for_xx_minutes_value:
    state.onboarding.driver_inactive_for_xx_minutes_value,
  when_a_package_is_delivered_sms:
    state.onboarding.when_a_package_is_delivered_sms,
  when_a_package_is_delivered_email:
    state.onboarding.when_a_package_is_delivered_email,
  when_an_order_is_received_by_pos_sms:
    state.onboarding.when_an_order_is_received_by_pos_sms,
  when_an_order_is_received_by_pos_email:
    state.onboarding.when_an_order_is_received_by_pos_email,
  personal_phone_number: state.user.phone,
  token: state.auth.token as string,

  user_id: state.user.id,
  store_id: state.store.id,
});

const mapDispatchToProps = (
  dispatch: Dispatch<AppActions>
): LinkDispatchProps => ({
  startSetOnboardPrefs: bindActionCreators(startSetOnboardingPrefs, dispatch),
  onboardNextPage: bindActionCreators(onboardNextPage, dispatch),
  onboardPrevPage: bindActionCreators(onboardPrevPage, dispatch),
  storeUserNotification: bindActionCreators(
    storeUserNotificationThunk,
    dispatch
  ),
  editUserPhone: bindActionCreators(editUserPhone, dispatch),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MyCommunicationPreferences);
