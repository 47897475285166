import * as React from "react";
import styled from "styled-components";
import { AppText } from "../../UI";
import { ViewListDropdownMenu } from ".";

interface ViewSelectProps {
  changeViewLimit: (limit: string) => void;
}

interface ViewSelectState {
  open: boolean;
  currentLabel: string;
  currentlValue: string;
}

const View_Stub = [
  { label: "View 10", value: "10" },
  { label: "View 25", value: "25" },
  { label: "View 50", value: "50" },
  { label: "View 100", value: "100" },
  { label: "View All", value: "100000" },
];
class ViewSelect extends React.Component<ViewSelectProps, ViewSelectState> {
  constructor(props: ViewSelectProps) {
    super(props);
    this.state = {
      open: false,
      currentLabel: "View 10",
      currentlValue: "10",
    };
  }
  openMenu = () => this.setState((prev) => ({ open: !prev.open }));
  closeMenu = () => this.setState({ open: false });
  changeCurrentLabel = (label: string) => {
    this.setState({
      currentLabel: label,
    });
  };
  render(): JSX.Element {
    const { open } = this.state;
    return (
      <Div>
        <ViewListContainer onClick={this.openMenu}>
          <CurrentSelection>{this.state.currentLabel}</CurrentSelection>
        </ViewListContainer>
        {open && (
          <ViewListDropdownMenu
            list={View_Stub}
            handleClickOutside={this.openMenu}
            changeViewLimit={this.props.changeViewLimit}
            changeCurrentLabel={this.changeCurrentLabel}
            closeMenu={this.closeMenu}
          />
        )}
      </Div>
    );
  }
}

interface DivProps {}
const Div = styled.div<DivProps>`
  position: relative;
`;

const CurrentSelection = styled(AppText)`
  opacity: 0.9;
  font-size: 18px;
  font-weight: 500;
  letter-spacing: 0.2px;
  color: #3b3a3a;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`;

const ViewListContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 80px;
  height: 100%;
  :hover {
    cursor: pointer;
  }
`;

export { ViewSelect };
