import * as React from "react";
import { connect } from "react-redux";
import { AppState } from "../../../store/configureStore";
import { AppActions } from "../../../types";
import { Formik, FormikProps, Form } from "formik";
import { Dispatch, bindActionCreators } from "redux";
import * as Yup from "yup";
import styled from "styled-components";
import { Container, AppText, HR, Button, Collapsible } from "../../UI";
import { TABLET_BREAKPOINT, PHONE_REG_EXP } from "../../../utils";
import { AuthButton } from "../../Pages/NewClientSetup";
import { OnboardInputField, OnboardCheckboxField } from "../../form";
// import { registerAdminThunk } from "../../../api/auth";
import { NavLink } from "react-router-dom";
import { api } from "../../../api/api";
import EditPassword from "../../modal/EditPassword";

// const onboardSchema = Yup.object().shape({
//   store_name: Yup.string()
//     .required("* Store Name is required")
//     .max(100, "* name too long"),
//   admin_email: Yup.string()
//     .email("* Invalid email")
//     .required("* Email is required"),
//   store_address: Yup.string()
//     .required("* Store Address is required")
//     .max(250, "* Too Long!"),
//   store_phone_number: Yup.string()
//     .required("Store Phone # is required")
//     .matches(PHONE_REG_EXP, "* Phone number is not valid")
// });
const onboardSchema = Yup.object().shape({
  // store_name: Yup.string()
  //   .required("* Store Name is required")
  //   .max(100, "* name too long"),
  // store_address: Yup.string()
  //   .required("* Admin Name is required")
  //   .max(100, "* name too long"),
  admin_email: Yup.string().email("* Invalid email"),
  // .required("* Email is required"),
  // nexmo_number: Yup.string()
  //   .required("* Nexmo number is required")
  //   .max(10, "* number exceeded")
  // ach_number: Yup.string()
  //   .required("* ACH number is required")
  //   .max(10, "* number exceeded"),
  // subscription_price: Yup.string()
  //   .required("* Subscription Price required")
  //   .max(100, "* name too long"),
  // subscription_notes: Yup.string().max(100, "* name too long"),
  // name_on_credit_card: Yup.string()
  //   .required("* Credit Card name is required")
  //   .max(15, "* name  too long"),
  // credit_card_number: Yup.string()
  //   .required("* Credit Card is required")
  //   .max(8, "* Number exceeded"),
  // security_code: Yup.string()
  //   .required("* Security Code is required")
  //   .max(3, "* Number exceeded"),
  // expiration_date: Yup.string().required("* Date is required"),
  // billing_zip: Yup.string().required("* Zip code is required"),
  // subscription_start_date: Yup.string().required("* Security Code is required")
});
interface EditMobileAppProps {}

interface EditMobileAppState {
  edit: boolean;
}
interface MyFormikProps {
  scanning_before_delivery: boolean;
  scanning_at_destination: boolean;
  signature: boolean;
  photo: boolean;
  delivery_type: boolean;
}

type Props = EditMobileAppProps & LinkStateProps & LinkDispatchProps;

class EditMobileApp extends React.Component<Props, EditMobileAppState> {
  constructor(props: Props) {
    super(props);

    this.state = {
      edit: false,
    };
  }
  toggleEdit = () => this.setState((prev) => ({ edit: !prev.edit }));
  render() {
    const { edit } = this.state;
    return (
      <Formik
        initialValues={{
          scanning_before_delivery: false,
          scanning_at_destination: false,
          signature: false,
          photo: false,
          delivery_type: false,
        }}
        validationSchema={onboardSchema}
        onSubmit={async (values, { setFieldError, setStatus }) => {
          setStatus({ error: "", loading: true });
          setStatus({ loading: false });
          const requirementArray: any = [];
          values.scanning_before_delivery ? requirementArray.push(1) : null;
          values.scanning_at_destination ? requirementArray.push(2) : null;
          values.signature ? requirementArray.push(3) : null;
          values.photo ? requirementArray.push(4) : null;
          values.delivery_type ? requirementArray.push(5) : null;
          const { user_id, store_id, token } = this.props;
          try {
          } catch (err) {
            console.log(err);
          }
        }}
      >
        {({
          status,
          setStatus,
          submitForm,
          touched,
          errors,
        }: FormikProps<MyFormikProps>) => {
          console.log("status: ", status);
          return (
            <MobileAppContainer>
              <AccountSetupText>DRIVER MOBILE APP SETTINGS</AccountSetupText>
              <Div>
                <Grid>
                  <LeftGrid>
                    <TitleSetupText>DELIVERY REQUIREMENTS</TitleSetupText>
                    <Table>
                      <LabelText>Scanning Before Delivery</LabelText>
                      <OnboardCheckboxField
                        name="scanning_before_delivery"
                        disabled={!edit}
                      />
                      <LabelText>Scanning at Destination</LabelText>
                      <OnboardCheckboxField
                        name="scanning_at_destination"
                        disabled={!edit}
                      />
                      <LabelText>Signature</LabelText>
                      <OnboardCheckboxField name="signature" disabled={!edit} />
                      <LabelText>Photo</LabelText>
                      <OnboardCheckboxField name="photo" disabled={!edit} />
                      <LabelText>Delivery Type</LabelText>
                      <OnboardCheckboxField
                        name="delivery_type"
                        disabled={!edit}
                      />
                    </Table>
                  </LeftGrid>
                  <RightGrid>
                    <TitleSetupText>ACTIVE DELIVERY RUN</TitleSetupText>
                    <div>
                      <p
                        style={{
                          color: "#d8d8d8",
                          fontSize: "15.6px",
                        }}
                      >
                        Default Map View
                      </p>
                      <Collapsible />
                    </div>
                    <Div style={{ marginBottom: "55px" }} />
                    <Div style={{ marginBottom: "55px" }} />
                    <Div style={{ marginBottom: "55px" }} />
                  </RightGrid>
                </Grid>
              </Div>
              <ButtonDiv>
                {edit ? (
                  <>
                    <AuthButton type="submit" onClick={this.toggleEdit}>
                      Cancel
                    </AuthButton>
                    <AuthButton type="submit" onClick={submitForm}>
                      SAVE
                    </AuthButton>
                  </>
                ) : (
                  <>
                    <AuthButton type="submit" onClick={this.toggleEdit}>
                      EDIT
                    </AuthButton>
                    <NavLink to="/settings">
                      <AuthButton type="submit">RETURN TO SETTINGS</AuthButton>
                    </NavLink>
                  </>
                )}
              </ButtonDiv>
            </MobileAppContainer>
          );
        }}
      </Formik>
    );
  }
}

const MobileAppContainer = styled(Container)`
  background-image: linear-gradient(to left, #4ba5eb, #60999e);
  padding: 53px 50px 45px 75px;
  display: grid;
  grid-template-rows: 130px 1fr 1fr;
  background-size: cover;
  background-attachment: fixed;
  background-position: center center;
  overflow-x: auto;
  height: 100vh;
`;
const AccountSetupText = styled(AppText)`
  font-size: 27px;
  color: #fff;
`;

const StyledOnboardInputField = styled(OnboardInputField)`
  border-bottom: transparent;
`;
const TitleSetupText = styled(AppText)`
  font-size: 19px;
  color: #fff;
  margin-bottom: 50px;
`;
const LeftGrid = styled.div`
  display: grid;
  grid-column: 1;
  /* align-content: center; */
  justify-content: flex-start;
  padding-bottom: 60px;
  gap: 10px;
`;

const RightGrid = styled.div`
  display: grid;
  grid-column: 2;
  /* align-content: center; */
  justify-content: flex-start;
  padding-bottom: 60px;
  gap: 10px;
`;

const Grid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  padding-bottom: 0px;
`;
const Div = styled.div`
  display: grid;
  grid-template-rows: auto min-content;
`;

const SubscriptionGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column: 1;
  /* margin-bottom: 165px; */
`;

const SubLeft = styled.div`
  padding-right: 50px;
`;

const ButtonDiv = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
`;
const SubRight = styled.div``;

const UpdatePassword = styled.button`
  background-color: transparent;
  border: solid 1px white;
  height: 40px;
  width: 163px;
  border-radius: 2px;
  font-size: 13px;
  font-weight: 500;
  font-family: Avenir, Lato, sans-serif;
  color: white;
  :focus {
    outline: none;
  }
  :active {
    box-shadow: 0 3px 0 #dedfe2;
    top: 3px;
    transform: translateY(2px);
    background-color: #ffff;
    border: solid 1px white;
    font-size: 13px;
    font-weight: 500;
    font-family: Avenir, Lato, sans-serif;
    color: gray;
    /* opacity: 0.1; */
  }
`;

const Table = styled.div`
  display: grid;
  grid-template-columns: min-content min-content;
  white-space: nowrap;
  column-gap: 0px;
  row-gap: 25px;
  column-gap: 170px;
`;

const LabelText = styled(AppText)`
  font-size: 18px;
  color: #f7f7f7;
  font-weight: 300;
`;

interface LinkStateProps {
  token: string;
  admin_email: string;
  user_id: number;
  store_name: string;
  store_phone: string;
  store_address: string;
  store_id: number;
}

interface LinkDispatchProps {
  // signup: ({
  //   first_name,
  //   last_name,
  //   email,
  //   store_name,
  //   nexmo_num
  // }: {
  //   first_name: string;
  //   last_name: string;
  //   email: string;
  //   store_name: string;
  //   nexmo_num: string;
  // }) => void;
}

const mapDispatchToProps = (
  dispatch: Dispatch<AppActions>
): LinkDispatchProps => ({
  // signup: bindActionCreators(registerAdminThunk, dispatch)
});

const mapStateToProps = (state: AppState): LinkStateProps => ({
  token: state.auth.token as string,
  admin_email: state.user.email as string,
  user_id: state.user.id as number,
  store_name: state.store.name as string,
  store_phone: state.store.phone as string,
  store_address: state.store.address.address_1 as string,
  store_id: state.store.id as number,
});

export default connect(mapStateToProps, mapDispatchToProps)(EditMobileApp);
