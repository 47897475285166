import { User, UserActionTypes, LoggedUser } from "./../types/index";

// const userReducerDefaultState: User = {
//   user_id: null,
//   user_uuid: "",
//   user_email: "",
//   user_firebase_auth_id: "",
//   user_first_name: "",
//   user_last_name: "",
//   user_is_active: 1,
//   user_last_active_epoch: 1551653943,
//   user_profile_image: "",
//   user_token: null,
//   user_unread_notifications: 0,
//   user_num_vehicles: 0,
//   user_is_new: 0,
//   user_type: 0,
//   user_created_epoch: 1551653911740,
//   user_updated_epoch: 1551653943,
//   settings: {
//     setting_id: null,
//     user_id: null,
//     notification_is_enabled: 0
//   },
//   vehicle_preference: {
//     vehice_preference_id: null,
//     user_id: null,
//     vehicle_color: "",
//     vehicle_num_seats: 0,
//     vehicle_model: "",
//     vehicle_min_price: 1000,
//     vehicle_max_price: 20000,
//     vehicle_condition_is_new: 0,
//     vehicle_condition_is_used: 0,
//     updated_epoch: 1551653911746
//   }
// };

const userReducerDefaultState: LoggedUser = {
  email: "",
  first_name: "",
  id: null,
  is_active: null,
  is_registered: "",
  last_name: "",
  nexmo_num: "",
  store_id: null,
  updated_at: null,
  user_type_id: null,
  phone: null
};
// This reducer holds user information that is returned from login/signup
// post requests

const userReducer = (
  state = userReducerDefaultState,
  action: UserActionTypes
): LoggedUser => {
  switch (action.type) {
    // case "FETCH_USER":
    //   return { ...action.user };
    case "EDIT_USER":
      return { ...state, ...action.user };
    case "GET_USER":
      return { ...state, ...action.user };
    case "EDIT_USER_PHONE":
      return { ...state, phone: action.phoneNumber };
    case "LOGIN":
      return { ...state, store_id: action.store_id as number };
    case "LOGOUT":
      return userReducerDefaultState;
    default:
      return state;
  }
};

export default userReducer;
