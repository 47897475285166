import {
  FETCH_PROPERTIES,
  AppActions,
  EDIT_PROPERTY,
  ADD_PROPERTY,
  REMOVE_PROPERTY,
  CustomFields,
  UPDATE_DRIVER_CUSTOM_FIELD_VALUE,
  GET_DRIVER_CUSTOM_FIELD_VALUES,
  CustomFieldByEntity,
  EDIT_ORDER_PROPERTY,
  FETCH_ORDER_PROPERTIES,
  ADD_ORDER_PROPERTY,
  REMOVE_ORDER_PROPERTY,
  GET_ORDER_CUSTOM_FIELD_VALUES,
  UPDATE_ORDER_CUSTOM_FIELD_VALUE,
  ADD_DELIVERY_PROPERTY,
  EDIT_DELIVERY_PROPERTY,
  FETCH_DELIVERY_PROPERTIES,
  REMOVE_DELIVERY_PROPERTY,
  GET_DELIVERY_CUSTOM_FIELD_VALUES,
  UPDATE_DELIVERY_CUSTOM_FIELD_VALUE,
  ADD_DELIVERY_RUN_PROPERTY,
  EDIT_DELIVERY_RUN_PROPERTY,
  FETCH_DELIVERY_RUN_PROPERTIES,
  REMOVE_DELIVERY_RUN_PROPERTY,
  GET_DELIVERY_RUN_CUSTOM_FIELD_VALUES,
  UPDATE_DELIVERY_RUN_CUSTOM_FIELD_VALUE
} from "../types";
import App from "../App";

// driver actions
export const addProperty = (property: CustomFields): AppActions => ({
  type: ADD_PROPERTY,
  property
});

export const editProperty = (
  property_id: number,
  property: CustomFields
): AppActions => ({
  type: EDIT_PROPERTY,
  property_id,
  property
});

export const fetchProperties = (property: CustomFields[]): AppActions => ({
  type: FETCH_PROPERTIES,
  property
});

export const removeProperty = (property_id: number): AppActions => ({
  type: REMOVE_PROPERTY,
  property_id
});

export const getDriverCustomFieldValues = (
  customValues: CustomFieldByEntity[]
): AppActions => ({
  type: GET_DRIVER_CUSTOM_FIELD_VALUES,
  customValues
});

export const updateDriverCustomFieldValue = (
  value: string,
  id: number,
  custom_field_id: number
): AppActions => ({
  type: UPDATE_DRIVER_CUSTOM_FIELD_VALUE,
  value,
  id,
  custom_field_id
});

//order actions

export const addOrderProperty = (property: CustomFields): AppActions => ({
  type: ADD_ORDER_PROPERTY,
  property
});

export const editOrderProperty = (
  property_id: number,
  property: CustomFields
): AppActions => ({
  type: EDIT_ORDER_PROPERTY,
  property_id,
  property
});

export const fetchOrderProperties = (property: CustomFields[]): AppActions => ({
  type: FETCH_ORDER_PROPERTIES,
  property
});

export const removeOrderProperty = (property_id: number): AppActions => ({
  type: REMOVE_ORDER_PROPERTY,
  property_id
});

export const getOrderCustomFieldValues = (
  customValues: CustomFieldByEntity[]
): AppActions => ({
  type: GET_ORDER_CUSTOM_FIELD_VALUES,
  customValues
});

export const updateOrderCustomFieldValue = (
  value: string,
  id: number,
  custom_field_id: number
): AppActions => ({
  type: UPDATE_ORDER_CUSTOM_FIELD_VALUE,
  value,
  id,
  custom_field_id
});

//delivery actions

export const addDeliveryProperty = (property: CustomFields): AppActions => ({
  type: ADD_DELIVERY_PROPERTY,
  property
});

export const editDeliveryProperty = (
  property_id: number,
  property: CustomFields
): AppActions => ({
  type: EDIT_DELIVERY_PROPERTY,
  property_id,
  property
});

export const fetchDeliveryProperties = (
  property: CustomFields[]
): AppActions => ({
  type: FETCH_DELIVERY_PROPERTIES,
  property
});

export const removeDeliveryProperty = (property_id: number): AppActions => ({
  type: REMOVE_DELIVERY_PROPERTY,
  property_id
});

export const getDeliveryCustomFieldValues = (
  customValues: CustomFieldByEntity[]
): AppActions => ({
  type: GET_DELIVERY_CUSTOM_FIELD_VALUES,
  customValues
});

export const updateDeliveryCustomFieldValue = (
  value: string,
  id: number,
  custom_field_id: number
): AppActions => ({
  type: UPDATE_DELIVERY_CUSTOM_FIELD_VALUE,
  value,
  id,
  custom_field_id
});

//delivery run actions

export const addDeliveryRunProperty = (property: CustomFields): AppActions => ({
  type: ADD_DELIVERY_RUN_PROPERTY,
  property
});

export const editDeliveryRunProperty = (
  property_id: number,
  property: CustomFields
): AppActions => ({
  type: EDIT_DELIVERY_RUN_PROPERTY,
  property_id,
  property
});

export const fetchDeliveryRunProperties = (
  property: CustomFields[]
): AppActions => ({
  type: FETCH_DELIVERY_RUN_PROPERTIES,
  property
});

export const removeDeliveryRunProperty = (property_id: number): AppActions => ({
  type: REMOVE_DELIVERY_RUN_PROPERTY,
  property_id
});

export const getDeliveryRunCustomFieldValues = (
  customValues: CustomFieldByEntity[]
): AppActions => ({
  type: GET_DELIVERY_RUN_CUSTOM_FIELD_VALUES,
  customValues
});

export const updateDeliveryRunCustomFieldValue = (
  value: string,
  id: number,
  custom_field_id: number
): AppActions => ({
  type: UPDATE_DELIVERY_RUN_CUSTOM_FIELD_VALUE,
  value,
  id,
  custom_field_id
});
