import React from "react";
import NumberFormat from "react-number-format";
import styled from "styled-components";
import { AppText } from "../UI";
import { Field, FieldProps, ErrorMessage } from "formik";

interface MyNumberInputProps {
  format?: string;
  mask?: string;
  title: string;
  name: string;
  style?: any;
}

// interface MyNumberInputState {}

// type Props = MyNumberInputProps;
// class MyNumberInput extends React.Component<Props, MyNumberInputState> {
//   state = {
//     value: ""
//   };
//   render() {
//     return
export const MyNumberInput = ({
  name,
  title,
  placeholder,
  style,
  inputWidth,
  type = "text",
  formatValue,
  format,
  disabled
}: {
  name: string;
  title?: string;
  placeholder: string;
  style?: React.CSSProperties;
  inputWidth?: string;
  type?: string;
  formatValue?: (p: string) => string;
  format: string;
  disabled?: boolean;
}) => (
  <div>
    <Field
      name={name}
      render={({ field, form: { errors, touched } }: FieldProps) => {
        return (
          <FormSegment>
            <AppTextFormTitle>{title}</AppTextFormTitle>
            <ThisInput
              format={"(###) ###-####"}
              // isNumericString={true}
              {...field}
              // value={this.state.value}
              // onValueChange={vals =>
              //   this.setState({ value: vals.formattedValue })
              // }
              // {...this.props}
              // error={errors}
              placeholder={placeholder}
              // inputWidth={inputWidth}
              type={"text"}
              disabled={disabled}
            />
          </FormSegment>
        );
      }}
    />
  </div>
);

export default MyNumberInput;

// const Div = styled.div`
//   display: flex;
//   flex-direction: row;
//   justify-content: center;
// `;

const FormSegment = styled.div``;

const AppTextFormTitle = styled(AppText)`
  font-size: 15.8px;
  color: #d8d8d8;
  font-weight: 300;
`;

const ThisInput = styled(NumberFormat)`
  padding: 0;
  background: transparent;
  border-width: 0;
  font-size: 18.7px;
  color: #f7f7f7;
  outline: "none";
  border-width: ${props => (!!props.value ? 0 : "0 0 1px 0")};
  box-shadow: none;
  font-weight: 300;
  border-bottom: none;
  ::placeholder {
    color: #f7f7f7;
    font-size: 14.4px;
    font-family: ${props => props.theme.PRIMARY_FONT};
    opacity: 0.5;
  }
  :focus {
    box-shadow: none;
    outline: none;
    border-width: 0;
  }
  :disabled {
    background: transparent;
  }
`;
