import * as React from "react";
import { connect } from "react-redux";
import { AppState } from "../../../store/configureStore";
import { AppActions, LoggedUser } from "../../../types";
import { Dispatch, bindActionCreators } from "redux";
import * as Yup from "yup";
import {
  GeneralInfo,
  MyCommunicationPreferences,
  CustomerCommunicationPreferences
} from "../../Segments/onboarding";
import styled from "styled-components";
import { Container, AppText, HR, Button } from "../../UI";
import { TABLET_BREAKPOINT, PHONE_REG_EXP } from "../../../utils";
import {
  onboardNextPage,
  onboardPrevPage,
  startSetOnboardingPrefs,
  clearOnboardingPrefs
} from "../../../actions/onboarding";
import { Formik } from "formik";
import { getStoreThunk } from "../../../api/store";
import { getStore } from "../../../actions/store";
import { UserStore } from "../../../types/store";
import { NavLink } from "react-router-dom";
import DriverCustomProperties from "./CustomPropertiesPages/DriverCustomProperties";
import OrderCustomProperties from "./CustomPropertiesPages/OrderCustomProperties";
import DeliveryCustomProperties from "./CustomPropertiesPages/DeliveryCustomProperties";
import DeliveryRunCustomProperties from "./CustomPropertiesPages/DeliveryRunCustomProperties";

interface EditCustomPropertiesProps {}

interface EditCustomPropertiesState {
  currentPage: string;
}

type Props = EditCustomPropertiesProps & LinkStateProps & LinkDispatchProps;

class EditCustomProperties extends React.Component<
  Props,
  EditCustomPropertiesState
> {
  constructor(props: Props) {
    super(props);
    this.state = {
      currentPage: "drivers"
    };
  }
  renderPage = (page): JSX.Element => {
    switch (page) {
      case "drivers":
        return <DriverCustomProperties />;
      case "orders":
        return <OrderCustomProperties />;
      case "deliveries":
        return <DeliveryCustomProperties />;
      case "delivery_runs":
        return <DeliveryRunCustomProperties />;
      default:
        return <div>drivers</div>;
    }
  };
  componentDidMount = () => {
    // this.props.clearOnboardingPrefs();
    const { user_store_id } = this.props;
    // this.props.getStore(user_store_id);
    //get store info
  };
  render(): JSX.Element {
    return (
      <OnboardingContainer>
        <AccountSetupText>MANAGE CUSTOM PROPERTIES</AccountSetupText>
        <CustomPropContainer>
          <TrackerDiv>
            <PageTitleName
              selected={this.state.currentPage === "drivers"}
              onClick={() => this.setState({ currentPage: "drivers" })}
            >
              DRIVERS
            </PageTitleName>

            <PageTitleName
              selected={this.state.currentPage === "orders"}
              onClick={() => this.setState({ currentPage: "orders" })}
            >
              ORDERS
            </PageTitleName>

            <PageTitleName
              selected={this.state.currentPage === "deliveries"}
              onClick={() => this.setState({ currentPage: "deliveries" })}
            >
              DELIVERIES
            </PageTitleName>

            <PageTitleName
              selected={this.state.currentPage === "delivery_runs"}
              onClick={() => this.setState({ currentPage: "delivery_runs" })}
            >
              DELIVERY RUNS
            </PageTitleName>
          </TrackerDiv>
          {this.renderPage(this.state.currentPage)}
        </CustomPropContainer>
      </OnboardingContainer>
    );
  }
}

interface TitleNameProps {
  selected: boolean;
}

const OnboardingContainer = styled(Container)`
  background-image: linear-gradient(to left, #4ba5eb, #60999e);
  padding: 53px 50px 45px 75px;
  display: grid;
  grid-template-rows: 70px 800px 100px;
  background-size: cover;
  background-attachment: fixed;
  background-position: center center;
  overflow-x: auto;
  height: 100vh;
`;

const TrackerDiv = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  /* margin: 0 3px; */
  width: 60vw;
  grid-row: 2;
`;

const PageTitleName = styled(AppText)<TitleNameProps>`
  font-size: 21px;
  text-align: center;
  color: #ffffff;
  opacity: ${props => (props.selected ? "1" : "0.6")}
  /* position: absolute; */
  top: 25px;
  white-space: nowrap;
  @media (max-width: ${TABLET_BREAKPOINT}px) {
    white-space: normal;
  }
  :hover {
    cursor: pointer;
  }
`;

export const AuthButton = styled(Button)`
  width: 269px;
  height: 40px;
  background-color: white;
  color: ${props => props.theme.PRIMARY_COLOR};
`;

const AccountSetupText = styled(AppText)`
  font-size: 27px;
  color: #fff;
`;

const OpenCircle = styled.div`
  width: 15px;
  min-width: 15px;
  height: 15px;
  border: solid 2px #ffffff;
  border-radius: 50%;
  /* box-sizing: content-box; */
  position: relative;
  display: flex;
  justify-content: center;
`;

const ThisHR = styled(HR)`
  max-width: 313px;
`;

const FilledCircle = styled(OpenCircle)`
  background-color: #fff;
`;

const CustomPropContainer = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
`;

const Spread = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
`;
interface LinkStateProps {
  user_store_id: any;
  store: UserStore;
  user: any;
  auth: any;
}

interface LinkDispatchProps {
  getStore: (store_id: number) => void;
}

const mapDispatchToProps = (
  dispatch: Dispatch<AppActions>
): LinkDispatchProps => ({
  getStore: bindActionCreators(getStoreThunk, dispatch)
});

// isAuthenticated is true if user_token exists in redux
const mapStateToProps = (state: AppState): LinkStateProps => ({
  user_store_id: state.user.store_id,
  store: state.store,
  user: state.user,
  auth: state.auth
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EditCustomProperties);
