import * as React from "react";
import { connect } from "react-redux";
import { withRouter, RouteComponentProps } from "react-router";
import styled from "styled-components";
// import BackArrow from "react-icons/lib/md/chevron-left";
import { MdChevronLeft } from "react-icons/md";

import * as Yup from "yup";
import {
  Container,
  SecondaryButton,
  Button,
  HR,
  Spinner,
  AppText,
} from "../UI";
import { FieldDetail, ColorItem } from "../Dumb";
// import { Toggle } from "react-toggle-component";
import { PRIMARY_GRADIENT_FROM } from "../../utils";
import { Formik, FormikProps, FieldArray } from "formik";
import {
  InputField,
  SelectField,
  CheckboxField,
  OnboardCheckboxField,
} from "../form";
import Switch from "react-toggle-switch";
import "react-toggle-switch/dist/css/switch.min.css";
import { api } from "../../api/api";
import { OrderDetailType } from "../../api/responseTypes";
import { AppState } from "../../store/configureStore";

import { Dispatch, bindActionCreators } from "redux";
import {
  AppActions,
  CustomFieldByEntity,
  CustomFields,
  CustomFieldsByEntityListValue,
} from "../../types";
import { states } from "../../static/states";
import { phones } from "../../static/phones";
import bwipjs from "bwip-js";
import {
  barcodeSampleArray,
  convertBarcodeTypesToLibraryTypes,
} from "../../static/barcodes";
import { Link } from "react-router-dom";

interface RouterProps {
  id: string;
}

interface FieldArray {
  custom_field_id: number;
  field: number | undefined;
  label: string;
  slug: string;
  value: string | null;
  filter_type: number;
  listValues: CustomFieldsByEntityListValue[];
}

interface MyFormikProps {
  custom_fields: FieldArray[];
}

interface OrderDetailProps {}
interface OrderDetailState {
  edit: boolean;
  toggle: boolean;
  loading: boolean;
  order: OrderDetailType;
  custom_fields: FieldArray[];
}

type Props = RouteComponentProps<RouterProps> &
  OrderDetailProps &
  LinkStateProps &
  LinkDispatchProps;

class OrderDetail extends React.Component<Props, OrderDetailState> {
  constructor(props: Props) {
    super(props);

    this.state = {
      edit: false,
      toggle: false,
      loading: true,
      order: {
        id: 0,
        driver_id: null,
        customer_id: null,
        address_id: 0,
        status: null,
        num_boxes: null,
        amount_to_collect: null,
        description: null,
        notes: "",
        created_at: "",
        updated_at: "",
        deleted_at: null,
        reference: null,
        date_imported: null,
        address_relevance: 0,
        delivered: null,
        delivery_date: null,
        address: {
          id: 0,
          address_1: "",
          address_2: "",
          city: "",
          state: "",
          zip: "",
          created_at: "",
          updated_at: "",
          deleted_at: null,
          latitude: 0,
          longitude: 0,
          notes: null,
        },
        customer: {
          address_id: 0,
          created_at: "",
          deleted_at: "",
          email: "",
          first_name: "",
          id: 0,
          last_name: "",
          phone: "",
          phone_type: "",
          subscription_price: "",
          updated_at: "",
        },
        packages: [],
      },
      custom_fields: [],
    };
  }

  toggleEdit = () => this.setState((prev) => ({ edit: !prev.edit }));

  deleteOrder = async () => {
    const id = this.props.match.params.id;
    await api.deleteOrder({ id }, this.props.token);
    this.props.history.goBack();
  };

  componentDidMount = async () => {
    const id = this.props.match.params.id;
    const order = await api.getOrder({ id }, this.props.token);
    console.log("order---", order);
    const customValues = (await api.getAllCustomProperties(
      id,
      this.props.token,
      "Order"
    )) as CustomFieldByEntity[];
    const finalList =
      this.props.allCustomFields && customValues
        ? customValues.map((field) => {
            const slug = this.props.allCustomFields
              .filter(
                (allcustomFields) =>
                  allcustomFields.id === field.custom_field.id
              )
              .map((obj) => {
                return {
                  display: obj.display,
                  slug: obj.slug,
                  filter_id: obj.field_type_id,
                };
              })[0];
            return {
              field: field.id,
              value: field.value,
              custom_field_id: field.custom_field.id,
              label: slug ? slug.display : "",
              slug: slug ? slug.slug : "",
              filter: slug ? slug.filter_id : "",
              listValues: field.custom_field.listValues,
              filter_type: field.custom_field.field_type_id,
            };
          })
        : [];

    this.setState({
      order: !!order ? order : this.state.order,
      loading: false,
      custom_fields: finalList,
    });
    // window.addEventListener("load", this.drawCanvas);
  };

  goToNewPage = () => {};

  render() {
    const {
      edit,
      order,
      order: {
        address: { address_1, address_2, city, state, zip },
        customer: { first_name, last_name, phone, phone_type, email },
        created_at,
      },
      loading,
      custom_fields,
    } = this.state;
    const { token } = this.props;
    const {
      history: { goBack },
    } = this.props;
    console.log("order detail state", this.state);
    if (loading) {
      return <Spinner />;
    }
    return (
      <Container>
        <Div>
          <Horizontal onClick={goBack}>
            <MdChevronLeft color={PRIMARY_GRADIENT_FROM} size={42} />
            {/* <img src={arrowLeft} color={PRIMARY_GRADIENT_FROM} /> */}
            <ReturnText>RETURN TO ORDERS</ReturnText>
          </Horizontal>
          <Formik
            initialValues={{
              // amount_to_collect: this.state.order.amount_to_collect,
              num_boxes: this.state.order.num_boxes,
              description: this.state.order.description,
              notes: this.state.order.notes,
              address_1: this.state.order.address.address_1,
              city: this.state.order.address.city,
              state: this.state.order.address.state,
              zip: this.state.order.address.zip,
              first_name: first_name,
              last_name: last_name,
              phone: phone,
              phone_type: phone_type,
              email: email,
              custom_fields: this.state.custom_fields,
            }}
            // validationSchema={}
            onSubmit={async (values, { setFieldError, setStatus }) => {
              const { token } = this.props;
              setStatus({ error: "", loading: true });
              const response = await api.editOrder(
                { ...values },
                parseInt(this.props.match.params.id),
                token
              );
              values.custom_fields.forEach((field) => {
                if (!!field.field) {
                  api.updateCustomFieldValue(
                    { id: field.field, value: field.value as string },
                    token
                  );
                }
                if (!field.field && field.value) {
                  api.createCustomFieldValue(
                    {
                      custom_field_id: field.custom_field_id,
                      entity_id: parseInt(this.props.match.params.id),
                      value: field.value,
                    },
                    token
                  );
                }
              });
              this.setState(
                (prev) => ({
                  order: { ...prev.order, ...response },
                  custom_fields: values.custom_fields,
                }),
                () => {
                  this.toggleEdit();
                  setStatus({ loading: false });
                }
              );
            }}
          >
            {({
              status,
              setStatus,
              submitForm,
              values,
            }: FormikProps<MyFormikProps>) => {
              return (
                <Main>
                  <Spread>
                    <Horizontal>
                      <NameText>{`${first_name.toUpperCase()} ${last_name.toUpperCase()}`}</NameText>
                    </Horizontal>
                    <Horizontal>
                      {edit ? (
                        <>
                          <CancelText clickable onClick={this.toggleEdit}>
                            Cancel
                          </CancelText>
                          <SaveButton type="submit" onClick={submitForm}>
                            Save
                          </SaveButton>
                        </>
                      ) : (
                        <>
                          <Link
                            to={`/order_barcode/${this.props.match.params.id}`}
                          >
                            <ShowBarcodesButton
                              type="button"
                              style={{
                                alignSelf: "center",
                                gridRow: "2 / span 1",
                                gridColumn: "1 / span 1",
                              }}
                            >
                              Show Barcodes
                            </ShowBarcodesButton>
                          </Link>
                          <SecondaryButton
                            style={{ width: "130px", marginLeft: "12px" }}
                            onClick={this.toggleEdit}
                            type="button"
                          >
                            Edit Order
                          </SecondaryButton>
                          <DeleteButton
                            onClick={this.deleteOrder}
                            style={{ width: "130px", marginLeft: "12px" }}
                            type="button"
                          >
                            Delete Order
                          </DeleteButton>
                        </>
                      )}
                    </Horizontal>
                  </Spread>
                  <ThisHR />
                  <Grid>
                    <SectionTitle style={{ alignSelf: "center" }}>
                      SYSTEM PROPERTIES
                    </SectionTitle>
                    {edit ? (
                      <>
                        <InputField
                          style={{
                            gridRow: "2 / span 1",
                            gridColumn: "1 / span 1",
                          }}
                          name="first_name"
                          placeholder="Customer First Name"
                          title="Customer First Name"
                        />
                        <InputField
                          style={{
                            gridRow: "2 / span 1",
                            gridColumn: "2 / span 1",
                          }}
                          name="last_name"
                          placeholder="Customer Last name"
                          title="Customer Last name"
                        />
                        <InputField
                          style={{
                            gridRow: "2 / span 1",
                            gridColumn: "3 / span 1",
                          }}
                          name="email"
                          placeholder="Customer Email"
                          title="Customer Email"
                        />
                        <InputField
                          style={{
                            gridRow: "3 / span 1",
                            gridColumn: "1 / span 1",
                          }}
                          name="phone"
                          placeholder="Customer Phone"
                          title="Customer Phone"
                        />
                        <SelectField
                          name="phone_type"
                          placeholder=""
                          title="Phone Types"
                          options={phones}
                          style={{
                            gridRow: "3 / span 1",
                            gridColumn: "2 / span 1",
                          }}
                          inputWidth={130}
                        />
                        <FieldDetail
                          style={{
                            gridRow: "3 / span 1",
                            gridColumn: "3 / span 1",
                          }}
                          title="Order ID"
                          value={`${this.state.order.id}`}
                        />
                        <FieldDetail
                          style={{
                            gridRow: "4 / span 1",
                            gridColumn: "1 / span 1",
                          }}
                          title="Date Imported"
                          value={
                            this.state.order.date_imported
                              ? this.state.order.date_imported
                              : "N/A"
                          }
                        />
                        <FieldDetail
                          style={{
                            gridRow: "4 / span 1",
                            gridColumn: "2 / span 1",
                          }}
                          title="Time Since Imported"
                          value={created_at}
                        />
                        <FieldDetail
                          style={{
                            gridRow: "4 / span 1",
                            gridColumn: "3 / span 1",
                          }}
                          title="Last Modified Date"
                          value={
                            this.state.order.updated_at
                              ? this.state.order.updated_at
                              : "N/A"
                          }
                        />
                        <div
                          style={{
                            gridRow: "5 / span 1",
                            gridColumn: "1 / span 1",
                          }}
                        >
                          <InputField
                            name="address_1"
                            placeholder="Address"
                            title="Address"
                          />
                          <InputField
                            name="city"
                            placeholder="City"
                            title="City"
                          />
                          <SelectField
                            name="state"
                            placeholder=""
                            title="State"
                            options={states}
                            inputWidth={100}
                          />
                          <InputField
                            name="zip"
                            placeholder="Zipcode"
                            title="Zipcode"
                          />
                        </div>
                        <FieldDetail
                          style={{
                            gridRow: "5 / span 1",
                            gridColumn: "2 / span 1",
                          }}
                          title="Store Code"
                          value={`${this.state.order.id}`}
                        />
                        <FieldDetail
                          style={{
                            gridRow: "5 / span 1",
                            gridColumn: "3 / span 1",
                          }}
                          title="Store ID"
                          value={`${this.props.store_id}`}
                        />
                        <InputField
                          style={{
                            gridRow: "6 / span 1",
                            gridColumn: "1 / span 1",
                          }}
                          name="num_boxes"
                          placeholder="Number of Boxes"
                          title="Number of Boxes"
                        />
                        <InputField
                          style={{
                            gridRow: "6 / span 1",
                            gridColumn: "2 / span 1",
                          }}
                          name="description"
                          placeholder="Item description"
                          title="Item Description"
                        />
                        <InputField
                          style={{
                            gridRow: "6 / span 1",
                            gridColumn: "3 / span 1",
                          }}
                          name="notes"
                          placeholder="Notes"
                          title="Notes"
                        />
                      </>
                    ) : (
                      <>
                        <FieldDetail
                          style={{
                            gridRow: "2 / span 1",
                            gridColumn: "1 / span 1",
                          }}
                          title="Customer First Name"
                          value={`${first_name}`}
                        />
                        <FieldDetail
                          style={{
                            gridRow: "2 / span 1",
                            gridColumn: "2 / span 1",
                          }}
                          title="Customer Last Name"
                          value={`${last_name}`}
                        />
                        <FieldDetail
                          style={{
                            gridRow: "2 / span 1",
                            gridColumn: "3 / span 1",
                          }}
                          title="Customer Email"
                          value={`${email}`}
                        />
                        <FieldDetail
                          style={{
                            gridRow: "3 / span 1",
                            gridColumn: "1 / span 1",
                          }}
                          title="Customer Phone"
                          value={`${phone}`}
                        />
                        <FieldDetail
                          style={{
                            gridRow: "3 / span 1",
                            gridColumn: "2 / span 1",
                          }}
                          title="Phone Type"
                          value={phone_type ? `${phone_type}` : `N/A`}
                        />
                        <FieldDetail
                          style={{
                            gridRow: "3 / span 1",
                            gridColumn: "3 / span 1",
                          }}
                          title="Order ID"
                          value={`${this.state.order.id}`}
                        />
                        <FieldDetail
                          style={{
                            gridRow: "4 / span 1",
                            gridColumn: "1 / span 1",
                          }}
                          title="Date Imported"
                          value={
                            this.state.order.date_imported
                              ? this.state.order.date_imported
                              : "N/A"
                          }
                        />
                        <FieldDetail
                          style={{
                            gridRow: "4 / span 1",
                            gridColumn: "2 / span 1",
                          }}
                          title="Time Since Imported"
                          value={created_at}
                        />
                        <FieldDetail
                          style={{
                            gridRow: "4 / span 1",
                            gridColumn: "3 / span 1",
                          }}
                          title="Last Modified Date"
                          value={
                            this.state.order.updated_at
                              ? this.state.order.updated_at
                              : "N/A"
                          }
                        />
                        <FieldDetail
                          style={{
                            gridRow: "5 / span 1",
                            gridColumn: "1 / span 1",
                          }}
                          title="Delivery Address"
                          value={`${address_1}\n${city}, ${state}, ${zip}`}
                        />
                        <FieldDetail
                          style={{
                            gridRow: "5 / span 1",
                            gridColumn: "2 / span 1",
                          }}
                          title="Store Code"
                          value={`${this.state.order.id}`}
                        />
                        <FieldDetail
                          style={{
                            gridRow: "5 / span 1",
                            gridColumn: "3 / span 1",
                          }}
                          title="Store ID"
                          value={`${this.props.store_id}`}
                        />
                        <FieldDetail
                          style={{
                            gridRow: "6 / span 1",
                            gridColumn: "1 / span 1",
                          }}
                          title="Number of Boxes"
                          value={`${
                            this.state.order.num_boxes
                              ? this.state.order.num_boxes
                              : 0
                          }`}
                        />
                        <FieldDetail
                          style={{
                            gridRow: "6 / span 1",
                            gridColumn: "2 / span 1",
                          }}
                          title="Item Description"
                          value={`${
                            this.state.order.description
                              ? this.state.order.description
                              : ""
                          }`}
                        />
                        <FieldDetail
                          style={{
                            gridRow: "6 / span 1",
                            gridColumn: "3 / span 1",
                          }}
                          title="Notes"
                          value={`${
                            this.state.order.notes ? this.state.order.notes : ""
                          }`}
                        />
                      </>
                    )}
                    <ThisHR
                      style={{
                        gridRow: "7 / span 1",
                        gridColumn: "1 / span 3",
                      }}
                    />
                  </Grid>
                  <Grid>
                    <SectionTitle
                      style={{
                        alignSelf: "center",
                        gridRow: "1 / span 1",
                        gridColumn: "1 / span 1",
                      }}
                    >
                      CUSTOM PROPERTIES
                    </SectionTitle>
                    <EmptyDiv
                      style={{
                        gridRow: "1 / span 1",
                        gridColumn: "2 / span 1",
                      }}
                    />
                    <EmptyDiv
                      style={{
                        gridRow: "1 / span 1",
                        gridColumn: "3 / span 1",
                      }}
                    />
                    {edit ? (
                      <>
                        <FieldArray
                          name="custom_fields"
                          render={(arrayHelpers) => (
                            <>
                              {values.custom_fields.map((fields, index) => {
                                if (fields.filter_type === 3) {
                                  const optionItems = fields.listValues.map(
                                    (values) => {
                                      return {
                                        label: values.value,
                                        data: values.value,
                                        value: values.value,
                                      };
                                    }
                                  );
                                  return (
                                    <div key={fields.custom_field_id}>
                                      <SelectField
                                        name={`custom_fields[${index}].value`}
                                        placeholder={
                                          fields.value ? fields.value : ""
                                        }
                                        options={optionItems}
                                        title={fields.label}
                                      />
                                    </div>
                                  );
                                }
                                if (fields.filter_type === 6) {
                                  return (
                                    <div key={fields.custom_field_id}>
                                      <ValueText>{fields.label}</ValueText>
                                      <CheckboxField
                                        title={``}
                                        name={`custom_fields[${index}].value`}
                                      />
                                    </div>
                                  );
                                }
                                return (
                                  <div key={fields.custom_field_id}>
                                    <InputField
                                      name={`custom_fields[${index}].value`}
                                      placeholder={
                                        fields.value ? fields.value : ""
                                      }
                                      title={fields.label}
                                    />
                                  </div>
                                );
                              })}
                            </>
                          )}
                        />
                      </>
                    ) : (
                      <>
                        {custom_fields.length > 0
                          ? custom_fields.map((values) => {
                              if (values.filter_type === 6) {
                                return (
                                  <FieldDetail
                                    title={values.label}
                                    value={values.value ? "true" : "false"}
                                  />
                                );
                              }
                              return (
                                <FieldDetail
                                  title={values.label}
                                  value={values.value ? values.value : ""}
                                />
                              );
                            })
                          : null}
                      </>
                    )}
                  </Grid>
                  <EmptyGrid>
                    <ThisHR
                      style={{
                        gridRow: "1 / span 1",
                        gridColumn: "1 / span 3",
                      }}
                    />
                  </EmptyGrid>
                </Main>
              );
            }}
          </Formik>
        </Div>
      </Container>
    );
  }
}

const Div = styled.div`
  display: flex;
  flex-direction: column;
`;

const Main = styled(Div)`
  margin-left: 90px;
`;

const Spread = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-end;
`;

const EmptyDiv = styled.div``;

const Grid = styled.div`
  display: grid;
  grid-template-rows: min-content 50px 50px 50px min-content;
  grid-template-columns: 400px 400px 400px;
  row-gap: 25px;
`;

const EmptyGrid = styled.div`
  display: grid;
  grid-template-rows: min-content 50px;
  grid-template-columns: 400px;
  row-gap: 25px;
`;

const Horizontal = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: min-content;
  white-space: nowrap;
  :hover {
    cursor: pointer;
  }
`;

const ReturnText = styled(AppText)`
  font-size: 15px;
  font-weight: 600;
  letter-spacing: 0.3px;
  color: ${(props) => props.theme.PRIMARY_GRADIENT_FROM};
  margin-left: 17px;
`;

const ThisColorItem = styled(ColorItem)`
  height: 24px;
  width: 24px;
  margin: 0;
`;
const CancelText = styled(AppText)`
  font-size: 13px;
  font-weight: 500;
  color: #3b3a3a;
  margin-right: 65px;
`;

const DeleteButton = styled(Button)`
  background-color: #c4183c;
`;

const SaveButton = styled(Button)`
  width: 130px;
  height: 40px;
`;

const SectionTitle = styled(AppText)`
  font-size: 15px;
  font-weight: 900;
  color: #3b3a3a;
`;
const ThisHR = styled(HR)`
  margin: 15px 0 25px 0;
`;
const NameText = styled(AppText)`
  opacity: 0.9;
  font-size: 18px;
  font-weight: 500;
  letter-spacing: 0.2px;
  color: #3b3a3a;
  margin-left: 17px;
`;

const ValueText = styled(AppText)`
  opacity: 0.5;
  font-size: 14px;
  font-weight: 500;
  color: #3b3a3a;
  margin-bottom: 7px;
`;

const ShowBarcodesButton = styled(Button)`
  width: 130px;
  height: 40px;
`;

const BarcodesContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  margin-top: 20px;
`;

const StyledBarcodes = styled.canvas`
  width: 175px;
  height: 100px;
  margin-bottom: 10px;
`;

interface LinkStateProps {
  token: string;
  customValues: CustomFieldByEntity[];
  allCustomFields: CustomFields[];
  barcode_type: string;
  store_id: number | null;
}
interface LinkDispatchProps {}

const mapDispatchToProps = (
  dispatch: Dispatch<AppActions>
): LinkDispatchProps => ({});
// isAuthenticated is true if user_token exists in redux
const mapStateToProps = (state: AppState): LinkStateProps => ({
  customValues: state.orderCustomValues as CustomFieldByEntity[],
  allCustomFields: state.customOrderFields as CustomFields[],
  token: state.auth.token as string,
  barcode_type: state.store.barcode_type,
  store_id: state.store.id,
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(OrderDetail));
