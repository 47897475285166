import React from "react";
import ReactSelect, { components } from "react-select";
import { Styles } from "react-select/lib/styles";
import { Option } from "react-select/lib/filters";
import { PRIMARY_FONT } from "../../utils";
import { Icon, Checkbox } from ".";
import { FieldArrayRenderProps } from "formik";
import { dropdownIcon } from "../../img";

// * Description of react-select components
//   https://react-select.com/components
//   https://react-select.com/props#dropdownindicator

// TODO: figure out how to do types on this component

interface Props {
  inputWidth?: number;
  error?: boolean;
  arrayHelpers?: FieldArrayRenderProps;
  options: Option[];
}

export const Select = ({
  options,
  field,
  form,
  inputWidth = 236.7,
  error = false,
  // arrayHelpers,
  ...props
}): any => {
  const DropdownIndicator = (props: any) => {
    return (
      components.DropdownIndicator && (
        <components.DropdownIndicator {...props}>
          <Icon src={dropdownIcon} />
        </components.DropdownIndicator>
      )
    );
  };

  const Option = (OptionProps: any) => {
    if (!props.isMulti) {
      return <components.Option {...OptionProps} />;
    }
    return (
      <components.Option {...OptionProps}>
        <Checkbox checked={OptionProps.isSelected} onChange={() => null} />
        <label>{OptionProps.value}</label>
      </components.Option>
    );
  };

  const ValueContainer = (ValueContainerProps: any) => {
    if (!props.isMulti) {
      return <components.ValueContainer {...ValueContainerProps} />;
    }
    return (
      <components.ValueContainer {...ValueContainerProps}>
        <Checkbox
          checked={ValueContainerProps.isSelected}
          onChange={() => null}
        />
        <label>{ValueContainerProps.value}</label>
      </components.ValueContainer>
    );
  };

  const customStyles: Styles = {
    valueContainer: (base, state) => ({
      ...base,
      padding: 0,
      margin: 0,
      maxHeight: "50px",
      overflow: "hidden"
    }),

    multiValue: base => ({ ...base, whiteSpace: "nowrap", maxWidth: "130px" }),
    menuList: base => ({ ...base, maxHeight: 220 }),
    menu: base => ({ ...base, maxHeight: 220 }),
    multiValueLabel: base => ({
      ...base,
      fontFamily: PRIMARY_FONT,
      color: "#252733",
      fontSize: 16
    }),
    option: (base, state) => ({
      ...base,
      fontFamily: PRIMARY_FONT,
      fontSize: "16px",
      color: "#252733",
      backgroundColor: "#fff",
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      "&:hover": {
        cursor: "pointer"
      }
    }),
    placeholder: base => ({
      ...base,
      margin: 0,
      color: "#9fa2b4",
      fontSize: 16,
      fontFamily: PRIMARY_FONT
    }),
    singleValue: base => ({ ...base, margin: 0 }),
    dropdownIndicator: base => ({
      ...base,
      marginRight: 15,
      "&:hover": {
        cursor: "pointer"
      }
    }),
    indicatorSeparator: base => ({
      ...base,
      display: "none"
    }),
    // placeholder: base => ({ ...base, padding: 0 }),
    control: (base, state) => ({
      ...base,
      width: inputWidth,
      height: 37.8,
      backgroundColor: state.isDisabled ? "lightgray" : "#ffffff",
      borderRadius: 1.8,
      border: "solid 0.9px #d0d7dd",
      boxSizing: "border-box",
      paddingLeft: 14.4,
      outline: error ? "none" : "initial",
      borderColor: error ? "red" : "#dfe0eb",
      boxShadow: error ? "0 0 5px red" : "initial",
      color: "#252733",
      fontSize: 16,
      fontFamily: PRIMARY_FONT,
      "&:hover": {
        border: "solid 1 #dfe0eb",
        cursor: "pointer"
      }
    })
  };

  return (
    <ReactSelect
      {...props}
      components={{ DropdownIndicator, Option }}
      styles={customStyles}
      options={options}
      name={field.name}
      value={
        options ? options.find(option => option.value === field.value) : ""
      }
      onChange={(value: any, action: any) => {
        if (props.isMulti) {
          form.setFieldValue(action.name, value.map(item => item.value));
        } else {
          form.setFieldValue(action.name, value.value);
        }
      }}
      // onChange={(option: Option) =>
      //   form.setFieldValue(field.name, option.value)
      // }
      onBlur={field.onBlur}
      menuPortalTarget={document.body}
    />
  );
};

// * OnChange configuration
// * https://gist.github.com/hubgit/e394e9be07d95cd5e774989178139ae8
