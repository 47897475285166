import * as React from "react";
import { connect } from "react-redux";
import { Container, Spinner, AppText, PageLoadSpinner } from "../UI";
import {
  DynamicTable,
  ColumnType,
  SelectedMenuOption,
  ColumnColors,
  RowData,
} from "../Dumb";
import { AppState } from "../../store/configureStore";
import {
  AppActions,
  LoggedUser,
  ItemList,
  CustomFilters,
  CustomFields,
  FilterField,
  CustomFieldsList,
  MenuItem,
} from "../../types";
import { Dispatch, bindActionCreators } from "redux";
import { api } from "../../api/api";
import styled from "styled-components";
import {
  fetchDeliveryRunProperties,
  updateDeliveryRunInitialLoad,
  addDeliveryRunCustomFilters,
  updateDeliveryRunCustomListLoad,
  addDeliveryRunCustomFiltersField,
  updateDefaultDeliveryRunID,
  updateFromDetailedDeliveryRun,
  setDeliveryRunCustomFieldFilters,
} from "../../actions";
import { getFilterType, debouncer, toastError } from "../../utils";
import {
  DeliveryRunResponse,
  DeliveryRunsResponseType,
} from "../../api/responseTypes";

const EmptyGridDiv = styled.div`
  width: 100%;
  height: 200px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  border-width: 1px;
  border-style: solid;
  border-color: #000;
`;

const EmptyGrid = (
  <EmptyGridDiv>
    <AppText>You don't have any delivery runs on this page</AppText>
  </EmptyGridDiv>
);
interface DeliveryRunsProps {
  store_id: number;
  token: string;
}

interface DeliveryRunsState {
  deliveryRuns: any[];
  loading: boolean;
  searchKeyword: string;
  currentPage: number;
  limit: string;
  from: number;
  to: number;
  total: number;
  per_page: number;
  list_id: number | null;
  item_list: [] | ItemList[];
  last_page: number | null;
  customFilters: CustomFilters[];
  changeListLoad: boolean;
  exporting: boolean;
}

export const delivery_run_field = [
  {
    label: "Driver Id",
    value: "driver_id",
    operator_type: "numeric",
    listValues: [] as any,
  },
  {
    label: "Num Deliveries",
    value: "num_deliveries",
    operator_type: "numeric",
    listValues: [] as any,
  },
  {
    label: "Status",
    value: "status",
    operator_type: "text",
    listValues: [] as any,
  },
  {
    label: "Issue Notes",
    value: "issue_note",
    operator_type: "text",
    listValues: [] as any,
  },
  {
    label: "Scheduled Start Time",
    value: "scheduled_start_time",
    operator_type: "text",
    listValues: [] as any,
  },
  {
    label: "Scheduled End Time",
    value: "scheduled_end_time",
    operator_type: "text",
    listValues: [] as any,
  },
  {
    label: "Route Selection Method",
    value: "route_selection_method",
    operator_type: "text",
    listValues: [] as any,
  },
  {
    label: "Sequence Num",
    value: "sequence_num",
    operator_type: "numeric",
    listValues: [] as any,
  },
  {
    label: "Created At",
    value: "created_at",
    operator_type: "date",
    listValues: [] as any,
  },
  {
    label: "Updated At",
    value: "updated_at",
    operator_type: "date",
    listValues: [] as any,
  },
  {
    label: "Deleted At",
    value: "deleted_at",
    operator_type: "date",
    listValues: [] as any,
  },
  {
    label: "Actual Start Time",
    value: "actual_start_time",
    operator_type: "date",
    listValues: [] as any,
  },
  {
    label: "Actual End Time",
    value: "actual_end_time",
    operator_type: "date",
    listValues: [] as any,
  },
  {
    label: "Revised End Time",
    value: "revised_end_time",
    operator_type: "date",
    listValues: [] as any,
  },
  {
    label: "Current Position",
    value: "current_position",
    operator_type: "text",
    listValues: [] as any,
  },
];

const list_stub = [
  {
    title: true,
    label: "STANDARD LISTS",
  },
  {
    title: false,
    label: "All Delivery Runs",
  },
  {
    title: false,
    label: "Active Delivery Runs",
  },
  {
    title: false,
    label: "Completed Delivery Runs",
  },
  {
    title: false,
    label: "Scheduled Delivery Runs",
  },
  {
    title: false,
    label: "Not Scheduled Delivery Runs",
  },
  {
    title: true,
    label: "CUSTOM LISTS",
  },
  {
    title: false,
    label: "NYC deliveries",
  },
  {
    title: false,
    label: "Food Deliveries",
  },
  {
    title: false,
    label: "Furniture deliveries",
  },
];

type Props = DeliveryRunsProps &
  DeliveryRunsState &
  LinkDispatchProps &
  LinkStateProps;

class DeliveryRuns extends React.Component<Props, DeliveryRunsState> {
  constructor(props: Props) {
    super(props);
    this.state = {
      deliveryRuns: [],
      loading: true,
      currentPage: 1,
      from: 1,
      to: 10,
      total: 0,
      per_page: 10,
      list_id: null,
      item_list: [],
      limit: "10",
      last_page: null,
      searchKeyword: "",
      customFilters: [],
      changeListLoad: false,
      exporting: false,
    };
  }

  componentDidMount = async () => {
    const {
      store_id,
      token,
      fetchDeliveryRunProperties,
      addDeliveryRunCustomFiltersField,
      setDeliveryRunCustomFieldFilters,
    } = this.props;

    const propFilter = this.props.deliveryRunFilters
      ? this.props.deliveryRunFilters.map((field) => field.display)
      : [];
    this.setState({ loading: true });

    const deliveryRunColumnFilters = await api.getColumnFilters({
      entity: "DeliveryRun",
      store_id: store_id,
      token: token,
    });
    console.log("deliveryRunColumnFilters", deliveryRunColumnFilters);
    setDeliveryRunCustomFieldFilters(deliveryRunColumnFilters.fields);

    //initial get customfields
    //TODO figure out fetchDelivery
    const initialCustomFields = (await api.getCustomField(
      { store_id: store_id, entity_name: "DeliveryRun" },
      token
    )) as CustomFieldsList;

    fetchDeliveryRunProperties(initialCustomFields.data);

    let customFilters = initialCustomFields.data
      ? initialCustomFields.data.map((obj) => {
          return {
            display: obj.display,
            field: obj.slug,
            visible: false,
          };
        })
      : [];

    this.setState({
      customFilters: customFilters,
    });

    // const filtersToUse = this.state.customFilters.filter(
    //   (field) => !propFilter.includes(field.display)
    // );

    // this.addCustomList(filtersToUse, this.props.deliveryRunFilters);

    // append intial custom fields to filter list

    let mappedDeliveryRunCustomFieldsFilter = initialCustomFields.data
      ? initialCustomFields.data.map((obj) => {
          return {
            label: obj.display,
            value: obj.slug,
            operator_type: getFilterType(obj.field_type_id) as string,
            listValues: obj.listValues,
          };
        })
      : [];
    const mappedDeliveryRunFieldsFilter = delivery_run_field.concat(
      mappedDeliveryRunCustomFieldsFilter
    );
    addDeliveryRunCustomFiltersField(mappedDeliveryRunFieldsFilter);

    //initial get delivery runs
    if (this.props.deliveryRunListLoaded) {
      const deliveryRuns = (await api.getDeliveryRuns(
        { store_id, page: 1, limit: this.state.limit },
        token
      )) as DeliveryRunsResponseType;
      const itemList = (await api.getItemLists(
        { store_id: store_id, entity: "DeliveryRun" },
        token
      )) as ItemList[];
      if(!!deliveryRuns.data){
      
        this.setState({
          deliveryRuns: deliveryRuns.data,
          currentPage: deliveryRuns.current_page,
          loading: false,
          from: deliveryRuns.from,
          to: deliveryRuns.to,
          total: deliveryRuns.total,
          per_page: deliveryRuns.per_page,
          last_page: deliveryRuns.last_page,
          item_list: itemList,
        });
      }else {
        this.setState({
          loading: false,
          item_list: itemList,

        })
      }
    }
    //get delivery rybs
    // const deliveryRuns = await api.getDeliveryRuns(
    //   { store_id, page: 1, limit: this.state.limit },
    //   token
    // );
    // const itemList = (await api.getItemLists(
    //   { store_id: store_id, entity: "DeliveryRun" },
    //   token
    // )) as ItemList[];
    // this.setState({
    //   deliveryRuns: deliveryRuns.data,
    //   currentPage: deliveryRuns.current_page,
    //   loading: false,
    //   from: deliveryRuns.from,
    //   to: deliveryRuns.to,
    //   total: deliveryRuns.total,
    //   per_page: deliveryRuns.per_page,
    //   item_list: itemList
    // });
  };

  componentDidUpdate = async (prevProps, prevState) => {
    const { store_id, token, deliveryRunListID } = this.props;

    if (this.props !== prevProps) {
      const deliveryRuns = (await api.getDeliveryRuns(
        {
          store_id,
          page: 1,
          item_list: deliveryRunListID,
          limit: this.state.limit,
        },
        token
      )) as DeliveryRunsResponseType;
      const itemList = (await api.getItemLists(
        { store_id: store_id, entity: "DeliveryRun" },
        token
      )) as ItemList[];
      if(!!deliveryRuns.data){
      
        this.setState({
          deliveryRuns: deliveryRuns.data,
          currentPage: deliveryRuns.current_page,
          loading: false,
          from: deliveryRuns.from,
          to: deliveryRuns.to,
          total: deliveryRuns.total,
          per_page: deliveryRuns.per_page,
          last_page: deliveryRuns.last_page,
          item_list: itemList,
          changeListLoad: false,
        });
      }else {
        this.setState({
          loading: false,
          item_list: itemList
        })
      }
    }
    if (this.props.deliveryRunListID !== prevProps.deliveryRunListID) {
      const deliveryRuns = (await api.getDeliveryRuns(
        {
          store_id,
          page: 1,
          item_list: deliveryRunListID,
          limit: this.state.limit,
        },
        token
      )) as DeliveryRunsResponseType;
      const itemList = (await api.getItemLists(
        { store_id: store_id, entity: "DeliveryRun" },
        token
      )) as ItemList[];
      if(!!deliveryRuns.data){
      
        this.setState({
          deliveryRuns: deliveryRuns.data,
          currentPage: deliveryRuns.current_page,
          loading: false,
          from: deliveryRuns.from,
          to: deliveryRuns.to,
          total: deliveryRuns.total,
          per_page: deliveryRuns.per_page,
          last_page: deliveryRuns.last_page,
          item_list: itemList,
        });
      }else {
        this.setState({
          loading: false,
          item_list: itemList
        })
      }
    }
    if (this.props.custom_properties !== prevProps.custom_properties) {
      const intialCustomFields = (await api.getCustomField(
        { store_id: store_id, entity_name: "DeliveryRun" },
        token
      )) as CustomFieldsList;
      let customFilters = intialCustomFields.data.map((obj) => {
        return {
          display: obj.display,
          field: obj.slug,
          visible: false,
        };
      });
      this.addCustomList(customFilters, this.props.deliveryRunFilters);
    }
    if (this.state.limit !== prevState.limit) {
      const deliveryRuns = await api.getDeliveryRuns(
        {
          store_id,
          page: 1,
          limit: this.state.limit,
          item_list: deliveryRunListID,
        },
        token
      );
      if(!!deliveryRuns.data){
      
        this.setState({
          deliveryRuns: deliveryRuns.data,
          currentPage: deliveryRuns.current_page,
          loading: false,
          from: deliveryRuns.from,
          to: deliveryRuns.to,
          total: deliveryRuns.total,
          per_page: deliveryRuns.per_page,
          last_page: deliveryRuns.last_page,
          changeListLoad: false,
          // item_list: itemList
        });
      }else {
        this.setState({
          loading: false,

        })
      }
    }
  };

  onColumnSave = async (filters: CustomFilters[]) => {
    const { store_id, token, setDeliveryRunCustomFieldFilters } = this.props;
    const deliveryRunColumnFilters = await api.editColumns({
      entity: "DeliveryRun",
      store_id: store_id,
      filters: filters,
      token: token,
    });

    setDeliveryRunCustomFieldFilters(deliveryRunColumnFilters.fields);
  };

  getDeliveryRuns = () => {
    const { deliveryRuns, searchKeyword, currentPage } = this.state;
    const { deliveryRunFilters } = this.props;
    const initialList = Array.isArray(deliveryRuns) ? deliveryRuns : [];
    // ? deliveryRuns.filter(delivery => {
    //     return Object.values(delivery).filter(
    //       field =>
    //         !!field &&
    //         `${field}`.toLowerCase().includes(searchKeyword.toLowerCase())
    //     ).length;
    //   })
    // : [];
    let filter = [] as string[];
    deliveryRunFilters.forEach((obj) => {
      if (obj.visible === true) {
        filter.push(obj.field);
      }
      if (obj.field === "id") {
        filter.push(obj.field);
      }
    });
    const filteredDeliveryRun = (obj) => {
      let result = {};

      for (let key in obj) {
        if (filter.includes(key)) {
          result[key] = obj[key];
        }
      }
      return result;
    };
    const finalList = initialList.map((obj) =>
      filteredDeliveryRun(obj)
    ) as RowData[];
    return finalList;
  };

  updateDeliveryRuns = async () => {
    const { store_id, token, deliveryRunListID } = this.props;
    //update drivers on driverListID change
    const deliveryRuns = (await api.getDeliveryRuns(
      {
        store_id,
        page: 1,
        item_list: deliveryRunListID,
        limit: this.state.limit,
      },
      token
    )) as DeliveryRunsResponseType;
    const itemList = (await api.getItemLists(
      { store_id: store_id, entity: "Driver" },
      token
    )) as ItemList[];
    if(!!deliveryRuns.data){
      this.setState({
        deliveryRuns: deliveryRuns.data,
        currentPage: deliveryRuns.current_page,
        loading: false,
        from: deliveryRuns.from,
        to: deliveryRuns.to,
        total: deliveryRuns.total,
        per_page: deliveryRuns.per_page,
        last_page: deliveryRuns.last_page,
      });
    }else {
      this.setState({
        loading: false,

      })
    }
  };

  nextPage = async () => {
    const { store_id, token, deliveryRunListID } = this.props;
    const { to, total, currentPage, limit } = this.state;
    if (to < total) {
      this.setState({
        changeListLoad: true,
      });
      const deliveryRuns = await api.getDeliveryRuns(
        {
          store_id: store_id,
          page: currentPage + 1,
          limit: this.state.limit,
          item_list: deliveryRunListID,
        },
        token
      );
      if( !!deliveryRuns.data){
        this.setState({
          deliveryRuns: deliveryRuns.data,
          currentPage: deliveryRuns.current_page,
          loading: false,
          from: deliveryRuns.from,
          to: deliveryRuns.to,
          total: deliveryRuns.total,
          last_page: deliveryRuns.last_page,
          changeListLoad: false,
        });
      }else {
        this.setState({
          loading: false,

        })
      }
    }
  };

  prevPage = async () => {
    const { store_id, token, deliveryRunListID } = this.props;
    const { to, per_page, currentPage, limit, last_page } = this.state;

    if (to === null) {
      this.setState({
        changeListLoad: true,
      });
      const deliveryRuns = await api.getDeliveryRuns(
        {
          store_id: store_id,
          page: last_page as number,
          limit: this.state.limit,
          item_list: deliveryRunListID,
        },
        token
      );
      if(!!deliveryRuns.data){
      
        this.setState({
          deliveryRuns: deliveryRuns.data,
          currentPage: deliveryRuns.current_page,
          loading: false,
          from: deliveryRuns.from,
          to: deliveryRuns.to,
          total: deliveryRuns.total,
          last_page: deliveryRuns.last_page,
          changeListLoad: false,
        });
      }else {
        this.setState({
          loading: false,

        })
      }
    }
    if (to > per_page) {
      this.setState({
        changeListLoad: true,
      });
      const deliveryRuns = await api.getDeliveryRuns(
        {
          store_id: store_id,
          page: currentPage - 1,
          limit: this.state.limit,
          item_list: deliveryRunListID,
        },
        token
      );
      if(!!deliveryRuns.data){
      
        this.setState({
          deliveryRuns: deliveryRuns.data,
          currentPage: deliveryRuns.current_page,
          loading: false,
          from: deliveryRuns.from,
          to: deliveryRuns.to,
          total: deliveryRuns.total,
          last_page: deliveryRuns.last_page,
          changeListLoad: false,
        });
      }else {
        this.setState({
          loading: false,

        })
      }
    }
  };

  firstPage = async () => {
    const { store_id, token, deliveryRunListID } = this.props;
    const { limit } = this.state;
    this.setState({
      changeListLoad: true,
    });
    const deliveryRuns = await api.getDeliveryRuns(
      {
        store_id: store_id,
        page: 1,
        limit: limit,
        item_list: deliveryRunListID,
      },
      token
    );
    if(!!deliveryRuns.data){
      
      this.setState({
        deliveryRuns: deliveryRuns.data,
        currentPage: deliveryRuns.current_page,
        loading: false,
        from: deliveryRuns.from,
        to: deliveryRuns.to,
        total: deliveryRuns.total,
        last_page: deliveryRuns.last_page,
        changeListLoad: false,
      });
    }else {
      this.setState({
        loading: false,

      })
    }
  };

  lastPage = async () => {
    const { store_id, token, deliveryRunListID } = this.props;
    const { limit, last_page } = this.state;
    this.setState({
      changeListLoad: true,
    });
    const deliveryRuns = await api.getDeliveryRuns(
      {
        store_id: store_id,
        page: last_page as number,
        limit: limit,
        item_list: deliveryRunListID,
      },
      token
    );
    if(!!deliveryRuns.data){
      
      this.setState({
        deliveryRuns: deliveryRuns.data,
        currentPage: deliveryRuns.current_page,
        loading: false,
        from: deliveryRuns.from,
        to: deliveryRuns.to,
        total: deliveryRuns.total,
        last_page: deliveryRuns.last_page,
        changeListLoad: false,
      });
    }else {
      this.setState({
        loading: false,

      })
    }
  };

  addCustomList = (
    filters: CustomFilters[],
    defaultFilters: CustomFilters[]
  ) => {
    // let newDefaultFilter = [] as CustomFilters[];
    let defaultFilterMap = defaultFilters.map(
      (defaultFilter) => defaultFilter.field
    );
    let filterMap = filters.map((filter) => filter.field);
    let newDefaultFilter = defaultFilters
      .map((defaultFilter) => {
        if (
          defaultFilterMap.includes(defaultFilter.field) &&
          !filterMap.includes(defaultFilter.field)
        ) {
          return defaultFilter;
        } else {
          // filters.forEach(filter => {
          //   if (defaultFilter.field === filter.field) {
          //     if (defaultFilter.display === filter.display) {
          //       newDefaultFilter.push(defaultFilter);
          //     } else {
          //       newDefaultFilter.push(filter);
          //     }
          //   }
          // });
        }
      })
      .filter((filter) => filter !== undefined);

    // const newFilter = filters.filter(filter => {
    //   return defaultFilterMap.includes(filter.field);
    // });
    // console.log("newFilter", newFilter);
    const finalFilterList = newDefaultFilter.concat(filters);

    if (this.props.deliveryRunCustomListLoaded) {
      this.props.addDeliveryRunCustomFilters(
        finalFilterList as CustomFilters[]
      );
    }
    this.props.updateDeliveryRunCustomListLoad();
  };

  renderNewListDeliveryRuns = async (id: number) => {
    const { store_id, token } = this.props;
    const deliveryRuns = (await api.getDeliveryRuns(
      { store_id, page: 1, item_list: id, limit: this.state.limit },
      token
    )) as DeliveryRunsResponseType;
    if(!!deliveryRuns.data){
      
      this.setState({
        deliveryRuns: deliveryRuns.data,
        currentPage: deliveryRuns.current_page,
        loading: false,
        from: deliveryRuns.from,
        to: deliveryRuns.to,
        total: deliveryRuns.total,
        per_page: deliveryRuns.per_page,
        last_page: deliveryRuns.last_page,
      });
    }else {
      this.setState({
        loading: false,

      })
    }
  };

  changeListRenderState = () => {
    this.setState({
      changeListLoad: true,
    });
  };

  changeViewLimit = (limit: string) => {
    if (limit !== this.state.limit) {
      this.setState({
        limit: limit,
        changeListLoad: true,
      });
    }
  };

  changeSearchText = (searchKeyword: string) => {
    this.setState({ searchKeyword });
    debouncer(this.searchOnDebounce, 500);
  };

  searchOnDebounce = async () => {
    const { store_id, token, deliveryRunListID } = this.props;
    const { limit, searchKeyword } = this.state;
    this.setState({
      changeListLoad: true,
    });

    const deliveryRuns = (await api.getDeliveryRuns(
      {
        store_id: store_id,
        page: 1,
        limit: limit,
        item_list: deliveryRunListID,
        searched_text: searchKeyword,
      },
      token
    )) as DeliveryRunsResponseType;

    if(!!deliveryRuns.data){
      
      this.setState({
        deliveryRuns: deliveryRuns.data,
        currentPage: deliveryRuns.current_page,
        loading: false,
        from: deliveryRuns.from,
        to: deliveryRuns.to,
        total: deliveryRuns.total,
        last_page: deliveryRuns.last_page,
        changeListLoad: false,
      });
    }else {
      this.setState({
        loading: false,

      })
    }
  };

  deliveryRunList = () => {
    let standardList = [{ title: true, label: "STANDARD LISTS" }] as MenuItem[];
    let customList = [
      {
        title: true,
        label: "CUSTOM LISTS",
      },
    ] as MenuItem[];
    //go through Item lists and append based on label
    this.state.item_list.forEach((item) => {
      //label tbd
      if (item.label === "All DeliveryRuns") {
        standardList.push({
          title: false,
          label: item.label,
          id: item.id,
          filter_ids: item.filter_ids,
        });
        this.props.updateDefaultDeliveryRunID(item.id);
      } else if (
        item.label === "Inactive DeliveryRuns" ||
        item.label === "Active DeliveryRuns" ||
        item.label === "Completed DeliveryRuns" ||
        item.label === "Scheduled DeliveryRuns"
      ) {
        standardList.push({
          title: false,
          label: item.label,
          id: item.code as string,
          filter_ids: "",
        });
      } else {
        customList.push({
          title: false,
          label: item.label,
          id: item.id,
          filter_ids: item.filter_ids,
        });
      }
    });
    return standardList.concat(customList);
  };

  isDefaultList = () => {
    const { deliveryRunListLabel } = this.props;
    if (
      deliveryRunListLabel === "" ||
      deliveryRunListLabel === "Active DeliveryRuns" ||
      deliveryRunListLabel === "Inactive DeliveryRuns" ||
      deliveryRunListLabel === "Completed DeliveryRuns" ||
      deliveryRunListLabel === "All DeliveryRuns" ||
      deliveryRunListLabel === "Scheduled DeliveryRuns"
    ) {
      return true;
    }
    return false;
  };

  exportDeliveryRuns = async () => {
    const { store_id, deliveryRunListID, token } = this.props;

    this.setState({
      exporting: true,
    });
    const response = await api.exportDeliveryRun(
      store_id,
      deliveryRunListID,
      token
    );

    this.setState({
      exporting: false,
    });
  };

  orderColumns = () => {
    const { deliveryRunFilters } = this.props;

    const newDeliveryRunColumn = deliveryRunFilters
      .filter((field) => !!field.visible)
      .sort((a, b) => (a.ordinal && b.ordinal ? a.ordinal - b.ordinal : 0))
      .map((filter) => filter.field);

    return newDeliveryRunColumn;
  };

  render(): JSX.Element {
    const { loading } = this.state;
    const { store_id, deliveryRunListID, token } = this.props;
    const massSelectionRequirement = "Need to select a DeliveryRun first";
    const deliveryRuns = this.getDeliveryRuns();
    const itemList =
      this.state.item_list.length > 1 ? this.deliveryRunList() : [];

    const selected_options_stub: SelectedMenuOption[] = [
      {
        text: "Delete",
        onClick: async (selected) => {
          const { store_id, token, deliveryRunListID } = this.props;
          const { currentPage } = this.state;
          if (selected.length > 0) {
            await api.massDeleteDeliveryruns(
              {
                deliveryRunIDs: selected as number[],
              },
              token
            );
            const deliveryRun = (await api.getDeliveryRuns(
              {
                store_id,
                page: currentPage,
                limit: this.state.limit,
                item_list: deliveryRunListID,
              },
              token
            )) as DeliveryRunsResponseType;
            if(!!deliveryRun.data){
              this.setState({
                deliveryRuns: deliveryRun.data,
                currentPage: deliveryRun.current_page,
                loading: false,
                from: deliveryRun.from,
                to: deliveryRun.to,
                total: deliveryRun.total,
                per_page: deliveryRun.per_page,
                last_page: deliveryRun.last_page,
              });
            }else {
              this.setState({
                loading: false,
      
              })
            }
            updateFromDetailedDeliveryRun();
          } else {
            toastError(massSelectionRequirement);
          }
        },
      },
    ];

    if (loading) {
      return (
        <Container>
          <Spinner />
        </Container>
      );
    }
    console.log("this.state", this.state)
    return (
      <Container style={{ marginTop: 1 }}>
        {this.state.changeListLoad && <PageLoadSpinner />}
        <DynamicTable
          columnTitles={!!deliveryRuns.length ? this.orderColumns() : []}
          // columnTypes={rowType_stub}
          // columnColors={colors_stub}
          // rowData={dataRow_stub}
          rowData={deliveryRuns}
          selectedMenuOptions={selected_options_stub}
          selectedMenuLabel="Delivery Runs"
          currentPageFirstItemNumber={this.state.currentPage + 1}
          onNextClick={this.nextPage}
          onPrevClick={this.prevPage}
          onFirstClick={this.firstPage}
          onLastClick={this.lastPage}
          renderEmptyGrid={EmptyGrid}
          excludedColumns={["deleted_at"]}
          searchOnChange={this.changeSearchText}
          searchValue={this.state.searchKeyword}
          totalItemCount={this.state.deliveryRuns.length}
          renderNewList={this.renderNewListDeliveryRuns}
          from={this.state.from}
          to={this.state.to}
          total={this.state.total}
          updateList={this.updateDeliveryRuns}
          changeViewLimit={this.changeViewLimit}
          limit={this.state.limit}
          currentPage={this.state.currentPage}
          list={itemList}
          basic={true}
          fieldList={this.props.deliveryRunFilters}
          customFieldList={this.state.customFilters}
          currentList={this.props.deliveryRunListLabel}
          listID={this.props.deliveryRunListID}
          isDefaultList={this.isDefaultList()}
          exportCSVList={this.exportDeliveryRuns}
          changeListRenderState={this.changeListRenderState}
          exporting={this.state.exporting}
          onColumnSave={this.onColumnSave}
        />
      </Container>
    );
  }
}

interface LinkStateProps {
  token: string;
  store_id: number;
  custom_properties: CustomFields[];
  deliveryRunListLabel: string;
  deliveryRunListLoaded: boolean;
  deliveryRunCustomListLoaded: boolean;
  deliveryRunListID: number;
  deliveryRunFilters: CustomFilters[];
}
interface LinkDispatchProps {
  fetchDeliveryRunProperties: (property: CustomFields[]) => void;
  updateDeliveryRunInitialLoad: () => void;
  addDeliveryRunCustomFilters: (filter: CustomFilters[]) => void;
  setDeliveryRunCustomFieldFilters: (filter: CustomFilters[]) => void;
  updateDeliveryRunCustomListLoad: () => void;
  addDeliveryRunCustomFiltersField: (filter: FilterField[]) => void;
  updateDefaultDeliveryRunID: (id: number | string) => void;
  updateFromDetailedDeliveryRun: () => void;
}

const mapDispatchToProps = (
  dispatch: Dispatch<AppActions>
): LinkDispatchProps => ({
  fetchDeliveryRunProperties: bindActionCreators(
    fetchDeliveryRunProperties,
    dispatch
  ),
  updateDeliveryRunInitialLoad: bindActionCreators(
    updateDeliveryRunInitialLoad,
    dispatch
  ),
  addDeliveryRunCustomFilters: bindActionCreators(
    addDeliveryRunCustomFilters,
    dispatch
  ),
  updateDeliveryRunCustomListLoad: bindActionCreators(
    updateDeliveryRunCustomListLoad,
    dispatch
  ),
  addDeliveryRunCustomFiltersField: bindActionCreators(
    addDeliveryRunCustomFiltersField,
    dispatch
  ),
  updateDefaultDeliveryRunID: bindActionCreators(
    updateDefaultDeliveryRunID,
    dispatch
  ),
  updateFromDetailedDeliveryRun: bindActionCreators(
    updateFromDetailedDeliveryRun,
    dispatch
  ),
  setDeliveryRunCustomFieldFilters: bindActionCreators(
    setDeliveryRunCustomFieldFilters,
    dispatch
  ),
});

const mapStateToProps = (state: AppState): LinkStateProps => ({
  store_id: state.user.store_id as number,
  token: state.auth.token as string,
  custom_properties: state.customDeliveryRunFields as CustomFields[],
  deliveryRunListLoaded: state.listViewIDs
    .deliveryRunListInitialLoad as boolean,
  deliveryRunListLabel: state.listViewIDs.deliveryRunLabel as string,
  deliveryRunListID: state.listViewIDs.deliveryRunListID as number,
  deliveryRunCustomListLoaded: state.customDeliveryRunFilterState
    .initialDeliveryRunCustomListLoad as boolean,
  deliveryRunFilters: state.customDeliveryRunFieldFilter as CustomFilters[],
});
export default connect(mapStateToProps, mapDispatchToProps)(DeliveryRuns);
