import { DefaultCustomDeliveryRunFilterStateActionTypes } from "../types";

const defaultCustomDeliveryRunFilterStateReducer = {
  initialDeliveryRunCustomListLoad: true
};

const customDeliveryRunFilterStateReducer = (
  state = defaultCustomDeliveryRunFilterStateReducer,
  action: DefaultCustomDeliveryRunFilterStateActionTypes
) => {
  switch (action.type) {
    case "UPDATE_DELIVERY_RUN_CUSTOM_LIST_LOAD":
      return { ...state, initialDeliveryRunCustomListLoad: false };
    case "RELOAD_DELIVERY_RUN_CUSTOM_LIST_LOAD":
      return { ...state, initialDeliveryRunCustomListLoad: true };
    case "LOGOUT":
      return defaultCustomDeliveryRunFilterStateReducer;
    default:
      return state;
  }
};

export default customDeliveryRunFilterStateReducer;
