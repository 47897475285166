import { DefaultCustomDriverFilterStateActionTypes } from "../types";

const defaultCustomDriverFilterStateReducer = {
  initialDriverCustomListLoad: true
};

const customDriverFilterStateReducer = (
  state = defaultCustomDriverFilterStateReducer,
  action: DefaultCustomDriverFilterStateActionTypes
) => {
  switch (action.type) {
    case "UPDATE_DRIVER_CUSTOM_LIST_LOAD":
      return { ...state, initialDriverCustomListLoad: false };
    case "RELOAD_DRIVER_CUSTOM_LIST_LOAD":
      return { ...state, initialDriverCustomListLoad: true };
    case "LOGOUT":
      return defaultCustomDriverFilterStateReducer;
    default:
      return state;
  }
};

export default customDriverFilterStateReducer;
