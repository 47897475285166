import * as React from "react";
import styled from "styled-components";
import { AppText } from "../../UI";
import { PRIMARY_COLOR } from "../../../utils";


interface CreateDeliveryRunTableProps {
  title: string;
  disable?: boolean;
  selectMode?: boolean;
  emptyState: boolean;
}

interface CreateDeliveryRunState {}

class CreateDeliveryRunTable extends React.Component<
  CreateDeliveryRunTableProps,
  CreateDeliveryRunState
> {
  constructor(props: CreateDeliveryRunTableProps) {
    super(props);
  }
  render() {
    const { title, emptyState, children } = this.props;
    return (
      <Div>
        <HeaderDiv>
          <HeaderTitle>{title}</HeaderTitle>
        </HeaderDiv>
        <SeperatorBar />
        <CardDiv emptyState={emptyState}>{children}</CardDiv>
      </Div>
    );
  }
}

interface CardDivProps {
  emptyState: boolean;
}

const Div = styled.div`
  width: 236px;
  height: 100%;
  background-color: #f6f6f6;
  overflow: hidden;
`;

const HeaderDiv = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 66px;
  width: 100%;
  background-color: #ffffff;
`;

const SeperatorBar = styled.div`
  height: 2px;
  border-top: solid #f6f6f6 1px;
  border-bottom: solid #ffffff 2px;
  width: 100%;
  background-color: #f6f6f6;
  padding-bottom: 1px;
`;

const HeaderTitle = styled(AppText)`
  font-size: 18px;
  font-weight: 18px;
  color: ${PRIMARY_COLOR};
`;

const CardDiv = styled.div<CardDivProps>`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: ${(props) => (props.emptyState ? "center" : "flex-start")};
  background-color: #f6f6f6;
  width: 100%;
  height: calc(100% - 70px);
  overflow: scroll;
`;

export default CreateDeliveryRunTable;
