import {
  AppActions,
  ADD_ORDER_FIELD_FILTER,
  ADD_DRIVER_FIELD_FILTER,
  ADD_DRIVER_CUSTOM_FILTERS,
  REMOVE_DRIVER_FIELD_FILTER,
  REMOVE_ORDER_FIELD_FILTER,
  UPDATE_DRIVER_CUSTOM_LIST_LOAD,
  RELOAD_DRIVER_CUSTOM_LIST_LOAD,
  CustomFilters,
  DELETE_DRIVER_FIELD_FILTER,
  UPDATE_ORDER_CUSTOM_LIST_LOAD,
  RELOAD_ORDER_CUSTOM_LIST_LOAD,
  ADD_DRIVER_FIELD_FILTER_OPTION,
  CustomFieldFilterMenu,
  FilterField,
  ADD_DELIVERY_FIELD_FILTER,
  REMOVE_DELIVERY_FIELD_FILTER,
  UPDATE_DELIVERY_CUSTOM_LIST_LOAD,
  RELOAD_DELIVERY_CUSTOM_LIST_LOAD,
  REMOVE_DELIVERY_RUN_FIELD_FILTER,
  ADD_DELIVERY_RUN_FIELD_FILTER,
  UPDATE_DELIVERY_RUN_CUSTOM_LIST_LOAD,
  RELOAD_DELIVERY_RUN_CUSTOM_LIST_LOAD,
  ADD_ORDER_CUSTOM_FILTERS,
  DELETE_ORDER_FIELD_FILTER,
  ADD_ORDER_FIELD_FILTER_OPTION,
  ADD_DELIVERY_RUN_FIELD_FILTER_OPTION,
  ADD_DELIVERY_RUN_CUSTOM_FILTERS,
  DELETE_DELIVERY_RUN_FIELD_FILTER,
  DELETE_DELIVERY_FIELD_FILTER,
  ADD_DELIVERY_FIELD_FILTER_OPTION,
  ADD_DELIVERY_CUSTOM_FILTERS,
  SET_DRIVER_FIELD_FILTERS,
  SET_ORDER_FIELD_FILTERS,
  SET_DELIVERY_FIELD_FILTERS,
  SET_DELIVERY_RUN_FIELD_FILTERS,
} from "../types";
// Delivery run field filters
export const addDeliveryRunFieldFilter = (filter: string): AppActions => ({
  type: ADD_DELIVERY_RUN_FIELD_FILTER,
  filter,
});

export const removeDeliveryRunFieldFilter = (filter: string): AppActions => ({
  type: REMOVE_DELIVERY_RUN_FIELD_FILTER,
  filter,
});

export const deleteDeliveryRunFieldFilter = (filter: string): AppActions => ({
  type: DELETE_DELIVERY_RUN_FIELD_FILTER,
  filter,
});

export const updateDeliveryRunCustomListLoad = (): AppActions => ({
  type: UPDATE_DELIVERY_RUN_CUSTOM_LIST_LOAD,
});

export const reloadDeliveryRunCustomListLoad = (): AppActions => ({
  type: RELOAD_DELIVERY_RUN_CUSTOM_LIST_LOAD,
});

export const addDeliveryRunCustomFiltersField = (
  filters: FilterField[]
): AppActions => ({
  type: ADD_DELIVERY_RUN_FIELD_FILTER_OPTION,
  filters,
});

export const addDeliveryRunCustomFilters = (
  filters: CustomFilters[]
): AppActions => ({
  type: ADD_DELIVERY_RUN_CUSTOM_FILTERS,
  filters,
});

// Delivery field filters
export const addDeliveryFieldFilter = (filter: string): AppActions => ({
  type: ADD_DELIVERY_FIELD_FILTER,
  filter,
});

export const removeDeliveryFieldFilter = (filter: string): AppActions => ({
  type: REMOVE_DELIVERY_FIELD_FILTER,
  filter,
});

export const deleteDeliveryFieldFilter = (filter: string): AppActions => ({
  type: DELETE_DELIVERY_FIELD_FILTER,
  filter,
});

export const updateDeliveryCustomListLoad = (): AppActions => ({
  type: UPDATE_DELIVERY_CUSTOM_LIST_LOAD,
});

export const reloadDeliveryCustomListLoad = (): AppActions => ({
  type: RELOAD_DELIVERY_CUSTOM_LIST_LOAD,
});

export const addDeliveryCustomFiltersField = (
  filters: FilterField[]
): AppActions => ({
  type: ADD_DELIVERY_FIELD_FILTER_OPTION,
  filters,
});

export const addDeliveryCustomFilters = (
  filters: CustomFilters[]
): AppActions => ({
  type: ADD_DELIVERY_CUSTOM_FILTERS,
  filters,
});
// order field filters

// for order filter options
export const addOrderCustomFiltersField = (
  filters: FilterField[]
): AppActions => ({
  type: ADD_ORDER_FIELD_FILTER_OPTION,
  filters,
});

export const addOrderCustomFilters = (
  filters: CustomFilters[]
): AppActions => ({
  type: ADD_ORDER_CUSTOM_FILTERS,
  filters,
});

export const addOrderFieldFilter = (filter: string): AppActions => ({
  type: ADD_ORDER_FIELD_FILTER,
  filter,
});

export const removeOrderFieldFilter = (filter: string): AppActions => ({
  type: REMOVE_ORDER_FIELD_FILTER,
  filter,
});

export const deleteOrderFieldFilter = (filter: string): AppActions => ({
  type: DELETE_ORDER_FIELD_FILTER,
  filter,
});

export const updateOrderCustomListLoad = (): AppActions => ({
  type: UPDATE_ORDER_CUSTOM_LIST_LOAD,
});

export const reloadOrderCustomListLoad = (): AppActions => ({
  type: RELOAD_ORDER_CUSTOM_LIST_LOAD,
});

// driver field filters

// SET filter options
//new
export const setDriverCustomFieldFilters = (
  filters: CustomFilters[]
): AppActions => ({
  type: SET_DRIVER_FIELD_FILTERS,
  filters,
});

export const setOrderCustomFieldFilters = (
  filters: CustomFilters[]
): AppActions => ({
  type: SET_ORDER_FIELD_FILTERS,
  filters,
});

export const setDeliveryCustomFieldFilters = (
  filters: CustomFilters[]
): AppActions => ({
  type: SET_DELIVERY_FIELD_FILTERS,
  filters,
});

export const setDeliveryRunCustomFieldFilters = (
  filters: CustomFilters[]
): AppActions => ({
  type: SET_DELIVERY_RUN_FIELD_FILTERS,
  filters,
});

//old

export const addDriverCustomFiltersField = (
  filters: FilterField[]
): AppActions => ({
  type: ADD_DRIVER_FIELD_FILTER_OPTION,
  filters,
});

export const addDriverCustomFilters = (
  filters: CustomFilters[]
): AppActions => ({
  type: ADD_DRIVER_CUSTOM_FILTERS,
  filters,
});
export const addDriverFieldFilter = (filter: string): AppActions => ({
  type: ADD_DRIVER_FIELD_FILTER,
  filter,
});

export const removeDriverFieldFilter = (filter: string): AppActions => ({
  type: REMOVE_DRIVER_FIELD_FILTER,
  filter,
});

export const deleteDriverFieldFilter = (filter: string): AppActions => ({
  type: DELETE_DRIVER_FIELD_FILTER,
  filter,
});
export const updateDriverCustomListLoad = (): AppActions => ({
  type: UPDATE_DRIVER_CUSTOM_LIST_LOAD,
});

export const reloadDriverCustomListLoad = (): AppActions => ({
  type: RELOAD_DRIVER_CUSTOM_LIST_LOAD,
});
