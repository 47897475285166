import * as React from "react";
import { connect } from "react-redux";
import { Formik, FormikProps, Form } from "formik";
import { Link, RouteComponentProps, withRouter } from "react-router-dom";
import * as Yup from "yup";
import styled from "styled-components";
import { Dispatch, bindActionCreators } from "redux";
import { InputField } from "../form";
import {
  AuthContainer,
  Button,
  Spinner,
  Card,
  AppText,
  Icon,
  AppTextRoman,
} from "../UI";
import { AppActions, Auth } from "../../types";
import { logo } from "../../img";
import { AuthError } from "../Dumb";
import { loginThunk } from "../../api/auth";
import { AppState } from "../../store/configureStore";

const signupSchema = Yup.object().shape({
  email: Yup.string().email("* Invalid email").required("* Email is required"),
  password: Yup.string()
    .required("* Please specify a password")
    .max(50, "* Too Long!"),
});

interface SignInProps {
  auth?: any;
}

interface SignInState {}

type Props = RouteComponentProps<{}> & SignInProps & LinkDispatchProps;

interface MyFormikProps {
  email: string;
  password: string;
}

class SignIn extends React.Component<Props, SignInState> {
  render(): JSX.Element {
    return (
      <Formik
        initialValues={{
          email: "",
          password: "",
        }}
        validationSchema={signupSchema}
        onSubmit={async (values, { setFieldError, setStatus }) => {
          const { email, password } = values;
          setStatus({ error: "", loading: true });
          try {
            this.props.loginUser(email, password);
          } catch (err) {
            console.log(err);
            setStatus({ loading: false });
          }
          // setTimeout(() => {
          //   setStatus({ loading: false });
          //   this.props.history.push("/onboarding");
          // }, 3000);
        }}
      >
        {({ status, setStatus }: FormikProps<MyFormikProps>) => {
          console.log("status: ", status);
          return (
            <AuthContainer>
              <Form>
                <Grid>
                  <Icon
                    src={logo}
                    width={173}
                    height={38}
                    style={{ objectFit: "contain" }}
                  />
                  <LoginTitleText>LOGIN</LoginTitleText>
                  <ThisCard>
                    <InputField name="email" placeholder="Username" />
                    <InputField
                      name="password"
                      placeholder="Password"
                      type="password"
                    />
                    <div>
                      <AuthError
                        status={status}
                        error={this.props.auth.error}
                      />
                    </div>
                    <div>
                      <Button type="submit">Login</Button>
                    </div>
                    <div>
                      <Link
                        to="/resetpassword"
                        style={{ textDecoration: "none" }}
                      >
                        <ForgotPasswordText>
                          Forgot Password?
                        </ForgotPasswordText>
                      </Link>
                    </div>
                  </ThisCard>
                  <RegisterText>Want to Register for an Account?</RegisterText>
                  <a href="https://www.scanovator.com/contact">
                    <DemoButton type="button">
                      Contact Us to Schedule a Demo
                    </DemoButton>
                  </a>
                </Grid>
              </Form>
            </AuthContainer>
          );
        }}
      </Formik>
    );
  }
}

const Grid = styled.div`
  display: grid;
  grid-template-rows: 84px 68px 300px 45px auto;
  justify-items: center;
`;

const RegisterText = styled(AppTextRoman)`
  font-size: 15px;
  line-height: 1.67;
  text-align: center;
  color: #ffffff;
`;
const ForgotPasswordText = styled(AppText)`
  font-size: 12.6px;
  font-weight: 500;
  color: #989898;
  text-align: center;
`;

const LoginTitleText = styled(AppText)`
  font-size: 26px;
  font-weight: 500;
  text-align: center;
  color: #ffffff;
`;

const DemoButton = styled(Button)`
  background-color: #ffffff;
  color: #60999e;
  font-size: 16.2px;
  height: 54px;
  width: 294px;
`;

const ThisCard = styled(Card)`
  height: 242px;
  width: 300px;
  display: grid;
  grid-template-rows: 60px 52px 26px 50px auto;
  justify-items: center;
`;

interface LinkDispatchProps {
  loginUser: (email: string, password: string) => void;
}

interface LinkStateProps {
  auth: Auth;
}

const mapStateToProps = (state: AppState): LinkStateProps => ({
  auth: state.auth,
});
const mapDispatchToProps = (
  dispatch: Dispatch<AppActions>
): LinkDispatchProps => ({
  loginUser: bindActionCreators(loginThunk, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(SignIn));
