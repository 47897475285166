const codes = [
  "EAN_13",
  "CODE_128",
  "CODE_39",
  "CODABAR",
  "EAN_8",
  "ITF",
  "UPC_A",
  "UPC_E",
  "PDF417",
  "AZTEC",
  "DATA_MATRIX",
];

export const barcode = codes.map((code) => ({
  label: code,
  value: code,
  data: "",
}));

//Code-128 barcode
export const barcodeSampleArray = [403984310984, 403984310985, 403984310986];

export const convertBarcodeTypesToLibraryTypes = (type: string) => {
  switch (type) {
    case "EAN_13":
      return "ean13";
    case "CODE_128":
      return "code128";
    case "CODE_39":
      return "code39";
    case "CODABAR":
      return "rationalizedCodabar";
    case "EAN_8":
      return "ean8";
    case "ITF":
      return "interleaved2of5";
    case "UPC_A":
      return "upca";
    case "UPC_E":
      return "upce";
    case "PDF417":
      return "pdf417";
    case "AZTEC":
      return "azteccode";
    case "DATA_MATRIX":
      return "datamatrix";
    default:
      return "";
  }
};
