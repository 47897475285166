import React from "react";
import { useField, useFormikContext } from "formik";
import { AppDatePicker, AppText } from "../UI";
import styled from "styled-components";
interface FormDatePickerProps {
  name: string;
  style?: React.CSSProperties;
  title?: string;
  type?: string;
  width?: number;
}

const FormDatePicker = ({ style, ...props }: FormDatePickerProps) => {
  const { setFieldValue } = useFormikContext();
  const [field, meta] = useField(props.name);
  return (
    <div style={style}>
      {props.title ? (
        <AppTextFormTitle>{props.title}</AppTextFormTitle>
      ) : (
        <AppTextFormTitle>&nbsp; </AppTextFormTitle>
      )}
      <AppDatePicker
        {...field}
        {...props}
        date={field.value}
        // error={meta.touched && meta.error}
        onDayChange={(day) => setFieldValue(field.name, day)}
      />
    </div>
  );
};

const AppTextFormTitle = styled(AppText)`
  opacity: 0.5;
  font-size: 14px;
  font-weight: 500;
  color: #3b3a3a;
  margin-bottom: 7px;
`;

export { FormDatePicker };
