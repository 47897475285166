import React from "react";
import { Field, FieldProps, ErrorMessage } from "formik";
import { Input, AppText } from "../UI";
import styled from "styled-components";
import { ErrorText } from "../Dumb";

export const OnboardInputField = ({
  name,
  title,
  placeholder,
  style,
  inputWidth,
  type = "text",
  formatValue,
  disabled
}: {
  name: string;
  title?: string;
  placeholder: string;
  style?: React.CSSProperties;
  inputWidth?: string;
  type?: string;
  formatValue?: (p: string) => string;
  disabled?: boolean;
}) => (
  <div style={style}>
    <Field
      name={name}
      render={({ field, form: { errors, touched } }: FieldProps) => {
        const error = !!errors[name] && !!touched[name];
        return (
          <FormSegment>
            {title && <AppTextFormTitle>{title}</AppTextFormTitle>}
            <ThisInput
              {...field}
              error={error}
              placeholder={placeholder}
              inputWidth={inputWidth}
              type={type}
              disabled={disabled}
            />
            <ErrorMessage name={field.name} component={ErrorText} />
          </FormSegment>
        );
      }}
    />
  </div>
);

const FormSegment = styled.div``;

const AppTextFormTitle = styled(AppText)`
  font-size: 15.8px;
  color: #d8d8d8;
  font-weight: 300;
`;

const ThisInput = styled(Input)`
  padding: 0;
  background: transparent;
  border-width: 0;
  font-size: 18.7px;
  color: #f7f7f7;
  outline: "none";
  border-width: ${props => (!!props.value ? 0 : "0 0 1px 0")};
  box-shadow: none;
  font-weight: 300;
  ::placeholder {
    color: #f7f7f7;
    font-size: 14.4px;
    font-family: ${props => props.theme.PRIMARY_FONT};
    opacity: 0.5;
  }
  :focus {
    box-shadow: none;
    outline: none;
    border-width: 0;
  }
  :disabled {
    background: transparent;
  }
`;
