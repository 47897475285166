import * as React from "react";
import styled from "styled-components";
import { AppText } from "../UI";
import { OrderType } from "../../types";
import { PRIMARY_COLOR } from "../../utils";
import { FaTrashAlt } from "react-icons/fa";
import { FiAlignJustify } from "react-icons/fi";

interface OrderModalSegmentProps {
  address: string;
  city: string;
  zip: string;
  num_boxes: number;
  amount_to_collect: number;
  index: number;
  order_id: number;
  order: OrderType;
  status: string | null;
  remove: <T>(index: number) => T | undefined;
  addToOrdersToDelete: (order_id: OrderType) => void;
}

interface OrderModalSegmentState {}

class OrderModalSegment extends React.Component<
  OrderModalSegmentProps,
  OrderModalSegmentState
> {
  constructor(props: OrderModalSegmentProps) {
    super(props);
  }

  render() {
    const {
      index,
      address,
      city,
      zip,
      num_boxes,
      amount_to_collect,
      order_id,
      order,
    } = this.props;
    return (
      <OrderModalSegmentContainer>
        <OrderModalSegmentInfo>
          <AppText>{index + 1}</AppText>
          <FiAlignJustify
            size={20}
            style={{
              color: "#dbdbdb",
              margin: "0px 10px",
            }}
          />
          <InfoContainer
            style={{
              width: "200px",
              marginRight: "10px",
            }}
          >
            <Title>Customer</Title>
            <Info>{`${order.customer.last_name} ${
              order.customer.first_name ? ", " + order.customer.first_name : ""
            }`}</Info>
            <Title>Address</Title>
            <Info>{`${address}, ${city} ${zip}`}</Info>
          </InfoContainer>
          <InfoContainer
            style={{
              width: "50px",
              marginRight: "10px",
            }}
          >
            <Title>Boxes</Title>
            <Info>{`${num_boxes ? num_boxes : ""}`}</Info>
          </InfoContainer>
          <InfoContainer
            style={{
              width: "100px",
              marginRight: "10px",
            }}
          >
            <Title>Collection Amount</Title>
            <Info>{`$${amount_to_collect ? amount_to_collect : ""}`}</Info>
          </InfoContainer>
        </OrderModalSegmentInfo>
        <TrashIcon
          onClick={() => {
            this.props.remove(index);
            this.props.addToOrdersToDelete(order);
          }}
        >
          <FaTrashAlt size={22} style={{ color: PRIMARY_COLOR }} />
        </TrashIcon>
      </OrderModalSegmentContainer>
    );
  }
}

const OrderModalSegmentContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;
const OrderModalSegmentInfo = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  margin: 8px 0px;
  width: 90%;
  height: 70ppx;
  background-color: #f6f6f6;
  border: solid 1px ${PRIMARY_COLOR};
  border-radius: 2px;
  padding: 0 0px 0 10px;
`;

const TrashIcon = styled.div`
  cursor: pointer;
`;
const InfoContainer = styled.div`
  display: flex;
  flex-direction: column;
`;
const Title = styled(AppText)`
  font-size: 11px;
  font-weight: 500;
  color: #a8a8a8;
`;
const Info = styled(AppText)`
  font-size: 13px;
  font-weight: 500;
`;

export default OrderModalSegment;
