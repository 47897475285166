import { api } from "./api";

export const storeUserNotificationThunk = (
  store_id,
  notification_ids,
  send_method
  // begin_time,
  // end_time
) => async (dispatch, getState) => {
  const token = getState().auth.token;
  try {
    // const params = new URLSearchParams();
    // params.append("user_id", user_id);
    // params.append("notification_ids[]", notification_ids);
    // params.append("send_method", send_method);
    // params.append("begin_time", "00:00:00");
    // params.append("end_time", "00:00:00");
    let notificationID = "";
    notification_ids.map((element, i) => {
      notificationID += `&notification_ids[${i}]=` + `${element}`;
    });
    const notification = await api.storeUserNotification(
      { store_id, notification_ids, send_method },
      token
    );
    console.log("notification status", notification);
  } catch (err) {
    console.log(err);
  }
};

export const storeCustomerNotificationThunk = (
  store_id,
  notification_ids,
  send_method,
  begin_time,
  end_time
) => async (dispatch, getState) => {
  const token = getState().auth.token;
  try {
    api.storeCustomerNotification(
      { store_id, notification_ids, send_method, begin_time, end_time },
      token
    );
  } catch (err) {
    console.log(err);
  }
};
