import {
  ADD_DRIVER,
  AppActions,
  EDIT_DRIVER,
  FETCH_DRIVERS,
  FETCH_DRIVER,
  REMOVE_DRIVER,
  Driver
} from "../types";

export const addDriver = (driver: Driver): AppActions => ({
  type: ADD_DRIVER,
  driver
});

export const editDriver = (driver_id: number, driver: Driver): AppActions => ({
  type: EDIT_DRIVER,
  driver_id,
  driver
});

export const fetchDrivers = (drivers: Driver[]): AppActions => ({
  type: FETCH_DRIVERS,
  drivers
});

export const fetchDriver = (driver: Driver): AppActions => ({
  type: FETCH_DRIVER,
  driver
});

export const removeDriver = (driver_id: number): AppActions => ({
  type: REMOVE_DRIVER,
  driver_id
});
