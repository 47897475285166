import { FilterField, CustomDriverFieldFilterActionTypes } from "../types";

const customDriverFieldMenuReducerDefaultState: FilterField[] = [];

const CustomFieldFilterMenuReducer = (
  state = customDriverFieldMenuReducerDefaultState,
  action: CustomDriverFieldFilterActionTypes
): FilterField[] => {
  switch (action.type) {
    case "ADD_DRIVER_FIELD_FILTER_OPTION":
      return action.filters;
    case "LOGOUT":
      return customDriverFieldMenuReducerDefaultState;
    default:
      return state;
  }
};

export default CustomFieldFilterMenuReducer;
