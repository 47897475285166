import * as React from "react";
import { connect } from "react-redux";
import { Formik, FormikProps, Form, FieldArray } from "formik";
import * as Yup from "yup";
import { Dispatch, bindActionCreators } from "redux";
import { AppActions, Driver, CustomFields } from "../../../types";
import { AppState } from "../../../store/configureStore";
import {
  InputField,
  OnboardInputField,
  SwitchField,
  SelectField,
} from "../../form";
import styled from "styled-components";
import { Button, Modal, AppText, HR, SecondaryButton } from "../../UI";
import { PRIMARY_COLOR } from "../../../utils";
import { ColorSelectField } from "../../form/ColorSelectField";
import { languages } from "../../../static";
import { Dropdown } from "semantic-ui-react";
import { api } from "../../../api/api";
import {
  addProperty,
  editProperty,
  reloadDriverCustomListLoad,
  addOrderProperty,
  editOrderProperty,
  reloadOrderCustomListLoad,
  addDeliveryProperty,
  reloadDeliveryCustomListLoad,
  editDeliveryProperty,
  addDeliveryRunProperty,
  reloadDeliveryRunCustomListLoad,
  editDeliveryRunProperty,
} from "../../../actions";
import { FiPlusCircle, FiMinusCircle } from "react-icons/fi";
import { FaBreadSlice } from "react-icons/fa";
const schema = Yup.object().shape({
  custom_name: Yup.string().required("* Name is required"),
});

const inputType = [
  { label: "Text", value: 1, data: "" },
  { label: "Email", value: 2, data: "" },
  { label: "Dropdown", value: 3, data: "" },
  { label: "Date", value: 4, data: "" },
  { label: "Number", value: 5, data: "" },
  { label: "Checkbox", value: 6, data: "" },
];

interface DropdownValues {
  id: number;
  custom_field_id: number;
  value: string;
  selected: number;
  created_at: string;
  updated_at: string;
  deleted_at: string;
}

interface MyFormikProps {
  custom_name: string;
  input_type: number;
}

interface CreateCustomPropertyProps {
  showModal: boolean;
  toggleModal: () => void;
  setDropdownValues?: (dropdowns: any) => void;
  type: string;
  edit?: boolean;
  selected_field?: number | null;
  field_type?: number | null;
  property_name?: string;
  dropdown_value?: DropdownValues[];
}

interface CreateCustomPropertyState {
  dropdowns: DropdownValues[];
}

interface PostCustomFieldValue {
  store_id: number;
  field_type_id: number;
  entity_name: string;
  display: string;
  slug: string;
  updated_at: string;
  created_at: string;
  id: number;
}
class CreateCustomProperty extends React.Component<
  Props,
  CreateCustomPropertyState
> {
  constructor(props: Props) {
    super(props);

    this.state = {
      dropdowns: [],
    };
  }
  componentDidMount = async () => {
    const { edit, field_type, token, selected_field } = this.props;
    if (!!edit && Number(field_type) === 3) {
      let dropdown_values = (await api.getCustomFieldListValues(
        selected_field as number,
        token
      )) as any;
      this.setState({
        dropdowns: dropdown_values,
      });
    }
  };
  componentDidUpdate = async (prevProps, prevState) => {
    const { edit, field_type, token, selected_field } = this.props;

    if (
      edit &&
      field_type !== prevProps.field_type &&
      selected_field !== prevProps.selected_field
    ) {
      let dropdown_values = (await api.getCustomFieldListValues(
        selected_field as number,
        token
      )) as any;

      this.setState({
        dropdowns: dropdown_values,
      });
    }
  };
  deleteCustomFieldValue = async (id: number) => {
    const { token } = this.props;
    await api.deleteCustomFieldListValue(id, token);
  };
  editPropertyDispatch = ({
    type,
    property_id,
    property,
  }: {
    type: string;
    property_id: number;
    property: CustomFields;
  }) => {
    const {
      edit_property,
      edit_order_property,
      edit_delivery_property,
      edit_delivery_run_property,
    } = this.props;
    switch (type) {
      case "Driver":
        edit_property(property_id, property);
        break;
      case "Order":
        edit_order_property(property_id, property);
        break;
      case "DeliveryRun":
        edit_delivery_run_property(property_id, property);
        break;
      case "Delivery":
        edit_delivery_property(property_id, property);
        break;
      default:
        () => {};
    }
  };
  addPropertyDispatch = (type: string, property: CustomFields) => {
    const {
      add_property,
      add_order_property,
      add_delivery_property,
      add_delivery_run_property,
    } = this.props;
    switch (type) {
      case "Driver":
        add_property(property);
        break;
      case "Order":
        add_order_property(property);
        break;
      case "DeliveryRun":
        add_delivery_run_property(property);
        break;
      case "Delivery":
        add_delivery_property(property);
        break;
      default:
        () => {};
    }
  };
  reloadCustomState = (type) => {
    const {
      reloadCustomListLoad,
      reloadDeliveryCustomListLoad,
      reloadDeliveryRunCustomListLoad,
    } = this.props;
    switch (type) {
      case "Driver":
        reloadCustomListLoad();
      case "Order":
        reloadOrderCustomListLoad();
      case "DeliveryRun":
        reloadDeliveryRunCustomListLoad();
      case "Delivery":
        reloadDeliveryCustomListLoad();
      default:
        () => {};
    }
  };
  // newDropDownField = async (id:  number, value: string) => {
  //   await api.createCustomFieldListValue(
  //     {
  //       custom_field_id: id,
  //       value: value,
  //       selected: 0
  //     },
  //     token
  //   );
  // }

  render(): JSX.Element {
    return (
      <Modal
        height="fit-content"
        width="fit-content"
        showModal={this.props.showModal}
        toggleModal={this.props.toggleModal}
        label="Rename Custom List"
      >
        <Formik
          initialValues={{
            custom_name: this.props.property_name
              ? this.props.property_name
              : "",
            input_type: 1,
            dropdown_values:
              this.props.dropdown_value && this.props.dropdown_value.length > 0
                ? (this.props.dropdown_value as DropdownValues[])
                : [
                    {
                      id: null,
                      custom_field_id: null,
                      value: "",
                      selected: 0,
                      created_at: "",
                      updated_at: "",
                      deleted_at: "",
                    },
                  ],
          }}
          onSubmit={async (values, { setFieldError, setStatus }) => {
            const {
              store_id,
              token,
              type,
              edit,
              selected_field,
              add_property,
              edit_property,
              field_type,
            } = this.props;
            const { custom_name, input_type, dropdown_values } = values;
            setStatus({ error: "", loading: true });
            try {
              if (edit && selected_field) {
                const editedCustomField = (await api.updateCustomField(
                  {
                    field_id: selected_field,
                    display: custom_name,
                    slug: custom_name.replace(/ /g, "_").toLocaleLowerCase(),
                  },
                  token
                )) as CustomFields;
                if (dropdown_values.length > 0 && field_type === 3) {
                  dropdown_values.forEach(async (value) => {
                    if (!!value.id) {
                      await api.editCustomFieldListValue(
                        {
                          custom_field_id: value.id,
                          value: value.value,
                        },
                        token
                      );
                    } else {
                      await api.createCustomFieldListValue(
                        {
                          custom_field_id: selected_field,
                          value: value.value,
                          selected: 0,
                        },
                        token
                      );
                    }
                  });
                }
                //switch case for edit property
                this.editPropertyDispatch({
                  type: type,
                  property_id: editedCustomField.id,
                  property: editedCustomField,
                });
                // edit_property(editedCustomField.id, editedCustomField);
                //switch case to reload state for property
                this.reloadCustomState(type);
              } else {
                const newCustomField = (await api.createCustomField(
                  {
                    store_id: store_id,
                    field_type_id: input_type,
                    entity_name: type,
                    display: custom_name,
                    slug: custom_name.replace(/ /g, "_").toLocaleLowerCase(),
                  },
                  token
                )) as PostCustomFieldValue;
                if (dropdown_values.length > 0 && input_type === 3) {
                  dropdown_values.forEach(async (value) => {
                    await api.createCustomFieldListValue(
                      {
                        custom_field_id: newCustomField.id,
                        value: value.value,
                        selected: 0,
                      },
                      token
                    );
                  });
                }
                if (dropdown_values.length > 0 && input_type === 6) {
                  dropdown_values.forEach(async (value) => {
                    await api.createCustomFieldListValue(
                      {
                        custom_field_id: newCustomField.id,
                        value: "checkbox",
                        selected: 0,
                      },
                      token
                    );
                  });
                }
                //swwitch case for add_property
                this.addPropertyDispatch(type, newCustomField as CustomFields);
                // add_property(newCustomField as CustomFields);
                //switch case for reload state for property
                this.reloadCustomState(type);
              }
              this.props.setDropdownValues
                ? this.props.setDropdownValues(dropdown_values)
                : null;
              this.props.toggleModal();
            } catch (e) {
              console.log("logging error: ", e);
              setStatus({ loading: false });
              this.props.toggleModal;
            }
            console.log("no error");
            setStatus({ loading: false });
          }}
        >
          {({
            status,
            touched,
            submitForm,
            errors,
            setFieldTouched,
            values,
          }: FormikProps<MyFormikProps>) => {
            return (
              <Form>
                <Div>
                  <Header>
                    {this.props.edit ? (
                      <HeaderText>EDIT CUSTOM PROPERTY</HeaderText>
                    ) : (
                      <HeaderText>CREATE CUSTOM PROPERTY</HeaderText>
                    )}
                  </Header>
                  <HR />
                  <Grid>
                    <InputField
                      style={{
                        gridColumn: "1 / span 1",
                        gridRow: "3 / span 1",
                      }}
                      name="custom_name"
                      placeholder=""
                      title="Custom Propery Name"
                      inputWidth="418px"
                    />

                    {this.props.edit ? null : (
                      <SelectField
                        style={{
                          gridColumn: "1 / span 1",
                          gridRow: "4 / span 2",
                        }}
                        name="input_type"
                        placeholder=""
                        title="Input Type"
                        options={inputType as any}
                        inputWidth={300}
                      />
                    )}
                  </Grid>
                  {this.props.edit && this.props.field_type === 3 ? (
                    <div>
                      <DropdownText>Dropdown Fields</DropdownText>
                      <FieldArray name="dropdown_values">
                        {({ insert, remove, name }) =>
                          values[name].map((_, i) => {
                            return (
                              <DropdownDiv>
                                <InputField
                                  name={`dropdown_values[${i}].value`}
                                  placeholder=""
                                  inputWidth="192px"
                                />
                                <CircleContainer>
                                  <PlusCircleDiv
                                    onClick={() =>
                                      insert(values[name].length, "")
                                    }
                                  >
                                    <FiPlusCircle size={20} color="#60999e" />
                                  </PlusCircleDiv>
                                  <MinusCircleDiv
                                    onClick={() => {
                                      if (i !== 0) {
                                        this.deleteCustomFieldValue(_.id);
                                        remove(i);
                                      }
                                    }}
                                  >
                                    <FiMinusCircle size={20} color="#c4183c" />
                                  </MinusCircleDiv>
                                </CircleContainer>
                              </DropdownDiv>
                            );
                          })
                        }
                      </FieldArray>
                    </div>
                  ) : null}
                  {values.input_type === 3 && !this.props.edit && (
                    <div>
                      <DropdownText>Dropdown Fields</DropdownText>
                      <FieldArray name="dropdown_values">
                        {({ insert, remove, name }) =>
                          values[name].map((_, i) => (
                            <DropdownDiv>
                              <InputField
                                name={`dropdown_values[${i}].value`}
                                placeholder=""
                                inputWidth="192px"
                              />
                              <CircleContainer>
                                <PlusCircleDiv
                                  onClick={() =>
                                    insert(values[name].length, "")
                                  }
                                >
                                  <FiPlusCircle size={20} color="#60999e" />
                                </PlusCircleDiv>
                                <MinusCircleDiv
                                  onClick={() => {
                                    if (i !== 0) remove(i);
                                  }}
                                >
                                  <FiMinusCircle size={20} color="#c4183c" />
                                </MinusCircleDiv>
                              </CircleContainer>
                            </DropdownDiv>
                          ))
                        }
                      </FieldArray>
                    </div>
                  )}
                  <Footer>
                    <CancelButton onClick={this.props.toggleModal}>
                      Cancel
                    </CancelButton>
                    <Button
                      width="130px"
                      type="submit"
                      style={{ justifySelf: "flex-end" }}
                      //TODO figure out why submit form submits twice
                      onClick={() => submitForm}
                      disabled={
                        !(
                          Object.keys(touched).length &&
                          !Object.keys(errors).length
                        )
                      }
                    >
                      {this.props.edit ? "Edit List" : "Create Property"}
                    </Button>
                  </Footer>
                </Div>
              </Form>
            );
          }}
        </Formik>
      </Modal>
    );
  }
}

const Grid = styled.div`
  display: grid;
  grid-template-columns: min-content min-content auto;
  grid-template-rows: 0px 0px 100px 100px;
  column-gap: 24px;
  padding: 25px 40px 0 40px;
`;

const Div = styled.div`
  display: flex;
  flex-direction: column;
  border-top: solid 1px ${PRIMARY_COLOR};
  border-radius: 2px;
  overflow-x: none;
  width: 507px;
`;

const HeaderText = styled(AppText)`
  font-size: 18px;
  font-weight: 600;
  letter-spacing: 0.3px;
  color: #60999e;
`;

const DropdownText = styled(AppText)`
  font-size: 14px;
  font-weight: 500;
  opacity: 0.5;
  color: black;
  padding-left: 40px;
`;
const Header = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height: 57px;
  padding: 0 40px 0 40px;
`;

const DropdownDiv = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 15px;
  padding-left: 40px;
  align-items: center;
`;
const CircleContainer = styled.div`
  display: flex;
  flex-direction: row;
  padding-left: 20px;
`;
const PlusCircleDiv = styled.div`
  padding: 0px 3px;
  :active {
    top: 3px;
    transform: translateY(2px);
  }
`;
const MinusCircleDiv = styled.div`
  padding: 0px 3px;
  :active {
    top: 3px;
    transform: translateY(2px);
  }
`;
const Footer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  height: 57px;
  padding: 0 40px 30px 40px;
`;

const CancelText = styled(AppText)`
  font-size: 13px;
  font-weight: 500;
  color: #3b3a3a;
  margin-right: 53.5px;
`;

const CancelButton = styled(Button)`
  height: 40px;
  width: 110px;
  border-radius: 4px;
  border: solid 1px rgba(71, 70, 71, 0.26);
  background-color: #fff;
  color: #000;
  margin-right: 21px;
`;

const StyledInputField = styled((props) => <InputField {...props} />)``;
type Props = CreateCustomPropertyProps & LinkDispatchProps & LinkStateProps;

interface LinkStateProps {
  token: string;
  store_id: number;
}

interface LinkDispatchProps {
  //driver
  add_property: (property: CustomFields) => void;
  edit_property: (property_id: number, property: CustomFields) => void;
  reloadCustomListLoad: () => void;
  //order
  add_order_property: (property: CustomFields) => void;
  edit_order_property: (property_id: number, property: CustomFields) => void;
  reloadOrderCustomListLoad: () => void;
  //delivery
  add_delivery_property: (property: CustomFields) => void;
  edit_delivery_property: (property_id: number, property: CustomFields) => void;
  reloadDeliveryCustomListLoad: () => void;
  // delivery run
  add_delivery_run_property: (property: CustomFields) => void;
  edit_delivery_run_property: (
    property_id: number,
    property: CustomFields
  ) => void;
  reloadDeliveryRunCustomListLoad: () => void;
}

const mapStateToProps = (state: AppState): LinkStateProps => ({
  store_id: state.user.store_id as number,
  token: state.auth.token as string,
});

const mapDispatchToProps = (
  dispatch: Dispatch<AppActions>
): LinkDispatchProps => ({
  //driver dispatches
  add_property: bindActionCreators(addProperty, dispatch),
  edit_property: bindActionCreators(editProperty, dispatch),
  reloadCustomListLoad: bindActionCreators(
    reloadDriverCustomListLoad,
    dispatch
  ),
  //order dispatches
  add_order_property: bindActionCreators(addOrderProperty, dispatch),
  edit_order_property: bindActionCreators(editOrderProperty, dispatch),
  reloadOrderCustomListLoad: bindActionCreators(
    reloadOrderCustomListLoad,
    dispatch
  ),
  //delivery dispatches
  add_delivery_property: bindActionCreators(addDeliveryProperty, dispatch),
  edit_delivery_property: bindActionCreators(editDeliveryProperty, dispatch),
  reloadDeliveryCustomListLoad: bindActionCreators(
    reloadDeliveryCustomListLoad,
    dispatch
  ),
  //delivery run dispatches
  add_delivery_run_property: bindActionCreators(
    addDeliveryRunProperty,
    dispatch
  ),
  edit_delivery_run_property: bindActionCreators(
    editDeliveryRunProperty,
    dispatch
  ),
  reloadDeliveryRunCustomListLoad: bindActionCreators(
    reloadDeliveryRunCustomListLoad,
    dispatch
  ),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CreateCustomProperty);
