import * as React from "react";
import styled from "styled-components";
import { AppState } from "../../store/configureStore";
import { connect } from "react-redux";
import { Dispatch, bindActionCreators } from "redux";
import {
  AppActions,
  LoggedUser,
  ItemList,
  OrderType,
  Driver,
  DeliveryRuns,
  DeliveryDriver,
  ImportedDeliveryRun,
} from "../../types";
import { AppText, Button, Spinner } from "../UI";
import {
  PRIMARY_COLOR,
  shortenDateOrShowToday,
  renderBasicTimeFormat,
} from "../../utils";
import { Link } from "react-router-dom";
import { FiBox } from "react-icons/fi";
import { FaMapMarkerAlt, FaRegCalendarAlt, FaRegClock } from "react-icons/fa";
import { api } from "../../api/api";
import { deliveryRunsLoaded } from "../../actions/createDeliveryRun";
import DeliveryRunContainer from "./DeliveryRunContainer";
import { clearDashBoardDriverDeliveryRun } from "../../actions";

interface DriverCardProps {
  id: number;
  first_name: string;
  last_name: string;
  delivery_runs: DeliveryRuns[];
  onDriverSelect?: (driverID: number) => void;
  onDeliveryRunSelect: (
    driverID: number,
    deliveryRunID: string,
    deliveryRunLabel: string,
    delivery: DeliveryRuns
  ) => void;
  updateDeliveryRun: (deliveryRun: ImportedDeliveryRun) => void;
  selectMode?: boolean;
  fromDashboard: boolean;
  selected_delivery_id?: number;
}

interface DriverCardState {
  hasDeliveryRun: boolean;
}

type Props = DriverCardProps & LinkStateProps & LinkDispatchProps;

class DriverCard extends React.Component<Props, DriverCardState> {
  constructor(props: Props) {
    super(props);
    this.state = {
      hasDeliveryRun: true,
    };
  }
  componentDidMount = async () => {
    const {
      id,
      token,
      delivery_runs,
      fromDashboard,
      updateDeliveryRun,
      clearDashBoardDriverDeliveryRun,
    } = this.props;

    const getAllDriversAndDeliveries = await api.getAllDeliveryRunsOnMapView({
      store_id: id,
      token: token,
    });
    //TODO fix brute force typescript
    const deliveryRuns = (await api.getDeliveryRunByDriver(id, token)) as any;
    Array.isArray(deliveryRuns) &&
      deliveryRuns.length === 0 &&
      this.setState({
        hasDeliveryRun: false,
      });
    if (deliveryRuns.length === 0) {
      clearDashBoardDriverDeliveryRun(id);
    }
    //temp fix - will need to add conditionals for API endpoint
    Array.isArray(deliveryRuns) &&
      deliveryRuns.forEach(async (delivery) => {
        const finalDeliveryRun = await api
          .getDeliveryRun(delivery.id as number, token as string)
          .then((r) => {
            this.setState({
              hasDeliveryRun: false,
            });
            return r;
          });

        const existingDeliveries = delivery_runs.map((deliveries) => {
          return deliveries.delivery_id;
        });

        if (!existingDeliveries.includes(delivery.id)) {
          updateDeliveryRun(finalDeliveryRun as ImportedDeliveryRun);
        }
      });
  };

  render() {
    const {
      first_name,
      last_name,
      delivery_runs,
      onDriverSelect,
      id,
      onDeliveryRunSelect,
      selectMode,
      fromDashboard,
      selected_delivery_id,
    } = this.props;

    return (
      <DriverCardContainer>
        <DriverDiv selectMode={selectMode ? selectMode : false}>
          <InfoContainer>
            {selectMode ? (
              <NameText>{`${first_name} ${last_name}`}</NameText>
            ) : (
              <Link to={`/driver/${id}`}>
                <NameText>{`${first_name} ${last_name}`}</NameText>
              </Link>
            )}
          </InfoContainer>
        </DriverDiv>
        <DeliveryRunsBackground>
          <div>
            {this.state.hasDeliveryRun && (
              <SpinnerDiv>
                <Spinner />
              </SpinnerDiv>
            )}
          </div>
          {delivery_runs.map((delivery) => {
            let total_boxes = 0;
            delivery.deliveries.forEach(
              (order) => (total_boxes += order.num_boxes as number)
            );
            return (
              <DeliveryRunContainer
                id={id}
                selectMode={selectMode ? selectMode : false}
                key={delivery.id}
                onDeliveryRunSelect={onDeliveryRunSelect}
                delivery={delivery}
                driver_selected={
                  delivery.delivery_id === selected_delivery_id ? true : false
                }
              />
            );
          })}
          <div>
            {selectMode && (
              <AddToDeliveryRunButton
                onClick={() => (!!onDriverSelect ? onDriverSelect(id) : null)}
                selectMode={selectMode ? selectMode : false}
              >
                Add New Delivery
              </AddToDeliveryRunButton>
            )}
          </div>
        </DeliveryRunsBackground>
      </DriverCardContainer>
    );
  }
}
interface DriverDivProps {
  selectMode: boolean;
  driver_selected?: boolean;
}
const DriverCardContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const SpinnerDiv = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  width: 190px;
  height: 61px;
  border-radius: 4px;
  border: solid 1px ${PRIMARY_COLOR};
  background-color: #ffffff;
  box-shadow: 2px 1px 4px 0 rgba(59, 58, 58, 0.5);
  padding: 0px 20px 0px 20px;
  margin: 8px 0px;
`;
const DriverDiv = styled.div<DriverDivProps>`
  width: 204px;
  height: 42px;
  border-radius: 4px;
  border: solid 1px #f2f2f2;
  background-color: #ffffff;
  margin-top: 16px;
`;

const DeliveryRunsBackground = styled.div`
  background-color: #d8d8d8;
  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: column;
  align-items: center;
`;

const InfoContainer = styled.div`
  display: flex;
  margin-top: 10px;
  margin-left: 14px;
`;

const NameText = styled(AppText)`
  font-size: 16px;
  font-weight: 500;
`;

const AddToDeliveryRunButton = styled(Button)<DriverDivProps>`
  width: 172px;
  border-radius: 4px;
  box-shadow: 2px 1px 4px 0 rgba(59, 58, 58, 0.5);
  margin: 8px 0px;
  &:hover {
    background-color: ${(props) => (props.selectMode ? "#bfd6d8" : "#ffffff")};
    transition: background-color 0.3s ease;
    cursor: pointer;
  }
`;

interface LinkStateProps {
  token: string;
}

interface LinkDispatchProps {
  deliveryRunsLoaded: () => void;
  clearDashBoardDriverDeliveryRun: (driverID: number) => void;
}
const mapDispatchToProps = (
  dispatch: Dispatch<AppActions>
): LinkDispatchProps => ({
  deliveryRunsLoaded: bindActionCreators(deliveryRunsLoaded, dispatch),
  clearDashBoardDriverDeliveryRun: bindActionCreators(
    clearDashBoardDriverDeliveryRun,
    dispatch
  ),
});
const mapStateToProps = (state: AppState): LinkStateProps => ({
  token: state.auth.token as string,
});
export default connect(mapStateToProps, mapDispatchToProps)(DriverCard);
