import React, { useState } from "react";
import { Field, FieldProps, ErrorMessage } from "formik";
import { Input, AppText } from "../UI";
import styled from "styled-components";
import { ErrorText } from "../Dumb";
import { api } from "../../api/api";
import { Suggestion } from "../../types";

export const AddressField = ({
  name,
  title,
  placeholder,
  style,
  inputWidth,
  type = "text",
  disabled,
  longitude,
  latitude,
  handleAutoSuggest,
  autoFillsuggestion,
  suggestions,
}: {
  name: string;
  title?: string;
  placeholder: string;
  style?: React.CSSProperties;
  inputWidth?: string;
  type?: string;
  disabled?: boolean;
  longitude: number;
  latitude: number;
  handleAutoSuggest: (suggestion: Suggestion[]) => void;
  autoFillsuggestion: ({
    address,
    city,
    state,
    zipcode,
  }: {
    address: string;
    city: string;
    state: string;
    zipcode: string;
  }) => void;
  suggestions: Suggestion[];
}) => {
  const [focus, setFocus] = useState(false);
  return (
    <div style={style}>
      <Field
        name={name}
        render={({
          field,
          form: { errors, touched, setFieldValue, setFieldTouched },
        }: FieldProps) => {
          const error = !!errors[name] && !!touched[name];
          return (
            <FormSegment>
              {/* {title ? (
              <AppTextFormTitle>{title}</AppTextFormTitle>
            ) : (
              <AppTextFormTitle>&nbsp; </AppTextFormTitle>
            )} */}
              {title && <AppTextFormTitle>{title}</AppTextFormTitle>}
              <Input
                {...field}
                onChange={async (value) => {
                  setFieldValue(name, value.target.value);
                  console.log("field value", value.target.value);
                  const data = await api.autoCorrectAddress({
                    address_query: value.target.value,
                    store_longitude: longitude,
                    store_latitude: latitude,
                  });
                  handleAutoSuggest(data.suggestions as any);
                }}
                onBlur={(event) => {
                  event.preventDefault();
                  setFocus(false);
                }}
                onFocus={() => setFocus(true)}
                error={error}
                placeholder={placeholder}
                inputWidth={inputWidth}
                type={type}
                disabled={disabled}
              />
              {focus && (
                <List>
                  {suggestions.map((suggestion) => {
                    const houseNumber = suggestion.address.houseNumber
                      ? suggestion.address.houseNumber
                      : "";
                    const city = suggestion.address.city
                      ? suggestion.address.city
                      : "";
                    const street = suggestion.address.street
                      ? suggestion.address.street
                      : "";

                    const postalCode = suggestion.address.postalCode
                      ? suggestion.address.postalCode
                      : "";

                    const state = suggestion.address.state
                      ? suggestion.address.state
                      : "";

                    return (
                      <ListItem
                        inputWidth={inputWidth}
                        onBlur={() => setFocus(false)}
                        onMouseDown={
                          (event) => {
                            event.preventDefault();
                            setFieldValue(
                              "address_1",
                              `${houseNumber} ${street}`
                            );
                            setFieldValue("city", city);
                            setFieldValue("state", state);
                            setFieldValue("zip", postalCode);
                            setFieldTouched("address_1", false);
                            setFocus(false);
                          }

                          // autoFillsuggestion({
                          //   address: `${houseNumber} ${street}`,
                          //   city: city,
                          //   state: state,
                          //   zipcode: postalCode,
                          // })
                        }
                      >{`${houseNumber} ${street} ${city}, ${state}, ${postalCode}`}</ListItem>
                    );
                  })}
                </List>
              )}
              <ErrorMessage name={field.name} component={ErrorText} />
            </FormSegment>
          );
        }}
      />
    </div>
  );
};

const FormSegment = styled.div``;

const AppTextFormTitle = styled(AppText)`
  opacity: 0.5;
  font-size: 14px;
  font-weight: 500;
  color: #3b3a3a;
  margin-bottom: 7px;
`;
interface ListProps {
  inputWidth?: string;
}

const List = styled.ul<ListProps>`
  position: relative;
  width: ${(props) => props.inputWidth};
  background-color: #ffffff;
  padding: 0;
  z-index: 1;
`;

const ListItem = styled.li<ListProps>`
  width: ${(props) => props.inputWidth};
  margin: 0;
  padding: 0;
  list-style: none;
  background: #fff;
`;
