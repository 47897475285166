import * as React from "react";
import styled from "styled-components";
import { Button, Modal, AppTextRoman, AppText } from "../UI";
import { PRIMARY_COLOR, toastError } from "../../utils";
import { AppState } from "../../store/configureStore";
import { bindActionCreators, Dispatch } from "redux";
import { AppActions, CustomFilters } from "../../types";
import { connect } from "react-redux";
import {
  addDriverFieldFilter,
  removeDriverFieldFilter,
  addOrderFieldFilter,
  removeOrderFieldFilter,
  addDeliveryRunFieldFilter,
  removeDeliveryRunFieldFilter,
  removeDeliveryFieldFilter,
  addDeliveryFieldFilter,
} from "../../actions";
import TitleReformat from "../../helpers/titleReformat";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";

interface Field {
  display: string;
  field: string;
  visible: boolean;
  ordinal?: number;
}

interface InternalField extends Field {
  selected: boolean;
}

interface ManageColumnsProps {
  showModal: boolean;
  toggleModal: () => void;
  fieldList: Field[];
  onSave: (filter: CustomFilters[]) => void;
  selectedMenuLabel?: string;
}

interface ManageColumnsState {
  fieldList: InternalField[];
}

type Props = ManageColumnsProps & LinkDispatchProps & LinkStateProps;

class ManageColumns extends React.PureComponent<Props, ManageColumnsState> {
  constructor(props: Props) {
    super(props);

    this.state = {
      fieldList: this.props.fieldList
        // .filter((field) => field.field !== "id")
        .map((field) => ({
          ...field,
          selected: false,
        })),
    };
  }

  toggleSelect = (field: string) => {
    if (field === "id" || field === "address" || field === "customer" || field === "status") {
      const idError = `Cannot select this field`;
      toastError(idError);
    } else {
      this.setState((prev) => ({
        fieldList: prev.fieldList.map((f) => {
          if (f.field === field) {
            return {
              ...f,
              selected: !f.selected,
            };
          }
          return f;
        }),
      }));
    }
  };

  componentDidUpdate = (prevProps) => {
    if (this.props.fieldList !== prevProps.fieldList) {
      this.setState({
        fieldList: this.props.fieldList
          // .filter((field) => field.field !== "id")
          .map((field) => ({
            ...field,
            selected: false,
          })),
      });
    }
  };

  convertSelectedAvailableToVisible = () => {
    // this.state.fieldList
    //   .filter((field) => field.selected === true)
    //   .map((field) => field.field)
    //   .forEach((field) => this.availableToVisibleActions(field));
    this.setState((prev) => ({
      fieldList: prev.fieldList
        // .filter(({ visible, selected }) => !visible && selected)
        .map((field) => {
          if (!field.visible && field.selected) {
            return {
              ...field,
              display: field.display,
              visible: true,
              selected: false,
            };
          }
          return field;
        }),
    }));
  };

  convertSelectedVisibleToAvailable = () => {
    // this.state.fieldList
    //   .filter((field) => field.selected === true)
    //   .map((field) => field.field)
    //   .forEach((field) => this.visibleToAvailableActions(field));
    this.setState((prev) => ({
      fieldList: prev.fieldList
        // .filter(({ visible, selected }) => visible && selected)
        .map((field) => {
          if (field.visible && field.selected) {
            return {
              ...field,
              display: field.display,
              visible: false,
              selected: false,
            };
          }
          return field;
        }),
    }));
  };

  visibleToAvailableActions = (filter: string) => {
    switch (this.props.selectedMenuLabel) {
      case "Drivers":
        this.props.removeDriverFieldFilter(filter);
        break;
      case "Orders":
        this.props.removeOrderFieldFilter(filter);
        break;
      case "Delivery Runs":
        this.props.removeDeliveryRunFieldFilter(filter);
        break;
      case "Deliveries":
        this.props.removeDeliveryFieldFilter(filter);
        break;
      default:
        return null;
    }
  };

  availableToVisibleActions = (filter: string) => {
    switch (this.props.selectedMenuLabel) {
      case "Drivers":
        this.props.addDriverFieldFilter(filter);
        break;
      case "Orders":
        this.props.addOrderFieldFilter(filter);
        break;
      case "Delivery Runs":
        this.props.addDeliveryRunFieldFilter(filter);
        break;
      case "Deliveries":
        this.props.addDeliveryFieldFilter(filter);
        break;
      default:
        return null;
    }
  };

  onSave = () => {
    const { fieldList } = this.state;
    this.props.onSave(
      fieldList.map(({ display, field, visible, ordinal }) => ({
        display,
        field,
        visible,
        ordinal,
      }))
    );
    this.props.toggleModal();
  };

  exitModal = () => {
    this.setState({
      fieldList: this.props.fieldList.map((field) => ({
        ...field,
        selected: false,
      })),
    });
    this.props.toggleModal();
  };

  getListStyle = (isDraggingOver) => ({
    background: isDraggingOver ? "lightblue" : "transparent",
  });

  getItemStyle = (isDragging, draggableStyle) => ({
    // some basic styles to make the items look a bit nicer
    userSelect: "none",
    // change background colour if dragging
    background: isDragging ? PRIMARY_COLOR : "transparent",
    // styles we need to apply on draggables
    ...draggableStyle,
  });

  reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    console.log("reorder result", result);
    let idArray = [
      { display: "ID", field: "id", selected: false, visible: false },
    ];

    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    const returnedResult = idArray.concat(result as any[]);

    return returnedResult;
  };

  render(): JSX.Element {
    const { fieldList } = this.state;
    // const { toggleModal, selectedMenuLabel } = this.props;
    console.log("fieldlist", fieldList);
    return (
      <Modal
        height="fit-content"
        width="fit-content"
        showModal={this.props.showModal}
        toggleModal={this.props.toggleModal}
        label="Add Driver"
      >
        <Div>
          <Header>
            <Title>SELECT FIELDS TO DISPLAY</Title>
          </Header>
          <Body>
            <div>
              <FieldText style={{ paddingLeft: 16 }}>
                Available Fields
              </FieldText>
              <Box>
                {fieldList
                  .filter(({ visible }) => !visible)
                  .map(({ display, field, selected }) => (
                    <FieldItem
                      onClick={() => this.toggleSelect(field)}
                      selected={selected}
                      key={field}
                    >
                      <FieldText>{display}</FieldText>
                    </FieldItem>
                  ))}
              </Box>
            </div>
            <Middle>
              <LeftArrow onClick={this.convertSelectedVisibleToAvailable} />
              <RightArrow onClick={this.convertSelectedAvailableToVisible} />
            </Middle>
            <div>
              <FieldText style={{ paddingLeft: 16 }}>Visible Fields</FieldText>
              <Box>
                <DragDropContext
                  onDragEnd={(result) => {
                    console.log("result:", result);
                    if (!result.destination) {
                      return;
                    }

                    const listToUse = this.state.fieldList.filter(
                      (list) => list.field !== "id"
                    );

                    const reorderedList = this.reorder(
                      listToUse,
                      result.source.index,
                      result.destination.index
                    ) as InternalField[];
                    console.log("reorderedList result:", reorderedList);
                    this.setState({
                      fieldList: reorderedList,
                    });
                  }}
                >
                  <Droppable droppableId={"columns"}>
                    {(provided, snapshot) => (
                      <div
                        {...provided.droppableProps}
                        style={this.getListStyle(snapshot.isDraggingOver)}
                        ref={provided.innerRef}
                      >
                        {fieldList
                          .filter(({ visible }) => visible)
                          .map(({ display, field, selected }, index) => (
                            <FieldItem
                              onClick={() => this.toggleSelect(field)}
                              selected={selected}
                              key={field}
                            >
                              <Draggable
                                key={field}
                                draggableId={field}
                                index={index}
                              >
                                {(provided, snapshot) => (
                                  <FieldText
                                    ref={provided.innerRef}
                                    {...provided.draggableProps}
                                    {...provided.dragHandleProps}
                                    onDragStart={(event: any) => {}}
                                    style={this.getItemStyle(
                                      snapshot.isDragging,
                                      provided.draggableProps.style
                                    )}
                                  >
                                    {display}
                                  </FieldText>
                                )}
                              </Draggable>
                            </FieldItem>
                          ))}
                      </div>
                    )}
                  </Droppable>
                </DragDropContext>
              </Box>
            </div>
          </Body>
          <Footer>
            <CancelButton onClick={this.exitModal}>EXIT</CancelButton>
            <SaveButton onClick={this.onSave}>Save</SaveButton>
          </Footer>
        </Div>
      </Modal>
    );
  }
}

const Body = styled.div`
  display: flex;
  flex-direction: row;
`;

const Div = styled.div`
  display: flex;
  flex-direction: column;
  border-radius: 2px;
  overflow-x: none;
  padding: 10px 50px 20px 50px;
`;

const Title = styled(AppTextRoman)`
  font-size: 15px;
  letter-spacing: 0.2px;
  color: #3b3a3a;
`;

const FieldText = styled(AppText)`
  font-size: 14px;
  color: #333333;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
`;

interface FieldItemProps {
  selected: boolean;
}

const FieldItem = styled.div<FieldItemProps>`
  padding-left: 16px;
  height: 30px;
  background-color: ${(props) => (props.selected ? "#d8d8d8" : "#fff")};
  :hover {
    cursor: pointer;
  }
  display: flex;
  flex-direction: row;
  align-items: center;
`;

FieldItem.defaultProps = {
  selected: false,
};

const Middle = styled.div`
  height: 300px;
  width: 50px;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
`;

const Header = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: 57px;
`;

const Box = styled.div`
  width: 250px;
  height: 280px;
  border-radius: 4px;
  border: solid 1px rgba(71, 70, 71, 0.26);
  overflow-y: auto;
  margin-top: 9px;
`;

const Footer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  margin-top: 35px;
`;

const SaveButton = styled(Button)`
  height: 40px;
  width: 110px;
  border-radius: 4px;
  border: solid 1px rgba(71, 70, 71, 0.26);
`;

const CancelButton = styled(Button)`
  height: 40px;
  width: 110px;
  border-radius: 4px;
  border: solid 1px rgba(71, 70, 71, 0.26);
  background-color: #fff;
  color: #000;
  margin-right: 21px;
`;

const LeftArrow = styled.div`
  width: 0;
  height: 0;
  border-bottom: 13px solid transparent;
  border-top: 13px solid transparent;
  border-right: 13px solid rgba(59, 58, 58, 0.9);
  border-radius: 5px;
  :hover {
    cursor: pointer;
  }
`;

const RightArrow = styled.div`
  width: 0;
  height: 0;
  border-top: 13px solid transparent;
  border-bottom: 13px solid transparent;
  border-left: 13px solid rgba(59, 58, 58, 0.9);
  border-radius: 5px;
  :hover {
    cursor: pointer;
  }
`;

interface LinkStateProps {
  showNewDriverModal: boolean;
  token: string;
  store_id: number;
  driverListID: number;
  driverListLoaded: boolean;
  driverFilters: CustomFilters[];
  driverCustomListLoaded: boolean;
}

interface LinkDispatchProps {
  addDriverFieldFilter: (filter: string) => void;
  removeDriverFieldFilter: (filter: string) => void;
  addOrderFieldFilter: (filter: string) => void;
  removeOrderFieldFilter: (filter: string) => void;
  addDeliveryRunFieldFilter: (filter: string) => void;
  removeDeliveryRunFieldFilter: (filter: string) => void;
  addDeliveryFieldFilter: (filter: string) => void;
  removeDeliveryFieldFilter: (filter: string) => void;
}

const mapDispatchToProps = (
  dispatch: Dispatch<AppActions>
): LinkDispatchProps => ({
  addDriverFieldFilter: bindActionCreators(addDriverFieldFilter, dispatch),
  removeDriverFieldFilter: bindActionCreators(
    removeDriverFieldFilter,
    dispatch
  ),
  addOrderFieldFilter: bindActionCreators(addOrderFieldFilter, dispatch),
  removeOrderFieldFilter: bindActionCreators(removeOrderFieldFilter, dispatch),
  addDeliveryRunFieldFilter: bindActionCreators(
    addDeliveryRunFieldFilter,
    dispatch
  ),
  removeDeliveryRunFieldFilter: bindActionCreators(
    removeDeliveryRunFieldFilter,
    dispatch
  ),
  addDeliveryFieldFilter: bindActionCreators(addDeliveryFieldFilter, dispatch),
  removeDeliveryFieldFilter: bindActionCreators(
    removeDeliveryFieldFilter,
    dispatch
  ),
});

// isAuthenticated is true if user_token exists in redux
const mapStateToProps = (state: AppState): LinkStateProps => ({
  showNewDriverModal: state.modal.showNewDriverModal,
  //temp
  store_id: state.user.store_id as number,
  token: state.auth.token as string,
  driverListID: state.listViewIDs.driverListID as number,
  driverListLoaded: state.listViewIDs.driverListInitialLoad as boolean,
  driverFilters: state.customDriverFieldFilter as CustomFilters[],
  driverCustomListLoaded: state.customDriverFilterState
    .initialDriverCustomListLoad as boolean,
});

export default connect(mapStateToProps, mapDispatchToProps)(ManageColumns);
