import * as React from "react";
import { connect } from "react-redux";
import { Formik, FormikProps, Form, FieldArray } from "formik";
import { Dispatch, bindActionCreators } from "redux";
import { AppActions, OrderType, DeliveryRuns } from "../../types";
import { AppState } from "../../store/configureStore";
import {
  InputField,
  OnboardInputField,
  SwitchField,
  SelectField,
  DayPickerField,
  TimePickField
} from "../form";
import styled from "styled-components";
import { Button, Modal, AppText, HR, SecondaryButton } from "../UI";
import { PRIMARY_COLOR } from "../../utils";
import moment from "moment";

interface DateSelectModalProps {
  showModal: boolean;
  toggleModal: () => void;
  name: string;
  time: string;
  handleReset: () => void;
  start_date: string;
}

interface DateSelectModalState {}

class DateSelectModal extends React.Component<
  DateSelectModalProps,
  DateSelectModalState
> {
  constructor(props: DateSelectModalProps) {
    super(props);

    this.state = {};
  }
  render(): JSX.Element {
    return (
      <Modal
        label={""}
        showModal={this.props.showModal}
        toggleModal={this.props.toggleModal}
        zIndex={"70"}
      >
        <Div>
          <Header>
            <HeaderText>SET CUSTOM DELIVERY TIME</HeaderText>
          </Header>
          <DayInput>
            <InputField
              name={"start_day"}
              placeholder={""}
              style={{
                color: PRIMARY_COLOR,
                background: "transparent"
              }}
            />
          </DayInput>
          <CalenderDiv>
            <DayPickerField
              name={"start_date"}
              start_date={this.props.start_date}
            />
          </CalenderDiv>
          <SwitchDiv>
            <AppText>Switch to inactive</AppText>
            <SwitchField name={"inactive"} title={""} />
          </SwitchDiv>
        </Div>
        <Footer>
          <CancelButton
            onClick={() => {
              this.props.handleReset();
              this.props.toggleModal();
            }}
          >
            Cancel
          </CancelButton>
          <Button
            width="130px"
            type="submit"
            style={{ justifySelf: "flex-end" }}
            onClick={this.props.toggleModal}
          >
            Save
          </Button>
        </Footer>
      </Modal>
    );
  }
}

const Div = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  border-top: solid 1px ${PRIMARY_COLOR};
  border-radius: 2px;
  overflow: hidden;
  width: 507px;
  z-index: 70px;
  padding: 0 20px 0 20px;
`;

const Header = styled.div`
  margin: 20px 0px;
`;

const DayInput = styled.div``;
const HeaderText = styled(AppText)`
  font-size: 16px;
  font-weight: 400px;
`;
const CalenderDiv = styled.div`
  display: flex;
  justify-content: center;
  box-shadow: 7px 2px 26px 5px rgba(0, 0, 0, 0.11);
  margin: 10px 0px 30px 0px;
`;
const SwitchDiv = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 30px;
`;
const StyledInput = styled(InputField)`
  background: transparent;
  color: #60999e;
`;
const CancelButton = styled(Button)`
  height: 40px;
  width: 110px;
  border-radius: 4px;
  border: solid 1px rgba(71, 70, 71, 0.26);
  background-color: #fff;
  color: #000;
  margin-right: 21px;
`;
const Footer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  height: 57px;
  padding: 0 40px 30px 40px;
`;
export default DateSelectModal;
