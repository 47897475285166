import { DefaultCustomDeliveryFilterStateActionTypes } from "../types";

const defaultCustomDeliveryFilterStateReducer = {
  initialDeliveryCustomListLoad: true
};

const customDeliveryFilterStateReducer = (
  state = defaultCustomDeliveryFilterStateReducer,
  action: DefaultCustomDeliveryFilterStateActionTypes
) => {
  switch (action.type) {
    case "UPDATE_DELIVERY_CUSTOM_LIST_LOAD":
      return { ...state, initialDeliveryCustomListLoad: false };
    case "RELOAD_DELIVERY_CUSTOM_LIST_LOAD":
      return { ...state, initialDeliveryCustomListLoad: true };
    case "LOGOUT":
      return defaultCustomDeliveryFilterStateReducer;
    default:
      return state;
  }
};

export default customDeliveryFilterStateReducer;
