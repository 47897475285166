import {
  PRIMARY_FONT,
  BACKGROUND_COLOR,
  MOBILE_BREAKPOINT,
  TABLET_BREAKPOINT,
  DESKTOP_BREAKPOINT,
  PRIMARY_COLOR,
  SECONDARY_COLOR,
  PRIMARY_FONT_ROMAN,
  PRIMARY_FONT_NEXT,
  PRIMARY_GRADIENT_FROM,
  PRIMARY_GRADIENT_TO
} from "./variables";
export const theme = {
  PRIMARY_COLOR: PRIMARY_COLOR,
  SECONDARY_COLOR: SECONDARY_COLOR,
  BACKGROUND_COLOR: BACKGROUND_COLOR,
  TITLE_COLOR: "#252733",
  TEXT_LIGHT_COLOR: "#9fa2b4",
  ERROR_TEXT: "red",
  SUCCESS_TEXT: "green",
  PRIMARY_FONT: PRIMARY_FONT,
  PRIMARY_FONT_ROMAN: PRIMARY_FONT_ROMAN,
  PRIMARY_FONT_NEXT: PRIMARY_FONT_NEXT,
  SECONDARY_FONT: "helvetica",
  MAX_WIDTH: "1292px",
  MOBILE_BREAKPOINT: `${MOBILE_BREAKPOINT}px`,
  TABLET_BREAKPOINT: `${TABLET_BREAKPOINT}px`,
  DESKTOP_BREAKPOINT: `${DESKTOP_BREAKPOINT}px`,
  PRIMARY_GRADIENT_FROM: PRIMARY_GRADIENT_FROM,
  PRIMARY_GRADIENT_TO: PRIMARY_GRADIENT_TO
};
