import React from "react";
import { Switch, Route, HashRouter } from "react-router-dom";
import createHistory from "history/createBrowserHistory";
import PublicRoute from "./PublicRoute";
import PrivateRoute from "./PrivateRoute";
import Header from "../Components/Segments/Header";
import {
  Profile,
  ForgotPassword,
  SignIn,
  Dashboard,
  Account,
  Billing,
  Deliveries,
  DeliveryRuns,
  Drivers,
  Orders,
  EditGeneralInfo,
  EditMyCommunication,
  EditCustomerCommunication,
  ResetPassword,
  OrderBarcode,
} from "../Components/Pages";
import NewClientSetup from "../Components/Pages/NewClientSetup";
import DriverDetail from "../Components/Pages/DriverDetail";
import OrderDetail from "../Components/Pages/OrderDetail";
import DeliveryRunDetail from "../Components/Pages/DeliveryRunDetail";
import ClientSetup from "../Components/Segments/onboarding/ClientSetup";
import EditMobileApp from "../Components/Pages/Settings/EditMobileApp";
import EditCustomProperties from "../Components/Pages/Settings/EditCustomProperties";
import DeliveryDetail from "../Components/Pages/DeliveryDetail";

// Public and Private routes are used to handle authentication
// If authed user tries to access a public route, he or she will be
// routed to /main
// If unauthed user tries to access a private route, he or she will be
// routed to /

// Authentication is determined on whether the user_token value exists in
// Redux.

export const history = createHistory();

// TODO: Switch onboarding route to private
// TODO: Switch back to Dashboard after demo

const AppRouter = () => (
  <HashRouter>
    <>
      <Switch>
        {/* <PrivateRoute path="/" component={Dashboard} exact={true} /> */}
        <PrivateRoute path="/" component={Drivers} exact={true} />
        <PublicRoute path="/signin" component={SignIn} />
        <Route path="/setup" component={ClientSetup} />
        <Route path="/onboarding" component={NewClientSetup} />
        <Route path="/forgotPassword" component={ForgotPassword} />
        <Route path="/resetPassword" component={ResetPassword} />
        <PrivateRoute path="/profile" component={Profile} />
        <PrivateRoute path="/dashboard" component={Dashboard} />
        <PrivateRoute path="/account" component={Account} />
        <PrivateRoute path="/billing" component={Billing} />
        <PrivateRoute path="/deliveries" component={Deliveries} exact={true} />
        <PrivateRoute path="/deliveries/:id" component={DeliveryDetail} />
        <PrivateRoute path="/deliveryRuns" component={DeliveryRuns} />
        <PrivateRoute path="/drivers" component={Drivers} exact={true} />
        <PrivateRoute path="/driver/:id" component={DriverDetail} />
        <PrivateRoute path="/order/:id" component={OrderDetail} />
        <PrivateRoute path="/order_barcode/:id" component={OrderBarcode} />
        <PrivateRoute path="/delivery_run/:id" component={DeliveryRunDetail} />
        <PrivateRoute path="/orders" component={Orders} />
        <PrivateRoute exact path="/settings" component={Profile} />
        <PrivateRoute
          path="/settings/general_info"
          component={EditGeneralInfo}
        />
        <PrivateRoute
          path="/settings/my_communication"
          component={EditMyCommunication}
        />
        <PrivateRoute
          path="/settings/customer_communication"
          component={EditCustomerCommunication}
        />
        <PrivateRoute path="/settings/mobile_app" component={EditMobileApp} />
        <PrivateRoute
          path="/settings/custom_properties"
          component={EditCustomProperties}
        />
      </Switch>
    </>
  </HashRouter>
);

export default AppRouter;
