import * as React from "react";
import { connect } from "react-redux";
import { Formik, FormikProps, Form } from "formik";
import * as Yup from "yup";
import { PHONE_REG_EXP } from "../../../utils";
import { Dispatch, bindActionCreators } from "redux";
import { AppActions } from "../../../types";
import {
  startSetOnboardingPrefs,
  onboardNextPage,
} from "../../../actions/onboarding";
import { AppState } from "../../../store/configureStore";
import {
  InputField,
  OnboardInputField,
  OnboardCheckboxField,
} from "../../form";
import styled from "styled-components";
import { AuthButton } from "../../Pages/NewClientSetup";
import { AppText } from "../../UI";
import MyNumberInput from "../../form/PhoneNumberField";
import { editAuthUserThunk } from "../../../api/auth";
import { editStoreThunk } from "../../../api/store";
import { copySync } from "fs-extra";

const onboardSchema = Yup.object().shape({
  store_name: Yup.string()
    .required("* Store Name is required")
    .max(100, "* name too long"),
  admin_email: Yup.string()
    .email("* Invalid email")
    .required("* Email is required"),
  store_address: Yup.string()
    .required("* Store Address is required")
    .max(250, "* Too Long!"),
  store_city: Yup.string().required("* City is Required"),
  store_state: Yup.string()
    .required(" State is required")
    .max(2, "* Two lettered State code"),
  store_zip: Yup.string()
    .required("* zip code is required")
    .max(5, "* Insert 5 digit zip code"),
  store_phone_number: Yup.string()
    .required("* Store Phone # is required")
    .matches(PHONE_REG_EXP, "* Phone number is not valid"),
});

interface GeneralInfoProps {}

interface GeneralInfoState {}

interface MyFormikProps {
  store_name: string;
  admin_email: string;
  store_address: string;
  store_city: string;
  store_state: string;
  store_zip: string;
  store_phone_number: string;
  require_scanning_for_delivery: boolean;
}

type Props = GeneralInfoProps & LinkDispatchProps & LinkStateProps;

class GeneralInfo extends React.Component<Props, GeneralInfoState> {
  render(): JSX.Element {
    const {
      store_name,
      admin_email,
      store_address,
      store_city,
      store_phone_number,
      store_state,
      store_zip,
    } = this.props;
    return store_name ? (
      <Formik
        initialValues={{
          store_name: store_name ? store_name : "",
          admin_email: admin_email ? admin_email : "",
          store_address: store_address ? store_address : "",
          store_city: store_city ? store_city : "",
          store_state: store_state ? store_state : "",
          store_zip: store_zip ? store_zip : "",
          store_phone_number: store_phone_number ? store_phone_number : "",
          require_scanning_for_delivery: false,
        }}
        validationSchema={onboardSchema}
        onSubmit={async (values, { setFieldError, setStatus }) => {
          const {
            admin_email,
            store_address,
            store_name,
            store_phone_number,
            store_zip,
            store_state,
            store_city,
          } = values;
          setStatus({ error: "", loading: true });
          this.props.startSetOnboardPrefs(values);
          setStatus({ loading: false });
          try {
            this.props.editStore({
              store_id: this.props.store_id,
              name: store_name,
              phone:
                store_phone_number !== this.props.store_phone_number
                  ? store_phone_number
                  : undefined,
              address_1:
                store_address !== this.props.store_address
                  ? store_address
                  : undefined,
              city:
                store_city !== this.props.store_city ? store_city : undefined,
              state:
                store_state !== this.props.store_city ? store_state : undefined,
              zip: store_zip !== this.props.store_zip ? store_zip : undefined,
              requires_scanning: values.require_scanning_for_delivery ? 1 : 0,
            });
            this.props.editUser({
              user_id: this.props.user_id,
              email:
                admin_email !== this.props.admin_email
                  ? admin_email
                  : undefined,
            });
            this.props.startSetOnboardPrefs(values);
            this.props.onboardNextPage();
            setStatus({ loading: false });
          } catch (err) {
            console.log(err);
          }
        }}
      >
        {({
          values,
          status,
          setStatus,
          submitForm,
          touched,
          errors,
        }: FormikProps<MyFormikProps>) => {
          return (
            <Div>
              <Grid>
                <OnboardInputField
                  name="store_name"
                  placeholder=""
                  title="Store Name"
                />
                <div>
                  <OnboardInputField
                    name="store_address"
                    placeholder=""
                    title="Store Address"
                  />
                  <OnboardInputField
                    name="store_city"
                    placeholder=""
                    title="City"
                    inputWidth="40"
                  />
                  <OnboardInputField
                    name="store_state"
                    placeholder=""
                    title="State"
                    inputWidth="20"
                  />
                  <OnboardInputField
                    name="store_zip"
                    placeholder=""
                    title="Zipcode"
                    inputWidth="20"
                  />
                </div>
                <OnboardInputField
                  name="admin_email"
                  placeholder=""
                  title="Admin Email"
                />
                <OnboardInputField
                  name="store_phone_number"
                  placeholder=""
                  title="Store Phone Number"
                />
                {/* <MyNumberInput
                  name="store_phone_number"
                  format="(###) ###-####"
                  title="Store Phone Number"
                  placeholdder=""
                /> */}
                <div style={{ display: "flex" }}>
                  <LabelText>Require Signature for Deliveries</LabelText>
                  <OnboardCheckboxField
                    style={{ marginLeft: "10px" }}
                    name="require_scanning_for_delivery"
                  />
                </div>
              </Grid>
              <AuthButton
                type="submit"
                style={{ justifySelf: "right" }}
                onClick={submitForm}
                disabled={!!Object.keys(errors).length}
              >
                NEXT
              </AuthButton>
            </Div>
          );
        }}
      </Formik>
    ) : (
      <AppText>loading</AppText>
    );
  }
}

const Grid = styled.div`
  display: grid;
  grid-template-columns: 350px auto;
  align-content: center;
  justify-content: center;
  padding-bottom: 150px;
  gap: 60px;
  @media (max-width: 720px) {
    margin-top: 30px;
  }
`;

const Div = styled.div`
  display: grid;
  grid-template-rows: auto min-content;
`;

const LabelText = styled(AppText)`
  font-size: 18px;
  color: #f7f7f7;
  font-weight: 300;
`;

interface LinkStateProps {
  store_name: string;
  admin_email: string;
  store_address: string;
  store_city: string;
  store_state: string;
  store_zip: string;
  store_phone_number: string;
  store_id: number | null;
  user_id: number | null;
}

interface LinkDispatchProps {
  startSetOnboardPrefs: (onboardPrefs: Object) => void;
  onboardNextPage: () => void;
  editUser: ({
    user_id,
    first_name,
    last_name,
    email,
    password,
  }: {
    user_id: number | null;
    first_name?: string;
    last_name?: string;
    email?: string;
    password?: string;
  }) => void;
  editStore: ({
    store_id,
    name,
    phone,
    address_1,
    address_2,
    city,
    state,
    zip,
    requires_scanning,
  }: {
    store_id: number | null;
    name?: string;
    phone?: string;
    address_1?: string;
    address_2?: string;
    city?: string;
    state?: string;
    zip?: string;
    requires_scanning?: number;
  }) => void;
}

const mapStateToProps = (state: AppState): LinkStateProps => ({
  store_name: state.store.name,
  admin_email: state.user.email,
  store_address: state.store.address.address_1,
  store_city: state.store.address.city,
  store_state: state.store.address.state,
  store_zip: state.store.address.zip,
  store_phone_number: state.store.phone,
  store_id: state.store.id,
  user_id: state.user.id,
});

const mapDispatchToProps = (
  dispatch: Dispatch<AppActions>
): LinkDispatchProps => ({
  startSetOnboardPrefs: bindActionCreators(startSetOnboardingPrefs, dispatch),
  onboardNextPage: bindActionCreators(onboardNextPage, dispatch),
  editUser: bindActionCreators(editAuthUserThunk, dispatch),
  editStore: bindActionCreators(editStoreThunk, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(GeneralInfo);
