import {
  OrderType,
  UPDATE_DASHBOARD_DRIVERS,
  UPDATE_DASHBOARD_ORDERS,
  UPDATE_DASHBOARD_ORDER_SELECT,
  DELETE_DASHBOARD_ORDER,
  DASHBOARD_LOADED,
  UPDATE_DASHBOARD_DELIVERY_RUNS,
  DASHBOARD_DELIVERY_RUNS_LOADED,
  SHOW_DASHBOARD_ORDERS,
  UPDATE_CURRENT_DASHBOARD_DELIVERY_RUN,
  SelectedDashboard,
  SHOW_DASHBOARD_EDIT_DELIVERY_RUN,
  UPDATE_DASHBOARD_MODAL_INFO,
  UpdateModal,
  FILTER_DASHBOARD_ORDER,
  CLEAR_DASHBOARD_STATE,
  UPDATE_FROM_DETAILED_DELIVERY_RUN,
  UPDATE_DETAILED_DELIVERY_RUN,
  UPDATE_SELECTED_DASHBOARD_ORDERS,
  CLEAR_DASHBOARD_DRIVER_DELIVERY_RUN
} from "../types";

import {
  DeliveryDriver,
  ImportedDeliveryRun,
  DeliveryRuns
} from "../types/createDeliveryRun";
import { DeliveryRunResponse } from "../api/responseTypes";

export const updateDashBoardDrivers = (drivers: DeliveryDriver[]) => ({
  type: UPDATE_DASHBOARD_DRIVERS,
  drivers
});

export const updateDashboardOrders = (orders: OrderType[]) => ({
  type: UPDATE_DASHBOARD_ORDERS,
  orders
});

export const updateOrderSelect = (orderID: number) => ({
  type: UPDATE_DASHBOARD_ORDER_SELECT,
  orderID
});

export const updateDashboardDeliveryRuns = (
  delivery_runs: ImportedDeliveryRun
) => ({
  type: UPDATE_DASHBOARD_DELIVERY_RUNS,
  delivery_runs
});

export const deleteDashboardOrder = (orderID: number) => ({
  type: DELETE_DASHBOARD_ORDER,
  orderID
});

export const dashboardLoaded = () => ({
  type: DASHBOARD_LOADED
});

export const dashboardDeliveryRunsLoaded = () => ({
  type: DASHBOARD_DELIVERY_RUNS_LOADED
});

export const showDashboardOrders = () => ({
  type: SHOW_DASHBOARD_ORDERS
});

export const updateCurrentDashboardDeliveryRun = (
  deliveryRun: SelectedDashboard
) => ({
  type: UPDATE_CURRENT_DASHBOARD_DELIVERY_RUN,
  deliveryRun
});

export const showDashBoardEditDeliveryRun = () => ({
  type: SHOW_DASHBOARD_EDIT_DELIVERY_RUN
});

export const updateDashboardModalInfo = (modalInfo: UpdateModal) => ({
  type: UPDATE_DASHBOARD_MODAL_INFO,
  modalInfo
});

export const updateFromDetailedDeliveryRun = () => ({
  type: UPDATE_FROM_DETAILED_DELIVERY_RUN
});

export const updateDetailedDeliveryRun = (id: number) => ({
  type: UPDATE_DETAILED_DELIVERY_RUN,
  id
});

export const filterDashboardOrders = (
  orderIDs: number[],
  deliveryRunID: number
) => ({
  type: FILTER_DASHBOARD_ORDER,
  orderIDs,
  deliveryRunID
});

export const clearDashboardState = () => ({
  type: CLEAR_DASHBOARD_STATE
});

export const updateSelectedDashboardOrders = (orderID: number) => ({
  type: UPDATE_SELECTED_DASHBOARD_ORDERS,
  orderID
});

export const clearDashBoardDriverDeliveryRun = (driverID: number) => ({
  type: CLEAR_DASHBOARD_DRIVER_DELIVERY_RUN,
  driverID
});
