import {
  AppActions,
  TOGGLE_NEW_DRIVER_MODAL,
  TOGGLE_COLUMN_SWITCH_MODAL,
  TOGGLE_NEW_ORDER_MODAL
} from "../types";

export const toggleNewDriverModal = (): AppActions => ({
  type: TOGGLE_NEW_DRIVER_MODAL
});

export const toggleNewOrderModal = (): AppActions => ({
  type: TOGGLE_NEW_ORDER_MODAL
});

export const toggleColumnSwitchModal = (): AppActions => ({
  type: TOGGLE_COLUMN_SWITCH_MODAL
});

export const toggleDeliveryRunDetailModal = (): AppActions => ({
  type: TOGGLE_COLUMN_SWITCH_MODAL
});
