import { UserStore, Address } from "../types/store";
import { StoreActionTypes } from "../types";

const defaultStoreReducerState: UserStore = {
  id: null,
  name: "",
  address_id: null,
  phone: "",
  requires_scanning: 0,
  created_at: "",
  update_at: "",
  deleted_at: null,
  code: "string",
  barcode_type: "",
  address: {
    id: 0,
    address_1: "",
    address_2: "",
    city: "",
    state: "",
    zip: "",
    created_at: "",
    updated_at: "",
    deleted_at: "",
    latitude: 0,
    longitude: 0,
    notes: ""
  } as Address
};

const storeReducer = (
  state = defaultStoreReducerState,
  action: StoreActionTypes
): UserStore => {
  switch (action.type) {
    case "GET_STORE":
      return {
        ...state,
        id: action.store.id,
        name: action.store.name,
        address_id: action.store.address_id,
        phone: action.store.phone,
        barcode_type: action.store.barcode_type,
        address: action.store.address
          ? action.store.address
          : {
              id: 0,
              address_1: "",
              address_2: "",
              city: "",
              state: "",
              zip: "",
              created_at: "",
              updated_at: "",
              deleted_at: "",
              latitude: 0,
              longitude: 0,
              notes: ""
            }
      };
    case "UPDATE_STORE":
      return {
        ...state,
        id: action.store.id,
        name: action.store.name,
        address_id: action.store.address_id,
        phone: action.store.phone,
        address: action.store.address
      };
    case "LOGOUT":
      return defaultStoreReducerState;

    default:
      return state;
  }
};

export default storeReducer;
