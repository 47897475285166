import { ConsumerActionTypes, Consumer } from "../types";

const consumerReducerDefaultState: Consumer[] = [];

const consumerReducer = (
  state = consumerReducerDefaultState,
  action: ConsumerActionTypes
): Consumer[] => {
  switch (action.type) {
    case "FETCH_CONSUMERS":
      return [...action.consumers];
    case "ADD_CONSUMER":
      return [...state, action.consumer];
    case "REMOVE_CONSUMER":
      return state.filter(consumer => action.user_id === consumer.user_id);
    case "EDIT_CONSUMER":
      return state.map((consumer: Consumer) => {
        if (consumer.user_id === action.user_id) {
          return {
            ...consumer,
            ...action.consumer
          };
        }
        return consumer;
      });
    case "LOGOUT":
      return consumerReducerDefaultState;
    default:
      return state;
  }
};

export default consumerReducer;
