export const PRIMARY_COLOR = "#60999e";
export const SECONDARY_COLOR = "#fff";
export const PRIMARY_FONT = "Avenir, Lato, sans-serif";
export const PRIMARY_FONT_ROMAN = "Avenir-Roman";
export const PRIMARY_FONT_NEXT = "AvenirNext";
export const PRIMARY_FONT_URL = "";
export const BACKGROUND_COLOR = "#fff";
export const TITLE_COLOR = "#000";
export const API_URL = "";
export const API_BASE_URL = "";

export const PRIMARY_GRADIENT_FROM = "#60999e";
export const PRIMARY_GRADIENT_TO = "#4ba5eb";

export const MOBILE_BREAKPOINT = 320;
export const TABLET_BREAKPOINT = 720;
export const DESKTOP_BREAKPOINT = 1024;

export const PROFILE_PLACEHOLDER_IMAGE = "";

export const SENTRY_ID =
  "https://606bb92d4bed45878c1b38836bb32043@sentry.io/1450599";

// export const ONBOARDING_NUM_PAGES = 3;
export const ONBOARDING_NUM_PAGES = 4;

export const PHONE_REG_EXP = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

export const GRAPHQL_SERVER =
  "https://scan-be-1-741d3bec80.herokuapp.com/scan-be-1/dev";
export const GRAPHQL_SERVER_WS =
  "wss://scan-be-1-741d3bec80.herokuapp.com/scan-be-1/dev";
export const AUTH_TOKEN_FIELD_NAME = "scanovator-token";

export const HERE_MAPS_APP_ID = "p3CrA4BnnJN4kbpptAlX";
export const HERE_MAPS_APP_CODE = "HU_a6BCcIsoes-zmt-0Sow";
