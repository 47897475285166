import { ItemListStore, ListActionTypes } from "../types";

const defaultListState: ItemListStore = {
  orderListID: "today_orders",
  orderListInitialLoad: true,
  orderLabel: "Today's Orders",
  orderFilters: "",
  defaultOrderID: 0,
  driverListID: null,
  driverListInitialLoad: true,
  driverLabel: "",
  driverFilters: "",
  defaultDriverID: 0,
  deliveryRunListID: null,
  deliveryRunListInitialLoad: true,
  deliveryRunLabel: "",
  deliveryRunFilters: "",
  defaultDeliveryRunID: 0,
  deliveryListID: null,
  deliveryListInitialLoad: true,
  deliveryLabel: "",
  deliveryFilters: "",
  defaultDeliveryID: 0,
};

const listViewReducer = (
  state = defaultListState,
  action: ListActionTypes
): ItemListStore => {
  switch (action.type) {
    case "UPDATE_DRIVER_LIST":
      return {
        ...state,
        driverListID: action.id,
      };
    case "UPDATE_DRIVER_FILTERS":
      return {
        ...state,
        driverFilters: action.filters,
      };
    case "UPDATE_DRIVER_LIST_INITIAL_LOAD":
      return {
        ...state,
        driverListInitialLoad: false,
      };
    case "UPDATE_DRIVER_LIST_LABEL":
      return {
        ...state,
        driverLabel: action.label,
      };
    case "UPDATE_DEFAULT_DRIVER_ID":
      return {
        ...state,
        defaultDriverID: action.id,
      };
    case "UPDATE_ORDER_LIST":
      return {
        ...state,
        orderListID: action.id,
      };
    case "UPDATE_ORDER_FILTERS":
      return {
        ...state,
        orderFilters: action.filters,
      };
    case "UPDATE_ORDER_LIST_INITIAL_LOAD":
      return {
        ...state,
        orderListInitialLoad: false,
      };
    case "UPDATE_ORDER_LIST_LABEL":
      return {
        ...state,
        orderLabel: action.label,
      };
    case "UPDATE_DEFAULT_ORDER_ID":
      return {
        ...state,
        defaultOrderID: action.id,
      };
    case "UPDATE_DELIVERY_RUN_LIST":
      return {
        ...state,
        deliveryRunListID: action.id,
      };
    case "UPDATE_DELIVERY_RUN_FILTERS":
      return {
        ...state,
        deliveryRunFilters: action.filters,
      };
    case "UPDATE_DELIVERY_RUN_LIST_INITIAL_LOAD":
      return {
        ...state,
        deliveryRunListInitialLoad: false,
      };
    case "UPDATE_DELIVERY_RUN_LIST_LABEL":
      return {
        ...state,
        deliveryRunLabel: action.label,
      };
    case "UPDATE_DEFAULT_DELIVERY_RUN_ID":
      return {
        ...state,
        defaultDeliveryRunID: action.id,
      };
    case "UPDATE_DELIVERY_LIST":
      return {
        ...state,
        deliveryListID: action.id,
      };
    case "UPDATE_DELIVERY_FILTERS":
      return {
        ...state,
        deliveryFilters: action.filters,
      };
    case "UPDATE_DELIVERY_LIST_INITIAL_LOAD":
      return {
        ...state,
        deliveryListInitialLoad: false,
      };
    case "UPDATE_DELIVERY_LIST_LABEL":
      return {
        ...state,
        deliveryLabel: action.label,
      };
    case "UPDATE_DEFAULT_DELIVERY_ID":
      return {
        ...state,
        defaultDeliveryID: action.id,
      };
    case "LOGOUT":
      return defaultListState;
    default:
      return state;
  }
};

export default listViewReducer;
