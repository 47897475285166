import styled from "styled-components";

interface Props {
  src: string;
  clickable?: boolean;
  size?: "small" | "medium" | "large" | "chat";
}
const Avatar = styled.img<Props>`
  width: ${props => {
    switch (props.size) {
      case "small":
        return "47px";
      case "medium":
        return "56px";
      case "chat":
        return "62px";
      case "large":
        return "133px";
      default:
        return "133px";
    }
  }};
  height: ${props => {
    switch (props.size) {
      case "small":
        return "47px";
      case "medium":
        return "56px";
      case "chat":
        return "62px";
      case "large":
        return "133px";
      default:
        return "133px";
    }
  }};
  border-radius: 50%;
  cursor: ${props => (props.clickable ? "pointer" : "default")};
`;

Avatar.defaultProps = {
  clickable: false,
  size: "large"
};

export { Avatar };
