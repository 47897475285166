import * as React from "react";
import styled from "styled-components";
import { AppState } from "../../store/configureStore";
import { connect } from "react-redux";
import { Dispatch, bindActionCreators } from "redux";
import { AppActions, DeliveryRuns } from "../../types";
import { AppText, Button } from "../UI";
import {
  PRIMARY_COLOR,
  shortenDateOrShowToday,
  renderBasicTimeFormat,
} from "../../utils";
import { Link } from "react-router-dom";
import { FiBox } from "react-icons/fi";
import { FaMapMarkerAlt, FaRegCalendarAlt, FaRegClock } from "react-icons/fa";
import { api } from "../../api/api";
import { deliveryRunsLoaded } from "../../actions/createDeliveryRun";
import { updateFromDetailedDeliveryRun } from "../../actions";

interface DeliveryRunContainerProps {
  id: number;
  delivery: DeliveryRuns;
  onDeliveryRunSelect: (
    driverID: number,
    deliveryRunID: string,
    deliveryRunLabel: string,
    delivery: DeliveryRuns
  ) => void;
  selectMode: boolean;
  selected_delivery_id?: number;
  driver_selected: boolean;
}

interface DeliveryRunContainerState {}

type Props = DeliveryRunContainerProps & LinkStateProps & LinkDispatchProps;

class DeliveryRunContainer extends React.Component<
  Props,
  DeliveryRunContainerState
> {
  constructor(props: Props) {
    super(props);
  }
  componentDidMount = async () => {
    const {
      id,
      onDeliveryRunSelect,
      selectMode,
      delivery,
      selected_delivery_id,
      selectedDetailedDeliveryRunID,
      updateFromDetailedDeliveryRun,
      fromDetailedDeliveryRun,
    } = this.props;

    if (fromDetailedDeliveryRun) {
      if (selectedDetailedDeliveryRunID === delivery.delivery_id) {
        Array.isArray(delivery.deliveries) &&
          onDeliveryRunSelect(id, delivery.id, delivery.label, delivery);
        updateFromDetailedDeliveryRun();
      } else {
        //cannot find delivery run try again
      }
    }
  };

  componentDidUpdate = (prevProps, prevState) => {
    const { id, delivery, onDeliveryRunSelect } = this.props;

    if (this.props.delivery.deliveries !== prevProps.delivery.deliveries) {
      console.log("UPDATE RAN!");
      // Array.isArray(delivery.deliveries) &&
      //   onDeliveryRunSelect(id, delivery.id, delivery.label, delivery);
    }
  };

  render() {
    const {
      id,
      onDeliveryRunSelect,
      selectMode,
      delivery,
      driver_selected,
      selected_delivery_id,
    } = this.props;
    return (
      <DeliveryRunContainerDiv
        selectMode={selectMode}
        key={delivery.id}
        onClick={() =>
          onDeliveryRunSelect(id, delivery.id, delivery.label, delivery)
        }
        driver_selected={driver_selected}
      >
        <FirstRow>
          <DeliveryRunText
            driver_selected={driver_selected}
          >{`${delivery.label}`}</DeliveryRunText>
          <IconContainer>
            <FiBox size={13} color={PRIMARY_COLOR} />
            <IconText driver_selected={driver_selected}>
              {delivery.num_deliveries}
            </IconText>
          </IconContainer>
        </FirstRow>
        <HR />
        <SecondRow>
          <LeftSecondRow>
            <IconContainer>
              <FaMapMarkerAlt size={13} color={"#a78b6b"} />
              <IconText driver_selected={driver_selected}>
                {delivery.deliveries.length}
              </IconText>
            </IconContainer>
            <IconContainer style={{ marginLeft: "10px" }}>
              <FaRegClock size={13} color={"#e06156"} />
              <IconText driver_selected={driver_selected}>
                {renderBasicTimeFormat(delivery.schedule_day)}
              </IconText>
            </IconContainer>
          </LeftSecondRow>
          <IconContainer>
            <FaRegCalendarAlt size={13} color={"#fca503"} />
            <IconText driver_selected={driver_selected}>
              {shortenDateOrShowToday(delivery.schedule_date)}
            </IconText>
          </IconContainer>
        </SecondRow>
      </DeliveryRunContainerDiv>
    );
  }
}
interface DriverDivProps {
  selectMode: boolean;
  driver_selected?: boolean;
}

interface TextProps {
  driver_selected?: boolean;
}
const HR = styled.div`
  width: 100%;
  border: solid 1px #d8d8d8;
  margin: 5px 0px;
`;

const DeliveryRunText = styled(AppText)<TextProps>`
  font-size: 13px;
  font-weight: ${(props) => (props.driver_selected ? "bold" : "normal")};
`;

const IconText = styled(AppText)<TextProps>`
  font-size: 11px;
  font-weight: normal;
  margin-left: 2px;
`;

const DeliveryRunContainerDiv = styled.div<DriverDivProps>`
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  width: 190px;
  height: 61px;
  border-radius: 4px;
  border: solid 1px ${PRIMARY_COLOR};
  background-color: ${(props) =>
    props.driver_selected ? "#fca503" : "#ffffff"};
  box-shadow: 2px 1px 4px 0 rgba(59, 58, 58, 0.5);
  padding: 0px 20px 0px 20px;
  margin: 8px 0px;

  &:hover {
    background-color: ${(props) => (props.selectMode ? "#bfd6d8" : "#ffca69")};
    transition: background-color 0.3s ease;
    cursor: pointer;
  }
`;

const FirstRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`;

const SecondRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`;

const LeftSecondRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

const IconContainer = styled.div`
  display: flex;
  flex-direction: row;
`;
interface LinkStateProps {
  token: string;
  selectedDetailedDeliveryRunID: number;
  fromDetailedDeliveryRun: boolean;
}

interface LinkDispatchProps {
  deliveryRunsLoaded: () => void;
  updateFromDetailedDeliveryRun: () => void;
}
const mapDispatchToProps = (
  dispatch: Dispatch<AppActions>
): LinkDispatchProps => ({
  deliveryRunsLoaded: bindActionCreators(deliveryRunsLoaded, dispatch),
  updateFromDetailedDeliveryRun: bindActionCreators(
    updateFromDetailedDeliveryRun,
    dispatch
  ),
});
const mapStateToProps = (state: AppState): LinkStateProps => ({
  token: state.auth.token as string,
  selectedDetailedDeliveryRunID: state.dashboard.detailedDeliveryRun,
  fromDetailedDeliveryRun: state.dashboard.fromDetailedDeliveryRun,
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DeliveryRunContainer);
