import {
  CustomFilters,
  CustomDeliveryRunFieldFilterActionTypes,
} from "../types";

const customDeliveryRunFieldFilterReducerDefaultState: CustomFilters[] = [
  { display: "ID", field: "id", visible: false },
  // { display: "Driver Id", field: "driver_id", visible: true },
  { display: "Num Deliveries", field: "num_deliveries", visible: true },
  { display: "Status", field: "status", visible: true },
  { display: "Issue Notes", field: "issue_note", visible: true },
  {
    display: "Scheduled Start Time",
    field: "scheduled_start_time",
    visible: true,
  },
  { display: "Scheduled End Time", field: "scheduled_end_time", visible: true },
  {
    display: "Route Selection Method",
    field: "route_selection_method",
    visible: true,
  },
  { display: "Sequence Num", field: "sequence_num", visible: true },
  { display: "Created At", field: "created_at", visible: true },
  { display: "Updated At", field: "updated_at", visible: true },
  { display: "Deleted At", field: "deleted_at", visible: true },
  { display: "Actual Start Time", field: "actual_start_time", visible: true },
  { display: "Actual End Time", field: "actual_end_time", visible: true },
  { display: "Revised End Time", field: "revised_end_time", visible: true },
  { display: "Current Position", field: "current_position", visible: true },
  // { display: "Orders", field: "orders", visible: true },
];

const customDeliveryRunFieldFilterReducer = (
  state = customDeliveryRunFieldFilterReducerDefaultState,
  action: CustomDeliveryRunFieldFilterActionTypes
): CustomFilters[] => {
  switch (action.type) {
    case "SET_DELIVERY_RUN_FIELD_FILTERS":
      return action.filters;
    case "ADD_DELIVERY_RUN_CUSTOM_FILTERS":
      return action.filters;
    case "ADD_DELIVERY_RUN_FIELD_FILTER":
      const addDeliveryRunState = state.map((obj) => {
        if (obj.field === action.filter) {
          return {
            display: obj.display,
            field: obj.field,
            visible: true,
          };
        } else {
          return {
            display: obj.display,
            field: obj.field,
            visible: obj.visible,
          };
        }
      }) as any;
      return addDeliveryRunState;
    case "REMOVE_DELIVERY_RUN_FIELD_FILTER":
      const removeDeliveryRunState = state.map((obj) => {
        if (obj.field === action.filter) {
          return {
            display: obj.display,
            field: obj.field,
            visible: false,
          };
        } else {
          return {
            display: obj.display,
            field: obj.field,
            visible: obj.visible,
          };
        }
      }) as any;
      return removeDeliveryRunState;
    case "DELETE_DELIVERY_RUN_FIELD_FILTER":
      return state.filter((filter) => filter.field !== action.filter);
    case "LOGOUT":
      return customDeliveryRunFieldFilterReducerDefaultState;
    default:
      return state;
  }
};

export default customDeliveryRunFieldFilterReducer;
