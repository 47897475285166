import React from "react";
import { Field, FieldProps, ErrorMessage } from "formik";
import { Input, AppText } from "../UI";
import styled from "styled-components";
import { ErrorText } from "../Dumb";

export const InputField = ({
  name,
  title,
  placeholder,
  style,
  inputWidth,
  type = "text",
  disabled
}: {
  name: string;
  title?: string;
  placeholder: string;
  style?: React.CSSProperties;
  inputWidth?: string;
  type?: string;
  disabled?: boolean;
}) => (
  <div style={style}>
    <Field
      name={name}
      render={({ field, form: { errors, touched } }: FieldProps) => {
        const error = !!errors[name] && !!touched[name];
        return (
          <FormSegment>
            {/* {title ? (
              <AppTextFormTitle>{title}</AppTextFormTitle>
            ) : (
              <AppTextFormTitle>&nbsp; </AppTextFormTitle>
            )} */}
            {title && <AppTextFormTitle>{title}</AppTextFormTitle>}
            <Input
              {...field}
              error={error}
              placeholder={placeholder}
              inputWidth={inputWidth}
              type={type}
              disabled={disabled}
            />
            <ErrorMessage name={field.name} component={ErrorText} />
          </FormSegment>
        );
      }}
    />
  </div>
);

const FormSegment = styled.div``;

const AppTextFormTitle = styled(AppText)`
  opacity: 0.5;
  font-size: 14px;
  font-weight: 500;
  color: #3b3a3a;
  margin-bottom: 7px;
`;
