import * as firebase from "firebase/app";
import "firebase/auth";

const config = {
  apiKey: "AIzaSyArPNc7GtZRl2aw3IL0rFTNgdKUF943kxg",
  authDomain: "scanovator-c0b78.firebaseapp.com",
  databaseURL: "https://scanovator-c0b78.firebaseio.com",
  projectId: "scanovator-c0b78",
  storageBucket: "scanovator-c0b78.appspot.com",
  messagingSenderId: "503751955465"
};

firebase.initializeApp(config);

export const auth = firebase.auth();

// export const googleProvider = new firebase.auth.GoogleAuthProvider();

// * Guide: https://firebase.google.com/docs/auth/web/google-signin#before_you_begin

export const googleProvider = () => {
  const googleProvider = new firebase.auth.GoogleAuthProvider();
  googleProvider.addScope("email");
  return googleProvider;
};

export const facebookProvider = () => {
  const facebookProvider = new firebase.auth.FacebookAuthProvider();
  facebookProvider.addScope("email");
  return facebookProvider;
};

export const emailProvider = () => {
  const emailProvider = new firebase.auth.EmailAuthProvider();
  return emailProvider;
};

export const reauthenticate = (currentPassword: string) => {
  const user = auth.currentUser;
  const cred = firebase.auth.EmailAuthProvider.credential(
    user!.email as string,
    currentPassword
  );
  return user!.reauthenticateWithCredential(cred);
};
