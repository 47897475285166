import {
  OrderType,
  DeliveryDriver,
  DashboardActionTypes,
  SelectedDashboard,
  DeliveryRuns,
} from "../types";
import { DeliveryRunResponse } from "../api/responseTypes";

const dashboardReducerDefaultState = {
  orders: [] as OrderType[],
  drivers: [] as DeliveryDriver[],
  initialLoad: true,
  deliveryRunLoad: true,
  editDeliveryRunModal: false,
  showOrders: false,
  currentDeliveryRun: {
    id: 0,
    delivery: [],
    status: "",
    route_selection_method: "",
  } as SelectedDashboard,
  modal_driver_id: 0,
  modal_route_selection_method: "",
  modal_startTime_date: "",
  modal_startTime_day: "",
  modal_delivery: {} as DeliveryRuns,
  modal_travel_time: "",
  fromDetailedDeliveryRun: false,
  detailedDeliveryRun: 0,
};

const dashboardReducer = (
  state = dashboardReducerDefaultState,
  action: DashboardActionTypes
) => {
  switch (action.type) {
    case "UPDATE_DASHBOARD_DRIVERS":
      if (state.initialLoad) {
        return {
          ...state,
          drivers: action.drivers,
          initialLoad: false,
        };
      }
      return {
        ...state,
        drivers: state.drivers,
      };
    case "UPDATE_DASHBOARD_ORDERS":
      return {
        ...state,
        orders: action.orders,
      };
    case "UPDATE_DASHBOARD_ORDER_SELECT":
      return {
        ...state,
        orders: state.orders.map((order) => {
          if (action.orderID === order.id) {
            return {
              ...order,
              selected: !order.selected,
            };
          }
          return order;
        }),
      };
    case "DELETE_DASHBOARD_ORDER":
      return {
        ...state,
        orders: state.orders.filter((order) => action.orderID !== order.id),
      };
    case "DASHBOARD_LOADED":
      return {
        ...state,
        initialLoad: !state.initialLoad,
      };
    case "UPDATE_DASHBOARD_DELIVERY_RUNS":
      if (state.deliveryRunLoad) {
        return {
          ...state,
          drivers: state.drivers.map((driver) => {
            if (driver.id === action.delivery_runs.driver_id) {
              const existingDeliveryRun = {
                num_deliveries: action.delivery_runs.num_deliveries,
                driver_id: action.delivery_runs.driver_id,
                delivery_id: action.delivery_runs.id,
                id: `${driver.first_name}_${driver.last_name}_${action.delivery_runs.id}`,
                route_selection_method:
                  action.delivery_runs.route_selection_method,
                status: action.delivery_runs.status,
                label: `${action.delivery_runs.status} run ${action.delivery_runs.id}`,
                schedule_date: action.delivery_runs.scheduled_start_time.split(
                  " "
                )[0],
                schedule_day: action.delivery_runs.scheduled_start_time.split(
                  " "
                )[1],
                new_delivery: false,
                inactive:
                  action.delivery_runs.status !== "not scheduled"
                    ? true
                    : false,
                deliveries: action.delivery_runs.deliveries.map((delivery) => {
                  return {
                    id: delivery.order_id,
                    driver_id: action.delivery_runs.driver_id,
                    customer_id: delivery.customer.id,
                    address_id: delivery.customer.address_id,
                    status: action.delivery_runs.status,
                    num_boxes: delivery.num_boxes,
                    amount_to_collect: delivery.amount_to_collect,
                    description: "",
                    notes: "",
                    created_at: "",
                    updated_at: "",
                    deleted_at: "",
                    reference: "",
                    date_imported: null,
                    address_relevance: 1,
                    delivered: "",
                    delivery_date: "",
                    address: delivery.customer.address,
                    customer: {
                      address_id: delivery.customer.address_id,
                      created_at: "",
                      deleted_at: null,
                      email: delivery.customer.email,
                      first_name: delivery.customer.first_name,
                      id: delivery.customer.id,
                      last_name: delivery.customer.last_name,
                      phone: delivery.customer.phone,
                      phone_type: delivery.customer.phone_type,
                      subscription_price: delivery.customer.subscription_price,
                      updated_at: "",
                    },
                    selected: false,
                  };
                }),
              };
              return {
                ...driver,
                deliveryRuns: [...driver.deliveryRuns, existingDeliveryRun],
              };
            }
            return driver;
          }),
        };
      }
      return {
        ...state,
        drivers: state.drivers,
      };
    case "CLEAR_DASHBOARD_DRIVER_DELIVERY_RUN":
      return {
        ...state,
        drivers: state.drivers.map((driver) => {
          if (driver.id === action.driverID) {
            return {
              ...driver,
              deliveryRuns: [],
            };
          }
          return driver;
        }),
      };
    case "DASHBOARD_DELIVERY_RUNS_LOADED":
      return {
        ...state,
        deliveryRunLoad: false,
      };
    case "SHOW_DASHBOARD_ORDERS":
      return {
        ...state,
        showOrders: true,
      };
    case "UPDATE_CURRENT_DASHBOARD_DELIVERY_RUN":
      return {
        ...state,
        currentDeliveryRun: action.deliveryRun,
      };
    case "SHOW_DASHBOARD_EDIT_DELIVERY_RUN":
      return {
        ...state,
        editDeliveryRunModal: !state.editDeliveryRunModal,
      };
    case "UPDATE_DASHBOARD_MODAL_INFO":
      return {
        ...state,
        modal_driver_id: action.modalInfo.modal_driver_id,
        modal_route_selection_method:
          action.modalInfo.modal_route_selection_method,
        modal_startTime_date: action.modalInfo.modal_startTime_date,
        modal_startTime_day: action.modalInfo.modal_startTime_day,
        modal_delivery: action.modalInfo.modal_delivery,
        modal_travel_time: action.modalInfo.modal_travel_time,
      };
    case "FILTER_DASHBOARD_ORDER":
      return {
        ...state,
        orders: state.orders.filter((order) =>
          action.orderIDs.includes(order.id)
        ),
        drivers: state.drivers.map((driver) => {
          return {
            ...driver,
            deliveryRuns: driver.deliveryRuns.map((deliveryRun) => {
              if (deliveryRun.delivery_id === action.deliveryRunID) {
                return {
                  ...deliveryRun,
                  deliveries: deliveryRun.deliveries.filter((delivery) =>
                    action.orderIDs.includes(delivery.id)
                  ),
                };
              }
              return deliveryRun;
            }),
          };
        }),
      };
    case "UPDATE_FROM_DETAILED_DELIVERY_RUN":
      return {
        ...state,
        fromDetailedDeliveryRun: !state.fromDetailedDeliveryRun,
      };
    case "UPDATE_DETAILED_DELIVERY_RUN": {
      return {
        ...state,
        detailedDeliveryRun: action.id,
      };
    }
    case "UPDATE_SELECTED_DASHBOARD_ORDERS":
      return {
        ...state,
        orders: state.orders.map((order) => {
          if (action.orderID === order.id) {
            return {
              ...order,
              selected: !order.selected,
            };
          }
          return order;
        }),
      };
    case "CLEAR_DASHBOARD_STATE":
      return dashboardReducerDefaultState;
    case "LOGOUT":
      return dashboardReducerDefaultState;
    default:
      return state;
  }
};

export default dashboardReducer;
