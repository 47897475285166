import { FilterActionTypes, Onboarding, OnboardingActionTypes } from "../types";

const DEFAULT_DRIVER_INACTIVE_TIME_MINUTES = 10;

const onboardingReducerDefaultState: Onboarding = {
  currentPage: 0,
  admin_email: "",
  store_address: "",
  store_name: "",
  store_phone_number: "",
  personal_phone_number: "",
  driver_inactive_for_xx_minutes_email: false,
  driver_inactive_for_xx_minutes_sms: false,
  driver_inactive_for_xx_minutes_value: DEFAULT_DRIVER_INACTIVE_TIME_MINUTES,
  limit_customer_comm_to_certain_times_email_from: "",
  limit_customer_comm_to_certain_times_email_to: "",
  limit_customer_comm_to_certain_times_phone_from: "",
  limit_customer_comm_to_certain_times_phone_to: "",
  limit_customer_comm_to_certain_times_sms_from: "",
  limit_customer_comm_to_certain_times_sms_to: "",
  require_signature_for_deliveries_email: false,
  require_signature_for_deliveries_phone: false,
  require_signature_for_deliveries_sms: false,
  when_a_package_is_delivered_email: false,
  when_a_package_is_delivered_sms: false,
  when_an_order_is_received_by_pos_email: false,
  when_an_order_is_received_by_pos_sms: false,
  when_delivery_has_been_delayed_email: false,
  when_delivery_has_been_delayed_phone: false,
  when_delivery_has_been_delayed_sms: false,
  when_delivery_is_completed_email: false,
  when_delivery_is_completed_phone: false,
  when_delivery_is_completed_sms: false,
  when_delivery_is_scheduled_email: false,
  when_delivery_is_scheduled_phone: false,
  when_delivery_is_scheduled_sms: false,
  when_delivery_will_arrive_soon_email: false,
  when_delivery_will_arrive_soon_phone: false,
  when_delivery_will_arrive_soon_sms: false,
  when_driver_is_returning_to_store_email: false,
  when_driver_is_returning_to_store_sms: false,
  when_driver_leaves_email: false,
  when_driver_leaves_sms: false
};

const onboardingReducer = (
  state = onboardingReducerDefaultState,
  action: OnboardingActionTypes
): Onboarding => {
  switch (action.type) {
    case "CLEAR_ONBOARDING_PREFS":
      return onboardingReducerDefaultState;
    case "SET_ONBOARDING_PAGE":
      return {
        ...state,
        currentPage: action.page
      };
    case "SET_ONBOARDING_PREFS":
      return {
        ...state,
        ...action.onboardingPrefs
      };
    // case "LOGOUT":
    //   return onboardingReducerDefaultState;
    default:
      return state;
  }
};

export default onboardingReducer;
