import React from "react";
import { connect } from "react-redux";
import { Route, Redirect } from "react-router-dom";
import { AppState } from "../store/configureStore";
import Header from "../Components/Segments/Header";
import { components } from "react-select";

// PrivateRoute
// See AppRouter for more information

interface PrivateRouteProps {
  component: any;
  path: string;
  exact?: boolean;
}

type Props = PrivateRouteProps & LinkStateProps;

export const PrivateRoute = ({
  isAuthenticated,
  component: Component,
  is_registered,
  path,
  ...rest // sets the rest of the parameters to the rest variable
}: Props) => {
  console.log("isAuthenticated private route: ", isAuthenticated);
  console.log("is registered?", is_registered);
  console.log("isAuthenticated component: ", Component);
  console.log("PROPS", path);
  return (
    <div>
      <Header />
      <Route
        {...rest}
        component={
          (props: any) => {
            if (isAuthenticated && is_registered === 0) {
              return <Redirect exact to="/onboarding" />;
            } else if (isAuthenticated) {
              return <Component {...props} />;
            } else {
              return <Redirect to="/signin" />;
            }
          }
          // isAuthenticated ? (
          //   <Component {...props} />
          // ) : (
          //   <Redirect to="/signin" />
          // )
          //BELOW IS BREAKING
          // isAuthenticated ? (
          //   is_registered === 1 ? (
          //     <Component {...props} />
          //   ) : (
          //     <Redirect to="/onboarding" />
          //   )
          // ) : (
          //   <Redirect to="/signin" />
          // )
        }
      />
    </div>
  );
};

interface LinkStateProps {
  isAuthenticated: boolean;
  is_registered: number | null;
}
// isAuthenticated is true if user_token exists in redux
const mapStateToProps = (state: AppState): LinkStateProps => ({
  isAuthenticated: !!state.auth.token,
  is_registered: state.auth.is_registered
});

export default connect(mapStateToProps)(PrivateRoute);
