import { FilterField, CustomOrderFieldFilterActionTypes } from "../types";

const customOrderFieldMenuReducerDefaultState: FilterField[] = [];

const CustomOrderFieldFilterMenuReducer = (
  state = customOrderFieldMenuReducerDefaultState,
  action: CustomOrderFieldFilterActionTypes
): FilterField[] => {
  switch (action.type) {
    case "ADD_ORDER_FIELD_FILTER_OPTION":
      return action.filters;
    case "LOGOUT":
      return customOrderFieldMenuReducerDefaultState;
    default:
      return state;
  }
};

export default CustomOrderFieldFilterMenuReducer;
