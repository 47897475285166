import * as React from "react";
import { connect } from "react-redux";
import styled from "styled-components";
import { AppText, Button, TestCheckbox } from "../UI";
import AddIcon from "react-icons/lib/fa/plus";
// import EditIcon from "react-icons/lib/md/create";
// import TrashIcon from "react-icons/lib/fa/trash";
import { MdEdit } from "react-icons/md";

import { FaTrashAlt } from "react-icons/fa";
import { PRIMARY_COLOR } from "../../utils";
import { CustomFields, AppActions } from "../../types";
import { ColumnColors } from "./DynamicTable";
import { formatDate } from "../../utils";
import CreateCustomProperty from "../modal/CustomPrefModals/CreateCustomProperty";
import DeleteCustomProperty from "../modal/CustomPrefModals/DeleteCustomProperty";
import { api } from "../../api/api";
import { AppState } from "../../store/configureStore";
import { Dispatch } from "redux";

export const CUSTOM_ROW_COLOR_EVEN = "#fff";
export const CUSTOM_ROW_COLOR_ODD = "#f4f4f4";

interface DropdownValues {
  id: number;
  custom_field_id: number;
  value: string;
  selected: number;
  created_at: string;
  updated_at: string;
  deleted_at: string;
}
export interface DynamicCustomFieldTableProps {
  columnColors?: ColumnColors[];
  type: string;
  list: CustomFields[];
}

export interface DynamicCustomFieldTableState {
  list: CustomFields[];
  selected_field: number | null;
  field_type: number | undefined | null;
  editProperty: boolean;
  deleteProperty: boolean;
  selected_slug: string;
  property_name: string;
  dropdowns: DropdownValues[];
}
class DynamicCustomFieldTable extends React.Component<
  Props,
  DynamicCustomFieldTableState
> {
  constructor(props: Props) {
    super(props);

    this.state = {
      list: [],
      selected_field: null,
      field_type: null,
      selected_slug: "",
      editProperty: false,
      deleteProperty: false,
      property_name: "",
      dropdowns: [],
    };
  }

  getRowColor = (rowIndex: number): string | undefined => {
    // If column color is specified, use it. If not, use default alternative colors
    // declared at the top of this component
    // const { columnColors } = this.props;
    return rowIndex % 2 === 0 ? CUSTOM_ROW_COLOR_EVEN : CUSTOM_ROW_COLOR_ODD;
  };

  getFieldType = (type: number) => {
    switch (type) {
      case 1:
        return "Text";
      case 2:
        return "Email";
      case 3:
        return "Dropdown";
      case 4:
        return "Date";
      case 5:
        return "Number";
      case 6:
        return "Checkbox";
      default:
        return null;
    }
  };

  toggleNewCustomProperty = () => {
    this.setState((prev) => ({ editProperty: !prev.editProperty }));
  };

  toggleDeleteProperty = () => {
    this.setState((prev) => ({ deleteProperty: !prev.deleteProperty }));
  };

  selectField = async (
    id: number,
    field_id?: number,
    property_name?: string
  ) => {
    if (field_id === 3 || field_id === 6) {
      let dropdown_values = (await api.getCustomFieldListValues(
        id as number,
        this.props.token
      )) as any;
      this.setState({
        selected_field: id,
        field_type: field_id,
        property_name: property_name ? property_name : "",
        dropdowns: dropdown_values ? dropdown_values : [],
      });
    }

    this.setState({
      selected_field: id,
      field_type: field_id,
      property_name: property_name ? property_name : "",
    });
  };
  selectSlug = (slug: string) => {
    this.setState({
      selected_slug: slug,
    });
  };

  editField = (id: number, field_id: number, property_name: string) => {
    this.selectField(id, field_id, property_name);
    this.toggleNewCustomProperty();
  };

  deleteField = (id: number, slug: string) => {
    this.selectField(id);
    this.selectSlug(slug);
    this.toggleDeleteProperty();
  };

  deleteCustomField = () => {};

  setDropdownValues = (dropdowns) => {
    this.setState({
      dropdowns: dropdowns,
    });
  };

  render() {
    const { list, type } = this.props;

    return (
      <>
        <CreateCustomProperty
          key={this.state.property_name}
          setDropdownValues={this.setDropdownValues}
          showModal={this.state.editProperty}
          toggleModal={this.toggleNewCustomProperty}
          type={type}
          selected_field={this.state.selected_field}
          field_type={this.state.field_type}
          property_name={this.state.property_name}
          dropdown_value={this.state.dropdowns}
          edit={true}
        />
        <DeleteCustomProperty
          showModal={this.state.deleteProperty}
          toggleModal={this.toggleDeleteProperty}
          type={type}
          selected_field={this.state.selected_field}
          selected_slug={this.state.selected_slug}
        />

        <TableContainer>
          <HeaderRow>
            <RowItem color={this.getRowColor(1)}>
              <HeaderText style={{ width: "400px" }}>Name</HeaderText>
            </RowItem>
            <RowItem color={this.getRowColor(1)}>
              <HeaderText style={{ width: "150px" }}>Field Type</HeaderText>
            </RowItem>
            <RowItem color={this.getRowColor(1)}>
              <HeaderText style={{ width: "200px" }}>Last Modified</HeaderText>
            </RowItem>
            <RowItem color={this.getRowColor(1)}>
              <HeaderText style={{ width: "200px" }}>Creation Date</HeaderText>
            </RowItem>
            <RowItem style={{ width: "50px" }} color={this.getRowColor(1)} />
            <RowItem style={{ width: "50px" }} color={this.getRowColor(1)} />
          </HeaderRow>
          <ListRow>
            {list.map((row, index) => {
              return (
                <HeaderRow key={index}>
                  <RowItem color={this.getRowColor(index)}>
                    <HeaderText style={{ width: "400px" }}>
                      {row.display}
                    </HeaderText>
                  </RowItem>
                  <RowItem color={this.getRowColor(index)}>
                    <HeaderText style={{ width: "150px" }}>
                      {typeof row.field_type_id === "string"
                        ? this.getFieldType(Number.parseInt(row.field_type_id))
                        : this.getFieldType(row.field_type_id)}
                    </HeaderText>
                  </RowItem>
                  <RowItem color={this.getRowColor(index)}>
                    <HeaderText style={{ width: "200px" }}>
                      {row.updated_at}
                    </HeaderText>
                  </RowItem>
                  <RowItem color={this.getRowColor(index)}>
                    <HeaderText style={{ width: "200px" }}>
                      {row.created_at}
                    </HeaderText>
                  </RowItem>
                  <RowItemButton
                    style={{ width: "50px" }}
                    color={this.getRowColor(index)}
                    onClick={() =>
                      this.editField(row.id, row.field_type_id, row.display)
                    }
                  >
                    <MdEdit color={PRIMARY_COLOR} size={26} />
                  </RowItemButton>
                  <RowItemButton
                    style={{ width: "50px" }}
                    color={this.getRowColor(index)}
                    onClick={() => this.deleteField(row.id, row.slug)}
                  >
                    <FaTrashAlt color={PRIMARY_COLOR} size={26} />
                  </RowItemButton>
                </HeaderRow>
              );
            })}
          </ListRow>
        </TableContainer>
      </>
    );
  }
}

interface RowItemProps {
  column?: number;
  color?: string;
}
interface DivProps {
  length: number;
}

const ListRow = styled.div`
  max-height: 540px;
  overflow-x: auto;
`;
const TableContainer = styled.div``;
const HeaderRow = styled.div`
  display: flex;
  display: -webkit-flex;
  flex-direction: row;
  -webkit-flex-direction: row;
  flex-wrap: no-wrap;
  -webkit-flex-wrap: no-wrap;
  width: 100%;
  background-color: ${(props) => CUSTOM_ROW_COLOR_EVEN};
  font-weight: bold;
`;
const RowData = styled.div``;

const Div = styled.div<DivProps>`
  border: solid 1px #d8d8d8;
  border-bottom: solid 1px #d8d8d8;
  display: grid;
  grid-template-columns: min-content repeat(${(props) => props.length}, 1fr);
  width: auto;
  overflow-x: auto;
`;

const ItemText = styled(AppText)`
  font-size: 14px;
  color: #333333;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const HeaderText = styled(ItemText)`
  opacity: 0.5;
  font-weight: 900;
  flex-grow: 1;
  -webkit-flex-grow: 1;
`;
const RowItem = styled.div<RowItemProps>`
  display: flex;
  display: -webkit-flex;
  flex-direction: row;
  -webkit-flex-direction: row;
  flex-grow: 0;
  -webkit-flex-grow: 0;
  border: 1px;
  background-color: ${(props) => props.color};
  padding: 14px 12px;
  border-right: solid 0.5px #d8d8d8;
`;
const RowItemButton = styled.div<RowItemProps>`
  display: flex;
  display: -webkit-flex;
  flex-direction: row;
  -webkit-flex-direction: row;
  flex-grow: 0;
  -webkit-flex-grow: 0;
  border: 1px;
  background-color: ${(props) => props.color};
  padding: 14px 12px;
  border-right: solid 0.5px #d8d8d8;
  cursor: pointer;
`;

RowItem.defaultProps = {
  color: "#fff",
  column: 1,
};

type Props = DynamicCustomFieldTableProps & LinkDispatchProps & LinkStateProps;

interface LinkStateProps {
  token: string;
  store_id: number;
}
interface LinkDispatchProps {}

const mapStateToProps = (state: AppState): LinkStateProps => ({
  store_id: state.user.store_id as number,
  token: state.auth.token as string,
});
const mapDispatchToProps = (
  dispatch: Dispatch<AppActions>
): LinkDispatchProps => ({});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DynamicCustomFieldTable);
