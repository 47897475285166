import * as React from "react";
import { connect } from "react-redux";
import * as Yup from "yup";
import { Dispatch, bindActionCreators } from "redux";
import { AppActions, Driver } from "../../../types";
import { AppState } from "../../../store/configureStore";

import styled from "styled-components";
import { Button, Modal, AppText, HR, SecondaryButton } from "../../UI";
import {
  addDriver,
  toggleNewDriverModal,
  removeProperty,
  reloadDriverCustomListLoad,
  removeDriverFieldFilter,
  deleteDriverFieldFilter,
  reloadOrderCustomListLoad,
  removeOrderProperty,
  removeDeliveryProperty,
  reloadDeliveryCustomListLoad,
  removeDeliveryRunProperty,
  reloadDeliveryRunCustomListLoad,
  deleteDeliveryRunFieldFilter,
  deleteOrderFieldFilter,
  deleteDeliveryFieldFilter
} from "../../../actions";
import { PRIMARY_COLOR } from "../../../utils";
import { api } from "../../../api/api";

interface DeleteCustomPropertyProps {
  showModal: boolean;
  toggleModal: () => void;
  type: string;
  selected_field: number | null;
  selected_slug: string;
}

interface DeleteCustomPropertyState {}

class DeleteCustomProperty extends React.Component<
  Props,
  DeleteCustomPropertyState
> {
  deletePropertyDispatch = ({
    type,
    slug,
    id
  }: {
    type: string;
    slug: string;
    id: number;
  }) => {
    const {
      delete_driver_property,
      reloadDriverCustomListLoad,
      deleteDriverFieldFilter,
      delete_order_property,
      reloadOrderCustomListLoad,
      deleteOrderFieldFilter,
      delete_delivery_property,
      reloadDeliveryCustomListLoad,
      delete_delivery_run_property,
      reloadDeliveryRunCustomListLoad,
      deleteDeliveryRunFieldFilter,
      deleteDeliveryFieldFilter
    } = this.props;
    switch (type) {
      case "Driver":
        delete_driver_property(id);
        reloadDriverCustomListLoad();
        deleteDriverFieldFilter(slug);
        break;
      case "Order":
        delete_order_property(id);
        reloadOrderCustomListLoad();
        deleteOrderFieldFilter(slug);
        break;
      case "DeliveryRun":
        delete_delivery_run_property(id);
        reloadDeliveryRunCustomListLoad();
        deleteDeliveryRunFieldFilter(slug);
        break;
      case "Delivery":
        delete_delivery_property(id);
        reloadDeliveryCustomListLoad();
        deleteDeliveryFieldFilter(slug);
        break;
      default:
        () => {};
    }
  };

  deleteProperty = async (id: number, slug: string) => {
    const {
      token,
      toggleModal,

      type
    } = this.props;
    await api.deleteCustomField(id, token);
    this.deletePropertyDispatch({ type: type, slug: slug, id: id });

    toggleModal();
  };
  render(): JSX.Element {
    return (
      <Modal
        height="fit-content"
        width="fit-content"
        showModal={this.props.showModal}
        toggleModal={this.props.toggleModal}
        label="Rename Custom List"
      >
        <Div>
          <Header>
            <HeaderText>WARNING</HeaderText>
          </Header>
          <HR />
          <Body>
            <BodyText>
              By deleting this custom field, you'll be permanently deleting all
              the data stored in this field. Please confirm you'd like to
              proceed with this permanent action.
            </BodyText>
          </Body>
          <Footer>
            <CancelButton onClick={this.props.toggleModal}>Cancel</CancelButton>
            <Button
              width="130px"
              type="submit"
              style={{ justifySelf: "flex-end" }}
              //TODO figure out why submit form submits twice
              onClick={() =>
                this.deleteProperty(
                  this.props.selected_field as number,
                  this.props.selected_slug as string
                )
              }
            >
              Delete
            </Button>
          </Footer>
        </Div>
      </Modal>
    );
  }
}

const Div = styled.div`
  display: flex;
  flex-direction: column;
  border-top: solid 1px ${PRIMARY_COLOR};
  border-radius: 2px;
  overflow-x: none;
  width: 507px;
`;

const HeaderText = styled(AppText)`
  font-size: 18px;
  font-weight: 600;
  letter-spacing: 0.3px;
  color: #60999e;
`;

const Header = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height: 57px;
  padding: 0 40px 0 40px;
`;

const Footer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  height: 57px;
  padding: 0 40px 30px 40px;
`;
const Body = styled.div`
  height: 150px;
  display: flex;
  padding-top: 40px;
  padding-right: 50px;
  padding-left: 50px;
`;

const BodyText = styled(AppText)`
  font-size: 15px;
  font-weight: 500;
  line-height: 1.6;
  letter-spacing: normal;
  color: black;
`;
const CancelText = styled(AppText)`
  font-size: 13px;
  font-weight: 500;
  color: #3b3a3a;
  margin-right: 53.5px;
`;

const CancelButton = styled(Button)`
  height: 40px;
  width: 110px;
  border-radius: 4px;
  border: solid 1px rgba(71, 70, 71, 0.26);
  background-color: #fff;
  color: #000;
  margin-right: 21px;
`;

type Props = DeleteCustomPropertyProps & LinkDispatchProps & LinkStateProps;

interface LinkStateProps {
  token: string;
  store_id: number;
}

interface LinkDispatchProps {
  //drover
  delete_driver_property: (property_id: number) => void;
  reloadDriverCustomListLoad: () => void;
  deleteDriverFieldFilter: (filter: string) => void;
  //order
  delete_order_property: (property_id: number) => void;
  reloadOrderCustomListLoad: () => void;
  deleteOrderFieldFilter: (filter: string) => void;
  //delivery
  delete_delivery_property: (property_id: number) => void;
  reloadDeliveryCustomListLoad: () => void;
  deleteDeliveryFieldFilter: (filter: string) => void;
  //delivery run
  delete_delivery_run_property: (property_id: number) => void;
  reloadDeliveryRunCustomListLoad: () => void;
  deleteDeliveryRunFieldFilter: (filter: string) => void;
}

const mapStateToProps = (state: AppState): LinkStateProps => ({
  store_id: state.user.store_id as number,
  token: state.auth.token as string
});

const mapDispatchToProps = (
  dispatch: Dispatch<AppActions>
): LinkDispatchProps => ({
  //driver
  delete_driver_property: bindActionCreators(removeProperty, dispatch),
  reloadDriverCustomListLoad: bindActionCreators(
    reloadDriverCustomListLoad,
    dispatch
  ),
  deleteDriverFieldFilter: bindActionCreators(
    deleteDriverFieldFilter,
    dispatch
  ),
  //order
  delete_order_property: bindActionCreators(removeOrderProperty, dispatch),
  reloadOrderCustomListLoad: bindActionCreators(
    reloadOrderCustomListLoad,
    dispatch
  ),
  deleteOrderFieldFilter: bindActionCreators(deleteOrderFieldFilter, dispatch),
  //delivery
  delete_delivery_property: bindActionCreators(
    removeDeliveryProperty,
    dispatch
  ),
  reloadDeliveryCustomListLoad: bindActionCreators(
    reloadDeliveryCustomListLoad,
    dispatch
  ),
  deleteDeliveryFieldFilter: bindActionCreators(
    deleteDeliveryFieldFilter,
    dispatch
  ),
  //delivery run
  delete_delivery_run_property: bindActionCreators(
    removeDeliveryRunProperty,
    dispatch
  ),
  reloadDeliveryRunCustomListLoad: bindActionCreators(
    reloadDeliveryRunCustomListLoad,
    dispatch
  ),
  deleteDeliveryRunFieldFilter: bindActionCreators(
    deleteDeliveryRunFieldFilter,
    dispatch
  )
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DeleteCustomProperty);
