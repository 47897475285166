import {
  CustomFieldByEntity,
  DeliveryCustomFieldValueActionTypes
} from "../types";

const deliveryCustomValuesReducerDefaultState: CustomFieldByEntity[] = [];

const deliveryCustomValuesReducer = (
  state = deliveryCustomValuesReducerDefaultState,
  action: DeliveryCustomFieldValueActionTypes
): CustomFieldByEntity[] => {
  switch (action.type) {
    case "GET_DELIVERY_CUSTOM_FIELD_VALUES":
      return [...action.customValues];
    // case "UPDATE_CUSTOM_FIELD_VALUE":
    //   return state.map((value: any) => {
    //     if (value.id === action.id) {
    //       return {
    //         value: action.value,
    //         custom_field_id: action.custom_field.id as any,
    //         id: action.id
    //       };
    //     }
    //     return value;
    //   });
    case "LOGOUT":
      return deliveryCustomValuesReducerDefaultState;
    default:
      return state;
  }
};

export default deliveryCustomValuesReducer;
