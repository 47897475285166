import * as React from "react";
import { connect } from "react-redux";
import { Spinner, AppText, BasicButton } from "../UI";
import { AppState } from "../../store/configureStore";
import {
  AppActions,
  LoggedUser,
  ItemList,
  OrderType,
  DeliveryRuns,
  DeliveryDriver,
  ImportedDeliveryRun,
  SelectedDashboard,
  UpdateModal,
} from "../../types";
import { Dispatch, bindActionCreators } from "redux";
import HereMaps from "../Dumb/HereMap/HereMaps";
import { api } from "../../api/api";
import styled from "styled-components";
import {
  updateDashBoardDrivers,
  updateDashboardDeliveryRuns,
  updateDashboardOrders,
  updateOrderSelect,
  dashboardLoaded,
  deleteDashboardOrder,
  showDashboardOrders,
  updateCurrentDashboardDeliveryRun,
  showDashBoardEditDeliveryRun,
  updateDashboardModalInfo,
  updateSelectedDashboardOrders,
} from "../../actions/dashboard";
import DeliveryRunModal from "../modal/DeliveryRunModal";
import {
  avgLocation,
  formatCurrentTime,
  formatGivenTime,
  formatCurrentDate,
  formatGivenDate,
  toastError,
} from "../../utils";
import CreateDeliveryRunTable from "../Dumb/HereMap/CreateDeliveryRunTable";
import {
  Driver,
  DriverList,
  MicroDriverResponse,
} from "../../api/responseTypes";
import { UserStore } from "../../types/store";
import { getStore } from "../../actions/store";
import { socket } from "../../socket/socket";
import OrderCard from "../Segments/OrderCard";
import { EmptyCard } from "../Dumb";
import DriverCard from "../Segments/DriverCard";

interface DashboardProps {
  // lat: number;
  // lng: number;
}

interface DashboardState {
  modal_route_selection_method: string;
  modal_startTime_day: string;
  modal_startTime_date: string;
  modal_delivery: DeliveryRuns;
  modal_driver_id: number;
  modal_travel_time: string;
  microDrivers: MicroDriverResponse[];
}

type Props = DashboardProps & LinkStateProps & LinkDispatchProps;
class Dashboard extends React.Component<Props, DashboardState> {
  updateDeliveryRun: any;
  constructor(props: Props) {
    super(props);
    //temp state
    this.state = {
      modal_route_selection_method: "",
      modal_startTime_day: "",
      modal_startTime_date: "",
      modal_driver_id: 0,
      modal_delivery: {} as DeliveryRuns,
      modal_travel_time: "",
      microDrivers: [] as MicroDriverResponse[],
    };
  }

  componentDidMount = async () => {
    const {
      store_id,
      token,
      getStore,
      updateDashboardDrivers,
      showDashBoardEditDeliveryRun,
    } = this.props;
    //TODO create switch case for firstLoad to run getStore
    const store = await api.getStore({ store_id: store_id }, token);

    api.createDefaultList(store_id, token);

    getStore(store as UserStore);

    // stub item_list_id for active drivers
    const drivers = (await api.getDrivers(
      { store_id, page: 1, limit: "1000", item_list: "active_drivers" },
      token
    )) as DriverList;
    const active_drivers = Array.isArray(drivers.data)
      ? (drivers.data.map((drivers) => {
          return {
            id: drivers.id,
            first_name: drivers.first_name,
            last_name: drivers.last_name,
            deliveryRuns: [] as DeliveryRuns[],
          };
        }) as DeliveryDriver[])
      : [];
    updateDashboardDrivers(active_drivers);

    //socket init

    // try {
    //   const microDriverResponse = await fetch(
    //     `https://scanovatordrivers.app/getAllDriverCoords?clientId=${store_id}`,
    //     { mode: "cors" }
    //   );
    //   const driverResponse = (await microDriverResponse.json()) as MicroDriverResponse[];
    //   if (!!driverResponse.length) {
    //     this.setState({ microDrivers: driverResponse });
    //   }
    //   socket.emit("clientId", store_id);
    //   socket.on("driver coords", (driver: MicroDriverResponse) => {
    //     // Update react component state
    //     this.updateDriver(driver);
    //   });
    // } catch (error) {
    //   console.log("error", error);
    //   // toastError("Error Fetching Driver Coords");
    // }
  };

  onDeliveryRunSelect = async (
    driverID: number,
    deliveryRunID: string,
    deliveryRunLabel: string,
    delivery: DeliveryRuns
  ) => {
    const {
      token,
      showDashboardOrders,
      updateDashboardOrders,
      updateCurrentDashboardDeliveryRun,
      updateDashboardModalInfo,
    } = this.props;
    const selectedDashboard = {
      id: delivery.delivery_id,
      status: delivery.status,
      route_selection_method: delivery.route_selection_method,
      delivery: delivery.deliveries.map((order) => {
        return {
          latitude: order.address.latitude,
          longitude: order.address.longitude,
          status: order.status,
        };
      }),
    };

    //dispatch selected delivery
    let order_ids = [] as number[];
    delivery.deliveries.forEach((order) => order_ids.push(order.id));
    const data =
      order_ids.length > 0
        ? ((await api.calculateRoute(
            order_ids as number[],
            token as string
          )) as any)
        : "No orders to check";
    updateDashboardModalInfo({
      modal_driver_id: delivery.driver_id,
      modal_route_selection_method: delivery.route_selection_method,
      modal_startTime_date: delivery.schedule_date,
      modal_startTime_day: delivery.schedule_day,
      modal_delivery: delivery,
      modal_travel_time: data.summary ? data.summary.trafficTime : data,
    });
    this.setState({
      modal_driver_id: delivery.driver_id,
      modal_route_selection_method: delivery.route_selection_method,
      modal_startTime_date: delivery.schedule_date,
      modal_startTime_day: delivery.schedule_day,
      modal_delivery: delivery,
      modal_travel_time: data.summary ? data.summary.trafficTime : data,
    });
    updateDashboardOrders(delivery.deliveries);
    updateCurrentDashboardDeliveryRun(selectedDashboard as SelectedDashboard);
    //show delivery run modal
    showDashboardOrders();
  };

  onOrderSelect = (orderID: number) => {
    const { updateSelectedDashboardOrders } = this.props;

    updateSelectedDashboardOrders(orderID);
  };

  showDashBoardModal = () => {
    const { showDashBoardEditDeliveryRun } = this.props;
    showDashBoardEditDeliveryRun();
  };

  updateDriver = (driver: MicroDriverResponse) => {
    // If drive is not already in state, add it
    if (
      !this.state.microDrivers.filter((d) => d.driverId === driver.driverId)
        .length
    ) {
      return this.setState((prev) => ({
        microDrivers: [...prev.microDrivers, driver],
      }));
    }
    // If driver is already in state, update coords
    this.setState((prev) => ({
      microDrivers: prev.microDrivers.map((d) => {
        if (d.driverId === driver.driverId) {
          return driver;
        }
        return d;
      }),
    }));
  };

  render(): JSX.Element {
    const {
      drivers,
      orders,
      showOrders,
      updateDashboardDeliveryRuns,
      storeLat,
      storeLng,
      selectedDeliveryRunID,
      showDashBoard,
      modal_driver_id,
      modal_route_selection_method,
      modal_startTime_date,
      modal_startTime_day,
      modal_delivery,
      modal_travel_time,
    } = this.props;
    const avgLoc = avgLocation(orders);

    return (
      <DashboardContainer>
        <DeliveryRunModal
          driver_id={modal_driver_id}
          route_selection_method={modal_route_selection_method}
          start_day={modal_startTime_day}
          start_date={modal_startTime_date}
          delivery={modal_delivery}
          showModal={showDashBoard}
          toggleModal={this.showDashBoardModal}
          travel_time={modal_travel_time}
          fromDashboard={true}
        />
        <DriverBox>
          <CreateDeliveryRunTable
            title={"DRIVERS"}
            emptyState={!drivers.length}
          >
            {drivers &&
              drivers.map((driver) => {
                return (
                  <div key={driver.id}>
                    <DriverCard
                      key={driver.id}
                      first_name={driver.first_name}
                      last_name={driver.last_name}
                      delivery_runs={driver.deliveryRuns}
                      {...driver}
                      onDeliveryRunSelect={this.onDeliveryRunSelect}
                      updateDeliveryRun={updateDashboardDeliveryRuns}
                      selected_delivery_id={selectedDeliveryRunID}
                      fromDashboard={true}
                    />
                  </div>
                );
              })}
          </CreateDeliveryRunTable>
        </DriverBox>
        <MapContainer>
          {storeLat && storeLng && (
            <StyledHereMaps
              lat={isNaN(avgLoc[0]) ? storeLat : avgLoc[0]}
              lng={isNaN(avgLoc[1]) ? storeLng : avgLoc[1]}
              zoom={12}
              drivers={this.state.microDrivers}
              orders={orders}
              selectedDashBoardRoute={this.props.selectedDeliveryOrders}
              fromDashBoard={true}
            />
          )}
        </MapContainer>
        {showOrders && (
          <OrderBox>
            <CreateDeliveryRunTable
              title={"ORDERS"}
              emptyState={!orders.length}
            >
              {orders && !!orders.length ? (
                orders.map((order) => {
                  return (
                    <div
                      key={order.id}
                      onClick={() => this.onOrderSelect(order.id)}
                    >
                      <OrderCard
                        recieved={
                          order.date_imported ? order.date_imported : "Manual"
                        }
                        cases={order.num_boxes ? order.num_boxes : 0}
                        {...order.customer}
                        {...order.address}
                        {...order}
                      />
                    </div>
                  );
                })
              ) : (
                <EmptyCard
                  primaryText="All Orders Have Been Assigned To a Delivery Run"
                  secondaryText="Click Save Delivery Runs to Proceed"
                />
              )}
            </CreateDeliveryRunTable>
            <SubmitButton onClick={this.showDashBoardModal}>
              Edit Delivery Run
            </SubmitButton>
          </OrderBox>
        )}
      </DashboardContainer>
    );
  }
}

const DashboardContainer = styled.div`
  display: flex;
`;

const MapContainer = styled.div`
  /* position: absolute; */
  padding-top: 60px;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
`;

const StyledHereMaps = styled(HereMaps)`
  /* position: absolute; */
  z-index: 0;
  top: 80px;
`;

const DriverBox = styled.div`
  padding-top: 60px;
  height: calc(100vh);
  z-index: 50;
  transition: box-shadow 0.1s ease;
`;

const OrderBox = styled.div`
  /* position: absolute; */
  z-index: 2;
  padding-top: 60px;
  height: calc(100vh);
`;

const SubmitButton = styled(BasicButton)`
  position: absolute;
  bottom: 0;
  right: 0;
  z-index: 50;
  /* :active {
    box-shadow: 0 3px 0 #7bc1c7;
    top: 95.2%;
    transform: translateY(2px);
  } */
`;

interface LinkStateProps {
  token: string;
  store_id: number;
  drivers: DeliveryDriver[];
  orders: OrderType[];
  showOrders: boolean;
  storeLat: number;
  storeLng: number;
  selectedDeliveryRunID: number;
  selectedDeliveryOrders: {
    latitude: number;
    longitude: number;
    status: string;
  }[];
  selectedDeliveryStatus: string;
  selectedDeliveryRouteMethod: string;
  showDashBoard: boolean;
  modal_driver_id: number;
  modal_route_selection_method: string;
  modal_startTime_date: string;
  modal_startTime_day: string;
  modal_delivery: DeliveryRuns;
  modal_travel_time: string;
}

interface LinkDispatchProps {
  getStore: (store: UserStore) => void;
  updateDashboardDrivers: (drivers: DeliveryDriver[]) => void;
  showDashboardOrders: () => void;
  updateDashboardOrders: (orders: OrderType[]) => void;
  updateDashboardDeliveryRuns: (delivery_runs: ImportedDeliveryRun) => void;
  updateCurrentDashboardDeliveryRun: (id: SelectedDashboard) => void;
  showDashBoardEditDeliveryRun: () => void;
  updateDashboardModalInfo: (modalInfo: UpdateModal) => void;
  updateSelectedDashboardOrders: (orderID: number) => void;
}

const mapDispatchToProps = (
  dispatch: Dispatch<AppActions>
): LinkDispatchProps => ({
  getStore: bindActionCreators(getStore, dispatch),
  updateDashboardDrivers: bindActionCreators(updateDashBoardDrivers, dispatch),
  showDashboardOrders: bindActionCreators(showDashboardOrders, dispatch),
  updateDashboardOrders: bindActionCreators(updateDashboardOrders, dispatch),
  updateDashboardDeliveryRuns: bindActionCreators(
    updateDashboardDeliveryRuns,
    dispatch
  ),
  updateCurrentDashboardDeliveryRun: bindActionCreators(
    updateCurrentDashboardDeliveryRun,
    dispatch
  ),
  showDashBoardEditDeliveryRun: bindActionCreators(
    showDashBoardEditDeliveryRun,
    dispatch
  ),
  updateDashboardModalInfo: bindActionCreators(
    updateDashboardModalInfo,
    dispatch
  ),
  updateSelectedDashboardOrders: bindActionCreators(
    updateSelectedDashboardOrders,
    dispatch
  ),
});

const mapStateToProps = (state: AppState): LinkStateProps => ({
  store_id: state.user.store_id as number,
  token: state.auth.token as string,
  drivers: state.dashboard.drivers,
  orders: state.dashboard.orders,
  showOrders: state.dashboard.showOrders,
  storeLat: state.store.address.latitude,
  storeLng: state.store.address.longitude,
  selectedDeliveryRunID: state.dashboard.currentDeliveryRun.id,
  selectedDeliveryOrders: state.dashboard.currentDeliveryRun.delivery,
  selectedDeliveryStatus: state.dashboard.currentDeliveryRun.status,
  selectedDeliveryRouteMethod:
    state.dashboard.currentDeliveryRun.route_selection_method,
  showDashBoard: state.dashboard.editDeliveryRunModal,
  modal_driver_id: state.dashboard.modal_driver_id,
  modal_route_selection_method: state.dashboard.modal_route_selection_method,
  modal_startTime_date: state.dashboard.modal_startTime_date,
  modal_startTime_day: state.dashboard.modal_startTime_day,
  modal_delivery: state.dashboard.modal_delivery,
  modal_travel_time: state.dashboard.modal_travel_time,
});

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);
