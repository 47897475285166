import { api } from "./api";
import { login, getMe, logout, startError } from "../actions/auth";
import { history } from "../routers/AppRouter";
import { access } from "fs-extra";
import { LoggedUser, AppActions } from "../types";
import { AppState } from "../store/configureStore";
import { editUser } from "../actions";
import { Dispatch } from "redux";

export const loginPostOnboardThunk = ({
  access_token,
  is_registered,
  store_id: store_id_p
}: {
  access_token: string;
  is_registered: number;
  store_id?: number;
}) => {
  return (dispatch: Dispatch<AppActions>, getState: () => AppState) => {
    const store_id = store_id_p ? store_id_p : getState().user.store_id;
    dispatch(
      login({ access_token, is_registered, store_id: store_id as number })
    );
  };
};

export const loginThunk = (
  email: string,
  password: string
) => async dispatch => {
  try {
    const accessToken: any = await api.login({ email, password });
    console.log("error? ", !accessToken.ok);
    if (!accessToken.ok) {
      const logError = startError(accessToken.data.error);
      dispatch(logError);
    }
    const token = accessToken.data.token.original;
    const userInfo = accessToken.data.user;
    console.log(userInfo);
    if (token.access_token) {
      // const loginAction = login(validateLogin(token);
      const loginAction = login({
        access_token: token.access_token,
        is_registered: userInfo.is_registered,
        store_id: userInfo.store_id
      });
      const getUserAction = getMe(userInfo);
      dispatch(loginAction);
      dispatch(getUserAction);
    }
  } catch (err) {
    console.log(err);
  }
};

export const sendForgotEmailThunk = email => async dispatch => {
  try {
    const formData = new FormData();
    formData.append("email", email);
    await api.forgotEmail({ email });
    console.log("Forgot email sent!");
  } catch (err) {
    console.log(err);
  }
};

// export const resetPasswordThunk = (
//   token,
//   email,
//   new_password
// ) => async dispatch => {
//   try {
//     const status = await api.resetPassword({ email, new_password }, token);
//     //TODO resetPass reducer
//     // const action = resetPass(status.data)
//     // dispatch(action)
//   } catch (err) {
//     console.log(err);
//   }
// };

export const logoutThunk = () => async dispatch => {
  try {
    await api.logout();
    dispatch(logout());
  } catch (err) {
    console.log(err);
  }
};

export const editAuthUserThunk = ({
  user_id,
  first_name,
  last_name,
  email,
  password,
  is_registered
}: {
  user_id: number | null;
  first_name?: string;
  last_name?: string;
  email?: string;
  password?: string;
  is_registered?: number;
}) => async (dispatch, getState) => {
  const token = getState().auth.token;
  try {
    const res: any = await api.editUser(
      {
        user_id: user_id as number,
        first_name,
        last_name,
        email,
        password,
        is_registered
      },
      token
    );
    const userInfo: LoggedUser = res.data;
    dispatch(editUser(userInfo));
  } catch (err) {
    console.log(err);
  }
};

// export const registerAdminThunk = (fields: {
//   first_name: string;
//   last_name: string;
//   email: string;
//   store_name: string;
//   nexmo_num: string;
// }) => async dispatch => {
//   try {
//     await api.registerAdmin(fields);
//   } catch (err) {
//     err;
//   }
// };
