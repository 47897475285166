import * as React from "react";
import { connect } from "react-redux";
import { Formik, FormikProps, Form } from "formik";
import * as Yup from "yup";
import { PHONE_REG_EXP, formatPhoneNumber } from "../../../utils";
import { Dispatch, bindActionCreators } from "redux";
import { AppActions } from "../../../types";
import {
  startSetOnboardingPrefs,
  onboardNextPage,
  onboardPrevPage,
} from "../../../actions/onboarding";
import { AppState } from "../../../store/configureStore";
import {
  InputField,
  OnboardInputField,
  OnboardCheckboxField,
  TimePickField,
} from "../../form";
import styled from "styled-components";
import { AuthButton } from "../../Pages/NewClientSetup";
import { AppTextRoman, AppText, Container } from "../../UI";
import { login } from "../../../actions";
import CustomTimePicker from "../../UI/Timepicker";
import { storeCustomerNotificationThunk } from "../../../api/notification";
import { editAuthUserThunk, loginPostOnboardThunk } from "../../../api/auth";
import { withRouter, RouteComponentProps, NavLink } from "react-router-dom";

const onboardSchema = Yup.object().shape({
  limit_customer_comm_to_certain_times_email_from: Yup.string().notRequired(),
  limit_customer_comm_to_certain_times_email_to: Yup.string().notRequired(),
  limit_customer_comm_to_certain_times_phone_from: Yup.string().notRequired(),
  limit_customer_comm_to_certain_times_phone_to: Yup.string().notRequired(),
  limit_customer_comm_to_certain_times_sms_from: Yup.string().notRequired(),
  limit_customer_comm_to_certain_times_sms_to: Yup.string().notRequired(),
  require_signature_for_deliveries_email: Yup.boolean().required(),
  require_signature_for_deliveries_phone: Yup.boolean().required(),
  require_signature_for_deliveries_sms: Yup.boolean().required(),
  when_delivery_has_been_delayed_email: Yup.boolean().required(),
  when_delivery_has_been_delayed_phone: Yup.boolean().required(),
  when_delivery_has_been_delayed_sms: Yup.boolean().required(),
  when_delivery_is_completed_email: Yup.boolean().required(),
  when_delivery_is_completed_phone: Yup.boolean().required(),
  when_delivery_is_completed_sms: Yup.boolean().required(),
  when_delivery_is_scheduled_sms: Yup.boolean().required(),
  when_delivery_is_scheduled_email: Yup.boolean().required(),
  when_delivery_is_scheduled_phone: Yup.boolean().required(),
  when_delivery_will_arrive_soon_email: Yup.boolean().required(),
  when_delivery_will_arrive_soon_phone: Yup.boolean().required(),
  when_delivery_will_arrive_soon_sms: Yup.boolean().required(),
});

interface EditCustomerCommunicationPreferencesProps {}

interface EditCustomerMyCommunicationPreferencesState {
  edit: boolean;
}

interface MyFormikProps {
  limit_customer_comm_to_certain_times_email_from: string;
  limit_customer_comm_to_certain_times_email_to: string;
  limit_customer_comm_to_certain_times_phone_from: string;
  limit_customer_comm_to_certain_times_phone_to: string;
  limit_customer_comm_to_certain_times_sms_from: string;
  limit_customer_comm_to_certain_times_sms_to: string;
  require_signature_for_deliveries_email: boolean;
  require_signature_for_deliveries_phone: boolean;
  require_signature_for_deliveries_sms: boolean;
  when_delivery_has_been_delayed_email: boolean;
  when_delivery_has_been_delayed_phone: boolean;
  when_delivery_has_been_delayed_sms: boolean;
  when_delivery_is_completed_email: boolean;
  when_delivery_is_completed_phone: boolean;
  when_delivery_is_completed_sms: boolean;
  when_delivery_is_scheduled_sms: boolean;
  when_delivery_is_scheduled_email: boolean;
  when_delivery_is_scheduled_phone: boolean;
  when_delivery_will_arrive_soon_email: boolean;
  when_delivery_will_arrive_soon_phone: boolean;
  when_delivery_will_arrive_soon_sms: boolean;
  sms_start_alert_time: string;
  sms_end_alert_time: string;
  email_start_alert_time: string;
  email_end_alert_time: string;
  phone_start_alert_time: string;
  phone_end_alert_time: string;
}

type Props = RouteComponentProps<{}> &
  EditCustomerCommunicationPreferencesProps &
  LinkDispatchProps &
  LinkStateProps;

class EditCustomerCommunicationPreferences extends React.Component<
  Props,
  EditCustomerMyCommunicationPreferencesState
> {
  constructor(props: Props) {
    super(props);

    this.state = {
      edit: false,
    };
  }
  toggleEdit = () => this.setState((prev) => ({ edit: !prev.edit }));
  render(): JSX.Element {
    const { edit } = this.state;
    return (
      <Formik
        initialValues={{
          limit_customer_comm_to_certain_times_email_from: this.props
            .limit_customer_comm_to_certain_times_email_from,
          limit_customer_comm_to_certain_times_email_to: this.props
            .limit_customer_comm_to_certain_times_email_to,
          limit_customer_comm_to_certain_times_phone_from: this.props
            .limit_customer_comm_to_certain_times_phone_from,
          limit_customer_comm_to_certain_times_phone_to: this.props
            .limit_customer_comm_to_certain_times_phone_to,
          limit_customer_comm_to_certain_times_sms_from: this.props
            .limit_customer_comm_to_certain_times_sms_from,
          limit_customer_comm_to_certain_times_sms_to: this.props
            .limit_customer_comm_to_certain_times_sms_to,
          require_signature_for_deliveries_email: this.props
            .require_signature_for_deliveries_email,
          require_signature_for_deliveries_phone: this.props
            .require_signature_for_deliveries_phone,
          require_signature_for_deliveries_sms: this.props
            .require_signature_for_deliveries_sms,
          when_delivery_has_been_delayed_email: this.props
            .when_delivery_has_been_delayed_email,
          when_delivery_has_been_delayed_phone: this.props
            .when_delivery_has_been_delayed_phone,
          when_delivery_has_been_delayed_sms: this.props
            .when_delivery_has_been_delayed_sms,
          when_delivery_is_completed_email: this.props
            .when_delivery_is_completed_email,
          when_delivery_is_completed_phone: this.props
            .when_delivery_is_completed_phone,
          when_delivery_is_completed_sms: this.props
            .when_delivery_is_completed_sms,
          when_delivery_is_scheduled_sms: this.props
            .when_delivery_is_scheduled_sms,
          when_delivery_is_scheduled_email: this.props
            .when_delivery_is_scheduled_email,
          when_delivery_is_scheduled_phone: this.props
            .when_delivery_is_completed_phone,
          when_delivery_will_arrive_soon_email: this.props
            .when_delivery_will_arrive_soon_email,
          when_delivery_will_arrive_soon_phone: this.props
            .when_delivery_will_arrive_soon_phone,
          when_delivery_will_arrive_soon_sms: this.props
            .when_delivery_will_arrive_soon_sms,
          sms_start_alert_time: "",
          sms_end_alert_time: "",
          email_start_alert_time: "",
          email_end_alert_time: "",
          phone_start_alert_time: "",
          phone_end_alert_time: "",
        }}
        validationSchema={onboardSchema}
        onSubmit={async (values, { setFieldError, setStatus }) => {
          const smsArray: any = [];
          const emailArray: any = [];
          const phoneArray: any = [];
          values.require_signature_for_deliveries_sms ? smsArray.push(6) : null;
          values.require_signature_for_deliveries_email
            ? emailArray.push(6)
            : null;
          values.require_signature_for_deliveries_phone
            ? phoneArray.push(6)
            : null;
          values.when_delivery_is_scheduled_sms ? smsArray.push(7) : null;
          values.when_delivery_is_scheduled_phone ? phoneArray.push(7) : null;
          values.when_delivery_is_scheduled_email ? emailArray.push(7) : null;
          values.when_delivery_will_arrive_soon_sms ? smsArray.push(8) : null;
          values.when_delivery_will_arrive_soon_email
            ? emailArray.push(8)
            : null;
          values.when_delivery_will_arrive_soon_phone
            ? phoneArray.push(8)
            : null;
          values.when_delivery_is_completed_sms ? smsArray.push(9) : null;
          values.when_delivery_is_completed_email ? emailArray.push(9) : null;
          values.when_delivery_is_completed_phone ? phoneArray.push(9) : null;
          values.when_delivery_has_been_delayed_sms ? smsArray.push(10) : null;
          values.when_delivery_has_been_delayed_email
            ? emailArray.push(10)
            : null;
          values.when_delivery_has_been_delayed_phone
            ? phoneArray.push(10)
            : null;
          try {
            smsArray.length !== 0 && this.props.store_id !== null
              ? this.props.storeCustomerNotification(
                  this.props.store_id as number,
                  smsArray,
                  "sms",
                  values.sms_start_alert_time + ":00",
                  values.sms_end_alert_time + ":00"
                )
              : null,
              emailArray.length !== 0 && this.props.store_id !== null
                ? this.props.storeCustomerNotification(
                    this.props.store_id as number,
                    emailArray,
                    "email",
                    values.email_start_alert_time + ":00",
                    values.email_end_alert_time + ":00"
                  )
                : null,
              phoneArray.length !== 0 && this.props.store_id !== null
                ? this.props.storeCustomerNotification(
                    this.props.store_id as number,
                    phoneArray,
                    "phone",
                    values.phone_start_alert_time + ":00",
                    values.phone_end_alert_time + ":00"
                  )
                : null;
            // this.props.editUser({
            //   user_id: this.props.user_id,
            //   is_registered: 1
            // });
            // this.props.login({
            //   access_token: this.props.access_token,
            //   is_registered: 1
            // });
          } catch (err) {
            console.log(err);
          }
          setStatus({ error: "", loading: true });
          // TODO: cleanup when backend is available
          setStatus({ loading: false });
          // this.props.history.push("/drivers");
        }}
      >
        {({
          status,
          setStatus,
          submitForm,
          touched,
          errors,
        }: FormikProps<MyFormikProps>) => {
          return (
            <CustomerCommunicationPreferencesContainer>
              <AccountSetupText>
                CUSTOMER COMMUNICATION PREFERENCES
              </AccountSetupText>
              <Div>
                <Grid>
                  <Table>
                    <div />
                    <DescriptionText>SMS</DescriptionText>
                    <DescriptionText>EMAIL</DescriptionText>
                    <DescriptionText>PHONE CALL</DescriptionText>
                    <LabelText>Require Signature for Deliveries</LabelText>
                    <OnboardCheckboxField
                      name="require_signature_for_deliveries_sms"
                      disabled={!edit}
                    />
                    <OnboardCheckboxField
                      name="require_signature_for_deliveries_email"
                      disabled={!edit}
                    />
                    <OnboardCheckboxField
                      name="require_signature_for_deliveries_phone"
                      disabled={!edit}
                    />
                    <LabelText>When a delivery is scheduled</LabelText>
                    <OnboardCheckboxField
                      name="when_delivery_is_scheduled_sms"
                      disabled={!edit}
                    />
                    <OnboardCheckboxField
                      name="when_delivery_is_scheduled_email"
                      disabled={!edit}
                    />
                    <OnboardCheckboxField
                      name="when_delivery_is_scheduled_phone"
                      disabled={!edit}
                    />
                    <LabelText>When a delivery will arrive soon</LabelText>
                    <OnboardCheckboxField
                      name="when_delivery_will_arrive_soon_sms"
                      disabled={!edit}
                    />
                    <OnboardCheckboxField
                      name="when_delivery_will_arrive_soon_email"
                      disabled={!edit}
                    />
                    <OnboardCheckboxField
                      name="when_delivery_will_arrive_soon_phone"
                      disabled={!edit}
                    />
                    <LabelText>When a delivery is completed</LabelText>
                    <OnboardCheckboxField
                      name="when_delivery_is_completed_sms"
                      disabled={!edit}
                    />
                    <OnboardCheckboxField
                      name="when_delivery_is_completed_email"
                      disabled={!edit}
                    />
                    <OnboardCheckboxField
                      name="when_delivery_is_completed_phone"
                      disabled={!edit}
                    />
                    <LabelText>When a delivery has been delayed</LabelText>
                    <OnboardCheckboxField
                      name="when_delivery_has_been_delayed_sms"
                      disabled={!edit}
                    />
                    <OnboardCheckboxField
                      name="when_delivery_has_been_delayed_email"
                      disabled={!edit}
                    />
                    <OnboardCheckboxField
                      name="when_delivery_has_been_delayed_phone"
                      disabled={!edit}
                    />
                    <LabelText>Limit Customer Comm. to Certain Times</LabelText>
                    <TimeDiv>
                      <TimePickField
                        title={"sms_start_alert_time"}
                        name={"sms_start_alert_time"}
                        type={"AM"}
                        // disabled={!edit}
                      />
                      <LabelText>-</LabelText>
                      <TimePickField
                        title={"sms_end_alert_time"}
                        name={"sms_end_alert_time"}
                        type={"PM"}
                        // disabled={!edit}
                      />
                      {/* <CustomTimePicker event={"SMS"} type={"AM"} /> */}
                      {/* <CustomTimePicker event={"SMS"} type={"PM"} /> */}
                    </TimeDiv>
                    <TimeDiv>
                      <TimePickField
                        title={"email_start_alert_time"}
                        name={"email_start_alert_time"}
                        type={"AM"}
                        // disabled={!edit}
                      />
                      <LabelText>-</LabelText>
                      <TimePickField
                        title={"email_end_alert_time"}
                        name={"email_end_alert_time"}
                        type={"PM"}
                        // disabled={!edit}
                      />
                    </TimeDiv>
                    <TimeDiv>
                      <TimePickField
                        title={"phone_start_alert_time"}
                        name={"phone_start_alert_time"}
                        type={"AM"}
                        // disabled={!edit}
                      />
                      <LabelText>-</LabelText>
                      <TimePickField
                        title={"phone_end_alert_time"}
                        name={"phone_end_alert_time"}
                        type={"PM"}
                        // disabled={!edit}
                      />
                    </TimeDiv>
                  </Table>
                  <div />
                </Grid>
              </Div>
              <Spread>
                {edit ? (
                  <>
                    <AuthButton type="submit" onClick={this.toggleEdit}>
                      Cancel
                    </AuthButton>
                    <AuthButton type="submit" onClick={submitForm}>
                      SAVE
                    </AuthButton>
                  </>
                ) : (
                  <>
                    <AuthButton type="submit" onClick={this.toggleEdit}>
                      EDIT
                    </AuthButton>
                    <NavLink to="/settings">
                      <AuthButton type="submit">RETURN TO SETTINGS</AuthButton>
                    </NavLink>
                  </>
                )}
              </Spread>
            </CustomerCommunicationPreferencesContainer>
          );
        }}
      </Formik>
    );
  }
}

const CustomerCommunicationPreferencesContainer = styled(Container)`
  background-image: linear-gradient(to left, #4ba5eb, #60999e);
  grid-template-rows: 130px 1fr 1fr;
  padding: 53px 50px 45px 75px;
  display: grid;
  background-size: cover;
  background-attachment: fixed;
  background-position: center center;
  overflow-x: auto;
  height: 100vh;
`;

const AccountSetupText = styled(AppText)`
  font-size: 27px;
  color: #fff;
`;

const Grid = styled.div`
  display: grid;
  grid-template-rows: min-content auto;
  gap: 20px;
  max-width: 1040px;
  box-sizing: border-box;
  /* padding-left: 120px; */
  margin-top: 40px;
`;

const Div = styled.div`
  display: grid;
  grid-template-rows: auto min-content;
`;

const TimeDiv = styled.div`
  display: flex;
  flex-direction: row;
`;
const Table = styled.div`
  display: grid;
  grid-template-columns: min-content min-content min-content min-content;
  white-space: nowrap;
  column-gap: 0px;
  row-gap: 25px;
  column-gap: 170px;
`;

const Spread = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
`;

const TableContainer = styled.div`
  display: flex;
  flex-direction: row;
`;

const DescriptionText = styled(AppText)`
  font-size: 15px;
  line-height: 1.67;
  color: #ffffff;
  font-weight: 300;
  text-align: center;
`;

const LabelText = styled(AppText)`
  font-size: 18px;
  color: #f7f7f7;
  font-weight: 300;
`;

interface LinkStateProps extends MyFormikProps {
  user_id: number | null;
  access_token: string;
  store_id: number | null;
}

interface LinkDispatchProps {
  startSetOnboardPrefs: (onboardPrefs: Object) => void;
  onboardNextPage: () => void;
  onboardPrevPage: () => void;
  login: ({
    access_token,
    is_registered,
    store_id,
  }: {
    access_token: string;
    is_registered: number;
    store_id?: number;
  }) => void;
  storeCustomerNotification: (
    customer_id: number,
    notification_ids: [],
    send_method: string,
    begin_time: string,
    end_time: string
  ) => void;
  editUser: ({
    user_id,
    first_name,
    last_name,
    email,
    password,
    is_registered,
  }: {
    user_id: number | null;
    first_name?: string;
    last_name?: string;
    email?: string;
    password?: string;
    is_registered: number;
  }) => void;
}
//TODO standardizing redux action
const mapStateToProps = (state: AppState): LinkStateProps => ({
  limit_customer_comm_to_certain_times_email_from:
    state.onboarding.limit_customer_comm_to_certain_times_email_from,
  limit_customer_comm_to_certain_times_email_to:
    state.onboarding.limit_customer_comm_to_certain_times_email_to,
  limit_customer_comm_to_certain_times_phone_from:
    state.onboarding.limit_customer_comm_to_certain_times_phone_from,
  limit_customer_comm_to_certain_times_phone_to:
    state.onboarding.limit_customer_comm_to_certain_times_phone_to,
  limit_customer_comm_to_certain_times_sms_from:
    state.onboarding.limit_customer_comm_to_certain_times_sms_from,
  limit_customer_comm_to_certain_times_sms_to:
    state.onboarding.limit_customer_comm_to_certain_times_sms_to,
  require_signature_for_deliveries_email:
    state.onboarding.require_signature_for_deliveries_email,
  require_signature_for_deliveries_phone:
    state.onboarding.require_signature_for_deliveries_phone,
  require_signature_for_deliveries_sms:
    state.onboarding.require_signature_for_deliveries_sms,
  when_delivery_has_been_delayed_email:
    state.onboarding.when_delivery_has_been_delayed_email,
  when_delivery_has_been_delayed_phone:
    state.onboarding.when_delivery_has_been_delayed_phone,
  when_delivery_has_been_delayed_sms:
    state.onboarding.when_delivery_has_been_delayed_sms,
  when_delivery_is_completed_email:
    state.onboarding.when_delivery_is_completed_email,
  when_delivery_is_completed_phone:
    state.onboarding.when_delivery_is_completed_phone,
  when_delivery_is_completed_sms:
    state.onboarding.when_delivery_is_completed_sms,
  when_delivery_is_scheduled_email:
    state.onboarding.when_delivery_is_scheduled_email,
  when_delivery_is_scheduled_phone:
    state.onboarding.when_delivery_is_scheduled_phone,
  when_delivery_is_scheduled_sms:
    state.onboarding.when_delivery_is_scheduled_sms,
  when_delivery_will_arrive_soon_email:
    state.onboarding.when_delivery_will_arrive_soon_email,
  when_delivery_will_arrive_soon_phone:
    state.onboarding.when_delivery_will_arrive_soon_phone,
  when_delivery_will_arrive_soon_sms:
    state.onboarding.when_delivery_will_arrive_soon_sms,
  sms_start_alert_time: "",
  sms_end_alert_time: "",
  email_start_alert_time: "",
  email_end_alert_time: "",
  phone_start_alert_time: "",
  phone_end_alert_time: "",
  user_id: state.user.id,
  access_token: state.auth.token as string,
  store_id: state.user.store_id as number,
});

const mapDispatchToProps = (
  dispatch: Dispatch<AppActions>
): LinkDispatchProps => ({
  startSetOnboardPrefs: bindActionCreators(startSetOnboardingPrefs, dispatch),
  onboardNextPage: bindActionCreators(onboardNextPage, dispatch),
  onboardPrevPage: bindActionCreators(onboardPrevPage, dispatch),
  login: bindActionCreators(loginPostOnboardThunk, dispatch),
  storeCustomerNotification: bindActionCreators(
    storeCustomerNotificationThunk,
    dispatch
  ),
  editUser: bindActionCreators(editAuthUserThunk, dispatch),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(EditCustomerCommunicationPreferences));
