import React from "react";
import { Field, FieldProps, ErrorMessage } from "formik";
import styled from "styled-components";
// import Switch from "react-switch";
// import Switch from 'react-toggle-switch';
// import { Toggle } from "react-toggle-component";
import Switch from "react-toggle-switch";
import { Checkbox, AppText } from "../UI";
import { ErrorText } from "../Dumb";
import "react-toggle-switch/dist/css/switch.min.css";

export const SwitchField = ({
  name,
  title,
  style
}: {
  name: string;
  title: string;
  style?: React.CSSProperties;
}) => (
  <div style={style}>
    <Field
      name={name}
      render={({ field, form, form: { errors, touched } }: FieldProps) => {
        const error = !!errors.email && !!touched.email;
        return (
          <div>
            <Horizontal style={{ alignItems: "center" }}>
              <SwitchText>{title}</SwitchText>
              {/* <Switch
                {...field}
                uncheckedIcon={false}
                checkedIcon={false}
                checked={field.value}
              /> */}
              {/* <Toggle
                {...field}
                checked={field.value}
                onToggle={field.onChange}
                leftBackgroundColor="gray"
                rightBackgroundColor="#228B22"
                borderColor="transparent"
                knobColor="#fff"
                width="40px"
              /> */}
              <Switch
                onClick={() => form.setFieldValue(name, !field.value)}
                // onClick={field.onChange}
                on={field.value}
              />
            </Horizontal>
            <ErrorMessage name={field.name} component={ErrorText} />
          </div>
        );
      }}
    />
  </div>
);

const Horizontal = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 25px;
`;

const SwitchText = styled(AppText)`
  line-height: 1.86;
  font-size: 15px;
  color: #3b3a3a;
  white-space: nowrap;
  margin-right: 15px;
`;
