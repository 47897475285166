import { DefaultCustomOrderFilterStateActionTypes } from "../types";

const defaultCustomOrderFilterStateReducer = {
  initialOrderCustomListLoad: true
};

const customOrderFilterStateReducer = (
  state = defaultCustomOrderFilterStateReducer,
  action: DefaultCustomOrderFilterStateActionTypes
) => {
  switch (action.type) {
    case "UPDATE_ORDER_CUSTOM_LIST_LOAD":
      return { ...state, initialOrderCustomListLoad: false };
    case "RELOAD_ORDER_CUSTOM_LIST_LOAD":
      return { ...state, initialOrderCustomListLoad: true };
    case "LOGOUT":
      return defaultCustomOrderFilterStateReducer;
    default:
      return state;
  }
};

export default customOrderFilterStateReducer;
