import { Operator } from "../types";

const operators_raw: Operator[] = [
  "Equals",
  "Contains",
  "Does not equal",
  "Does not contain",
  "Equals multiple",
  "Does not equal multiple",
  "Greater than",
  "Less than",
  "Greater than or equal to",
  "Less than or equal to"
];

export const operators = operators_raw.map(operator => ({
  label: operator,
  value: operator,
  data: undefined
}));
