import * as React from "react";
import { connect } from "react-redux";
import { withRouter, RouteComponentProps } from "react-router";
import styled from "styled-components";
// import BackArrow from "react-icons/lib/md/chevron-left";
import { MdChevronLeft } from "react-icons/md";

import * as Yup from "yup";
import {
  Container,
  SecondaryButton,
  Button,
  HR,
  Spinner,
  AppText,
  ColorBox,
} from "../UI";
import { FieldDetail, ColorItem } from "../Dumb";
// import { Toggle } from "react-toggle-component";
import { PRIMARY_GRADIENT_FROM } from "../../utils";
import { Formik, FormikProps, FieldArray } from "formik";
import {
  InputField,
  SelectField,
  CheckboxField,
  OnboardCheckboxField,
  CustomColorSelectField,
  SwitchField,
} from "../form";
import { languages, transportation } from "../../static";
import { ColorSelectField } from "../form/ColorSelectField";
import Switch from "react-toggle-switch";
import "react-toggle-switch/dist/css/switch.min.css";
import { api } from "../../api/api";
import { Driver } from "../../api/responseTypes";
import { AppState } from "../../store/configureStore";

import { Dispatch, bindActionCreators } from "redux";
import {
  AppActions,
  CustomFieldByEntity,
  CustomFieldsList,
  CustomFields,
  CustomFieldsByEntityListValue,
} from "../../types";
import {
  toggleNewDriverModal,
  getDriverCustomFieldValues,
} from "../../actions";

// TODO: Allow email to be editable

const editDriverSchema = Yup.object().shape({
  first_name: Yup.string()
    .required("* You must specify a first name")
    .max(100, "* Too Long!"),
  last_name: Yup.string()
    .required("* You must specify a last name")
    .max(100, "* Too Long!"),
  // email: Yup.string()
  //   .email("* Invalid email")
  //   .required("* Email is required"),
  language: Yup.string().required("* You must selected a language"),
  color: Yup.string().required("* You must select a color"),
});

interface FieldArray {
  custom_field_id: number;
  field: number | undefined;
  label: string;
  slug: string;
  value: string | null;
  filter_type: number;
  listValues: CustomFieldsByEntityListValue[];
}

interface MyFormikProps {
  first_name: string;
  last_name: string;
  // email: string;
  language: string;
  color: string;
  custom_fields: FieldArray[];
  is_active: number;
}

interface RouterProps {
  id: string;
}

interface DriverDetailProps {}
interface DriverDetailState {
  edit: boolean;
  toggle: boolean;
  toggleCustomColorPicker: boolean;
  driver: Driver;
  loading: boolean;
  custom_fields: FieldArray[];
}

// const stub = {
//   first_name: "Cory",
//   last_name: "McAboy",
//   email: "cory.mcaboy@gmail.com",
//   language: "English",
//   driver_id: "7823478239472890",
//   date_created: "03/22/2019 6:55 AM",
//   last_modified_date: "03/22/2019 6:55 AM",
//   active: true,
//   color: "orange"
// };

type Props = RouteComponentProps<RouterProps> &
  DriverDetailProps &
  LinkStateProps &
  LinkDispatchProps;

// const defaultProperties = ["id", "store_id", "ex"]
class DriverDetail extends React.Component<Props, DriverDetailState> {
  constructor(props: Props) {
    super(props);

    this.state = {
      edit: false,
      toggle: false,
      loading: true,
      toggleCustomColorPicker: false,
      driver: {
        color: "",
        created_at: "",
        deleted_at: "",
        email: "",
        external_driver_id: "",
        first_name: "",
        id: 0,
        is_active: 1,
        language: "",
        last_name: "",
        store_id: 0,
        updated_at: "",
        password: "test",
        transportation_type: "",
      },
      custom_fields: [],
    };
  }
  toggleEdit = () => this.setState((prev) => ({ edit: !prev.edit }));
  toggleToggle = () => {
    console.log("toggle click");
    this.setState((prev) => ({ toggle: !prev.toggle }));
  };
  toggleCustomColor = () =>
    this.setState((prev) => ({
      toggleCustomColorPicker: !prev.toggleCustomColorPicker,
    }));
  deleteDriver = async () => {
    const id = this.props.match.params.id;
    await api.deleteDriver({ id }, this.props.token);
    this.props.history.goBack();
  };
  componentDidMount = async () => {
    // console.log("componentdidmount");
    const id = this.props.match.params.id;
    const driver = await api.getDriver({ id }, this.props.token);
    const customValues = (await api.getAllCustomProperties(
      id,
      this.props.token,
      "Driver"
    )) as CustomFieldByEntity[];
    const finalList =
      this.props.allCustomFields && customValues
        ? customValues.map((field) => {
            const slug = this.props.allCustomFields
              .filter(
                (allcustomFields) =>
                  allcustomFields.id === field.custom_field.id
              )
              .map((obj) => {
                return {
                  display: obj.display,
                  slug: obj.slug,
                  filter_id: obj.field_type_id,
                };
              })[0];
            return {
              field: field.id,
              value: field.value,
              custom_field_id: field.custom_field.id,
              label: slug ? slug.display : "",
              slug: slug ? slug.slug : "",
              filter: slug ? slug.filter_id : "",
              listValues: field.custom_field.listValues,
              filter_type: field.custom_field.field_type_id,
            };
          })
        : [];
    this.setState({ driver, loading: false, custom_fields: finalList });
    this.props.getAllCustomValues(customValues);
  };
  setCustomFieldsState = (customfields: FieldArray[]) => {
    this.setState({
      custom_fields: customfields,
    });
  };
  render() {
    const { edit, loading, custom_fields } = this.state;
    const { customValues, allCustomFields } = this.props;

    // const finalList =
    //   allCustomFields && customValues
    //     ? customValues.map(field => {
    //         const slug = allCustomFields
    //           .filter(
    //             allcustomFields => allcustomFields.id === field.custom_field_id
    //           )
    //           .map(obj => {
    //             return {
    //               display: obj.display,
    //               slug: obj.slug
    //             };
    //           })[0];

    //         return {
    //           field: field.id,
    //           value: field.value,
    //           custom_field_id: field.custom_field_id,
    //           label: slug.display,
    //           slug: slug.slug
    //         };
    //       })
    //     : [];
    // this.setCustomFieldsState(finalList);
    // console.log("FINAL LIST", finalList);
    const {
      history: { goBack },
    } = this.props;
    if (loading) {
      return <Spinner />;
    }
    return (
      <Container>
        <Div>
          <Horizontal onClick={goBack}>
            <MdChevronLeft color={PRIMARY_GRADIENT_FROM} size={42} />
            {/* <img src={arrowLeft} color={PRIMARY_GRADIENT_FROM} /> */}
            <ReturnText>GO BACK</ReturnText>
          </Horizontal>
          <Formik
            initialValues={{
              first_name: this.state.driver.first_name,
              last_name: this.state.driver.last_name,
              color: this.state.driver.color,
              // email: this.state.driver.email,
              language: this.state.driver.language,
              is_active: this.state.driver.is_active,
              custom_fields: this.state.custom_fields,
              password: this.state.driver.password,
              transportation_type: this.state.driver.transportation_type,
            }}
            validationSchema={editDriverSchema}
            onSubmit={async (values, { setFieldError, setStatus }) => {
              const { token } = this.props;
              setStatus({ error: "", loading: true });
              const response = await api.editDriver(
                {
                  ...values,
                  id: parseInt(this.props.match.params.id),
                },
                token
              );
              values.custom_fields.forEach((field) => {
                if (!!field.field) {
                  api.updateCustomFieldValue(
                    { id: field.field, value: field.value as string },
                    token
                  );
                }
                if (!field.field && field.value) {
                  api.createCustomFieldValue(
                    {
                      custom_field_id: field.custom_field_id,
                      entity_id: parseInt(this.props.match.params.id),
                      value: field.value,
                    },
                    token
                  );
                }
              });

              this.setState(
                (prev) => ({ driver: { ...prev.driver, ...response } }),
                () => {
                  this.toggleEdit();
                  setStatus({ loading: false });
                }
              );
              this.setState({
                custom_fields: values.custom_fields,
              });
            }}
          >
            {({
              status,
              setStatus,
              submitForm,
              values,
            }: FormikProps<MyFormikProps>) => {
              return (
                <Main>
                  <Spread>
                    <Horizontal>
                      <ThisColorItem
                        color={this.state.driver.color}
                        selected={false}
                      />
                      <NameText>{`${this.state.driver.first_name.toUpperCase()} ${this.state.driver.last_name.toUpperCase()}`}</NameText>
                    </Horizontal>
                    <Horizontal>
                      {edit ? (
                        <>
                          <CancelText clickable onClick={this.toggleEdit}>
                            Cancel
                          </CancelText>
                          <SaveButton type="submit" onClick={submitForm}>
                            Save
                          </SaveButton>
                        </>
                      ) : (
                        <>
                          <SecondaryButton
                            style={{ width: "130px" }}
                            onClick={this.toggleEdit}
                            type="button"
                          >
                            Edit Driver
                          </SecondaryButton>
                          <DeleteButton
                            onClick={this.deleteDriver}
                            style={{ width: "130px", marginLeft: "12px" }}
                            type="button"
                          >
                            Delete Driver
                          </DeleteButton>
                        </>
                      )}
                    </Horizontal>
                  </Spread>
                  <ThisHR />
                  <Grid>
                    <SectionTitle style={{ alignSelf: "center" }}>
                      SYSTEM PROPERTIES
                    </SectionTitle>
                    {edit ? (
                      <>
                        <InputField
                          style={{
                            gridRow: "2 / span 1",
                            gridColumn: "1 / span 1",
                          }}
                          name="first_name"
                          placeholder="First name"
                          title="First name"
                        />
                        <InputField
                          style={{
                            gridRow: "2 / span 1",
                            gridColumn: "2 / span 1",
                          }}
                          name="last_name"
                          placeholder="Last name"
                          title="Last name"
                        />
                        {/* <InputField
                          style={{
                            gridRow: "2 / span 1",
                            gridColumn: "3 / span 1"
                          }}
                          name="email"
                          placeholder="Email"
                          title="Email"
                        /> */}
                        <SelectField
                          name="language"
                          options={languages}
                          placeholder="Select Language"
                          title="Language"
                          style={{
                            gridRow: "3 / span 1",
                            gridColumn: "1 / span 1",
                          }}
                        />
                        <SelectField
                          name="transportation_type"
                          options={transportation}
                          placeholder="Select vehicle mode"
                          title="Transportation"
                          style={{
                            gridRow: "3 / span 1",
                            gridColumn: "2 / span 1",
                          }}
                        />
                        <SwitchField
                          style={{
                            gridRow: "4 / span 1",
                            gridColumn: "2 / span 1",
                          }}
                          name={"is_active"}
                          title={"Active"}
                        />
                        <div
                          style={{
                            gridRow: "4 / span 1",
                            gridColumn: "3 / span 1",
                            display: "flex",
                            flexDirection: "row",
                          }}
                        >
                          <ColorSelectField name="color" title="Color" />
                          <CustomColorDiv onClick={this.toggleCustomColor}>
                            <ColorBox backgroundColor={values.color} />
                            <div
                              style={{
                                margin: "0px 3px",
                              }}
                            />
                            <RegularText>Custom</RegularText>
                          </CustomColorDiv>
                          {this.state.toggleCustomColorPicker && (
                            <CustomSelectColorDiv>
                              <CustomColorSelectField
                                name="color"
                                title=""
                                close={this.toggleCustomColor}
                              />
                            </CustomSelectColorDiv>
                          )}
                        </div>
                        <InputField
                          style={{
                            gridRow: "5 / span 1",
                            gridColumn: "1 / span 1",
                          }}
                          name="password"
                          placeholder=""
                          type="password"
                          title="Password"
                        />
                      </>
                    ) : (
                      <>
                        <FieldDetail
                          style={{
                            gridRow: "2 / span 1",
                            gridColumn: "1 / span 1",
                          }}
                          title="First name"
                          value={this.state.driver.first_name}
                        />
                        <FieldDetail
                          style={{
                            gridRow: "2 / span 1",
                            gridColumn: "2 / span 1",
                          }}
                          title="Last name"
                          value={this.state.driver.last_name}
                        />
                        {/* <FieldDetail
                          style={{
                            gridRow: "2 / span 1",
                            gridColumn: "3 / span 1"
                          }}
                          title="Email"
                          value={this.state.driver.email}
                        /> */}
                        <FieldDetail
                          style={{
                            gridRow: "3 / span 1",
                            gridColumn: "1 / span 1",
                          }}
                          title="Language"
                          value={this.state.driver.language}
                        />
                        <FieldDetail
                          style={{
                            gridRow: "3 / span 1",
                            gridColumn: "2 / span 1",
                          }}
                          title="Transportation Type"
                          value={this.state.driver.transportation_type}
                        />
                        <FieldDetail
                          style={{
                            gridRow: "4 / span 1",
                            gridColumn: "2 / span 1",
                          }}
                          title="Active"
                          value=""
                          type="children"
                        >
                          <Switch
                            on={
                              this.state.driver.is_active === 1 ? true : false
                            }
                            enabled={false}
                          />
                        </FieldDetail>
                      </>
                    )}
                    <FieldDetail
                      style={{
                        gridRow: "2 / span 1",
                        gridColumn: "3 / span 1",
                      }}
                      title="Email"
                      value={this.state.driver.email}
                    />

                    <FieldDetail
                      style={{
                        gridRow: "3 / span 1",
                        gridColumn: "3 / span 1",
                      }}
                      title="Date Created"
                      value={this.state.driver.created_at}
                    />
                    <FieldDetail
                      style={{
                        gridRow: "4 / span 1",
                        gridColumn: "1 / span 1",
                      }}
                      title="Last Modified Date"
                      value={this.state.driver.updated_at}
                    />

                    <ThisHR
                      style={{
                        gridRow: "6 / span 1",
                        gridColumn: "1 / span 3",
                      }}
                    />
                  </Grid>
                  <Grid>
                    <SectionTitle
                      style={{
                        alignSelf: "center",
                        gridRow: "1 / span 1",
                        gridColumn: "1 / span 1",
                      }}
                    >
                      CUSTOM PROPERTIES
                    </SectionTitle>
                    <EmptyDiv
                      style={{
                        gridRow: "1 / span 1",
                        gridColumn: "2 / span 1",
                      }}
                    />
                    <EmptyDiv
                      style={{
                        gridRow: "1 / span 1",
                        gridColumn: "3 / span 1",
                      }}
                    />
                    {edit ? (
                      <>
                        <FieldArray
                          name="custom_fields"
                          render={(arrayHelpers) => (
                            <>
                              {values.custom_fields.map((fields, index) => {
                                if (fields.filter_type === 3) {
                                  const optionItems = fields.listValues.map(
                                    (values) => {
                                      return {
                                        label: values.value,
                                        data: values.value,
                                        value: values.value,
                                      };
                                    }
                                  );
                                  return (
                                    <div key={fields.custom_field_id}>
                                      <SelectField
                                        name={`custom_fields[${index}].value`}
                                        placeholder={
                                          fields.value ? fields.value : ""
                                        }
                                        options={optionItems}
                                        title={fields.label}
                                      />
                                    </div>
                                  );
                                }
                                if (fields.filter_type === 6) {
                                  return (
                                    <div key={fields.custom_field_id}>
                                      <ValueText>{fields.label}</ValueText>
                                      <CheckboxField
                                        title={``}
                                        name={`custom_fields[${index}].value`}
                                      />
                                    </div>
                                  );
                                }
                                return (
                                  <div key={fields.custom_field_id}>
                                    <InputField
                                      name={`custom_fields[${index}].value`}
                                      placeholder={
                                        fields.value ? fields.value : ""
                                      }
                                      title={fields.label}
                                    />
                                  </div>
                                );
                              })}
                            </>
                          )}
                        />
                      </>
                    ) : (
                      <>
                        {custom_fields.length > 0
                          ? custom_fields.map((values) => {
                              if (values.filter_type === 6) {
                                return (
                                  <FieldDetail
                                    title={values.label}
                                    value={values.value ? "true" : "false"}
                                  />
                                );
                              }
                              return (
                                <FieldDetail
                                  title={values.label}
                                  value={values.value ? values.value : ""}
                                />
                              );
                            })
                          : null}
                      </>
                    )}
                  </Grid>
                </Main>
              );
            }}
          </Formik>
        </Div>
      </Container>
    );
  }
}

const Div = styled.div`
  display: flex;
  flex-direction: column;
`;

const Main = styled(Div)`
  margin-left: 90px;
`;

const Spread = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-end;
`;

const Grid = styled.div`
  display: grid;
  grid-template-rows: min-content 50px 50px 50px min-content;
  grid-template-columns: 400px 400px 400px;
  row-gap: 25px;
`;

const ValueText = styled(AppText)`
  opacity: 0.5;
  font-size: 14px;
  font-weight: 500;
  color: #3b3a3a;
  margin-bottom: 7px;
`;
const Horizontal = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: min-content;
  white-space: nowrap;
  :hover {
    cursor: pointer;
  }
`;

const CancelText = styled(AppText)`
  font-size: 13px;
  font-weight: 500;
  color: #3b3a3a;
  margin-right: 65px;
`;

const DeleteButton = styled(Button)`
  background-color: #c4183c;
`;

const SaveButton = styled(Button)`
  width: 130px;
  height: 40px;
`;

const ThisColorItem = styled(ColorItem)`
  height: 24px;
  width: 24px;
  margin: 0;
`;

const NameText = styled(AppText)`
  opacity: 0.9;
  font-size: 18px;
  font-weight: 500;
  letter-spacing: 0.2px;
  color: #3b3a3a;
  margin-left: 17px;
`;
const EmptyDiv = styled.div``;

const CustomSelectColorDiv = styled.div`
  position: fixed;
  top: 50%;
  left: 50%;
  z-index: 4;
`;

const CustomColorDiv = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  border-radius: 2px;
  border: solid 1px rgba(59, 58, 58, 0.25);
  background-color: #ffffff;
  height: 40px;
  padding: 0 5px;
  margin-top: 26px;
  margin-left: 10px;
  :hover {
    cursor: pointer;
  }
`;

const ReturnText = styled(AppText)`
  font-size: 15px;
  font-weight: 600;
  letter-spacing: 0.3px;
  color: ${(props) => props.theme.PRIMARY_GRADIENT_FROM};
  margin-left: 17px;
`;

const SectionTitle = styled(AppText)`
  font-size: 15px;
  font-weight: 900;
  color: #3b3a3a;
`;

const RegularText = styled(AppText)`
  font-size: 15px;
  font-weight: normal;
`;

const ThisHR = styled(HR)`
  margin: 15px 0 25px 0;
`;

interface LinkStateProps {
  token: string;
  customValues: CustomFieldByEntity[];
  allCustomFields: CustomFields[];
}
interface LinkDispatchProps {
  // toggleNewDriverModal: () => void;
  getAllCustomValues: (customValues: CustomFieldByEntity[]) => void;
}

const mapDispatchToProps = (
  dispatch: Dispatch<AppActions>
): LinkDispatchProps => ({
  // toggleNewDriverModal: bindActionCreators(toggleNewDriverModal, dispatch),
  getAllCustomValues: bindActionCreators(getDriverCustomFieldValues, dispatch),
});
// isAuthenticated is true if user_token exists in redux
const mapStateToProps = (state: AppState): LinkStateProps => ({
  token: state.auth.token as string,
  customValues: state.driverCustomValues as CustomFieldByEntity[],
  allCustomFields: state.customDriverFields as CustomFields[],
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(DriverDetail));
