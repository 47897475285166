import { FilterField, CustomDeliveryFieldFilterActionTypes } from "../types";

const customDeliveryFieldMenuReducerDefaultState: FilterField[] = [];

const CustomDeliveryFieldFilterMenuReducer = (
  state = customDeliveryFieldMenuReducerDefaultState,
  action: CustomDeliveryFieldFilterActionTypes
): FilterField[] => {
  switch (action.type) {
    case "ADD_DELIVERY_FIELD_FILTER_OPTION":
      return action.filters;
    case "LOGOUT":
      return customDeliveryFieldMenuReducerDefaultState;
    default:
      return state;
  }
};

export default CustomDeliveryFieldFilterMenuReducer;
