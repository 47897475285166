import * as React from "react";
import styled from "styled-components";
import { AppText, HR } from "../UI";
import { OrderType } from "../../types";
import { formatDefaultTimeToTime } from "../../utils";

interface OrderCardProps {
  first_name: string;
  last_name: string;
  id: number;
  recieved: string;
  address_1: string;
  city: string;
  state: string;
  zip: string;
  cases: number;
  selected?: boolean;
}

interface OrderCardState {}

class OrderCard extends React.Component<OrderCardProps, OrderCardState> {
  constructor(props: OrderCardProps) {
    super(props);
  }
  render() {
    const {
      first_name,
      last_name,
      recieved,
      address_1,
      city,
      state,
      zip,
      selected,
      id,
    } = this.props;
    console.log("ORDER CARD PROPS", this.props);
    return (
      <Div selected={selected ? selected : ""}>
        <InfoContainer>
          <DivHeader>
            <NameDiv>
              <NameText
                selected={selected ? selected : ""}
              >{`${first_name} ${last_name}`}</NameText>
            </NameDiv>
            <TimeDiv>
              <TimeText selected={selected ? selected : ""}>
                {recieved === "Manual"
                  ? recieved
                  : formatDefaultTimeToTime(recieved)}
              </TimeText>
            </TimeDiv>
          </DivHeader>
          <HR />
          <InfoDiv>
            <LabelText selected={selected ? selected : ""}>Address: </LabelText>
            <DetailText>{`${address_1}\n ${city}, ${state}, ${zip}`}</DetailText>
          </InfoDiv>
          <InfoDiv>
            <LabelText selected={selected ? selected : ""}>Order #: </LabelText>
            <DetailText>{`${id}`}</DetailText>
          </InfoDiv>
        </InfoContainer>
      </Div>
    );
  }
}

interface DivProps {
  selected: boolean | string;
}

const Div = styled.div<DivProps>`
  width: 220px;
  height: 120px;
  border-radius: 4px;
  border: solid 1px #efefef;
  transition: background-color 0.3s ease;
  background-color: ${(props) => (props.selected ? "#FF7F50" : "#ffffff")};
  margin: 4px 0px;
  box-shadow: 2px 1px 3px 0 rgba(0, 0, 0, 0.38);
`;
const DivHeader = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-content: center;
`;

const InfoDiv = styled.div`
  display: flex;
  flex-direction: row;
  margin: 2px 0px;
`;

const NameDiv = styled.div`
  width: 120px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;
const NameText = styled(AppText)<DivProps>`
  font-size: 12px;
  font-weight: 900;
  color: ${(props) => (props.selected ? "white" : "black")};
`;
const InfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 13px;
  margin-left: 18px;
  margin-right: 10px;
  margin-bottom: 10px;
`;
const LabelText = styled(AppText)<DivProps>`
  color: rgba(59, 58, 58, 0.5);
  font-size: 14;
  font-weight: normal;
  margin-right: 3px;
  color: ${(props) => (props.selected ? "white" : "black")};
`;
const TimeDiv = styled.div``;
const TimeText = styled(AppText)<DivProps>`
  font-size: 10;
  font-weight: normal;
  color: ${(props) => (props.selected ? "white" : "black")};
`;
const DetailText = styled(AppText)`
  font-size: 14;
  font-weight: normal;
`;
export default OrderCard;
