import { CustomFilters, CustomOrderFieldFilterActionTypes } from "../types";

const customOrderFieldFilterReducerDefaultState: CustomFilters[] = [
  { display: "ID", field: "id", visible: false },
  { display: "Address", field: "address", visible: true },
  { display: "Customer", field: "customer", visible: true },
  { display: "Customer ID", field: "customer_id", visible: true },
  { display: "Status", field: "status", visible: true },
  // { display: "Num Boxes", field: "num_boxes", visible: true },
  // { display: "Amount to Collect", field: "amount_to_collect", visible: true },
  // { display: "Description", field: "description", visible: true },
  { display: "Delivery Run ID", field: "delivery_run_id", visible: true },
  { display: "Notes", field: "notes", visible: true },
  { display: "Delivery Image", field: "delivery_image", visible: true },
  { display: "Minutes in Store", field: "minutes_in_store", visible: true },
  { display: "Minutes in Transit", field: "minutes_in_transit", visible: true },
  // { display: "Delivered", field: "delivered", visible: true },
  // { display: "Delivery Date", field: "delivery_date", visible: true },
  { display: "Created At", field: "created_at", visible: true },
  { display: "Updated At", field: "updated_at", visible: true },
  { display: "Deleted At", field: "deleted_at", visible: true },
  { display: "Completed", field: "completed", visible: true },
  { display: "Sequence Number", field: "sequence_number", visible: true },
  { display: "POS ID", field: "reference", visible: true },
  //Address
  //Customer
];

const customOrderFieldFilterReducer = (
  state = customOrderFieldFilterReducerDefaultState,
  action: CustomOrderFieldFilterActionTypes
): CustomFilters[] => {
  switch (action.type) {
    case "SET_ORDER_FIELD_FILTERS":
      return action.filters;
    case "ADD_ORDER_CUSTOM_FILTERS":
      return action.filters;
    case "ADD_ORDER_FIELD_FILTER":
      const addOrderState = state.map((obj) => {
        if (obj.field === action.filter) {
          return {
            display: obj.display,
            field: obj.field,
            visible: true,
          };
        } else {
          return {
            display: obj.display,
            field: obj.field,
            visible: obj.visible,
          };
        }
      }) as any;
      return addOrderState;
    case "REMOVE_ORDER_FIELD_FILTER":
      const removeOrderState = state.map((obj) => {
        if (obj.field === action.filter) {
          return {
            display: obj.display,
            field: obj.field,
            visible: false,
          };
        } else {
          return {
            display: obj.display,
            field: obj.field,
            visible: obj.visible,
          };
        }
      }) as any;
      return removeOrderState;
    case "DELETE_ORDER_FIELD_FILTER":
      return state.filter((filter) => filter.field !== action.filter);
    case "LOGOUT":
      return customOrderFieldFilterReducerDefaultState;
    default:
      return state;
  }
};

export default customOrderFieldFilterReducer;
