import { Modal, ModalActionTypes } from "../types";

const defaultModalReducerState: Modal = {
  showNewDriverModal: false,
  showNewOrderModal: false,
  showColumnSwitchModal: false,
  showDeliveryRunDetailModal: false
};

const modalReducer = (
  state = defaultModalReducerState,
  action: ModalActionTypes
): Modal => {
  console.log("action: ", action);
  switch (action.type) {
    case "TOGGLE_NEW_DRIVER_MODAL":
      return {
        ...state,
        showNewDriverModal: !state.showNewDriverModal
      };
    case "TOGGLE_NEW_ORDER_MODAL":
      return {
        ...state,
        showNewOrderModal: !state.showNewOrderModal
      };
    case "TOGGLE_COLUMN_SWITCH_MODAL":
      return {
        ...state,
        showColumnSwitchModal: !state.showColumnSwitchModal
      };
    case "TOGGLE_DELIVERY_RUN_DETAIL_MODAL":
      return {
        ...state,
        showDeliveryRunDetailModal: !state.showDeliveryRunDetailModal
      };
    case "LOGOUT":
      return defaultModalReducerState;
    default:
      return state;
  }
};

export default modalReducer;
