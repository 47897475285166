import {
  OrderType,
  DeliveryDriver,
  DeliveryRuns,
  CreateDeliveryRun,
  CreateDeliveryRunActionTypes,
} from "../types";
import { Drivers } from "../Components/Pages";
import { formatCurrentTime, formatCurrentDate } from "../utils/format";
import moment from "moment";

const createDeliveryRunReducerDefaultState: CreateDeliveryRun = {
  //mapOrders temporary static list of orders to render = nothing mutates here
  mapOrders: [] as OrderType[],
  orders: [] as OrderType[],
  drivers: [] as DeliveryDriver[],
  orderList: [] as number[],
  mapView: false,
  avgLoc: [0, 0],
  initialLoad: true,
  deliveryRunLoad: true,
  selectMode: false,
};

const createDeliveryRunReducer = (
  state = createDeliveryRunReducerDefaultState,
  action: CreateDeliveryRunActionTypes
) => {
  switch (action.type) {
    case "ADD_DELIVERY_RUN_ORDERS":
      const currentOrders = state.orders;
      return {
        ...state,
        mapOrders: action.orders,
        orders: action.orders
          .filter((orders) => orders.status === "Not Assigned")
          .map((orders) => {
            return {
              ...orders,
              selected: false,
            };
          })
          .concat(currentOrders) as OrderType[],
      };
    case "ADD_DELIVERY_RUN_DRIVERS":
      if (state.initialLoad) {
        return {
          ...state,
          drivers: action.drivers,
          initialLoad: false,
        };
      }
      return {
        ...state,
        drivers: state.drivers,
      };
    case "UPDATE_EXISTING_DELIVERY_RUNS":
      if (state.deliveryRunLoad) {
        return {
          ...state,
          drivers: state.drivers.map((driver) => {
            if (driver.id === action.delivery_runs.driver_id) {
              const existingDeliveryRun = {
                num_deliveries: action.delivery_runs.num_deliveries,
                driver_id: action.delivery_runs.driver_id,
                delivery_id: action.delivery_runs.id,
                id: `${driver.first_name}_${driver.last_name}_${action.delivery_runs.id}`,
                route_selection_method:
                  action.delivery_runs.route_selection_method,
                status: action.delivery_runs.status,
                label: `${action.delivery_runs.status} run ${action.delivery_runs.id}`,
                schedule_date: action.delivery_runs.scheduled_start_time.split(
                  " "
                )[0],
                schedule_day: action.delivery_runs.scheduled_start_time.split(
                  " "
                )[1],
                new_delivery: false,
                inactive:
                  action.delivery_runs.status !== "not scheduled"
                    ? true
                    : false,
                deliveries: action.delivery_runs.deliveries.map((delivery) => {
                  return {
                    id: delivery.order_id,
                    driver_id: action.delivery_runs.driver_id,
                    customer_id: delivery.customer.id,
                    address_id: delivery.customer.address_id,
                    status: action.delivery_runs.status,
                    num_boxes: delivery.num_boxes,
                    amount_to_collect: delivery.amount_to_collect,
                    description: "",
                    notes: "",
                    created_at: "",
                    updated_at: "",
                    deleted_at: "",
                    reference: "",
                    date_imported: null,
                    address_relevance: 1,
                    delivered: "",
                    delivery_date: "",
                    address: delivery.customer.address,
                    customer: {
                      address_id: delivery.customer.address_id,
                      created_at: "",
                      deleted_at: null,
                      email: delivery.customer.email,
                      first_name: delivery.customer.first_name,
                      id: delivery.customer.id,
                      last_name: delivery.customer.last_name,
                      phone: delivery.customer.phone,
                      phone_type: delivery.customer.phone_type,
                      subscription_price: delivery.customer.subscription_price,
                      updated_at: "",
                    },
                    selected: false,
                  };
                }),
              };
              return {
                ...driver,
                deliveryRuns: [...driver.deliveryRuns, existingDeliveryRun],
              };
            }
            return driver;
          }),
        };
      }
      return {
        ...state,
        drivers: state.drivers,
      };
    case "ADD_NEW_DELIVERY_RUN":
      // New Delivery Run to append to deliveryRuns
      const newDeliveries = [] as OrderType[];
      state.orders.forEach((orders) => {
        if (action.orderList.includes(orders.id)) {
          newDeliveries.push(orders);
        }
      });

      return {
        ...state,
        orderList: [],
        orders: state.orders.filter(
          (orders) => !action.orderList.includes(orders.id)
        ),
        drivers: state.drivers.map((drivers) => {
          if (drivers.id === action.driverID) {
            const newDeliveryRun = {
              driver_id: drivers.id,
              delivery_id: null,
              id: `New_${drivers.first_name}_${drivers.last_name}_${
                drivers.deliveryRuns.length + 1
              }`,
              route_selection_method: "optimized",
              status: "Active",
              label: `Delivery Run ${drivers.deliveryRuns.length + 1}`,
              deliveries: newDeliveries,
              schedule_date: formatCurrentDate(),
              schedule_day: formatCurrentTime(),
              inactive: false,
              new_delivery: true,
            };
            return {
              id: drivers.id,
              first_name: drivers.first_name,
              last_name: drivers.last_name,
              deliveryRuns: [...drivers.deliveryRuns, newDeliveryRun],
            };
          }
          return drivers;
        }) as DeliveryDriver[],
      };
    // const newDeliveryRun = state.drivers.forEach(driver => {
    //   if(action.driverID === driver.id){
    //     const newDeliveryOrder = [] as OrderType[];
    //     state.orders.forEach(orders => {
    //       if(action.orders.includes(orders.id)){
    //         newDeliveryOrder.push(orders)
    //       }
    //     })
    //   }
    // })
    // return {
    //   ...state,
    //   drivers: [...state.drivers, ]
    // };
    case "ADD_TO_DELIVERY_RUN":
      const newDeliveryRunOrders = [] as OrderType[];
      state.orders.forEach((orders) => {
        if (action.orderList.includes(orders.id)) {
          newDeliveryRunOrders.push(orders);
        }
      });

      return {
        ...state,
        orderList: [],
        orders: state.orders.filter(
          (orders) => !action.orderList.includes(orders.id)
        ),
        drivers: state.drivers.map((drivers) => {
          if (drivers.id === action.driverID) {
            const mappedDeliveries = drivers.deliveryRuns.map(
              (deliveryRuns) => {
                if (deliveryRuns.id === action.deliveryRunID) {
                  return {
                    ...deliveryRuns,
                    deliveries: deliveryRuns.deliveries.concat(
                      newDeliveryRunOrders
                    ),
                  };
                }
                return deliveryRuns;
              }
            );

            return {
              id: drivers.id,
              first_name: drivers.first_name,
              last_name: drivers.last_name,
              deliveryRuns: mappedDeliveries,
            };
          }
          return drivers;
        }) as DeliveryDriver[],
      };
    case "REMOVE_FROM_DELIVERY_RUN":
      return {
        ...state,
        drivers: state.drivers.map((driver) => {
          if (driver.id === action.driver_id) {
            return {
              ...driver,
              deliveryRuns: driver.deliveryRuns.map((deliveryRun) => {
                if (deliveryRun.id === action.delivery_run_id) {
                  const filteredDeliveryRunOrders = deliveryRun.deliveries.filter(
                    ({ id: id1 }) =>
                      !action.orders.some(({ id: id2 }) => {
                        return id2 === id1;
                      })
                  );

                  return {
                    ...deliveryRun,
                    deliveries: filteredDeliveryRunOrders,
                  };
                }
                return deliveryRun;
              }),
            };
          }
          return driver;
        }),
      };
    case "UPDATE_DELIVERY_RUN_SCHEDULE":
      return {
        ...state,
        drivers: state.drivers.map((driver) => {
          if (driver.id === action.driver_id) {
            return {
              ...driver,
              deliveryRuns: driver.deliveryRuns.map((deliveryRun) => {
                if (deliveryRun.id === action.delivery_run_id) {
                  return {
                    ...deliveryRun,
                    schedule_date: action.date,
                    schedule_day: action.time,
                    inactive: action.inactive,
                  };
                }
                return deliveryRun;
              }),
            };
          }
          return driver;
        }),
      };
    case "REMOVE_DELIVERY_RUN_ORDER":
      return {
        ...state,
        orders: state.orders.filter(
          (orders) => !action.orders.includes(orders.id)
        ),
      };
    case "UPDATE_MAP_VIEW_STATE":
      return {
        ...state,
        mapView: !state.mapView,
      };
    case "CLEAR_DELIVERY_RUN_ORDERS":
      return createDeliveryRunReducerDefaultState;
    case "DELIVERY_RUNS_LOADED":
      return {
        ...state,
        deliveryRunLoad: false,
      };
    case "UPDATE_AVG_LOCATION":
      return {
        ...state,
        avgLoc: action.location,
      };
    case "UPDATE_SELECTED_DELIVERY_RUN_ORDERS":
      return {
        ...state,
        orders: state.orders.map((order) => {
          if (action.orderID === order.id) {
            return {
              ...order,
              selected: !order.selected,
            };
          }
          return order;
        }),
      };
    case "ADD_TO_SELECTED_ORDERS":
      return {
        ...state,
        orderList: [...state.orderList, action.orderID],
      };
    case "DELETE_FROM_SELECTED_ORDERS":
      return {
        ...state,
        orderList: state.orderList.filter((list) => list !== action.orderID),
      };
    case "UPDATE_SELECT_STATE":
      return {
        ...state,
        selectMode: !state.selectMode,
      };
    case "ADD_TO_SELECTED_ORDERS":
      return {
        ...state,
        orderList: [...state.orderList, action.orderID],
      };
    case "DELETE_FROM_SELECTED_ORDERS":
      return {
        ...state,
        orderList: state.orderList.filter((list) => list !== action.orderID),
      };
    case "UPDATE_SELECT_STATE":
      return {
        ...state,
        selectMode: !state.selectMode,
      };
    case "LOGOUT":
      return createDeliveryRunReducerDefaultState;
    default:
      return state;
  }
};

export default createDeliveryRunReducer;
