import * as React from "react";
import { connect } from "react-redux";
import { Formik, FormikProps, Form } from "formik";
import { withRouter, RouteComponentProps } from "react-router";
import { Link } from "react-router-dom";
import * as Yup from "yup";
import styled from "styled-components";
import { Dispatch, bindActionCreators } from "redux";
import { InputField } from "../form";
import {
  AuthContainer,
  Button,
  Spinner,
  Card,
  AppText,
  Icon,
  AppTextRoman,
} from "../UI";
import { AppActions } from "../../types";
import { logo } from "../../img";
import { AuthError } from "../Dumb";
import { PRIMARY_COLOR, resetPassWord } from "../../utils";
import { api } from "../../api/api";
import { ResetPasswordResponse } from "../../api/responseTypes";

const signupSchema = Yup.object().shape({
  email: Yup.string().email("* Invalid email").required("* Email is required"),
  emailConfirm: Yup.string()
    .oneOf([Yup.ref("email"), null], "* Email does not match")
    .required("* You must confirm your email"),
});

interface ResetPasswordProps {}

interface ResetPasswordState {}

type Props = RouteComponentProps & ResetPasswordProps & LinkDispatchProps;

interface MyFormikProps {
  email: string;
  emailConfirm: string;
}

class ResetPassword extends React.Component<Props, ResetPasswordState> {
  render(): JSX.Element {
    return (
      <Formik
        initialValues={{
          email: "",
          emailConfirm: "",
        }}
        validationSchema={signupSchema}
        onSubmit={async (values, { setFieldError, setStatus }) => {
          const { emailConfirm } = values;
          try {
            const resetPassword = (await api.resetPassword({
              email: emailConfirm,
            })) as ResetPasswordResponse;
            if (resetPassword.success) {
              resetPassWord(resetPassword.success);
              setTimeout(() => {
                this.props.history.push("/signin");
              }, 3000);
            } else {
              resetPassWord(resetPassword.success);
            }
          } catch (error) {
            console.log(error);
          }
          setStatus({ error: "", loading: true });
          setStatus({ loading: false });
        }}
      >
        {({ status, setStatus }: FormikProps<MyFormikProps>) => {
          console.log("status: ", status);
          return (
            <Div>
              <Form>
                <LoginTitleText>RESET PASSWORD</LoginTitleText>
                <ThisCard>
                  <InputField name="email" placeholder="Email" />
                  <InputField name="emailConfirm" placeholder="Confirm email" />
                  <div>
                    {status && status.loading ? (
                      <Spinner />
                    ) : (
                      <Button type="submit">Submit</Button>
                    )}
                  </div>
                  <AuthError status={status} />
                  <Link to="/signin" style={{ textDecoration: "none" }}>
                    <ForgotPasswordText>Cancel</ForgotPasswordText>
                  </Link>
                </ThisCard>
              </Form>
            </Div>
          );
        }}
      </Formik>
    );
  }
}

const Div = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: inherit;
  height: 100%;
  width: 100%;
`;

const ForgotPasswordText = styled(AppText)`
  font-size: 12.6px;
  font-weight: 500;
  color: #989898;
  text-align: center;
`;

const LoginTitleText = styled(AppText)`
  font-size: 26px;
  font-weight: 500;
  text-align: center;
  color: ${PRIMARY_COLOR};
  margin-bottom: 32px;
`;

const ThisCard = styled(Card)`
  height: 294.6px;
  width: 300px;
  display: grid;
  grid-template-rows: 60px 60px 66px 58px auto;
  justify-items: center;
  border: solid 0.9px #d8d8d8;
`;

const ThisAuthContainer = styled(AuthContainer)`
  background: "#fff";
`;

interface LinkDispatchProps {}

const mapDispatchToProps = (
  dispatch: Dispatch<AppActions>
): LinkDispatchProps => ({});

export default connect(null, mapDispatchToProps)(withRouter(ResetPassword));
