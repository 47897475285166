import { api } from "./api";

import { getStore, updateStore } from "../actions/store";
import { UserStore } from "../types/store";

export const getStoreThunk = store_id => async (dispatch, getState) => {
  const token = getState().auth.token;
  try {
    const res: any = await api.getStore({ store_id }, token);
    const storeData = res;
    console.log("getStoreThunk data", storeData);
    const action = getStore(storeData);
    dispatch(action);
  } catch (err) {
    console.log(err);
  }
};

export const editStoreThunk = (fields: {
  store_id: number | null;
  name?: string;
  phone?: string;
  address_1?: string;
  city?: string;
  state?: string;
  zip?: string;
  requires_scanning?: number;
}) => async (dispatch, getState) => {
  const token = getState().auth.token;
  console.log("fields", fields);
  try {
    const res: any = await api.editStore(fields, token);
    console.log("edit store thunk", res.data);
    const userStore: UserStore = res.data;
    dispatch(updateStore(userStore));
  } catch (err) {
    console.log(err);
  }
};
