import * as React from "react";
import { connect } from "react-redux";
import styled from "styled-components";
import { Link, withRouter, RouteComponentProps } from "react-router-dom";
import { FaCog } from "react-icons/fa";
import DropdownMenu from "./DropdownMenu";
import { logo, authBackgroundImage, logoHeader } from "../../img";
import { Icon, AppText } from "../UI";

// * I would prefer to use a native box shadow rather than a hacked
// * div. I may try to change this later although not required.

interface HeaderProps {}
interface HeaderState {
  menuOpen: boolean;
}

type Props = RouteComponentProps<HeaderProps>;

class Header extends React.Component<Props, HeaderState> {
  constructor(props: Props) {
    super(props);

    this.state = {
      menuOpen: false,
    };
  }

  toggleMenu = () => this.setState((prev) => ({ menuOpen: !prev.menuOpen }));

  componentDidMount = () => {
    // * https://github.com/ReactTraining/react-router/issues/5251
    this.props.history.listen((location) => {
      if (location.pathname !== this.props.location.pathname) {
        this.props.location.pathname = location.pathname;
        this.forceUpdate();
      }
    });
  };

  render(): JSX.Element | null {
    const { menuOpen } = this.state;
    const {
      location: { pathname },
    } = this.props;
    if (
      pathname === "/signin" ||
      pathname === "/forgotpassword" ||
      pathname === "/onboarding" ||
      pathname === "/settings" ||
      pathname === "/settings/general_info" ||
      pathname === "/settings/my_communication" ||
      pathname === "/settings/customer_communication" ||
      pathname === "/settings/mobile_app" ||
      pathname === "/settings/custom_properties" ||
      pathname.includes("/order_barcode/")
    ) {
      return null;
    }
    return (
      <Div>
        <Main>
          <Link to="/" style={{ textDecoration: "none" }}>
            <LogoDiv>
              <Icon src={logoHeader} height={23} width={112} />
            </LogoDiv>
          </Link>
          <Link
            to="/dashboard"
            style={{
              textDecoration: "none",
              opacity: this.props.location.pathname === "/dashboard" ? 1 : 0.5,
            }}
          >
            <LinkText>DASHBOARD</LinkText>
          </Link>
          <Link
            to="/deliveryruns"
            style={{
              textDecoration: "none",
              opacity:
                this.props.location.pathname === "/deliveryruns" ? 1 : 0.5,
            }}
          >
            <LinkText>DELIVERY RUNS</LinkText>
          </Link>
          <Link
            to="/deliveries"
            style={{
              textDecoration: "none",
              opacity: this.props.location.pathname === "/deliveries" ? 1 : 0.5,
            }}
          >
            <LinkText>DELIVERIES</LinkText>
          </Link>
          <Link
            to="/orders"
            style={{
              textDecoration: "none",
              opacity: this.props.location.pathname === "/orders" ? 1 : 0.5,
            }}
          >
            <LinkText>ORDERS</LinkText>
          </Link>
          <Link
            to="/drivers"
            style={{
              textDecoration: "none",
              opacity: this.props.location.pathname === "/drivers" ? 1 : 0.5,
            }}
          >
            <LinkText>DRIVERS</LinkText>
          </Link>
          <MenuContainer>
            <ThisCog
              size={22}
              width={40}
              height={40}
              color="#fff"
              onClick={this.toggleMenu}
            />
            {menuOpen && (
              <DropdownMenu
                toggleMenu={this.toggleMenu}
                handleClickOutside={this.toggleMenu}
              />
            )}
          </MenuContainer>
        </Main>
      </Div>
    );
  }
}

const ThisCog = styled(FaCog)`
  :hover {
    cursor: pointer;
  }
  @media (max-width: 900px) {
    margin-bottom: 5px;
  }
`;

const Main = styled.div`
  background-image: linear-gradient(to left, #4ba5eb, #60999e);
  height: 60px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-right: 45px;
  padding-left: 45px;
  @media (max-width: 900px) {
    flex: 1;
    flex-direction: row;
    justify-content: space-around;
    flex-flow: row wrap;
  }
`;

const MenuContainer = styled.div`
  position: relative;
`;

const LinkText = styled(AppText)`
  opacity: 1;
  font-size: 16px;
  font-weight: 400;
  color: #ffffff;
  @media (max-width: 900px) {
    margin-left: 10px;
  }
`;

const Div = styled.div`
  position: fixed;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  z-index: 60;
`;

const LogoDiv = styled.div`
  padding: 12px 20px 15px 22px;
  margin-left: 32px;
  width: 120px;
  position: relative;
`;

export default connect()(withRouter(Header));
