import * as React from "react";
import { connect } from "react-redux";
import { Container, Spinner, AppText, PageLoadSpinner } from "../UI";
import {
  DynamicTable,
  ColumnType,
  SelectedMenuOption,
  ColumnColors,
  RowData,
} from "../Dumb";
import { DynamicTableContainer } from "../Dumb/DynamicTableContainer";
import { Query } from "react-apollo";
import gql from "graphql-tag";
import { AppState } from "../../store/configureStore";
import {
  AppActions,
  LoggedUser,
  ItemList,
  OrderType,
  CustomFieldsList,
  CustomFields,
  CustomFilters,
  FilterField,
  MenuItem,
} from "../../types";
import { Dispatch, bindActionCreators } from "redux";
import {
  toggleNewOrderModal,
  fetchOrderProperties,
  updateOrderInitialLoad,
  addOrderCustomFilters,
  updateOrderCustomListLoad,
  addOrderCustomFiltersField,
  updateDefaultOrderID,
  setOrderCustomFieldFilters,
} from "../../actions";
import FilterMenu from "../Segments/filter/FilterMenu";
import { api } from "../../api/api";
import { Order, OrderList } from "../../api/responseTypes";
import styled from "styled-components";
import NewOrder from "../modal/NewOrder";
import {
  addCreateDeliveryRunOrders,
  updateMapViewState,
  updateAvgLocation,
} from "../../actions/createDeliveryRun";
import CreateDeliveryRun from "./CreateDeliveryRun/CreateDeliveryRun";
import { avgLocation, getFilterType, debouncer, toastError } from "../../utils";

const EmptyGridDiv = styled.div`
  width: 100%;
  height: 200px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  border-width: 1px;
  border-style: solid;
  border-color: #000;
`;

const EmptyGrid = (
  <EmptyGridDiv>
    <AppText>You don't have any orders on this page</AppText>
  </EmptyGridDiv>
);
interface OrdersProps {
  store_id: number;
  token: string;
  showNewOrderModal: boolean;
}

interface OrdersState {
  orders: Order[];
  mapView: boolean;
  allOrdersMapView: boolean;
  loading: boolean;
  searchKeyword: string;
  currentPage: number;
  lastPage: number;
  limit: string;
  from: number;
  to: number;
  total: number;
  per_page: number;
  list_id: number | null;
  item_list: [] | ItemList[];
  last_page: number | null;
  lat: number;
  lng: number;
  customFilters: CustomFilters[];
  changeListLoad: boolean;
  exporting: boolean;
  scrollInverse: boolean;
}
export const titles_stub = [
  "Driver",
  "# Deliveries",
  "Status",
  "Start time",
  "ETA",
  "Route",
  "Date Created",
  "Date Last Modified",
];

export const order_field = [
  {
    label: "Status",
    value: "status",
    operator_type: "text",
    listValues: [] as any,
  },
  {
    label: "Num Boxes",
    value: "num_boxes",
    operator_type: "numeric",
    listValues: [] as any,
  },
  {
    label: "Amount to Collect",
    value: "amount_to_collect",
    operator_type: "numeric",
    listValues: [] as any,
  },
  {
    label: "Description",
    value: "description",
    operator_type: "text",
    listValues: [] as any,
  },
  {
    label: "Notes",
    value: "notes",
    operator_type: "text",
    listValues: [] as any,
  },
  {
    label: "Created At",
    value: "created_at",
    operator_type: "date",
    listValues: [] as any,
  },
  {
    label: "Updated At",
    value: "updated_at",
    operator_type: "date",
    listValues: [] as any,
  },
];

const list_stub = [
  {
    title: true,
    label: "STANDARD LISTS",
  },
  {
    title: false,
    label: "Active Orders",
  },
  {
    title: false,
    label: "Inactive Orders",
  },
  {
    title: false,
    label: "All Orders",
  },
  {
    title: true,
    label: "CUSTOM LISTS",
  },
  {
    title: false,
    label: "Candy",
  },
  {
    title: false,
    label: "Veggies",
  },
  {
    title: false,
    label: "Fruits",
  },
];
const colors_stub: ColumnColors[] = [
  undefined,
  undefined,
  undefined,
  undefined,
  undefined,
  undefined,
  undefined,
  undefined,
];

type Props = OrdersProps & OrdersState & LinkDispatchProps & LinkStateProps;

class Orders extends React.Component<Props, OrdersState> {
  constructor(props: Props) {
    super(props);
    this.state = {
      orders: [],
      mapView: false,
      allOrdersMapView: false,
      loading: true,
      searchKeyword: "",
      currentPage: 1,
      lastPage: 1,
      from: 1,
      to: 10,
      total: 0,
      per_page: 10,
      list_id: null,
      item_list: [],
      limit: "10",
      last_page: null,
      lat: 0,
      lng: 0,
      customFilters: [],
      changeListLoad: false,
      exporting: false,
      scrollInverse: false,
    };
  }

  componentDidMount = async () => {
    const {
      store_id,
      token,
      fetchOrderProperties,
      addOrderCustomFiltersField,
      setOrderCustomFieldFilters,
    } = this.props;
    const propFilter = this.props.orderFilters
      ? this.props.orderFilters.map((field) => field.display)
      : [];
    this.setState({ loading: true });

    const orderColumnFilters = await api.getColumnFilters({
      entity: "Order",
      store_id: store_id,
      token: token,
    });
    console.log("orderCOLUMNFILTERS", orderColumnFilters);
    setOrderCustomFieldFilters(orderColumnFilters.fields);

    //initial get customfields
    //TODO need to check what fetchProperties does

    const initialCustomFields = (await api.getCustomField(
      { store_id: store_id, entity_name: "Order" },
      token
    )) as CustomFieldsList;
    fetchOrderProperties(initialCustomFields.data);

    let customFilters = initialCustomFields.data
      ? initialCustomFields.data.map((obj) => {
          return {
            display: obj.display,
            field: obj.slug,
            visible: false,
            listValues: obj.listValues,
          };
        })
      : [];

    this.setState({
      customFilters: customFilters,
    });

    // const filtersToUse = this.state.customFilters.filter(
    //   (field) => !propFilter.includes(field.display)
    // );
    // this.addCustomList(filtersToUse, this.props.orderFilters);
    // append initialcustom fields to filter list
    let mappedOrderCustomFieldsFilter = initialCustomFields.data
      ? initialCustomFields.data.map((obj) => {
          return {
            label: obj.display,
            value: obj.slug,
            operator_type: getFilterType(obj.field_type_id) as string,
            listValues: obj.listValues,
          };
        })
      : [];
    const mappedOrderFieldsFilter = order_field.concat(
      mappedOrderCustomFieldsFilter
    );
    addOrderCustomFiltersField(mappedOrderFieldsFilter);

    //initial get all orders
    if (this.props.orderListLoaded) {
      const orders = (await api.getOrders(
        {
          store_id,
          page: 1,
          limit: "100",
          item_list: "today_orders",
        },
        token
      )) as OrderList;
      const itemList = (await api.getItemLists(
        { store_id: store_id, entity: "Order" },
        token
      )) as ItemList[];
      console.log("initial orders ---", orders.data);
      this.setState({
        orders: orders.data,
        currentPage: orders.current_page,
        lastPage: orders.last_page,
        loading: false,
        from: orders.from,
        to: orders.to,
        total: orders.total,
        per_page: orders.per_page,
        last_page: orders.last_page,
        item_list: itemList,
      });
    }
    //get orders
    // const orders = (await api.getOrders(
    //   { store_id, page: 1, limit: this.state.limit },
    //   token
    // )) as OrderList;
    // console.log("BOTTOM ORDERS ------", orders.data);
    // const itemList = (await api.getItemLists(
    //   { store_id: store_id, entity: "Order" },
    //   token
    // )) as ItemList[];
    // this.setState({
    //   orders: orders.data,
    //   currentPage: orders.current_page,
    //   loading: false,
    //   from: orders.from,
    //   to: orders.to,
    //   total: orders.total,
    //   per_page: orders.per_page,
    //   item_list: itemList
    // });
  };

  componentDidUpdate = async (prevProps, prevState) => {
    const { loading } = this.state;
    const { store_id, token, orderListID } = this.props;
    if (this.props !== prevProps) {
      let orders = {} as OrderList;
      if (orderListID === "today_orders") {
        orders = (await api.getOrders(
          {
            store_id,
            page: 1,
            item_list: orderListID,
            limit: "100",
          },
          token
        )) as OrderList;
      } else {
        orders = (await api.getOrders(
          {
            store_id,
            page: 1,
            item_list: orderListID,
            limit: this.state.limit,
          },
          token
        )) as OrderList;
      }
      const itemList = (await api.getItemLists(
        { store_id: store_id, entity: "Order" },
        token
      )) as ItemList[];
      this.setState({
        orders: orders.data,
        currentPage: orders.current_page,
        lastPage: orders.last_page,
        loading: false,
        from: orders.from,
        to: orders.to,
        total: orders.total,
        per_page: orders.per_page,
        last_page: orders.last_page,
        item_list: itemList,
        changeListLoad: false,
      });
    }
    if (this.props.orderListID !== prevProps.orderListID) {
      //update orders on ordersListID change
      let orders = {} as OrderList;
      if (orderListID === "today_orders") {
        orders = (await api.getOrders(
          {
            store_id,
            page: 1,
            item_list: orderListID,
            limit: "100",
          },
          token
        )) as OrderList;
      } else {
        orders = (await api.getOrders(
          {
            store_id,
            page: 1,
            item_list: orderListID,
            limit: this.state.limit,
          },
          token
        )) as OrderList;
      }
      const itemList = (await api.getItemLists(
        { store_id: store_id, entity: "Order" },
        token
      )) as ItemList[];
      this.setState({
        orders: orders.data,
        currentPage: orders.current_page,
        lastPage: orders.last_page,

        loading: false,
        from: orders.from,
        to: orders.to,
        total: orders.total,
        per_page: orders.per_page,
        last_page: orders.last_page,
        item_list: itemList,
      });
    }
    if (this.props.custom_properties !== prevProps.custom_properties) {
      const intialCustomFields = (await api.getCustomField(
        { store_id: store_id, entity_name: "Order" },
        token
      )) as CustomFieldsList;
      let customFilters = intialCustomFields.data.map((obj) => {
        return {
          display: obj.display,
          field: obj.slug,
          visible: false,
        };
      });
      this.addCustomList(customFilters, this.props.orderFilters);
    }
    if (this.state.limit !== prevState.limit) {
      const orders = (await api.getOrders(
        { store_id, page: 1, limit: this.state.limit, item_list: orderListID },
        token
      )) as OrderList;
      // const itemList = (await api.getItemLists(
      //   { store_id: store_id, entity: "Driver" },
      //   token
      // )) as ItemList[];
      this.setState({
        orders: orders.data,
        currentPage: orders.current_page,
        lastPage: orders.last_page,

        loading: false,
        changeListLoad: false,
        from: orders.from,
        to: orders.to,
        total: orders.total,
        per_page: orders.per_page,
        last_page: orders.last_page,
        // item_list: itemList
      });
    }
  };

  onColumnSave = async (filters: CustomFilters[]) => {
    const { store_id, token, setOrderCustomFieldFilters } = this.props;
    const orderColumnFilters = await api.editColumns({
      entity: "Order",
      store_id: store_id,
      filters: filters,
      token: token,
    });

    setOrderCustomFieldFilters(orderColumnFilters.fields);
  };

  newOrder = (orders: Order) => {
    // this.setState(prev => ({ orders: [...prev.orders, orders] }));
  };

  changeListRenderState = () => {
    this.setState({
      changeListLoad: true,
    });
  };

  changeViewLimit = (limit: string) => {
    if (limit !== this.state.limit) {
      this.setState({
        limit: limit,
        changeListLoad: true,
      });
    }
  };

  changeList = () => {
    this.setState({
      changeListLoad: true,
    });
  };
  changeSearchText = (searchKeyword: string) => {
    this.setState({ searchKeyword });
    debouncer(this.searchOnDebounce, 500);
  };

  searchOnDebounce = async () => {
    const { store_id, token, orderListID } = this.props;
    const { limit, searchKeyword } = this.state;
    this.setState({
      changeListLoad: true,
    });

    const orders = (await api.getOrders(
      {
        store_id: store_id,
        page: 1,
        limit: limit,
        item_list: orderListID,
        searched_text: searchKeyword,
      },
      token
    )) as OrderList;

    this.setState({
      orders: orders.data,
      currentPage: orders.current_page,
      lastPage: orders.last_page,

      loading: false,
      from: orders.from,
      to: orders.to,
      total: orders.total,
      last_page: orders.last_page,
      changeListLoad: false,
    });
  };

  changeMapState = (viewAllOrders?: boolean) => {
    this.setState({
      mapView: !this.state.mapView,
      allOrdersMapView: viewAllOrders ? true : false,
    });
  };

  getOrdersFunc = () => {
    const { orders, searchKeyword, currentPage } = this.state;
    const { orderFilters } = this.props;

    const initialList = Array.isArray(orders) ? orders : [];
    // ? orders.filter(orders => {
    //     return Object.values(orders).filter(
    //       field =>
    //         !!field &&
    //         `${field}`.toLowerCase().includes(searchKeyword.toLowerCase())
    //     ).length;
    //   })
    // : [];
    let filter = [] as string[];
    orderFilters.forEach((obj) => {
      if (obj.visible === true) {
        filter.push(obj.field);
      }
      if (obj.field === "id") {
        filter.push(obj.field);
      }
    });
    const filteredOrder = (obj) => {
      let result = {};

      for (let key in obj) {
        if (filter.includes(key)) {
          result[key] = obj[key];
        }
      }
      return result;
    };
    const finalList = initialList.map((obj) => filteredOrder(obj)) as RowData[];
    return finalList;
  };

  updateOrders = async () => {
    const { store_id, token, orderListID } = this.props;
    //update drivers on driverListID change
    const orders = (await api.getOrders(
      { store_id, page: 1, item_list: orderListID, limit: this.state.limit },
      token
    )) as OrderList;
    const itemList = (await api.getItemLists(
      { store_id: store_id, entity: "Order" },
      token
    )) as ItemList[];
    this.setState({
      orders: orders.data,
      currentPage: orders.current_page,
      lastPage: orders.last_page,

      loading: false,
      from: orders.from,
      to: orders.to,
      total: orders.total,
      per_page: orders.per_page,
      last_page: orders.last_page,
    });
  };

  onInfiniteScrollFetch = async () => {
    const { store_id, token, orderListID } = this.props;
    const { currentPage } = this.state;

    const orders = (await api.getOrders(
      {
        store_id: store_id,
        page: currentPage + 1,
        limit: "100",
        item_list: orderListID,
      },
      token
    )) as OrderList;
    this.setState({
      orders: this.state.orders.concat(orders.data),
      currentPage: orders.current_page,
      lastPage: orders.last_page,
      loading: false,
      from: orders.from,
      to: orders.to,
      total: orders.total,
      last_page: orders.last_page,
      changeListLoad: false,
      scrollInverse: !this.state.scrollInverse,
    });
  };

  nextPage = async () => {
    const { store_id, token, orderListID } = this.props;
    const { to, total, currentPage, limit } = this.state;
    if (to < total) {
      this.setState({
        changeListLoad: true,
      });
      const orders = (await api.getOrders(
        {
          store_id: store_id,
          page: currentPage + 1,
          limit: this.state.limit,
          item_list: orderListID,
        },
        token
      )) as OrderList;
      this.setState({
        orders: orders.data,
        currentPage: orders.current_page,
        lastPage: orders.last_page,

        loading: false,
        from: orders.from,
        to: orders.to,
        total: orders.total,
        last_page: orders.last_page,
        changeListLoad: false,
      });
    }
  };

  prevPage = async () => {
    const { store_id, token, orderListID } = this.props;
    const { to, per_page, currentPage, limit, last_page } = this.state;

    if (to === null) {
      this.setState({
        changeListLoad: true,
      });
      const orders = (await api.getOrders(
        {
          store_id: store_id,
          page: last_page as number,
          limit: this.state.limit,
          item_list: orderListID,
        },
        token
      )) as OrderList;
      this.setState({
        orders: orders.data,
        currentPage: orders.current_page,
        lastPage: orders.last_page,

        loading: false,
        from: orders.from,
        to: orders.to,
        total: orders.total,
        last_page: orders.last_page,
        changeListLoad: false,
      });
    }
    if (to > per_page) {
      this.setState({
        changeListLoad: true,
      });
      const orders = (await api.getOrders(
        {
          store_id: store_id,
          page: currentPage - 1,
          limit: this.state.limit,
          item_list: orderListID,
        },
        token
      )) as OrderList;
      this.setState({
        orders: orders.data,
        currentPage: orders.current_page,
        lastPage: orders.last_page,

        loading: false,
        from: orders.from,
        to: orders.to,
        total: orders.total,
        last_page: orders.last_page,
        changeListLoad: false,
      });
    }
  };

  firstPage = async () => {
    const { store_id, token, orderListID } = this.props;
    const { limit } = this.state;
    this.setState({
      changeListLoad: true,
    });
    const orders = (await api.getOrders(
      {
        store_id: store_id,
        page: 1,
        limit: limit,
        item_list: orderListID,
      },
      token
    )) as OrderList;
    this.setState({
      orders: orders.data,
      currentPage: orders.current_page,
      lastPage: orders.last_page,
      loading: false,
      from: orders.from,
      to: orders.to,
      total: orders.total,
      last_page: orders.last_page,
      changeListLoad: false,
    });
  };

  lastPage = async () => {
    const { store_id, token, orderListID } = this.props;
    const { limit, last_page } = this.state;
    this.setState({
      changeListLoad: true,
    });
    const orders = (await api.getOrders(
      {
        store_id: store_id,
        page: last_page as number,
        limit: limit,
        item_list: orderListID,
      },
      token
    )) as OrderList;
    this.setState({
      orders: orders.data,
      currentPage: orders.current_page,
      lastPage: orders.last_page,
      loading: false,
      from: orders.from,
      to: orders.to,
      total: orders.total,
      last_page: orders.last_page,
      changeListLoad: false,
    });
  };

  addCustomList = (
    filters: CustomFilters[],
    defaultFilters: CustomFilters[]
  ) => {
    // let newDefaultFilter = [] as CustomFilters[];
    let defaultFilterMap = defaultFilters.map(
      (defaultFilter) => defaultFilter.field
    );
    let filterMap = filters.map((filter) => filter.field);

    let newDefaultFilter = defaultFilters.map((defaultFilter) => {
      if (
        defaultFilterMap.includes(defaultFilter.field) &&
        !filterMap.includes(defaultFilter.field)
      ) {
        return defaultFilter;
      } else {
        // filters.forEach(filter => {
        //   if (defaultFilter.field === filter.field) {
        //     if (defaultFilter.display === filter.display) {
        //       newDefaultFilter.push(defaultFilter);
        //     } else {
        //       newDefaultFilter.push(filter);
        //     }
        //   }
        // });
      }
    });

    const newFilter = filters.filter((filter) => {
      return !defaultFilterMap.includes(filter.field);
    });

    const finalFilterList = newDefaultFilter.concat(newFilter);
    if (this.props.orderCustomListLoaded) {
      this.props.addOrderCustomFilters(finalFilterList as CustomFilters[]);
    }
    this.props.updateOrderCustomListLoad();
  };

  renderNewListOrders = async (id: number) => {
    const { store_id, token, orderListID } = this.props;
    const orders = (await api.getOrders(
      { store_id, page: 1, item_list: id, limit: this.state.limit },
      token
    )) as OrderList;
    // console.log("drivers", drivers);
    this.setState({
      orders: orders.data,
      currentPage: orders.current_page,
      lastPage: orders.last_page,
      loading: false,
      from: orders.from,
      to: orders.to,
      total: orders.total,
      per_page: orders.per_page,
      last_page: orders.last_page,
    });
  };

  orderList = () => {
    let standardList = [{ title: true, label: "STANDARD LISTS" }] as MenuItem[];
    let customList = [
      {
        title: true,
        label: "CUSTOM LISTS",
      },
    ] as MenuItem[];
    //go through Item lists and append based on label
    this.state.item_list.forEach((item) => {
      //label tbd
      if (item.label === "All Orders") {
        standardList.push({
          title: false,
          label: item.label,
          id: item.id,
          filter_ids: item.filter_ids,
        });
        this.props.updateDefaultOrderID(item.id as number);
      } else if (
        item.label === "Today's Orders" ||
        item.label === "Unassigned Orders" ||
        item.label === "Assigned Orders" ||
        item.label === "Past Orders" ||
        item.label === "Orders created today"
      ) {
        standardList.push({
          title: false,
          label: item.label,
          id: item.code as string,
          filter_ids: "",
        });
      } else {
        customList.push({
          title: false,
          label: item.label,
          id: item.id,
          filter_ids: item.filter_ids,
        });
      }
    });
    return standardList.concat(customList);
  };

  exportOrders = async () => {
    const { store_id, orderListID, token } = this.props;

    this.setState({
      exporting: true,
    });
    const response = await api.exportOrders(store_id, orderListID, token);

    this.setState({
      exporting: false,
    });
  };

  isDefaultList = () => {
    const { orderListLabel } = this.props;
    if (
      orderListLabel === "" ||
      orderListLabel === "All Orders" ||
      orderListLabel === "Unassigned Orders" ||
      orderListLabel === "Assigned Orders" ||
      orderListLabel === "Past Orders" ||
      orderListLabel === "Orders created today" ||
      orderListLabel === "Today's Orders"
    ) {
      return true;
    }
    return false;
  };

  orderColumns = () => {
    const { orderFilters } = this.props;
    console.log("newOrderColumn", orderFilters);

    const newOrderColumn = orderFilters
      .filter((field) => !!field.visible)
      .sort((a, b) => (a.ordinal && b.ordinal ? a.ordinal - b.ordinal : 0))
      .map((filter) => filter.field);
    return newOrderColumn;
  };

  render() {
    const {
      loading,
      mapView,
      limit,
      currentPage,
      lastPage,
      scrollInverse,
    } = this.state;
    const {
      addCreateDeliveryRunOrders,
      updateMapViewState,
      mapViewState,
      updateAvgLocation,
      orderListID,
      store_id,
      token,
    } = this.props;
    const orders = this.getOrdersFunc();
    console.log("orders---", this.state);
    const massSelectionRequirement = "Need to select a order first";
    const itemList = this.state.item_list.length > 1 ? this.orderList() : [];
    const selected_options_stub: SelectedMenuOption[] = [
      {
        text: "Make Delivery Runs",
        onClick: (selected) => {
          if (selected.length > 0) {
            addCreateDeliveryRunOrders(selected as OrderType[]);
            const avgLoc = avgLocation(selected as OrderType[]);
            //needs to be updated to redux state to persist
            updateAvgLocation(avgLoc);
            this.changeMapState();
            updateMapViewState();
          } else {
            toastError(massSelectionRequirement);
          }
        },
        //click to go to MapView
      },
      {
        text: "View All Orders on Map",
        onClick: (selected) => {
          updateAvgLocation([this.props.store_lat, this.props.store_lng]);
          this.changeMapState(true);
          updateMapViewState();
        },
      },
      {
        text: "Delete",
        onClick: async (selected) => {
          const { store_id, token, orderListID } = this.props;
          const { currentPage } = this.state;
          if (selected.length > 0) {
            await api.massDeleteOrders(
              {
                orderIDs: selected as RowData[],
              },
              token
            );
            const orders = (await api.getOrders(
              {
                store_id,
                page: currentPage,
                limit: this.state.limit,
                item_list: orderListID,
              },
              token
            )) as OrderList;

            this.setState({
              orders: orders.data,
              currentPage: orders.current_page,
              lastPage: orders.last_page,
              loading: false,
              from: orders.from,
              to: orders.to,
              total: orders.total,
              per_page: orders.per_page,
              last_page: orders.last_page,
            });
          } else {
            toastError(massSelectionRequirement);
          }
        },
      },
    ];
    if (loading) {
      return (
        <Container>
          <Spinner />
        </Container>
      );
    }
    if (mapViewState) {
      return (
        <CreateDeliveryRun
          changeMapState={this.changeMapState}
          lat={this.state.lat}
          lng={this.state.lng}
          viewAllOrders={this.state.allOrdersMapView}
        />
      );
    }
    return (
      <Container style={{ marginTop: 1 }}>
        <NewOrder
          showModal={this.props.showNewOrderModal}
          toggleModal={this.props.toggleNewOrderModal}
          onComplete={this.newOrder}
        />
        {this.state.changeListLoad && <PageLoadSpinner />}
        <DynamicTable
          customButtonClick={this.props.toggleNewOrderModal}
          columnTitles={!!orders.length ? this.orderColumns() : []}
          // columnTypes={rowType_stub}
          // columnColors={colors_stub}
          // rowData={dataRow_stub}
          rowData={orders}
          selectedMenuOptions={selected_options_stub}
          selectedMenuLabel="Orders"
          currentPageFirstItemNumber={this.state.currentPage + 1}
          onNextClick={this.nextPage}
          onPrevClick={this.prevPage}
          onFirstClick={this.firstPage}
          onLastClick={this.lastPage}
          renderEmptyGrid={EmptyGrid}
          excludedColumns={["deleted_at"]}
          searchOnChange={this.changeSearchText}
          searchValue={this.state.searchKeyword}
          totalItemCount={this.state.orders.length}
          renderNewList={this.renderNewListOrders}
          from={this.state.from}
          to={this.state.to}
          total={this.state.total}
          updateList={this.updateOrders}
          changeViewLimit={this.changeViewLimit}
          limit={this.state.limit}
          currentPage={this.state.currentPage}
          list={itemList}
          fieldList={this.props.orderFilters}
          customFieldList={this.state.customFilters}
          currentList={this.props.orderListLabel}
          listID={this.props.orderListID}
          isDefaultList={this.isDefaultList()}
          exportCSVList={this.exportOrders}
          changeListRenderState={this.changeListRenderState}
          exporting={this.state.exporting}
          onColumnSave={this.onColumnSave}
          disableViewSelect={orderListID === "today_orders"}
          infiniteScrollProp={orderListID === "today_orders"}
          onInfiniteScrollFetch={this.onInfiniteScrollFetch}
          hasNextPage={currentPage < lastPage}
          scrollInverse={scrollInverse}
        />
      </Container>
    );
  }
}

interface LinkStateProps {
  showNewOrderModal: boolean;
  token: string;
  store_id: number;
  mapViewState: boolean;
  custom_properties: CustomFields[];
  orderListLabel: string;
  orderListLoaded: boolean;
  orderCustomListLoaded: boolean;
  orderListID: number | string;
  orderFilters: CustomFilters[];
  store_lat: number;
  store_lng: number;
}

interface LinkDispatchProps {
  toggleNewOrderModal: () => void;
  addCreateDeliveryRunOrders: (orders: OrderType[]) => void;
  updateMapViewState: () => void;
  updateAvgLocation: (location: number[]) => void;
  fetchOrderProperties: (property: CustomFields[]) => void;
  updateOrderInitialLoad: () => void;
  addOrderCustomFilters: (filter: CustomFilters[]) => void;
  setOrderCustomFieldFilters: (filter: CustomFilters[]) => void;
  updateOrderCustomListLoad: () => void;
  addOrderCustomFiltersField: (filter: FilterField[]) => void;
  updateDefaultOrderID: (id: number | string) => void;
}

const mapDispatchToProps = (
  dispatch: Dispatch<AppActions>
): LinkDispatchProps => ({
  toggleNewOrderModal: bindActionCreators(toggleNewOrderModal, dispatch),
  addCreateDeliveryRunOrders: bindActionCreators(
    addCreateDeliveryRunOrders,
    dispatch
  ),
  updateMapViewState: bindActionCreators(updateMapViewState, dispatch),
  updateAvgLocation: bindActionCreators(updateAvgLocation, dispatch),
  fetchOrderProperties: bindActionCreators(fetchOrderProperties, dispatch),
  updateOrderInitialLoad: bindActionCreators(updateOrderInitialLoad, dispatch),
  addOrderCustomFilters: bindActionCreators(addOrderCustomFilters, dispatch),
  updateOrderCustomListLoad: bindActionCreators(
    updateOrderCustomListLoad,
    dispatch
  ),
  addOrderCustomFiltersField: bindActionCreators(
    addOrderCustomFiltersField,
    dispatch
  ),
  setOrderCustomFieldFilters: bindActionCreators(
    setOrderCustomFieldFilters,
    dispatch
  ),
  updateDefaultOrderID: bindActionCreators(updateDefaultOrderID, dispatch),
});

// isAuthenticated is true if user_token exists in redux
const mapStateToProps = (state: AppState): LinkStateProps => ({
  showNewOrderModal: state.modal.showNewOrderModal,
  store_id: state.user.store_id as number,
  token: state.auth.token as string,
  mapViewState: state.createDeliveryRun.mapView,
  custom_properties: state.customOrderFields as CustomFields[],
  orderListLoaded: state.listViewIDs.orderListInitialLoad as boolean,
  orderListLabel: state.listViewIDs.orderLabel as string,
  orderListID: state.listViewIDs.orderListID as number,
  orderCustomListLoaded: state.customOrderFilterState
    .initialOrderCustomListLoad as boolean,
  orderFilters: state.customOrderFieldFilter as CustomFilters[],
  store_lat: state.store.address.latitude,
  store_lng: state.store.address.longitude,
});

export default connect(mapStateToProps, mapDispatchToProps)(Orders);
