import * as React from "react";
import styled from "styled-components";
import { Icon, Input } from ".";
import { searchIcon } from "../../img";
import { PRIMARY_COLOR } from "../../utils";

export interface SearchInputProps {
  onChange: (textFilter: string) => void;
  placeholder: string;
  value: string;
  onEnterPress?: () => void;
}

const SearchInput: React.FunctionComponent<SearchInputProps> = ({
  onChange,
  value,
  placeholder,
  onEnterPress
}) => {
  const onTextChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    event.preventDefault();
    onChange(event.target.value);
  };
  const onEnter = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Enter") {
      onEnterPress && onEnterPress();
    }
  };
  return (
    <Div>
      <Icon src={searchIcon} color={PRIMARY_COLOR} />
      <ThisInput
        placeholder={placeholder}
        onChange={onTextChange}
        value={value}
        onKeyDown={onEnter}
      />
    </Div>
  );
};

const Div = styled.div`
  width: 189px;
  border: solid 1px rgba(71, 70, 71, 0.26);
  padding-left: 12px;
  background-color: #fff;
  border-radius: 4px;
  height: 50px;
  display: flex;
  align-items: center;
`;

const ThisInput = styled(Input)`
  border-width: 0;
  outline: "none";
  width: 100%;
  height: 100%;
  box-shadow: 0px 0px 0px white;
  padding-left: 12px;
  :focus {
    outline: none;
    box-shadow: 0px 0px 0px white;
  }
`;

export { SearchInput };
