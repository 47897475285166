import React from "react";
import TimePicker from "rc-time-picker";
import "rc-time-picker/assets/index.css";
import styled from "styled-components";

interface CustomTimePickerProps {
  type?: string;
  event?: string;
  value?: any;
  onChange?: (value: any) => void;
  disabled?: boolean;
}

interface CustomTimePickerState {}

type Props = CustomTimePickerProps;

class CustomTimePicker extends React.Component<Props, CustomTimePickerState> {
  constructor(props) {
    super(props);
    this.state = {
      value: null
    };
  }
  render() {
    return (
      <StyledTimePicker
        placeholder={this.props.type === "AM" ? "HM:MM AM" : "HM:MM PM"}
        onChange={this.props.onChange}
        showSecond={false}
        disabled={this.props.disabled}
      />
    );
  }
}

export default CustomTimePicker;

const StyledTimePicker = styled(TimePicker)`
  width: 80px;
  & .rc-time-picker-input {
    color: #fff;
    background-color: transparent;
    border: none;
    opacity: 0.7;
  }

  & .rc-time-picker-input::placeholder {
    color: #fff;
    opacity: 0.95;
  }
  & .rc-time-picker-clear-icon:hover:after {
    color: #fff;
    opacity: 0.75;
  }
  & .rc-time-picker-clear-icon:after {
    color: #fff;
    opacity: 0.75;
  }
  & .rc-time-picker-input[disabled] {
    color: #fff;
    opacity: 0.75;
  }
  & li.rc-time-picker-panel-select-option-disabled {
    color: #fff;
    opacity: 0.75;
  }
`;
