import { CustomFilters, CustomDriverFieldFilterActionTypes } from "../types";

const customDriverFieldFilterReducerDefaultState: CustomFilters[] = [
  { display: "ID", field: "id", visible: false },
  // { display: "Store ID", field: "store_id", visible: true },
  // { display: "External Driver ID", field: "external_driver_id", visible: true },
  { display: "First Name", field: "first_name", visible: true },
  { display: "Last Name", field: "last_name", visible: true },
  { display: "Email", field: "email", visible: true },
  { display: "Is Active", field: "is_active", visible: true },
  { display: "Language", field: "language", visible: true },
  { display: "Color", field: "color", visible: true },
  { display: "Created At", field: "created_at", visible: true },
  { display: "Updated At", field: "updated_at", visible: true },
  { display: "Deleted At", field: "deleted_at", visible: true },
];

const customDriverFieldFilterReducer = (
  state = customDriverFieldFilterReducerDefaultState,
  action: CustomDriverFieldFilterActionTypes
): CustomFilters[] => {
  switch (action.type) {
    case "SET_DRIVER_FIELD_FILTERS":
      return action.filters;
    case "ADD_DRIVER_CUSTOM_FILTERS":
      return action.filters;
    case "ADD_DRIVER_FIELD_FILTER":
      const addDriverState = state.map((obj) => {
        if (obj.field === action.filter) {
          return {
            display: obj.display,
            field: obj.field,
            visible: true,
          };
        } else {
          return {
            display: obj.display,
            field: obj.field,
            visible: obj.visible,
          };
        }
      }) as any;
      return addDriverState;
    case "REMOVE_DRIVER_FIELD_FILTER":
      const removeDriverState = state.map((obj) => {
        if (obj.field === action.filter) {
          return {
            display: obj.display,
            field: obj.field,
            visible: false,
          };
        } else {
          return {
            display: obj.display,
            field: obj.field,
            visible: obj.visible,
          };
        }
      }) as any;
      return removeDriverState;
    case "DELETE_DRIVER_FIELD_FILTER":
      return state.filter((filter) => filter.field !== action.filter);
    case "LOGOUT":
      return customDriverFieldFilterReducerDefaultState;
    default:
      return state;
  }
};

export default customDriverFieldFilterReducer;
