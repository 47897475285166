import * as React from "react";
import { connect } from "react-redux";
import { Formik, FormikProps, Form } from "formik";
import * as Yup from "yup";
import { Dispatch, bindActionCreators } from "redux";
import { AppActions, Driver, Lists, Filter } from "../../types";
import { AppState } from "../../store/configureStore";
import {
  InputField,
  OnboardInputField,
  SwitchField,
  SelectField,
} from "../form";
import styled from "styled-components";
import { Button, Modal, AppText, HR, SecondaryButton } from "../UI";
import {
  addDriver,
  toggleNewDriverModal,
  updateDriverList,
  updateDriverListLabel,
  updateOrderList,
  updateOrderListLabel,
  updateDeliveryRunList,
  updateDeliveryRunListLabel,
  updateDeliveryList,
  updateDeliveryListLabel,
} from "../../actions";
import { PRIMARY_COLOR, returnEntity } from "../../utils";
import { ColorSelectField } from "../form/ColorSelectField";
import { languages } from "../../static";
import { Input } from "semantic-ui-react";
import { api } from "../../api/api";

const schema = Yup.object().shape({
  list_name: Yup.string().required("* List name is required"),
});

interface MyFormikProps {
  list_name: string;
}

interface NewListProps {
  showModal: boolean;
  toggleModal: () => void;
  renderNewList?: (id: number) => void;
  list_type: string;
  filters?: Filter[];
}

interface NewListState {}

class NewList extends React.Component<Props, NewListState> {
  dispatchFunctions = (id: number, label: string) => {
    const {
      updateDriverList,
      updateDriverListLabel,
      updateOrderList,
      updateOrderListLabel,
      updateDeliveryRunList,
      updateDeliveryRunListLabel,
      updateDeliveryList,
      updateDeliveryListLabel,
    } = this.props;

    switch (this.props.list_type) {
      case "Drivers":
        updateDriverList(id);
        updateDriverListLabel(label);
        break;
      case "Orders":
        updateOrderList(id);
        updateOrderListLabel(label);
        break;
      case "Delivery Runs":
        updateDeliveryRunList(id);
        updateDeliveryRunListLabel(label);
        break;
      case "Delivery Runs":
        updateDeliveryList(id);
        updateDeliveryListLabel(label);
        break;
      default:
        break;
    }
  };

  render(): JSX.Element {
    return (
      <Modal
        height="fit-content"
        width="fit-content"
        showModal={this.props.showModal}
        toggleModal={this.props.toggleModal}
        label="New List"
      >
        <Formik
          initialValues={{
            list_name: "",
          }}
          validationSchema={schema}
          onSubmit={async (values, { setFieldError, setStatus }) => {
            const { list_name } = values;
            const {
              store_id,
              token,
              renderNewList,
              updateDriverList,
              updateDriverListLabel,
              list_type,
            } = this.props;
            setStatus({ error: "", loading: true });
            try {
              const newList = (await api.createNewList(
                {
                  store_id: store_id,
                  entity_name: returnEntity(list_type as string),
                  label: list_name,
                  filter_ids: this.props.filters
                    ? this.props.filters.map((filter) => filter.id)
                    : [],
                },
                token as string
              )) as Lists;
              this.dispatchFunctions(newList.id, newList.label);
              // updateDriverList(newList.id);
              // updateDriverListLabel(newList.label as string);
              renderNewList ? renderNewList(newList.id) : null;
              this.props.toggleModal();
            } catch (e) {
              console.log("logging error: ", e);
              setStatus({ loading: false });
            }
            console.log("no error");
            setStatus({ loading: false });
          }}
        >
          {({
            status,
            touched,
            submitForm,
            errors,
            setFieldTouched,
            values,
          }: FormikProps<MyFormikProps>) => {
            return (
              <Form>
                <Div>
                  <Header>
                    <HeaderText>NEW LIST</HeaderText>
                  </Header>
                  <HR />
                  <Grid>
                    <InputField
                      style={{
                        gridColumn: "1 / span 1",
                        gridRow: "1 / span 1",
                      }}
                      name="list_name"
                      placeholder=""
                      title="List Name"
                      inputWidth="670px"
                    />
                  </Grid>
                  <Footer>
                    <CancelButton onClick={this.props.toggleModal}>
                      Cancel
                    </CancelButton>
                    <Button
                      width="130px"
                      type="submit"
                      style={{ justifySelf: "flex-end" }}
                      //anonymous to avoid running twice
                      onClick={() => submitForm}
                      disabled={!values.list_name}
                    >
                      Create List
                    </Button>
                  </Footer>
                </Div>
              </Form>
            );
          }}
        </Formik>
      </Modal>
    );
  }
}

const Grid = styled.div`
  display: grid;
  grid-template-columns: min-content min-content auto;
  grid-template-rows: 0px 0px 100px;
  column-gap: 24px;
  padding: 25px 40px 0 40px;
`;

const Div = styled.div`
  display: flex;
  flex-direction: column;
  border-top: solid 1px ${PRIMARY_COLOR};
  border-radius: 2px;
  overflow-x: none;
  width: 750px;
`;

const HeaderText = styled(AppText)`
  font-size: 18px;
  font-weight: 600;
  letter-spacing: 0.3px;
  color: #60999e;
`;

const Header = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height: 57px;
  padding: 0 40px 0 40px;
`;

const Footer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  height: 57px;
  padding: 0 40px 30px 40px;
`;

const CancelText = styled(AppText)`
  font-size: 13px;
  font-weight: 500;
  color: #3b3a3a;
  margin-right: 53.5px;
`;

const CancelButton = styled(Button)`
  height: 40px;
  width: 110px;
  border-radius: 4px;
  border: solid 1px rgba(71, 70, 71, 0.26);
  background-color: #fff;
  color: #000;
  margin-right: 21px;
`;

const StyledInputField = styled((props) => <InputField {...props} />)``;
type Props = NewListProps & LinkDispatchProps & LinkStateProps;

interface LinkStateProps {
  store_id: number | null;
  token: string | null;
}

interface LinkDispatchProps {
  addDriver: (driver: Driver) => void;
  toggleNewDriverModal: () => void;
  updateDriverList: (id: number) => void;
  updateDriverListLabel: (label: string) => void;
  updateOrderList: (id: number) => void;
  updateOrderListLabel: (label: string) => void;
  updateDeliveryRunList: (id: number) => void;
  updateDeliveryRunListLabel: (label: string) => void;
  updateDeliveryList: (id: number) => void;
  updateDeliveryListLabel: (label: string) => void;
}

const mapStateToProps = (state: AppState): LinkStateProps => ({
  store_id: state.user.store_id,
  token: state.auth.token,
});

const mapDispatchToProps = (
  dispatch: Dispatch<AppActions>
): LinkDispatchProps => ({
  addDriver: bindActionCreators(addDriver, dispatch),
  //TODO CHANGE DRIVER MODAL FUNCTION
  toggleNewDriverModal: bindActionCreators(toggleNewDriverModal, dispatch),
  updateDriverList: bindActionCreators(updateDriverList, dispatch),
  updateDriverListLabel: bindActionCreators(updateDriverListLabel, dispatch),
  updateOrderList: bindActionCreators(updateOrderList, dispatch),
  updateOrderListLabel: bindActionCreators(updateOrderListLabel, dispatch),
  updateDeliveryRunList: bindActionCreators(updateDeliveryRunList, dispatch),
  updateDeliveryRunListLabel: bindActionCreators(
    updateDeliveryRunListLabel,
    dispatch
  ),
  updateDeliveryList: bindActionCreators(updateDeliveryList, dispatch),
  updateDeliveryListLabel: bindActionCreators(
    updateDeliveryListLabel,
    dispatch
  ),
});

export default connect(mapStateToProps, mapDispatchToProps)(NewList);
