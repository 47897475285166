import * as React from "react";
import styled from "styled-components";
import { AppTextRoman, AppText, Card, HR } from "../../UI";
import onClickOutside, {
  InjectedOnClickOutProps,
  HandleClickOutside
} from "react-onclickoutside";

interface ViewListDropdownMenuProps {
  list: any[];
  changeViewLimit: (limit: string) => void;
  changeCurrentLabel: (label: string) => void;
  closeMenu: () => void;
}

interface ViewListDropdownMenuState {}

type Props = ViewListDropdownMenuProps &
  InjectedOnClickOutProps &
  HandleClickOutside<any>;

class ViewListDropdownMenuClass extends React.Component<
  Props,
  ViewListDropdownMenuState
> {
  constructor(props: Props) {
    super(props);
    this.state = {};
  }

  onSelect = (label, value) => {
    this.props.changeViewLimit(value);
    this.props.changeCurrentLabel(label);
    this.props.closeMenu();
  };
  render() {
    const { list } = this.props;
    return (
      <Menu>
        {list.map((item, index) => (
          <MenuItem key={index}>
            <MenuItemText onClick={() => this.onSelect(item.label, item.value)}>
              {item.label}
            </MenuItemText>
          </MenuItem>
        ))}
      </Menu>
    );
  }
}

const Menu = styled(Card)`
  border-radius: 4px;
  border: solid 1px rgba(71, 70, 71, 0.26);
  width: 84px;
  height: fit-content;
  position: absolute;
  z-index: 2;
  padding: 15px 0 15px 0;
  top: 5px;
  transition: all 0.5s ease-in-out;
`;

const MenuItem = styled.div`
  height: 35px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  :hover {
    background-color: rgba(0, 0, 0, 0.1);
  }
`;
const MenuItemText = styled(AppText)`
  font-size: 14px;
  color: #333333;
  padding-left: 20px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  :hover {
    cursor: pointer;
  }
`;

const ViewListDropdownMenu = onClickOutside(ViewListDropdownMenuClass);

export { ViewListDropdownMenu };
