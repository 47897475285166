import styled from "styled-components";

interface Props {
  error?: boolean;
  inputWidth?: string;
}

const Input = styled.input<Props>`
  width: ${props => props.inputWidth};
  height: 37.8px;
  background-color: #ffffff;
  border-radius: 1.8px;
  border: solid 0.9px #d0d7dd;
  padding-left: 14.4px;
  outline: ${props => (props.error ? "none" : "")};
  border-color: ${props => (props.error ? "red" : "")};
  box-shadow: ${props => (props.error ? "0 0 5px red" : "")};
  box-sizing: border-box;
  color: #1a1a1a;
  font-size: 16px;
  font-family: ${props => props.theme.PRIMARY_FONT};
  &:disabled {
    background: lightgray;
  }
  :focus {
    box-shadow: 0px 0px 2px blue;
  }
  ::placeholder {
    color: #989898;
    font-size: 14.4px;
    font-family: ${props => props.theme.PRIMARY_FONT};
  }
`;

Input.defaultProps = {
  error: false,
  inputWidth: "236.7px" // 400 - 25px padding
};

export { Input };
