import * as React from "react";
import styled from "styled-components";
import onClickOutside, {
  InjectedOnClickOutProps,
  HandleClickOutside,
} from "react-onclickoutside";
import { AppTextRoman, AppText, Card, HR } from "../../UI";
import { Dispatch, bindActionCreators } from "redux";
import { connect } from "react-redux";
import { AppState } from "../../../store/configureStore";
import { AppActions, OperatorFilter, MenuItem } from "../../../types";
import {
  updateDriverList,
  updateDriverListLabel,
  updateDriverInitialLoad,
  updateDriverFilters,
  updateOrderList,
  updateOrderListLabel,
  updateOrderInitialLoad,
  updateOrderFilters,
  updateDeliveryRunList,
  updateDeliveryRunListLabel,
  updateDeliveryRunInitialLoad,
  updateDeliveryRunFilters,
  updateDeliveryList,
  updateDeliveryListLabel,
  updateDeliveryInitialLoad,
  updateDeliveryFilters,
} from "../../../actions";

// export interface MenuItem {
//   label: string;
//   title: boolean;
//   id?: number;
//   filter_ids?: string[] | null;
// }

interface InplaceDropdownProps {
  list: MenuItem[];
  onSelect: (index: number) => void;
  dropDownType: string | undefined;
  changeListRenderState: () => void;
}

interface InplaceDropdownState {}

type Props = InplaceDropdownProps &
  InjectedOnClickOutProps &
  HandleClickOutside<any> &
  LinkStateProps &
  LinkDispatchProps;

class InplaceDropdownMenuClass extends React.Component<
  Props,
  InplaceDropdownState
> {
  constructor(props: Props) {
    super(props);

    this.state = {};
  }
  updateListState = (id: number, label: string, filters: string[], index) => {
    const {
      dropDownType,
      onSelect,
      updateDriverList,
      updateDriverListLabel,
      updateOrderList,
      updateOrderListLabel,
      updateDriverInitialLoad,
      updateOrderInitialLoad,
      updateDriverFilters,
      updateOrderFilters,
      updateDeliveryRunList,
      updateDeliveryRunListLabel,
      updateDeliveryRunInitialLoad,
      updateDeliveryRunFilters,
      updateDeliveryList,
      updateDeliveryListLabel,
      updateDeliveryInitialLoad,
      updateDeliveryFilters,
      changeListRenderState,
      driverLabel,
      orderLabel,
      deliveryLabel,
      deliveryRunLabel,
    } = this.props;
    if (dropDownType === "Drivers") {
      if (driverLabel !== label) {
        changeListRenderState();
        updateDriverList(id);
        updateDriverListLabel(label);
        updateDriverFilters(filters);
        updateDriverInitialLoad();
      }
    }
    if (dropDownType === "Orders") {
      if (orderLabel !== label) {
        changeListRenderState();
        updateOrderList(id);
        updateOrderListLabel(label);
        updateOrderFilters(filters);
        updateOrderInitialLoad();
      }
    }
    if (dropDownType === "Delivery Runs") {
      if (deliveryRunLabel !== label) {
        changeListRenderState();
        updateDeliveryRunList(id);
        updateDeliveryRunListLabel(label);
        updateDeliveryRunFilters(filters);
        updateDeliveryRunInitialLoad();
      }
    }
    if (dropDownType === "Deliveries") {
      if (deliveryLabel !== label) {
        changeListRenderState();
        updateDeliveryList(id);
        updateDeliveryListLabel(label);
        updateDeliveryFilters(filters);
        updateDeliveryInitialLoad();
      }
    }
    onSelect(index);
  };
  render() {
    const {
      list,
      onSelect,
      dropDownType,
      driverLabel,
      orderLabel,
    } = this.props;
    return (
      <Menu>
        {list.map((item, index) => {
          return item.title ? (
            <Spread key={index}>
              <Title>{item.label}</Title>
              <ThisHR />
            </Spread>
          ) : (
            <MenuItemDiv key={index}>
              <MenuItemText
                onClick={() =>
                  this.updateListState(
                    item.id as number,
                    item.label as string,
                    item.filter_ids as string[],
                    index
                  ) as any
                }
              >
                {item.label}
              </MenuItemText>
            </MenuItemDiv>
          );
        })}
      </Menu>
    );
  }
}

const Title = styled(AppTextRoman)`
  font-size: 15px;
  letter-spacing: 0.2px;
  color: #3b3a3a;
  margin-right: 15px;
`;

const Spread = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  padding-left: 20px;
  height: 35px;
`;

const MenuItemDiv = styled.div`
  height: 35px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  :hover {
    background-color: rgba(0, 0, 0, 0.1);
  }
`;
const MenuItemText = styled(AppText)`
  font-size: 14px;
  color: #333333;
  padding-left: 20px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  :hover {
    cursor: pointer;
  }
`;

const Menu = styled(Card)`
  border-radius: 4px;
  border: solid 1px rgba(71, 70, 71, 0.26);
  width: 452px;
  height: fit-content;
  position: absolute;
  z-index: 2;
  padding: 15px 0 15px 0;
  top: 5px;
  transition: all 0.5s ease-in-out;
`;

const ThisHR = styled(HR)`
  opacity: 0.5;
  margin: 0;
`;

interface LinkStateProps {
  store_id: number | null;
  token: string | null;
  driverLabel: string;
  orderLabel: string;
  deliveryLabel: string;
  deliveryRunLabel: string;
}

interface LinkDispatchProps {
  updateDriverList: (id: number) => void;
  updateDriverListLabel: (label: string) => void;
  updateDriverInitialLoad: () => void;
  updateDriverFilters: (filters: string[]) => void;
  updateOrderList: (id: number) => void;
  updateOrderListLabel: (label: string) => void;
  updateOrderInitialLoad: () => void;
  updateOrderFilters: (filters: string[]) => void;
  updateDeliveryRunList: (id: number) => void;
  updateDeliveryRunListLabel: (label: string) => void;
  updateDeliveryRunInitialLoad: () => void;
  updateDeliveryRunFilters: (filters: string[]) => void;
  updateDeliveryList: (id: number) => void;
  updateDeliveryListLabel: (label: string) => void;
  updateDeliveryInitialLoad: () => void;
  updateDeliveryFilters: (filters: string[]) => void;
}

const mapStateToProps = (state: AppState): LinkStateProps => ({
  store_id: state.user.store_id,
  token: state.auth.token,
  driverLabel: state.listViewIDs.driverLabel,
  orderLabel: state.listViewIDs.orderLabel,
  deliveryLabel: state.listViewIDs.deliveryLabel,
  deliveryRunLabel: state.listViewIDs.deliveryRunLabel,
});

const mapDispatchToProps = (
  dispatch: Dispatch<AppActions>
): LinkDispatchProps => ({
  updateDriverList: bindActionCreators(updateDriverList, dispatch),
  updateDriverListLabel: bindActionCreators(updateDriverListLabel, dispatch),
  updateDriverInitialLoad: bindActionCreators(
    updateDriverInitialLoad,
    dispatch
  ),
  updateDriverFilters: bindActionCreators(updateDriverFilters, dispatch),
  updateOrderList: bindActionCreators(updateOrderList, dispatch),
  updateOrderListLabel: bindActionCreators(updateOrderListLabel, dispatch),
  updateOrderInitialLoad: bindActionCreators(updateOrderInitialLoad, dispatch),
  updateOrderFilters: bindActionCreators(updateOrderFilters, dispatch),
  updateDeliveryRunList: bindActionCreators(updateDeliveryRunList, dispatch),
  updateDeliveryRunListLabel: bindActionCreators(
    updateDeliveryRunListLabel,
    dispatch
  ),
  updateDeliveryRunInitialLoad: bindActionCreators(
    updateDeliveryRunInitialLoad,
    dispatch
  ),
  updateDeliveryRunFilters: bindActionCreators(
    updateDeliveryRunFilters,
    dispatch
  ),
  updateDeliveryList: bindActionCreators(updateDeliveryList, dispatch),
  updateDeliveryListLabel: bindActionCreators(
    updateDeliveryListLabel,
    dispatch
  ),
  updateDeliveryInitialLoad: bindActionCreators(
    updateDeliveryInitialLoad,
    dispatch
  ),
  updateDeliveryFilters: bindActionCreators(updateDeliveryFilters, dispatch),
});

const InplaceDropdownMenu = onClickOutside(InplaceDropdownMenuClass);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(InplaceDropdownMenu);
