export const colors_stub = [
  "Silver",
  "Red",
  "Green",
  "Blue",
  "Orange",
  "Purple",
  "Yellow",
  "Brown"
];
