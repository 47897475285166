const TitleReformat = (title: string) => {
  const newTitle = title
    .replace(/_|-/g, " ")
    .split(" ")
    .map(string => string.slice(0, 1).toUpperCase() + string.slice(1))
    .join(" ");
  return newTitle;
};

export default TitleReformat;
