import * as React from "react";

interface BillingProps {}

interface BillingState {}

class Billing extends React.Component<BillingProps, BillingState> {
  render(): JSX.Element {
    return <span>Billing</span>;
  }
}

export default Billing;
