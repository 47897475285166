import * as React from "react";
import styled from "styled-components";
// import BackArrow from "react-icons/lib/ti/arrow-right";
import { TiArrowRight } from "react-icons/ti";

import { AppTextRoman, SecondaryButton, Button, HR } from "../../UI";
import { Filter, OperatorFilter } from "../../../types";
import { FilterItem } from ".";
import AddFilterMenu from "./AddFilterMenu";
import { connect } from "react-redux";
import { Dispatch, bindActionCreators } from "redux";
import { AppState } from "../../../store/configureStore";
import { AppActions, FilterType } from "../../../types";
import { api } from "../../../api/api";
import { ToastContainer, toast } from "react-toastify";
import NewList from "../../modal/newDriverList";

interface FilterMenuProps {
  visible: boolean;
  toggleMenu: () => void;
  // filters: Filter[];
  filterType: string | undefined;
  updateList?: () => void;
  updateOrders?: () => void;
  renderNewList?: (id: number) => void;
}

interface FilterMenuState {
  displaySubMenu: boolean;
  filters: Filter[];
  showNewList: boolean;
  operators: OperatorFilter | null;
}
type Props = FilterMenuProps & LinkStateProps & LinkDispatchProps;

class FilterMenu extends React.Component<Props, FilterMenuState> {
  constructor(props: Props) {
    super(props);

    this.state = {
      displaySubMenu: false,
      filters: [],
      showNewList: false,
      operators: null,
    };
  }

  componentDidMount = async () => {
    const {
      token,
      filterType,
      store_id,
      driverListID,
      orderListID,
      deliveryRunListID,
    } = this.props;
    //gets existing filters
    const operators = (await api.getFilterOperator(token)) as OperatorFilter;
    this.setState({
      operators: operators,
    });
    if (
      filterType === "Drivers" &&
      typeof this.props.driverListID === "number"
    ) {
      let filters = (await api.getFilters(
        { item_list_id: driverListID },
        token
      )) as FilterType[];
      let filterList = filters.map((item) => {
        return {
          id: item.id,
          field: item.label,
          operator: item.operator,
          value: item.value,
        };
      });
      this.setState({
        filters: filterList as Filter[],
      });
    }
    if (filterType === "Orders" && typeof this.props.orderListID === "number") {
      let filters = (await api.getFilters(
        { item_list_id: orderListID },
        token
      )) as FilterType[];
      let filterList = filters.map((item) => {
        return {
          id: item.id,
          field: item.label,
          operator: item.operator,
          value: item.value,
        };
      });
      this.setState({
        filters: filterList as Filter[],
      });
    }
    if (
      filterType === "Delivery Runs" &&
      typeof this.props.deliveryRunListID === "number"
    ) {
      let filters = (await api.getFilters(
        { item_list_id: deliveryRunListID },
        token
      )) as FilterType[];
      let filterList = filters.map((item) => {
        return {
          id: item.id,
          field: item.label,
          operator: item.operator,
          value: item.value,
        };
      });
      this.setState({
        filters: filterList as Filter[],
      });
    }
  };

  toggleNewList = () => {
    this.setState((prev) => ({ showNewList: !prev.showNewList }));
  };

  toggleSubMenu = () => {
    this.setState((prev) => ({ displaySubMenu: !prev.displaySubMenu }));
  };

  componentDidUpdate = async (prevProps) => {
    const {
      token,
      store_id,
      driverListID,
      orderListID,
      deliveryRunListID,
      filterType,
    } = this.props;
    if (
      filterType === "Drivers" &&
      driverListID !== prevProps.driverListID &&
      typeof driverListID === "number"
    ) {
      let filters = (await api.getFilters(
        { item_list_id: driverListID },
        token
      )) as FilterType[];
      let filterList = filters.map((item) => {
        return {
          id: item.id,
          field: item.label,
          operator: item.operator,
          value: item.value,
        };
      });
      this.setState({
        filters: filterList as Filter[],
      });
    }
    if (
      filterType === "Orders" &&
      orderListID !== prevProps.orderListID &&
      typeof orderListID === "number"
    ) {
      let filters = (await api.getFilters(
        { item_list_id: orderListID },
        token
      )) as FilterType[];
      let filterList = filters.map((item) => {
        return {
          id: item.id,
          field: item.label,
          operator: item.operator,
          value: item.value,
        };
      });
      this.setState({
        filters: filterList as Filter[],
      });
    }
    if (
      filterType === "Delivery Runs" &&
      deliveryRunListID !== prevProps.deliveryRunListID &&
      typeof deliveryRunListID === "number"
    ) {
      let filters = (await api.getFilters(
        { item_list_id: deliveryRunListID },
        token
      )) as FilterType[];
      let filterList = filters.map((item) => {
        return {
          id: item.id,
          field: item.label,
          operator: item.operator,
          value: item.value,
        };
      });
      this.setState({
        filters: filterList as Filter[],
      });
    }
  };
  addToFilterList = (filter: Filter) => {
    let filterState = [...this.state.filters];
    filterState.push(filter);
    this.setState({
      filters: filterState,
    });
  };
  removeFilter = (id: number) => {
    let newFilter = this.state.filters.filter((filter) => filter.id !== id);
    this.setState({
      filters: newFilter,
    });
  };

  isDefault = (currentPage) => {
    const { driverListLabel, orderListLabel } = this.props;
    if (currentPage === "Drivers") {
      if (
        driverListLabel === "" ||
        driverListLabel === "All Drivers" ||
        driverListLabel === "Active Drivers" ||
        driverListLabel === "Inactive Drivers"
      ) {
        return true;
      }
    }
    if (currentPage === "Orders") {
    }
    if (currentPage === "Delivery Runs") {
    }
    return false;
  };
  isDefaultNotification = () => {
    return toast.error("Cannot make changes to default list", {
      position: "top-right",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: false,
      draggable: true,
    });
  };
  onClick = (id: number) => {};

  onDelete = (id: number) => {};
  updateList = async (filters: Filter[]) => {
    const {
      filterType,
      driverListID,
      driverListLabel,
      orderListID,
      orderListLabel,
      deliveryRunListID,
      deliveryRunListLabel,
      token,
      toggleMenu,
      updateList,
      updateOrders,
      deliveryListID,
      deliveryListLoaded,
      deliveryListLabel,
    } = this.props;
    const filterIDs = filters.map((filter) => filter.id);
    if (filterType === "Drivers") {
      try {
        await api.updateList(
          {
            list_id: driverListID,
            entity_name: "Driver",
            label: driverListLabel,
            filter_ids: filterIDs as [],
          },
          token
        );
        updateList ? updateList() : null;
        toggleMenu();
      } catch (error) {
        console.log(error);
      }
    }
    if (filterType === "Orders") {
      try {
        await api.updateList(
          {
            list_id: orderListID,
            entity_name: "Order",
            label: orderListLabel,
            filter_ids: filterIDs as [],
          },
          token
        );
        updateList ? updateList() : null;
        toggleMenu();
      } catch (error) {
        console.log(error);
      }
    }
    if (filterType === "Delivery Runs") {
      try {
        await api.updateList(
          {
            list_id: deliveryRunListID,
            entity_name: "DeliveryRun",
            label: deliveryRunListLabel,
            filter_ids: filterIDs as [],
          },
          token
        );
        updateList ? updateList() : null;
        toggleMenu();
      } catch (error) {
        console.log(error);
      }
    }
    if (filterType === "Deliveries") {
      try {
        await api.updateList(
          {
            list_id: deliveryListID,
            entity_name: "Delivery",
            label: deliveryListLabel,
            filter_ids: filterIDs as [],
          },
          token
        );
        !!updateList && updateList();
        toggleMenu();
      } catch (error) {
        console.log(error);
      }
    }
  };
  clearFilters = () => {
    this.setState({
      filters: [],
    });
  };
  render() {
    const { toggleMenu, visible } = this.props;
    const { displaySubMenu } = this.state;

    return (
      <>
        <AddFilterMenu
          visible={displaySubMenu}
          toggleMenu={this.toggleSubMenu}
          filterType={this.props.filterType}
          addToFilterList={this.addToFilterList}
        />
        <NewList
          list_type={this.props.filterType as string}
          showModal={this.state.showNewList}
          toggleModal={this.toggleNewList}
          renderNewList={this.props.renderNewList}
          filters={this.state.filters}
        />
        <Main visible={visible}>
          <div>
            <Spread style={{ paddingRight: "20px" }}>
              <FiltersTitle>FILTERS</FiltersTitle>
              <Clickable subMenu={this.state.displaySubMenu}>
                <TiArrowRight
                  size={36}
                  color="#3b3a3a"
                  opacity={this.state.displaySubMenu ? "0.5" : "1"}
                  onClick={
                    !this.state.displaySubMenu
                      ? this.props.toggleMenu
                      : () => {}
                  }
                />
              </Clickable>
            </Spread>
            <ThisHR />
            <Overflow>
              {this.state.filters.length > 0
                ? this.state.filters.map((filter) => (
                    <FilterItem
                      {...filter}
                      operators={this.state.operators}
                      onClick={this.onClick}
                      onDelete={this.onDelete}
                      key={filter.id}
                      removeFilter={this.removeFilter}
                    />
                  ))
                : null}
            </Overflow>
            <Spread style={{ paddingRight: "40px" }}>
              <FilterActionText
                onClick={() =>
                  this.isDefault(this.props.filterType)
                    ? console.log("true")
                    : this.toggleSubMenu()
                }
                clickable
              >
                ADD FILTER
              </FilterActionText>
              <FilterActionText
                clickable
                onClick={() =>
                  this.isDefault(this.props.filterType)
                    ? console.log("true")
                    : this.clearFilters()
                }
              >
                REMOVE ALL
              </FilterActionText>
            </Spread>
          </div>
          <Spread style={{ paddingRight: "20px" }}>
            <SaveAsButton width="164px" onClick={this.toggleNewList}>
              Save As
            </SaveAsButton>
            <Button
              onClick={() =>
                this.isDefault(this.props.filterType)
                  ? this.isDefaultNotification()
                  : this.updateList(this.state.filters)
              }
            >
              Save
            </Button>
          </Spread>
        </Main>
      </>
    );
  }
}

interface MainProps {
  visible?: boolean;
}

interface SubMenu {
  subMenu: boolean;
}

const Main = styled.div<MainProps>`
  position: fixed;
  z-index: 2;
  right: 0;
  bottom: 200px;
  transform: ${(props) =>
    props.visible ? "translate3d(0vw, 0, 0)" : "translate3d(100vw, 0, 0)"};
  transition: transform 0.3s cubic-bezier(0, 0.52, 0, 1);
  width: 452px;
  height: 610.5px;
  border-radius: 4px;
  border: solid 1px rgba(71, 70, 71, 0.26);
  background-color: #ffffff;
  padding: 15px 0 35px 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: stretch;
`;

Main.defaultProps = {
  visible: false,
};

const Spread = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

const Overflow = styled.div`
  max-height: 400px;
  overflow-y: auto;
  margin-bottom: 10px;
`;

const Clickable = styled.div<SubMenu>`
  :hover {
    cursor: ${(props) => (props.subMenu ? "not-allowed" : "pointer")};
  }
`;

const FiltersTitle = styled(AppTextRoman)`
  font-size: 15px;
  letter-spacing: 0.2px;
  color: #3b3a3a;
`;

const FilterActionText = styled(AppTextRoman)`
  font-size: 15px;
  letter-spacing: 0.2px;
  color: #60999e;
`;

const SaveAsButton = styled(SecondaryButton)`
  font-size: 13px;
  font-weight: 500;
  color: #3b3a3a;
`;

const ThisHR = styled(HR)`
  margin: 10px 0 20px 0;
  opacity: 0.5;
`;

interface LinkStateProps {
  token: string;
  store_id: number;
  driverListID: number;
  driverListLoaded: boolean;
  driverListLabel: string;
  orderListID: number;
  orderListLoaded: boolean;
  orderListLabel: string;
  deliveryRunListID: number;
  deliveryRunListLoaded: boolean;
  deliveryRunListLabel: string;
  deliveryListID: number;
  deliveryListLoaded: boolean;
  deliveryListLabel: string;
}

interface LinkDispatchProps {}

const mapDispatchToProps = (
  dispatch: Dispatch<AppActions>
): LinkDispatchProps => ({});

// isAuthenticated is true if user_token exists in redux
const mapStateToProps = (state: AppState): LinkStateProps => ({
  //temp
  store_id: state.user.store_id as number,
  token: state.auth.token as string,
  driverListID: state.listViewIDs.driverListID as number,
  driverListLoaded: state.listViewIDs.driverListInitialLoad as boolean,
  driverListLabel: state.listViewIDs.driverLabel as string,
  orderListID: state.listViewIDs.orderListID as number,
  orderListLoaded: state.listViewIDs.orderListInitialLoad as boolean,
  orderListLabel: state.listViewIDs.orderLabel as string,
  deliveryRunListID: state.listViewIDs.deliveryRunListID as number,
  deliveryRunListLoaded: state.listViewIDs
    .deliveryRunListInitialLoad as boolean,
  deliveryRunListLabel: state.listViewIDs.deliveryRunLabel as string,
  deliveryListID: state.listViewIDs.deliveryListID as number,
  deliveryListLoaded: state.listViewIDs.deliveryListInitialLoad as boolean,
  deliveryListLabel: state.listViewIDs.deliveryLabel as string,
});

export default connect(mapStateToProps, mapDispatchToProps)(FilterMenu);
