import React from "react";
import { Field, FieldProps, ErrorMessage } from "formik";
import { Input, AppText } from "../UI";
import styled from "styled-components";
import { ErrorText } from "../Dumb";
import { SketchPicker } from "react-color";

export const CustomColorSelectField = ({
  name,
  title,
  style,
  close
}: {
  name: string;
  title?: string;
  style?: React.CSSProperties;
  close: () => void;
}) => (
  <div>
    <Field
      name={name}
      render={({ field, form, form: { errors, touched } }: FieldProps) => {
        const error = !!errors[name] && !!touched[name];
        return (
          <FormSegment>
            {title && <AppTextFormTitle>{title}</AppTextFormTitle>}
            <SketchDiv>
              <SketchPicker
                color={field.value}
                onChangeComplete={color => form.setFieldValue(name, color.hex)}
              />
              <CloseDiv onClick={close}>X</CloseDiv>
            </SketchDiv>
            <ErrorMessage name={field.name} component={ErrorText} />
          </FormSegment>
        );
      }}
    />
  </div>
);

const FormSegment = styled.div``;

const SketchDiv = styled.div`
  display: flex;
  flex-direction: row;
`;
const CloseDiv = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 20px;
  height: 20px;
  background-color: white;
  border-radius: 4px;
  box-shadow: 2px 1px 3px 0 rgba(0, 0, 0, 0.38);
  margin-left: 2px;
  :hover {
    cursor: pointer;
  }
`;
const AppTextFormTitle = styled(AppText)`
  opacity: 0.5;
  font-size: 14px;
  font-weight: 500;
  color: #3b3a3a;
  margin-bottom: 7px;
`;
