import * as React from "react";
import { connect } from "react-redux";
import { Container, Spinner, AppText, PageLoadSpinner } from "../UI";
import {
  DynamicTable,
  ColumnType,
  SelectedMenuOption,
  ColumnColors,
  RowData,
} from "../Dumb";
import { DynamicTableContainer } from "../Dumb/DynamicTableContainer";
import { Query } from "react-apollo";
import gql from "graphql-tag";
import NewDriver from "../modal/NewDriver";
import { AppState } from "../../store/configureStore";
import {
  AppActions,
  LoggedUser,
  ItemList,
  MenuItem,
  CustomFieldsList,
  CustomFields,
  CustomFilters,
  CustomFieldFilterMenu,
  FilterField,
} from "../../types";
import { Dispatch, bindActionCreators } from "redux";
import {
  toggleNewDriverModal,
  updateDriverInitialLoad,
  addDriverCustomFilters,
  updateDriverCustomListLoad,
  fetchProperties,
  addDriverCustomFiltersField,
  updateDefaultDriverID,
  setDriverCustomFieldFilters,
} from "../../actions";
import FilterMenu from "../Segments/filter/FilterMenu";
import { api } from "../../api/api";
import { Driver, DriverList } from "../../api/responseTypes";
import styled from "styled-components";
import { getStoreThunk } from "../../api/store";
import { getFilterType, debouncer } from "../../utils/helpers";
import { toastError } from "../../utils";

const EmptyGridDiv = styled.div`
  width: 100%;
  height: 200px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  border-width: 1px;
  border-style: solid;
  border-color: #000;
`;

const EmptyGrid = (
  <EmptyGridDiv>
    <AppText>You don't have any drivers on this page</AppText>
  </EmptyGridDiv>
);

export const titles_stub = [
  "Driver",
  "# Deliveries",
  "Status",
  "Start time",
  "ETA",
  "Route",
  "Date Created",
  "Date Last Modified",
];

export const driver_field = [
  {
    label: "First Name",
    value: "first_name",
    operator_type: "text",
    listValues: [] as any,
  },
  {
    label: "Last Name",
    value: "last_name",
    operator_type: "text",
    listValues: [] as any,
  },
  {
    label: "Email",
    value: "email",
    operator_type: "email",
    listValues: [] as any,
  },
  {
    label: "Active",
    value: "is_active",
    operator_type: "dropdown",
    listValues: [
      {
        created_at: "",
        custom_field_id: 0,
        deleted_at: null,
        id: 51,
        selected: 0,
        updated_at: "",
        value: "active",
      },
      {
        created_at: "",
        custom_field_id: 0,
        deleted_at: null,
        id: 0,
        selected: 0,
        updated_at: "",
        value: "inactive",
      },
    ] as any,
  },
  {
    label: "Language",
    value: "language",
    operator_type: "text",
    listValues: [] as any,
  },
  {
    label: "Color",
    value: "color",
    operator_type: "text",
    listValues: [] as any,
  },
  {
    label: "Created At",
    value: "created_at",
    operator_type: "date",
    listValues: [] as any,
  },
  {
    label: "Updated At",
    value: "updated_at",
    operator_type: "date",
    listValues: [] as any,
  },
];
const colors_stub: ColumnColors[] = [
  undefined,
  undefined,
  undefined,
  undefined,
  undefined,
  undefined,
  undefined,
  undefined,
];

// const selected_options_stub: SelectedMenuOption[] = [
//   {
//     text: "Make Active",
//     onClick: async selected => {
//       console.log("selected: ", selected);
//       await api.makeActiveDrivers(selected);
//     }
//   },
//   {
//     text: "Make Inactive",
//     onClick: selected => console.log("selected: ", selected)
//   },
//   {
//     text: "Delete",
//     onClick: selected => console.log("selected: ", selected)
//   }
// ];

interface DriversProps {}
interface DriversState {
  drivers: Driver[];
  loading: boolean;
  searchKeyword: string;
  currentPage: number;
  from: number;
  to: number;
  total: number;
  per_page: number;
  list_id: number | null;
  item_list: [] | ItemList[];
  limit: string;
  last_page: number | null;
  customFilters: CustomFilters[];
  changeListLoad: boolean;
  exporting: boolean;
}

type Props = DriversProps & LinkStateProps & LinkDispatchProps;

class Drivers extends React.Component<Props, DriversState> {
  constructor(props: Props) {
    super(props);
    this.state = {
      drivers: [],
      loading: true,
      searchKeyword: "",
      currentPage: 1,
      from: 1,
      to: 10,
      total: 0,
      per_page: 10,
      list_id: null,
      item_list: [],
      limit: "10",
      last_page: null,
      customFilters: [],
      changeListLoad: false,
      exporting: false,
    };
  }

  componentDidMount = async () => {
    const {
      store_id,
      token,
      driverListID,
      fetchProperties,
      addDriverCustomFiltersField,
      setDriverCustomFieldFilters,
    } = this.props;
    const propFilter = this.props.driverFilters
      ? this.props.driverFilters.map((field) => field.display)
      : [];

    const driverColumnFilters = await api.getColumnFilters({
      entity: "Driver",
      store_id: store_id,
      token: token,
    });
    setDriverCustomFieldFilters(
      driverColumnFilters.fields ? driverColumnFilters.fields : []
    );

    this.setState({ loading: true });
    //initial get customfields
    //TODO need to check what fetchProperties does
    const initialCustomFields = (await api.getCustomField(
      { store_id: store_id, entity_name: "Driver" },
      token
    )) as CustomFieldsList;

    fetchProperties(initialCustomFields.data);

    let customFilters = initialCustomFields.data
      ? initialCustomFields.data.map((obj) => {
          return {
            display: obj.display,
            field: obj.slug,
            visible: false,
            listvalues: obj.listValues,
          };
        })
      : [];

    this.setState({
      customFilters: customFilters,
    });

    // const filtersToUse = this.state.customFilters.filter(
    //   (field) => !propFilter.includes(field.display)
    // );

    // this.addCustomList(filtersToUse, this.props.driverFilters);
    // append initialcustom fields to filter list
    let mappedDriverCustomFieldsFilter = initialCustomFields.data
      ? initialCustomFields.data.map((obj) => {
          return {
            label: obj.display,
            value: obj.slug,
            operator_type: getFilterType(obj.field_type_id) as string,
            listValues: obj.listValues,
          };
        })
      : [];
    const mappedDriverFieldsFilter = driver_field.concat(
      mappedDriverCustomFieldsFilter
    );
    //adds to filter menu
    addDriverCustomFiltersField(mappedDriverFieldsFilter);
    //initial get all drivers
    if (this.props.driverListLoaded) {
      const drivers = (await api.getDrivers(
        { store_id, page: 1, limit: this.state.limit },
        token
      )) as DriverList;
      const itemList = (await api.getItemLists(
        { store_id: store_id, entity: "Driver" },
        token
      )) as ItemList[];
      this.setState({
        drivers: drivers.data,
        currentPage: drivers.current_page,
        loading: false,
        from: drivers.from,
        to: drivers.to,
        total: drivers.total,
        per_page: drivers.per_page,
        item_list: itemList,
        last_page: drivers.last_page,
      });
      // this.props.updateDriverInitialLoad();
    }
    // const drivers = (await api.getDrivers(
    //   { store_id, page: 1, item_list: driverListID, limit: this.state.limit },
    //   token
    // )) as DriverList;
    // const itemList = (await api.getItemLists(
    //   { store_id: store_id, entity: "Driver" },
    //   token
    // )) as ItemList[];
    // this.setState({
    //   drivers: drivers.data,
    //   currentPage: drivers.current_page,
    //   loading: false,
    //   from: drivers.from,
    //   to: drivers.to,
    //   total: drivers.total,
    //   per_page: drivers.per_page,
    //   item_list: itemList
    // });

    //get column filters
  };

  componentDidUpdate = async (prevProps, prevState) => {
    const { store_id, token, driverListID } = this.props;
    if (this.props !== prevProps) {
      const drivers = await api.getDrivers(
        { store_id, page: 1, item_list: driverListID, limit: this.state.limit },
        token
      );
      const itemList = (await api.getItemLists(
        { store_id: store_id, entity: "Driver" },
        token
      )) as ItemList[];
      this.setState({
        drivers: drivers.data,
        currentPage: drivers.current_page,
        loading: false,
        from: drivers.from,
        to: drivers.to,
        total: drivers.total,
        per_page: drivers.per_page,
        item_list: itemList,
        last_page: drivers.last_page,
        changeListLoad: false,
      });
    }
    if (this.props.driverListID !== prevProps.driverListID) {
      //update drivers on driverListID change
      const drivers = (await api.getDrivers(
        { store_id, page: 1, item_list: driverListID, limit: this.state.limit },
        token
      )) as DriverList;
      const itemList = (await api.getItemLists(
        { store_id: store_id, entity: "Driver" },
        token
      )) as ItemList[];
      this.setState({
        drivers: drivers.data,
        currentPage: drivers.current_page,
        loading: false,
        from: drivers.from,
        to: drivers.to,
        total: drivers.total,
        per_page: drivers.per_page,
        item_list: itemList,
        last_page: drivers.last_page,
      });
    }

    if (this.props.custom_properties !== prevProps.custom_properties) {
      const intialCustomFields = (await api.getCustomField(
        { store_id: store_id, entity_name: "Driver" },
        token
      )) as CustomFieldsList;
      let customFilters = intialCustomFields.data.map((obj) => {
        return {
          display: obj.display,
          field: obj.slug,
          visible: false,
        };
      });
      // this.addCustomList(customFilters, this.props.driverFilters);
    }
    if (this.state.limit !== prevState.limit) {
      const drivers = (await api.getDrivers(
        {
          store_id,
          page: 1,
          item_list: driverListID,
          limit: this.state.limit,
        },
        token
      )) as DriverList;
      const itemList = (await api.getItemLists(
        { store_id: store_id, entity: "Driver" },
        token
      )) as ItemList[];
      this.setState({
        drivers: drivers.data,
        currentPage: drivers.current_page,
        loading: false,
        from: drivers.from,
        to: drivers.to,
        total: drivers.total,
        per_page: drivers.per_page,
        item_list: itemList,
        last_page: drivers.last_page,
        changeListLoad: false,
      });
    }
  };

  onColumnSave = async (filters: CustomFilters[]) => {
    const { store_id, token, setDriverCustomFieldFilters } = this.props;
    const driverColumnFilters = await api.editColumns({
      entity: "Driver",
      store_id: store_id,
      filters: filters,
      token: token,
    });

    setDriverCustomFieldFilters(driverColumnFilters.fields);
  };

  updateDrivers = async () => {
    const { store_id, token, driverListID } = this.props;
    //update drivers on driverListID change
    const drivers = (await api.getDrivers(
      { store_id, page: 1, item_list: driverListID, limit: this.state.limit },
      token
    )) as DriverList;
    const itemList = (await api.getItemLists(
      { store_id: store_id, entity: "Driver" },
      token
    )) as ItemList[];
    this.setState({
      drivers: drivers.data,
      currentPage: drivers.current_page,
      loading: false,
      from: drivers.from,
      to: drivers.to,
      total: drivers.total,
      per_page: drivers.per_page,
      last_page: drivers.last_page,
    });
  };

  nextPage = async () => {
    const { store_id, token, driverListID } = this.props;
    const { to, total, drivers, currentPage, limit } = this.state;
    if (to < total) {
      this.setState({
        changeListLoad: true,
      });
      const drivers = (await api.getDrivers(
        {
          store_id: store_id,
          page: currentPage + 1,
          limit: this.state.limit,
          item_list: driverListID,
        },
        token
      )) as DriverList;
      this.setState({
        drivers: drivers.data,
        currentPage: drivers.current_page,
        loading: false,
        from: drivers.from,
        to: drivers.to,
        total: drivers.total,
        last_page: drivers.last_page,
        changeListLoad: false,
      });
    }
  };

  prevPage = async () => {
    const { store_id, token, driverListID } = this.props;
    const { to, per_page, drivers, currentPage, limit, last_page } = this.state;
    if (to === null) {
      this.setState({
        changeListLoad: true,
      });
      const drivers = (await api.getDrivers(
        {
          store_id: store_id,
          page: last_page as number,
          limit: this.state.limit,
          item_list: driverListID,
        },
        token
      )) as DriverList;
      this.setState({
        drivers: drivers.data,
        currentPage: drivers.current_page,
        loading: false,
        from: drivers.from,
        to: drivers.to,
        total: drivers.total,
        last_page: drivers.last_page,
        changeListLoad: false,
      });
    }
    if (to > per_page) {
      this.setState({
        changeListLoad: true,
      });
      const drivers = (await api.getDrivers(
        {
          store_id: store_id,
          page: currentPage - 1,
          limit: this.state.limit,
          item_list: driverListID,
        },
        token
      )) as DriverList;
      this.setState({
        drivers: drivers.data,
        currentPage: drivers.current_page,
        loading: false,
        from: drivers.from,
        to: drivers.to,
        total: drivers.total,
        last_page: drivers.last_page,
        changeListLoad: false,
      });
    }
  };

  firstPage = async () => {
    const { store_id, token, driverListID } = this.props;
    const { limit } = this.state;
    this.setState({
      changeListLoad: true,
    });
    const drivers = (await api.getDrivers(
      {
        store_id: store_id,
        page: 1,
        limit: limit,
        item_list: driverListID,
      },
      token
    )) as DriverList;
    this.setState({
      drivers: drivers.data,
      currentPage: drivers.current_page,
      loading: false,
      from: drivers.from,
      to: drivers.to,
      total: drivers.total,
      last_page: drivers.last_page,
      changeListLoad: false,
    });
  };

  lastPage = async () => {
    const { store_id, token, driverListID } = this.props;
    const { limit, last_page } = this.state;
    this.setState({
      changeListLoad: true,
    });
    const drivers = (await api.getDrivers(
      {
        store_id: store_id,
        page: last_page as number,
        limit: limit,
        item_list: driverListID,
      },
      token
    )) as DriverList;
    this.setState({
      drivers: drivers.data,
      currentPage: drivers.current_page,
      loading: false,
      from: drivers.from,
      to: drivers.to,
      total: drivers.total,
      last_page: drivers.last_page,
      changeListLoad: false,
    });
  };

  newDriver = (driver: Driver) => {
    this.setState((prev) => ({ drivers: [...prev.drivers, driver] }));
  };

  changeSearchText = (searchKeyword: string) => {
    this.setState({ searchKeyword });
    debouncer(this.searchOnDebounce, 500);
  };

  searchOnDebounce = async () => {
    const { store_id, token, driverListID } = this.props;
    const { limit, searchKeyword } = this.state;
    this.setState({
      changeListLoad: true,
    });

    const drivers = (await api.getDrivers(
      {
        store_id: store_id,
        page: 1,
        limit: limit,
        item_list: driverListID,
        searched_text: searchKeyword,
      },
      token
    )) as DriverList;

    this.setState({
      drivers: drivers.data,
      currentPage: drivers.current_page,
      loading: false,
      from: drivers.from,
      to: drivers.to,
      total: drivers.total,
      last_page: drivers.last_page,
      changeListLoad: false,
    });
  };

  changeListRenderState = () => {
    this.setState({
      changeListLoad: true,
    });
  };

  changeViewLimit = (limit: string) => {
    if (limit !== this.state.limit) {
      this.setState({
        limit: limit,
        changeListLoad: true,
      });
    }
  };

  getDrivers = () => {
    const { drivers, searchKeyword, currentPage } = this.state;
    const { driverFilters } = this.props;
    const initialList = Array.isArray(drivers) ? drivers : [];
    // ? drivers.filter(driver => {
    //     return Object.values(driver).filter(
    //       field =>
    //         !!field &&
    //         `${field}`.toLowerCase().includes(searchKeyword.toLowerCase())
    //     ).length;
    //   })
    // : [];
    let filter = [] as string[];
    driverFilters.forEach((obj) => {
      if (obj.visible === true) {
        filter.push(obj.field);
      }
      if (obj.field === "id") {
        filter.push(obj.field);
      }
    });
    const filteredDriver = (obj) => {
      let result = {};

      for (let key in obj) {
        if (filter.includes(key)) {
          result[key] = obj[key];
        }
      }
      return result;
    };
    const finalList = initialList.map((obj) =>
      filteredDriver(obj)
    ) as RowData[];
    return finalList;
  };

  // addCustomList = (
  //   filters: CustomFilters[],
  //   defaultFilters: CustomFilters[]
  // ) => {
  //   let newDefaultFilter = [] as CustomFilters[];
  //   let defaultFilterMap = defaultFilters.map(
  //     (defaultFilter) => defaultFilter.field
  //   );
  //   let filterMap = filters.map((filter) => filter.field);
  //   defaultFilters.forEach((defaultFilter) => {
  //     if (
  //       defaultFilterMap.includes(defaultFilter.field) &&
  //       !filterMap.includes(defaultFilter.field)
  //     ) {
  //       newDefaultFilter.push(defaultFilter);
  //     } else {
  //       filters.forEach((filter) => {
  //         if (defaultFilter.field === filter.field) {
  //           if (defaultFilter.display === filter.display) {
  //             newDefaultFilter.push(defaultFilter);
  //           } else {
  //             newDefaultFilter.push(filter);
  //           }
  //         }
  //       });
  //     }
  //   });
  //   filters.forEach((filter) => {
  //     if (!defaultFilterMap.includes(filter.field)) {
  //       newDefaultFilter.push(filter);
  //     }
  //   });
  //   const finalFilterList = newDefaultFilter;
  //   if (this.props.driverCustomListLoaded) {
  //     // this.props.addDriverCustomFilters(finalFilterList);
  //   }
  //   this.props.updateDriverCustomListLoad();
  // };

  renderNewListDrivers = async (id: number) => {
    const { store_id, token } = this.props;
    const drivers = (await api.getDrivers(
      { store_id, page: 1, item_list: id, limit: this.state.limit },
      token
    )) as DriverList;
    // console.log("drivers", drivers);
    this.setState({
      drivers: drivers.data,
      currentPage: drivers.current_page,
      loading: false,
      from: drivers.from,
      to: drivers.to,
      total: drivers.total,
      per_page: drivers.per_page,
      last_page: drivers.last_page,
    });
  };

  //list menu of Driver items
  driverList = () => {
    let standardList = [{ title: true, label: "STANDARD LISTS" }] as MenuItem[];
    let customList = [
      {
        title: true,
        label: "CUSTOM LISTS",
      },
    ] as MenuItem[];
    //go through Item lists and append based on label
    this.state.item_list.forEach((item) => {
      //label tbd
      if (item.label === "All Drivers") {
        standardList.push({
          title: false,
          label: item.label,
          id: item.code,
          filter_ids: item.filter_ids,
        });
        this.props.updateDefaultDriverID(item.code as string);
      } else if (
        item.label === "Inactive Drivers" ||
        item.label === "Active Drivers"
      ) {
        standardList.push({
          title: false,
          label: item.label,
          id: item.code,
          filter_ids: item.filter_ids,
        });
      } else {
        customList.push({
          title: false,
          label: item.label,
          id: item.id,
          filter_ids: item.filter_ids,
        });
      }
    });
    return standardList.concat(customList);
  };

  isDefaultList = () => {
    const { driverListLabel } = this.props;
    if (
      driverListLabel === "" ||
      driverListLabel === "All Drivers" ||
      driverListLabel === "Active Drivers" ||
      driverListLabel === "Inactive Drivers"
    ) {
      return true;
    }
    return false;
  };

  exportDrivers = async () => {
    const { store_id, driverListID, token } = this.props;

    this.setState({
      exporting: true,
    });
    const response = await api.exportDrivers(store_id, driverListID, token);

    this.setState({
      exporting: false,
    });
  };

  orderColumns = () => {
    const { driverFilters } = this.props;

    const newDriverColumn = driverFilters
      .filter((field) => !!field.visible)
      .sort((a, b) => (a.ordinal && b.ordinal ? a.ordinal - b.ordinal : 0))
      .map((filter) => filter.field);

    return newDriverColumn;
  };

  render() {
    const { loading } = this.state;
    const { store_id, driverListID, token } = this.props;
    const massSelectionRequirement = "Need to select a driver first";
    const drivers = this.getDrivers().map((value) => {
      return {
        ...value,
        is_active: value.is_active === 0 ? "Inactive" : "Active",
      };
    });
    const itemList = this.state.item_list.length > 1 ? this.driverList() : [];

    const selected_options_stub: SelectedMenuOption[] = [
      {
        text: "Make Active",
        onClick: async (selected: number[] | RowData[]) => {
          const { store_id, token, driverListID } = this.props;
          const { currentPage } = this.state;
          if (selected.length > 0) {
            await api.makeActiveDrivers(
              { driverIDs: selected as number[] },
              token
            );
            const drivers = await api.getDrivers(
              {
                store_id,
                page: currentPage,
                item_list: driverListID,
                limit: this.state.limit,
              },
              token
            );
            this.setState({
              drivers: drivers.data,
              currentPage: drivers.current_page,
              loading: false,
              from: drivers.from,
              to: drivers.to,
              total: drivers.total,
              per_page: drivers.per_page,
              last_page: drivers.last_page,
            });
          } else {
            toastError(massSelectionRequirement);
          }
        },
      },
      {
        text: "Make Inactive",
        onClick: async (selected: number[] | RowData[]) => {
          const { store_id, token, driverListID } = this.props;
          const { currentPage } = this.state;
          if (selected.length > 0) {
            await api.makeInactiveDrivers(
              { driverIDs: selected as number[] },
              token
            );
            const drivers = await api.getDrivers(
              {
                store_id,
                page: currentPage,
                item_list: driverListID,
                limit: this.state.limit,
              },
              token
            );
            // console.log("drivers", drivers);

            this.setState({
              drivers: drivers.data,
              currentPage: drivers.current_page,
              loading: false,
              from: drivers.from,
              to: drivers.to,
              total: drivers.total,
              per_page: drivers.per_page,
              last_page: drivers.last_page,
            });
          } else {
            toastError(massSelectionRequirement);
          }
        },
      },
      {
        text: "Delete",
        onClick: async (selected) => {
          const { store_id, token, driverListID } = this.props;
          const { currentPage } = this.state;
          if (selected.length > 0) {
            await api.massDeleteDrivers(
              { driverIDs: selected as number[] },
              token
            );
            const drivers = await api.getDrivers(
              {
                store_id,
                page: currentPage,
                limit: this.state.limit,
                item_list: driverListID,
              },
              token
            );
            // console.log("drivers", drivers);

            this.setState({
              drivers: drivers.data,
              currentPage: drivers.current_page,
              loading: false,
              from: drivers.from,
              to: drivers.to,
              total: drivers.total,
              per_page: drivers.per_page,
              last_page: drivers.last_page,
            });
          } else {
            toastError(massSelectionRequirement);
          }
        },
      },
    ];
    if (loading) {
      return (
        <Container>
          <Spinner />
        </Container>
      );
    }
    return (
      <Container style={{ marginTop: 1 }}>
        <NewDriver
          showModal={this.props.showNewDriverModal}
          toggleModal={this.props.toggleNewDriverModal}
          onComplete={this.newDriver}
        />
        {this.state.changeListLoad && <PageLoadSpinner />}
        <DynamicTable
          customButtonClick={this.props.toggleNewDriverModal}
          columnTitles={!!drivers.length ? this.orderColumns() : []}
          // columnTypes={rowType_stub}
          // columnColors={colors_stub}
          // rowData={dataRow_stub}
          rowData={drivers}
          selectedMenuOptions={selected_options_stub}
          selectedMenuLabel="Drivers"
          currentPageFirstItemNumber={this.state.currentPage + 1}
          onNextClick={this.nextPage}
          onPrevClick={this.prevPage}
          onFirstClick={this.firstPage}
          onLastClick={this.lastPage}
          renderEmptyGrid={EmptyGrid}
          excludedColumns={["deleted_at"]}
          searchOnChange={this.changeSearchText}
          searchValue={this.state.searchKeyword}
          totalItemCount={this.state.drivers.length}
          from={this.state.from}
          to={this.state.to}
          total={this.state.total}
          renderNewList={this.renderNewListDrivers}
          list={itemList}
          updateList={this.updateDrivers}
          changeViewLimit={this.changeViewLimit}
          limit={this.state.limit}
          currentPage={this.state.currentPage}
          fieldList={this.props.driverFilters}
          // customFieldList={this.state.customFilters}
          currentList={this.props.driverListLabel}
          listID={this.props.driverListID}
          isDefaultList={this.isDefaultList()}
          exportCSVList={this.exportDrivers}
          changeListRenderState={this.changeListRenderState}
          exporting={this.state.exporting}
          onColumnSave={this.onColumnSave}
        />
      </Container>
    );
  }
}

interface LinkStateProps {
  showNewDriverModal: boolean;
  token: string;
  store_id: number;
  driverListID: number;
  driverListLoaded: boolean;
  driverFilters: CustomFilters[];
  driverCustomListLoaded: boolean;
  custom_properties: CustomFields[];
  driverListLabel: string;
}

interface LinkDispatchProps {
  toggleNewDriverModal: () => void;
  updateDriverInitialLoad: () => void;
  getStore: (store_id: number) => void;
  addDriverCustomFilters: (filter: CustomFilters[]) => void;
  setDriverCustomFieldFilters: (filter: CustomFilters[]) => void;
  updateDriverCustomListLoad: () => void;
  fetchProperties: (property: CustomFields[]) => void;
  addDriverCustomFiltersField: (filter: FilterField[]) => void;
  updateDefaultDriverID: (id: number | string) => void;
}

const mapDispatchToProps = (
  dispatch: Dispatch<AppActions>
): LinkDispatchProps => ({
  toggleNewDriverModal: bindActionCreators(toggleNewDriverModal, dispatch),
  updateDriverInitialLoad: bindActionCreators(
    updateDriverInitialLoad,
    dispatch
  ),
  getStore: bindActionCreators(getStoreThunk, dispatch),
  addDriverCustomFilters: bindActionCreators(addDriverCustomFilters, dispatch),
  updateDriverCustomListLoad: bindActionCreators(
    updateDriverCustomListLoad,
    dispatch
  ),
  fetchProperties: bindActionCreators(fetchProperties, dispatch),
  addDriverCustomFiltersField: bindActionCreators(
    addDriverCustomFiltersField,
    dispatch
  ),
  updateDefaultDriverID: bindActionCreators(updateDefaultDriverID, dispatch),
  setDriverCustomFieldFilters: bindActionCreators(
    setDriverCustomFieldFilters,
    dispatch
  ),
});

// isAuthenticated is true if user_token exists in redux
const mapStateToProps = (state: AppState): LinkStateProps => ({
  showNewDriverModal: state.modal.showNewDriverModal,
  //temp
  store_id: state.user.store_id as number,
  token: state.auth.token as string,
  custom_properties: state.customDriverFields as CustomFields[],
  driverListLoaded: state.listViewIDs.driverListInitialLoad as boolean,
  driverListLabel: state.listViewIDs.driverLabel as string,
  driverListID: state.listViewIDs.driverListID as number,
  driverCustomListLoaded: state.customDriverFilterState
    .initialDriverCustomListLoad as boolean,
  driverFilters: state.customDriverFieldFilter as CustomFilters[],
});

export default connect(mapStateToProps, mapDispatchToProps)(Drivers);
