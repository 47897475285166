import * as React from "react";
import { connect } from "react-redux";
import { Formik, FormikProps, Form } from "formik";
import { AppActions } from "../../types";
import styled from "styled-components";
import { Button, Modal, AppText, HR, SecondaryButton } from "../UI";
import { AppState } from "../../store/configureStore";
import { Dispatch, bindActionCreators } from "redux";
import {
  PRIMARY_COLOR,
  returnEntity,
  toastError,
  toastInfo,
} from "../../utils";
import { api } from "../../api/api";
import { ImportCSVResponse } from "../../api/responseTypes";

interface MyFormikProps {
  file: {} | null;
}

interface UploadCSVProps {
  showModal: boolean;
  toggleModal: () => void;
}

interface UploadCSVState {}
type Props = UploadCSVProps & LinkDispatchProps & LinkStateProps;

class UploadCSV extends React.Component<Props, UploadCSVState> {
  render(): JSX.Element {
    return (
      <Modal
        height="fit-content"
        width="fit-content"
        showModal={this.props.showModal}
        toggleModal={this.props.toggleModal}
        label="Import order CSV"
      >
        <Formik
          initialValues={{
            file: null,
          }}
          onSubmit={async (values, { setFieldError, setStatus }) => {
            const { token, store_id } = this.props;
            const { file } = values;
            setStatus({ error: "", loading: true });
            try {
              const response = (await api.importCSV(
                store_id,
                file,
                token
              )) as ImportCSVResponse;
              const notifcation = `Orders created: ${response.nbSuccess} \n
                                  Orders duplicated: ${response.nbAlready} \n
                                  Orders failed to upload: ${response.nbFailures}`;
              toastInfo(notifcation);
            } catch (error) {
              console.log("logging error: ", error);
              setStatus({ loading: false });
            }
            console.log("no error");
            setStatus({ loading: false });
            this.props.toggleModal();
          }}
        >
          {({
            status,
            touched,
            submitForm,
            errors,
            setFieldTouched,
            setFieldValue,
          }: FormikProps<MyFormikProps>) => {
            return (
              <Form>
                <Div>
                  <Header>
                    <HeaderText>UPLOAD ORDER CSV </HeaderText>
                  </Header>
                  <HR />
                  <Grid>
                    <div
                      style={{
                        gridColumn: "1 / span 1",
                        gridRow: "1 / span 1",
                      }}
                    >
                      <input
                        id="file"
                        name="file"
                        type="file"
                        accept="text/csv"
                        onChange={(event) => {
                          setFieldValue("file", event.currentTarget.files![0]);
                        }}
                        className="form-control"
                      />
                    </div>
                    <Footer
                      style={{
                        gridColumn: "1 / span 1",
                        gridRow: "3 / span 1",
                      }}
                    >
                      <CancelButton onClick={this.props.toggleModal}>
                        Cancel
                      </CancelButton>
                      <Button
                        width="130px"
                        type="submit"
                        style={{ justifySelf: "flex-end" }}
                      >
                        Upload
                      </Button>
                    </Footer>
                  </Grid>
                </Div>
              </Form>
            );
          }}
        </Formik>
      </Modal>
    );
  }
}

const Grid = styled.div`
  display: grid;
  grid-template-columns: min-content min-content auto;
  grid-template-rows: 0px 100px 100px;
  column-gap: 24px;
  padding: 25px 40px 0 40px;
`;

const Div = styled.div`
  display: flex;
  flex-direction: column;
  border-top: solid 1px ${PRIMARY_COLOR};
  border-radius: 2px;
  overflow-x: none;
  width: 507px;
`;

const HeaderText = styled(AppText)`
  font-size: 18px;
  font-weight: 600;
  letter-spacing: 0.3px;
  color: #60999e;
`;

const Header = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height: 57px;
  padding: 0 40px 0 40px;
`;

const Footer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  height: 57px;
  padding: 0 40px 30px 40px;
`;

const CancelText = styled(AppText)`
  font-size: 13px;
  font-weight: 500;
  color: #3b3a3a;
  margin-right: 53.5px;
`;

const CancelButton = styled(Button)`
  height: 40px;
  width: 110px;
  border-radius: 4px;
  border: solid 1px rgba(71, 70, 71, 0.26);
  background-color: #fff;
  color: #000;
  margin-right: 21px;
`;

interface LinkStateProps {
  store_id: number;
  token: string;
}

interface LinkDispatchProps {}

const mapStateToProps = (state: AppState): LinkStateProps => ({
  store_id: state.user.store_id as number,
  token: state.auth.token as string,
});

const mapDispatchToProps = (
  dispatch: Dispatch<AppActions>
): LinkDispatchProps => ({});
export default connect(mapStateToProps, mapDispatchToProps)(UploadCSV);
