import React from "react";
import { Field, FieldProps, ErrorMessage } from "formik";
import { OnboardCheckbox, TestCheckbox } from "../UI";
import styled from "styled-components";

export const OnboardCheckboxField = ({
  name,
  style,
  disabled = false
}: {
  name: string;
  style?: React.CSSProperties;
  disabled?: boolean;
}) => (
  <Div style={style}>
    <Field
      name={name}
      render={({ field, form: { errors, touched } }: FieldProps) => {
        const error = !!errors.email && !!touched.email;
        return (
          <TestCheckbox
            checkboxtype={"Onboarding"}
            checked={field.value}
            {...field}
            error={error}
            disabled={disabled}
          />
        );
        // <Checkbox {...field} error={error} checked={field.value} />;
      }}
    />
  </Div>
);

const Div = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
`;
