import styled from "styled-components";
import { BACKGROUND_COLOR, PRIMARY_COLOR } from "../../utils";

interface Props {
  disabled?: boolean;
  width?: string;
  height?: string;
  backgroundColor?: string;
  padding?: string;
}

const Button = styled.button<Props>`
  width: ${(props) => props.width};
  height: ${(props) => props.height};
  border-radius: 2px;
  color: #fff;
  opacity: ${(props) => (props.disabled ? 0.3 : 1)};
  background-color: ${(props) =>
    props.disabled ? "#7f7f7f" : props.backgroundColor};
  font-weight: 500;
  font-family: ${(props) => props.theme.PRIMARY_FONT};
  font-size: 13px;
  border-width: 0;
  padding: ${(props) => props.padding};
  :focus {
    outline: none;
  }
  :hover {
    cursor: ${(props) => (props.disabled ? "not-allowed" : "pointer")};
  }
  :active {
    box-shadow: 0 3px 0 #7bc1c7;
    top: 3px;
    transform: translateY(2px);
  }
`;

Button.defaultProps = {
  disabled: false,
  width: "236.7px",
  height: "40px",
  backgroundColor: PRIMARY_COLOR,
  padding: "0",
};

const BasicButton = styled.button<Props>`
  width: ${(props) => props.width};
  height: ${(props) => props.height};
  border-radius: 2px;
  color: #fff;
  opacity: ${(props) => (props.disabled ? 0.3 : 1)};
  background-color: ${(props) =>
    props.disabled ? "#7f7f7f" : props.backgroundColor};
  font-weight: 500;
  font-family: ${(props) => props.theme.PRIMARY_FONT};
  font-size: 13px;
  border-width: 0;
  padding: ${(props) => props.padding};
  :focus {
    outline: none;
  }
  :hover {
    cursor: ${(props) => (props.disabled ? "not-allowed" : "pointer")};
  }
`;

BasicButton.defaultProps = {
  disabled: false,
  width: "236.7px",
  height: "40px",
  backgroundColor: PRIMARY_COLOR,
  padding: "0",
};

const SecondaryButton = styled(Button)`
  height: 40px;
  background-color: #fff;
  border-radius: 2px;
  border: solid 1px rgba(71, 70, 71, 0.26);
  color: #3b3a3a;
  font-family: Avenir, Lato, sans-serif;
  font-size: 10px;
  font-weight: 500;
  letter-spacing: 0.1px;
  box-sizing: border-box;
`;

export { Button, SecondaryButton, BasicButton };
