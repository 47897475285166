import {
  OrderType,
  AppActions,
  ADD_DELIVERY_RUN_ORDERS,
  UPDATE_MAP_VIEW_STATE,
  CLEAR_DELIVERY_RUN_ORDERS,
  UPDATE_AVG_LOCATION,
  ADD_DELIVERY_RUN_DRIVERS,
  Driver,
  REMOVE_DELIVERY_RUN_ORDER,
  ADD_TO_DELIVERY_RUN,
  UPDATE_SELECTED_DELIVERY_RUN_ORDERS,
  ADD_TO_SELECTED_ORDERS,
  DELETE_FROM_SELECTED_ORDERS,
  UPDATE_SELECT_STATE,
  ADD_NEW_DELIVERY_RUN,
  REMOVE_FROM_DELIVERY_RUN,
  UPDATE_DELIVERY_RUN_SCHEDULE,
  UPDATE_EXISTING_DELIVERY_RUNS,
  DELIVERY_RUNS_LOADED
} from "../types";
import {
  CreateDeliveryRun,
  DeliveryDriver,
  ImportedDeliveryRun
} from "../types/createDeliveryRun";

export const addCreateDeliveryRunOrders = (orders: OrderType[]) => ({
  type: ADD_DELIVERY_RUN_ORDERS,
  orders
});

export const addCreateDeliveryRunDrivers = (drivers: DeliveryDriver[]) => ({
  type: ADD_DELIVERY_RUN_DRIVERS,
  drivers
});
export const updateMapViewState = () => ({
  type: UPDATE_MAP_VIEW_STATE
});

export const clearDeliveryRunOrders = () => ({
  type: CLEAR_DELIVERY_RUN_ORDERS
});

export const removeDeliveryRunOrders = (orders: number[]) => ({
  type: REMOVE_DELIVERY_RUN_ORDER,
  orders
});

export const addNewDeliveryRun = (driverID: number, orderList: number[]) => ({
  type: ADD_NEW_DELIVERY_RUN,
  driverID,
  orderList
});

export const addToDeliveryRun = (
  driverID: number,
  deliveryRunID: string,
  orderList: number[]
) => ({
  type: ADD_TO_DELIVERY_RUN,
  driverID,
  deliveryRunID,
  orderList
});
export const updateAvgLocation = (location: number[]) => ({
  type: UPDATE_AVG_LOCATION,
  location
});

export const updateSelectedDeliveryRunOrder = (orderID: number) => ({
  type: UPDATE_SELECTED_DELIVERY_RUN_ORDERS,
  orderID
});

export const addToSelectedOrders = (orderID: number) => ({
  type: ADD_TO_SELECTED_ORDERS,
  orderID
});

export const deleteFromSelectedOrders = (orderID: number) => ({
  type: DELETE_FROM_SELECTED_ORDERS,
  orderID
});

export const updateSelectState = () => ({
  type: UPDATE_SELECT_STATE
});

export const removeFromDeliveryRun = ({
  orders,
  driver_id,
  delivery_run_id
}: {
  orders: OrderType[];
  driver_id: number;
  delivery_run_id: string;
}) => ({
  type: REMOVE_FROM_DELIVERY_RUN,
  orders,
  driver_id,
  delivery_run_id
});

export const updateDeliveryRunSchedule = ({
  date,
  time,
  delivery_run_id,
  driver_id,
  inactive
}: {
  date: string;
  time: string;
  delivery_run_id: string;
  driver_id: number;
  inactive: boolean;
}) => ({
  type: UPDATE_DELIVERY_RUN_SCHEDULE,
  date,
  time,
  delivery_run_id,
  driver_id,
  inactive
});

export const updateExistingDeliveryRun = (
  delivery_runs: ImportedDeliveryRun
) => ({
  type: UPDATE_EXISTING_DELIVERY_RUNS,
  delivery_runs
});

export const deliveryRunsLoaded = () => ({
  type: DELIVERY_RUNS_LOADED
});
