import { FilterField, CustomDeliveryRunFieldFilterActionTypes } from "../types";

const customDeliveryRunFieldMenuReducerDefaultState: FilterField[] = [];

const CustomDeliveryRunFieldFilterMenuReducer = (
  state = customDeliveryRunFieldMenuReducerDefaultState,
  action: CustomDeliveryRunFieldFilterActionTypes
): FilterField[] => {
  switch (action.type) {
    case "ADD_DELIVERY_RUN_FIELD_FILTER_OPTION":
      return action.filters;
    case "LOGOUT":
      return customDeliveryRunFieldMenuReducerDefaultState;
    default:
      return state;
  }
};

export default CustomDeliveryRunFieldFilterMenuReducer;
