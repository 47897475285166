import styled from "styled-components";
import { authBackgroundImage } from "../../img";

export const AuthContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: inherit;
  background-image: url(${authBackgroundImage});
  height: 100%;
  background-size: cover;
  background-attachment: fixed;
  background-position: center center;
`;
