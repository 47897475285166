import * as React from "react";
import { connect } from "react-redux";
import { Formik, FormikProps, Form } from "formik";
import * as Yup from "yup";
import { Dispatch, bindActionCreators } from "redux";
import { AppActions } from "../../types";
import { AppState } from "../../store/configureStore";
import {
  InputField,
  OnboardInputField,
  SwitchField,
  SelectField,
  CustomColorSelectField,
} from "../form";
import styled from "styled-components";
import { Button, Modal, AppText, HR, SecondaryButton, ColorBox } from "../UI";
import { addDriver, toggleNewDriverModal } from "../../actions";
import { PRIMARY_COLOR, toastError } from "../../utils";
import { ColorSelectField } from "../form/ColorSelectField";
import { languages, transportation } from "../../static";
import { api } from "../../api/api";
import { Driver, ErrorMessage } from "../../api/responseTypes";

const newDriverSchema = Yup.object().shape({
  first_name: Yup.string()
    .required("* First name is required")
    .max(100, "* Too long!"),
  last_name: Yup.string()
    .required("* Last name is required")
    .max(100, "* Too long!"),
  email: Yup.string().email("* Invalid email").required("* Email is required"),
  language: Yup.string()
    .required("* Language is required")
    .max(250, "* Too Long!"),
  transportation_type: Yup.string()
    .required("* Transportation is required")
    .max(250, "* Too Long!"),
  password: Yup.string()
    .required("* Password is required")
    .max(100, "* Too long!"),
  confirm_password: Yup.string()
    .oneOf([Yup.ref("password"), null], "* Passwords must match!")
    .required("* You must confirm your new password"),
  active: Yup.boolean(),
  color: Yup.string().notRequired(),
});

interface NewDriverProps {
  showModal: boolean;
  toggleModal: () => void;
  onComplete: (driver: Driver) => void;
}

interface NewDriverState {
  toggleCustomColorPicker: boolean;
}

interface MyFormikProps {
  first_name: string;
  last_name: string;
  email: string;
  language: string;
  password: string;
  confirm_password: string;
  active: boolean;
  color: string;
  transportation_type: string;
}

type Props = NewDriverProps & LinkDispatchProps & LinkStateProps;

class NewDriver extends React.Component<Props, NewDriverState> {
  constructor(props: Props) {
    super(props);

    this.state = {
      toggleCustomColorPicker: false,
    };
  }

  toggleCustomColor = () =>
    this.setState((prev) => ({
      toggleCustomColorPicker: !prev.toggleCustomColorPicker,
    }));

  render(): JSX.Element {
    return (
      <Modal
        height="fit-content"
        width="fit-content"
        showModal={this.props.showModal}
        toggleModal={this.props.toggleModal}
        label="Add Driver"
      >
        <Formik
          initialValues={{
            confirm_password: "",
            email: "",
            first_name: "",
            language: "",
            last_name: "",
            password: "",
            active: true,
            color: "Silver",
            transportation_type: "",
          }}
          validationSchema={newDriverSchema}
          onSubmit={async (values, { setFieldError, setStatus }) => {
            setStatus({ error: "", loading: true });
            const newDriver = await api.createDriver(
              { ...values, store_id: this.props.store_id as number },
              this.props.token as string
            );
            setStatus({ loading: false });
            if (newDriver.errors) {
              //grabs error message
              toastError(newDriver.errors[Object.keys(newDriver.errors)[0]][0]);
            } else {
              !!this.props.onComplete &&
                this.props.onComplete(newDriver as Driver);
              this.props.toggleModal();
            }
          }}
        >
          {({
            status,
            setStatus,
            submitForm,
            touched,
            errors,
            values,
          }: FormikProps<MyFormikProps>) => {
            console.log("status: ", status);
            return (
              <Div>
                <Header style={{ gridColumn: "1 / span 3" }}>
                  <HeaderText>NEW DRIVER</HeaderText>
                  <SecondaryButton width="111px">Mass Import</SecondaryButton>
                </Header>
                <HR />
                <Grid>
                  <InputField
                    style={{
                      gridColumn: "1 / span 1",
                      gridRow: "1 / span 1",
                    }}
                    name="first_name"
                    placeholder=""
                    title="First name"
                    inputWidth="250px"
                  />
                  <InputField
                    style={{
                      gridColumn: "2 / span 1",
                      gridRow: "1 / span 1",
                    }}
                    name="last_name"
                    placeholder=""
                    title="Last Name"
                    inputWidth="250px"
                  />
                  <SwitchField
                    style={{
                      gridColumn: "3 / span 1",
                      gridRow: "1 / span 1",
                    }}
                    name="active"
                    title="Set as an active driver?"
                  />
                  <SelectField
                    name="language"
                    placeholder=""
                    title="Language"
                    options={languages}
                    style={{ gridColumn: "1 / span 1", gridRow: "2 / span 1" }}
                    inputWidth={250}
                  />
                  <InputField
                    style={{
                      gridColumn: "1 / span 1",
                      gridRow: "3 / span 1",
                    }}
                    name="email"
                    placeholder=""
                    title="Email"
                    inputWidth="250px"
                  />
                  <div
                    style={{
                      gridColumn: "2 / span 2",
                      gridRow: "2, span 1",
                      display: "flex",
                      flexDirection: "row",
                    }}
                  >
                    <ColorSelectField name="color" title="Color" />
                    <CustomColorDiv onClick={this.toggleCustomColor}>
                      <ColorBox backgroundColor={values.color} />
                      <div
                        style={{
                          margin: "0px 3px",
                        }}
                      />
                      <RegularText>Custom</RegularText>
                    </CustomColorDiv>
                    {this.state.toggleCustomColorPicker && (
                      <CustomSelectColorDiv>
                        <CustomColorSelectField
                          name="color"
                          title=""
                          close={this.toggleCustomColor}
                        />
                      </CustomSelectColorDiv>
                    )}
                  </div>
                  <InputField
                    style={{ gridColumn: "2 / span 1", gridRow: "3 / span 1" }}
                    name="password"
                    placeholder=""
                    title="Set New Password"
                    inputWidth="250px"
                    type="password"
                  />
                  <InputField
                    style={{ gridColumn: "3 / span 1", gridRow: "3 / span 1" }}
                    name="confirm_password"
                    placeholder=""
                    title="Confirm New Password"
                    type="password"
                  />
                  <SelectField
                    name="transportation_type"
                    placeholder=""
                    title="Transportation"
                    options={transportation}
                    style={{ gridColumn: "1 / span 1", gridRow: "4 / span 1" }}
                    inputWidth={250}
                  />
                </Grid>
                <HR />
                <Footer>
                  <CancelText
                    clickable
                    onClick={this.props.toggleNewDriverModal}
                  >
                    Cancel
                  </CancelText>
                  <Button
                    width="130px"
                    type="submit"
                    style={{ justifySelf: "flex-end" }}
                    onClick={submitForm}
                    disabled={
                      !(
                        Object.keys(touched).length &&
                        !Object.keys(errors).length
                      )
                    }
                  >
                    Create Driver
                  </Button>
                </Footer>
              </Div>
            );
          }}
        </Formik>
      </Modal>
    );
  }
}

const Grid = styled.div`
  display: grid;
  grid-template-columns: min-content min-content auto;
  grid-template-rows: 100px 100px 100px 100px;
  column-gap: 24px;
  padding: 32px 40px 0 40px;
`;

const Div = styled.div`
  display: flex;
  flex-direction: column;
  border-top: solid 1px ${PRIMARY_COLOR};
  border-radius: 2px;
  overflow-x: none;
`;

const HeaderText = styled(AppText)`
  font-size: 18px;
  font-weight: 600;
  letter-spacing: 0.3px;
  color: #60999e;
`;

const Header = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height: 57px;
  padding: 0 40px 0 40px;
`;

const Footer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  height: 57px;
  padding: 0 40px 0 40px;
`;

const ThisButton = styled(Button)`
  height: 40px;
  background-color: #fff;
  width: auto;
  border-radius: 4px;
  border: solid 1px rgba(71, 70, 71, 0.26);
`;

const CancelText = styled(AppText)`
  font-size: 13px;
  font-weight: 500;
  color: #3b3a3a;
  margin-right: 53.5px;
`;

const CustomColorDiv = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  border-radius: 2px;
  border: solid 1px rgba(59, 58, 58, 0.25);
  background-color: #ffffff;
  height: 40px;
  padding: 0 5px;
  margin-top: 26px;
  margin-left: 10px;
  :hover {
    cursor: pointer;
  }
`;

const CustomSelectColorDiv = styled.div`
  position: fixed;
  top: 50%;
  left: 50%;
  z-index: 4;
`;

const RegularText = styled(AppText)`
  font-size: 15px;
  font-weight: normal;
`;

interface LinkStateProps {
  token: string | null;
  store_id: number | null;
}

interface LinkDispatchProps {
  // addDriver: (driver: Driver) => void;
  toggleNewDriverModal: () => void;
}

const mapStateToProps = (state: AppState): LinkStateProps => ({
  token: state.auth.token,
  store_id: state.user.store_id,
});

const mapDispatchToProps = (
  dispatch: Dispatch<AppActions>
): LinkDispatchProps => ({
  // addDriver: bindActionCreators(addDriver, dispatch),
  toggleNewDriverModal: bindActionCreators(toggleNewDriverModal, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(NewDriver);
