import React from "react";
import { Field, FieldProps, ErrorMessage } from "formik";
import styled from "styled-components";
import { Checkbox, AppText } from "../UI";
import { ErrorText } from "../Dumb";

export const CheckboxField = ({
  name,
  title,
  style,
  disabled = false
}: {
  name: string;
  title: string;
  style?: React.CSSProperties;
  disabled?: boolean;
}) => (
  <div style={style}>
    <Field
      name={name}
      render={({ field, form: { errors, touched } }: FieldProps) => {
        const error = !!errors.email && !!touched.email;
        return (
          <div>
            <Horizontal style={{ alignItems: "center" }}>
              <Checkbox
                {...field}
                error={error}
                checked={field.value}
                disabled={disabled}
              />
              <CheckboxText>{title}</CheckboxText>
            </Horizontal>
            <ErrorMessage name={field.name} component={ErrorText} />
          </div>
        );
      }}
    />
  </div>
);

const Horizontal = styled.div`
  display: flex;
`;

const CheckboxText = styled(AppText)`
  color: #989898;
  font-size: 13px;
  line-height: 1.86;
`;
