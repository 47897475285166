import * as React from "react";
import styled from "styled-components";
import { connect } from "react-redux";
import { AppState } from "../../../store/configureStore";
import { Dispatch, bindActionCreators } from "redux";
import {
  HERE_MAPS_APP_CODE,
  HERE_MAPS_APP_ID,
  returnStoreMarker,
  returnTruckMarker,
} from "../../../utils";
import { OrderType } from "../../../types";
import { groupCSS } from "react-select/lib/components/Group";
import { marker, selectedMarker } from "../../../img";
import {
  returnMarker,
  orderInfoBubble,
  driverInfoBubble,
} from "../../../utils/";
import { Driver, MicroDriverResponse } from "../../../api/responseTypes";

declare global {
  interface Window {
    H: any;
  }
}

interface center {
  lat: number;
  lng: number;
}
interface HereMapsProps {
  lat: number;
  lng: number;
  zoom: number;
  orders?: OrderType[];
  drivers?: MicroDriverResponse[];
  selectedDashBoardRoute?: {
    latitude: number;
    longitude: number;
    status: string;
  }[];
  theme?: string;
  style?: string;
  fromDashBoard?: boolean;
}

interface HereMapsState {
  app_id: string;
  app_code: string;
  center: center;
  zoom: number;
  map: any;
  behavior: any;
  events: any;
  ui: any;
  theme: string;
  style: string;
  useHTTPS: boolean;
  intialOrderMarkerRender: boolean;
  initialDriverMarkerRender: boolean;
}

type Props = HereMapsProps & LinkStateProps;

class HereMaps extends React.Component<Props, HereMapsState> {
  orderMarkerGroup: any;
  driverMarkerGroup: any;
  orderRouteLine: any;
  platform: any;
  map: any;
  linestring: any;

  constructor(props: Props) {
    super(props);

    this.orderMarkerGroup = null;
    this.driverMarkerGroup = null;
    this.orderRouteLine = null;
    this.platform = null;
    this.map = null;
    this.linestring = null;

    this.state = {
      app_id: HERE_MAPS_APP_ID,
      app_code: HERE_MAPS_APP_CODE,
      center: {
        lat: props.lat,
        lng: props.lng,
      },
      zoom: props.zoom,
      map: null,
      events: null,
      behavior: null,
      ui: null,
      useHTTPS: true,
      theme: props.theme ? props.theme : "",
      style: props.style ? props.style : "",
      intialOrderMarkerRender: false,
      initialDriverMarkerRender: false,
    };
  }
  componentDidMount = () => {
    const { orders, drivers, fromDashBoard } = this.props;
    this.platform = this.getPlatform();
    const layers = this.platform.createDefaultLayers();
    const element = document.getElementById("here-map");
    let map = this.getMap(element, layers.normal.map, {
      center: this.state.center,
      zoom: this.state.zoom,
    });

    window.addEventListener("resize", () => {
      map.getViewPort().resize();
    });

    this.orderMarkerGroup = new window.H.map.Group();
    this.driverMarkerGroup = new window.H.map.Group();
    this.orderRouteLine = new window.H.map.Group();
    // let marker = new window.H.map.Marker({ lat: 40.72, lng: -73.99 });
    // map.addObject(marker);
    const events = this.getEvents(map);
    // eslint-disable-next-line
    const behavior = this.getBehavior(events);
    // eslint-disable-next-line
    const ui = this.getUI(map, layers);
    orders && this.mapOrderMarkers(orders, map, ui);
    drivers && this.mapDriverMarkers(drivers, map, ui);
    this.setState({ events, behavior, ui, map });
    if (fromDashBoard) {
      this.mapOrderRoutes(
        this.props.selectedDashBoardRoute as {
          latitude: number;
          longitude: number;
          status: string;
        }[],
        map
      );
    }
  };

  getPlatform = () => {
    return new window.H.service.Platform(this.state);
  };

  getMap = (container, layers, settings) => {
    return new window.H.Map(container, layers, settings);
  };

  getEvents = (map) => {
    return new window.H.mapevents.MapEvents(map);
  };

  getBehavior = (events) => {
    return new window.H.mapevents.Behavior(events);
  };

  getUI = (map, layers) => {
    return new window.H.ui.UI.createDefault(map, layers);
  };

  mapOrderMarkers = (orders: OrderType[], map, ui) => {
    const { storeLat, storeLng } = this.props;
    const maps = [] as any[];
    orders.forEach((order) => {
      const baseMarker = returnMarker({
        cases: order.num_boxes as number,
        color: "#17c671",
      });
      const selectedMarker = returnMarker({
        cases: order.num_boxes as number,
        color: "#fca503",
      });

      const lat = order.address.latitude;
      const lng = order.address.longitude;
      const marker = new window.H.map.Marker(
        { lat: lat, lng: lng },
        { icon: order.selected ? selectedMarker : baseMarker }
      );
      marker.setData(orderInfoBubble(order));
      maps.push(marker);
    });
    const homeMarker = new window.H.map.Marker(
      { lat: storeLat, lng: storeLng },
      { icon: returnStoreMarker() }
    );
    maps.push(homeMarker);
    this.orderMarkerGroup.addObjects(maps);
    map.addObject(this.orderMarkerGroup);
    if (!this.state.intialOrderMarkerRender) {
      this.orderMarkerGroup.addEventListener(
        "tap",
        function (evt) {
          const bubble = new window.H.ui.InfoBubble(evt.target.getPosition(), {
            content: evt.target.getData(),
          });

          ui.addBubble(bubble);
        },
        false
      );
    }
    if (maps.length > 1) {
      map.setViewBounds(this.orderMarkerGroup.getBounds());
    }
    this.setState({
      intialOrderMarkerRender: true,
    });
  };

  mapOrderRoutes = (
    orders: {
      latitude: number;
      longitude: number;
      status: string;
    }[],
    map
  ) => {
    const { storeLat, storeLng } = this.props;

    let routingParameters = {
      // The routing mode:
      mode: "fastest;car",
      // The start point of the route:
      waypoint0: `geo!${storeLat},${storeLng}`,
      // The end point of the route:
      // To retrieve the shape of the route we choose the route
      // representation mode 'display'
      representation: "display",
    };

    orders.forEach((order, index) => {
      routingParameters[
        `waypoint${index + 1}`
      ] = `geo!${order.latitude},${order.longitude}`;
    });
    routingParameters[
      `waypoint${orders.length + 1}`
    ] = `geo!${storeLat},${storeLng}`;

    const onResult = (result) => {
      let route, routeShape;
      if (result.response.route) {
        let linestring = new window.H.geo.LineString();

        // Pick the first route from the response:
        route = result.response.route[0];
        // Pick the route's shape:
        routeShape = route.shape;

        // Push all the points in the shape into the linestring:
        routeShape.forEach(function (point) {
          let parts = point.split(",");
          linestring.pushLatLngAlt(parts[0], parts[1]);
        });

        // Retrieve the mapped positions of the requested waypoints:

        // Create a polyline to display the route:
        const routeLine = new window.H.map.Polyline(linestring, {
          style: { strokeColor: "#3079b5", lineWidth: 4 },
          arrows: { fillColor: "#17c671", width: 3, length: 3, frequency: 3 },
        });

        this.orderRouteLine.addObject(routeLine);
        // Add the route polyline and the two markers to the map:
        map.addObject(this.orderRouteLine);

        // Set the map's viewport to make the whole route visible:
        map.getViewModel().setCameraData({ bounds: routeLine.getBounds() });
      }
    };

    const router = this.platform.getRoutingService();

    // Call calculateRoute() with the routing parameters,
    // the callback and an error callback function (called if a
    // communication error occurs):
    router.calculateRoute(routingParameters, onResult, function (error) {
      alert(error.message);
    });
  };

  mapDriverMarkers = (driver: MicroDriverResponse[], map, ui) => {
    let maps = [] as any[];
    //TODO need to update driver icon
    let driverIcon = returnTruckMarker();

    driver.forEach((driver) => {
      let lat = driver.lat;
      let lng = driver.long;
      let marker = new window.H.map.Marker(
        { lat: lat, lng: lng },
        { icon: driverIcon }
      );
      // marker.setData(driverInfoBubble(driver));
      maps.push(marker);
    });
    this.driverMarkerGroup.addObjects(maps);
    map.addObject(this.driverMarkerGroup);
    if (!this.state.initialDriverMarkerRender) {
      this.driverMarkerGroup.addEventListener(
        "tap",
        function (evt) {
          const bubble = new window.H.ui.InfoBubble(evt.target.getPosition(), {
            content: evt.target.getData(),
          });

          ui.addBubble(bubble);
        },
        false
      );
    }

    this.setState({
      initialDriverMarkerRender: true,
    });
  };

  componentDidUpdate = (prevProps, prevState) => {
    const { drivers, orders, fromDashBoard } = this.props;
    const { ui } = this.state;
    if (this.props.orders !== prevProps.orders) {
      this.orderMarkerGroup.removeAll();
      orders && this.mapOrderMarkers(orders, this.state.map, this.state.ui);
    }
    if (this.props.drivers !== prevProps.drivers) {
      this.driverMarkerGroup.removeAll();
      drivers && this.mapDriverMarkers(drivers, this.state.map, this.state.ui);
    }
    if (
      this.props.selectedDashBoardRoute !== prevProps.selectedDashBoardRoute &&
      fromDashBoard
    ) {
      ui.getBubbles().forEach((bubble) => ui.removeBubble(bubble));
      this.orderRouteLine.removeAll();
      //rerender map routes
      this.mapOrderRoutes(
        this.props.selectedDashBoardRoute as {
          latitude: number;
          longitude: number;
          status: string;
        }[],
        this.state.map
      );
    }
    // if(this.props.orders !== prevProps.orders) {
    //   this.driverMarkerGroup.removeAll();
    //   drivers && this.mapDriverMarkers(drivers, this.state.map, this.state.ui)
    // }
  };

  render() {
    return <HereMap id="here-map" />;
  }
}

const HereMap = styled.div`
  width: 100%;
  height: 100%;
  background: grey;
  z-index: -99;
`;

interface LinkStateProps {
  storeLat: number;
  storeLng: number;
}

const mapStateToProps = (state: AppState): LinkStateProps => ({
  storeLat: state.store.address.latitude,
  storeLng: state.store.address.longitude,
});

export default connect(mapStateToProps, null)(HereMaps);
