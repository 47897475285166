import styled from "styled-components";

type Props = {
  width?: string;
};

const SpaceBetween = styled.div<Props>`
  width: ${props => props.width};
`;

SpaceBetween.defaultProps = {
  width: "14px"
};

export { SpaceBetween };
