export const resolvers = {
  Mutation: {}
};
export const defaults = {
  user: {
    __typename: "user",
    id: 0
  }
};

export const typeDefs = `
  type user {
    id: Int!
  }
  type Mutation {
    alterId(id: Int!): user
  }
  type Query {
    visibilityFilter: String
    getUser: user
  }
`;
