import * as React from "react";
import styled from "styled-components";
import * as Yup from "yup";
import { AppTextRoman, SecondaryButton, Button, HR } from "../../UI";
import { Filter, AddFilterType, FilterField } from "../../../types";
import { Formik, FormikProps } from "formik";
import { SelectField, InputField, FormDatePicker } from "../../form";
import { titles_stub, driver_field } from "../../Pages/Drivers";
import { operators } from "../../../static";
import { Dispatch, bindActionCreators } from "redux";
import { connect } from "react-redux";
import { AppState } from "../../../store/configureStore";
import { AppActions, OperatorFilter } from "../../../types";
import { api } from "../../../api/api";
import { returnEntity } from "../../../utils/helpers";
import moment from "moment";

const titles = titles_stub.map((title) => ({
  label: title,
  value: title,
  data: undefined,
}));

const selectField = driver_field.map((title) => ({
  label: title.label,
  value: title.label,
  data: title.operator_type,
}));
const addFilterSchema = Yup.object().shape({
  field: Yup.string().required("* Please select a field"),
  // operator: Yup.string().required("* Please select an operator"),
  // value: Yup.string().required("* Please select a value"),
});

interface AddFilterMenuProps {
  visible: boolean;
  toggleMenu: () => void;
  onComplete?: (filter: Filter) => void;
  field?: string;
  operator?: string;
  value?: string;
  filterType: string | undefined;
  store_id: number | null;
  token: string | null;
  addToFilterList: (filter: Filter) => void;
}

interface AddFilterMenuState {
  operators: OperatorFilter | null;
  fieldType: string;
}

interface MyFormikProps {
  field?: string;
  operator?: string;
  value?: string;
  startDate?: string;
  endDate?: string;
}

type Props = AddFilterMenuProps & LinkStateProps & LinkDispatchProps;

class AddFilterMenu extends React.Component<Props, AddFilterMenuState> {
  constructor(props: Props) {
    super(props);
    this.state = {
      operators: null,
      fieldType: "",
    };
  }
  componentDidMount = async () => {
    const { token } = this.props;
    const operators = (await api.getFilterOperator(token)) as OperatorFilter;
    this.setState({
      operators: operators,
    });
  };
  onComplete = (filter: Filter) => {};
  returnSelectField = (type) => {
    const {
      driverFilterMenu,
      orderFilterMenu,
      deliveryRunFilterMenu,
      deliveryFilterMenu,
    } = this.props;
    switch (type) {
      case "Drivers":
        return driverFilterMenu.map((title) => ({
          //label vs value
          label: title.label,
          value: title.value,
          data: title.operator_type,
          listValues: title.listValues,
        }));
      case "Orders":
        return orderFilterMenu.map((title) => ({
          //label vs value
          label: title.label,
          value: title.value,
          data: title.operator_type,
          listValues: title.listValues,
        }));
      case "Delivery Runs":
        return deliveryRunFilterMenu.map((title) => ({
          //label vs value
          label: title.label,
          value: title.value,
          data: title.operator_type,
          listValues: title.listValues,
        }));
      case "Deliveries":
        return deliveryFilterMenu.map((title) => ({
          //label vs value
          label: title.label,
          value: title.value,
          data: title.operator_type,
          listValues: title.listValues,
        }));
      default:
        return [];
    }
  };
  setFieldType = (type, list) => {
    const field_type = list.filter((field) => {
      return field.value === type;
    });
    const data = field_type.length > 0 ? field_type[0].data : "none";

    if (this.state.fieldType !== data) {
      this.setState({
        fieldType: data,
      });
    }
  };
  returnOperatorField = (type) => {
    if (this.state.operators && type !== "none") {
      return this.state.operators.type_operators[`${type}`].map((type) => ({
        //label vs value
        label: this.state.operators
          ? this.state.operators.labels[`${type}`]
          : type,
        value: this.state.operators ? type : type,
        data: this.state.operators
          ? this.state.operators.labels[`${type}`]
          : type,
      }));
    }
  };

  returnListValues = (fieldValue: string | undefined) => {
    const { filterType } = this.props;
    if (!!fieldValue) {
      const listValue = this.returnSelectField(filterType)
        .filter((fields) => {
          return fields.value === fieldValue;
        })
        .map((field) => {
          return field.listValues;
        })[0]
        .map((values) => {
          return {
            label: values.value,
            value: values.value,
            data: values.value,
          };
        });

      return listValue;
    }
    return [];
  };

  render() {
    const {
      visible,
      toggleMenu,
      onComplete,
      field,
      value,
      operator,
    } = this.props;
    console.log("FIELD TYPE", this.state.fieldType);
    return (
      <Formik
        initialValues={{
          field,
          operator,
          value,
        }}
        validationSchema={addFilterSchema}
        onSubmit={async (values, { setFieldError, setStatus }) => {
          const { field, operator, value, startDate, endDate } = values;
          const { filterType, token, addToFilterList } = this.props;
          const entity = returnEntity(filterType as string);
          try {
            const newFilter =
              this.state.fieldType === "date"
                ? ((await api.createFilter(
                    {
                      entity_name: entity as string,
                      slug: field as string,
                      label: field as string,
                      operator: "between",
                      value: `${moment(startDate).unix()}, ${moment(
                        endDate
                      ).unix()}` as string,
                    },
                    token as string
                  )) as AddFilterType)
                : ((await api.createFilter(
                    {
                      entity_name: entity as string,
                      slug: field as string,
                      label: field as string,
                      operator: operator !== undefined ? operator : "date",
                      value: value as string,
                    },
                    token as string
                  )) as AddFilterType);
            //filter to add top list of existing filters
            const filterToAdd = {
              id: newFilter.id,
              field: newFilter.slug,
              operator: newFilter.operator,
              value: newFilter.value,
            };
            console.log("filter to Add", filterToAdd);
            addToFilterList(filterToAdd);
          } catch (error) {
            console.log(error);
          }
          toggleMenu();
          setStatus({ error: "", loading: true });
          setStatus({ loading: false });
        }}
      >
        {({
          values,
          status,
          setStatus,
          submitForm,
        }: FormikProps<MyFormikProps>) => {
          const fieldList = this.returnSelectField(this.props.filterType);
          const listValues = this.returnListValues(values.field);

          return (
            <Main visible={visible}>
              <FieldDiv>
                <SelectField
                  name="field"
                  placeholder="Select field"
                  title="Field"
                  options={fieldList}
                  inputWidth={358}
                  additionalOnChange={
                    this.setFieldType(values.field, fieldList) as any
                    // this.testFunc("a string") as any
                  }
                />
              </FieldDiv>
              {this.state.fieldType !== "date" && (
                <FieldDiv>
                  <SelectField
                    name="operator"
                    placeholder="Select operator"
                    title="Operator"
                    options={
                      this.returnOperatorField(this.state.fieldType) as any
                    }
                    inputWidth={358}
                  />
                </FieldDiv>
              )}
              {this.state.fieldType === "dropdown" ? (
                <FieldDiv>
                  <SelectField
                    name="value"
                    placeholder="Select a Value"
                    title="Value"
                    options={listValues as any}
                    inputWidth={358}
                  />
                </FieldDiv>
              ) : this.state.fieldType === "date" ? (
                <FieldDiv>
                  <FormDatePicker name="startDate" title="Start Date" />
                  <FormDatePicker name="endDate" title="End Date" />
                </FieldDiv>
              ) : (
                <FieldDiv>
                  <InputField
                    name="value"
                    placeholder="Input a Value"
                    title="Value"
                    inputWidth="358px"
                  />
                </FieldDiv>
              )}

              <Spread style={{ paddingRight: "20px" }}>
                <SaveAsButton width="164px" onClick={toggleMenu}>
                  Cancel
                </SaveAsButton>
                <Button type="submit" width="164px" onClick={submitForm}>
                  Done
                </Button>
              </Spread>
            </Main>
          );
        }}
      </Formik>
    );
  }
}

interface MainProps {
  visible?: boolean;
}

const Main = styled.div<MainProps>`
  position: fixed;
  z-index: 1;
  right: 450px;
  bottom: 200px;
  transform: ${(props) =>
    props.visible ? "translate3d(0vw, 0, 0)" : "translate3d(100vw, 0, 0)"};
  transition: transform 0.3s cubic-bezier(0, 0.52, 0, 1);
  width: 436px;
  height: 448.5px;
  border-radius: 4px;
  border: solid 1px rgba(71, 70, 71, 0.26);
  background-color: #ffffff;
  padding: 30px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: stretch;
`;

Main.defaultProps = {
  visible: false,
};

const Spread = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

const Overflow = styled.div`
  max-height: 400px;
  overflow-y: auto;
  margin-bottom: 10px;
`;

const Clickable = styled.div`
  :hover {
    cursor: pointer;
  }
`;

const FiltersTitle = styled(AppTextRoman)`
  font-size: 15px;
  letter-spacing: 0.2px;
  color: #3b3a3a;
`;

const FilterActionText = styled(AppTextRoman)`
  font-size: 15px;
  letter-spacing: 0.2px;
  color: #60999e;
`;

const SaveAsButton = styled(SecondaryButton)`
  font-size: 13px;
  font-weight: 500;
  color: #3b3a3a;
`;

const FieldDiv = styled.div`
  /* margin: 5px 0px; */
`;

const ThisHR = styled(HR)`
  margin: 10px 0 20px 0;
  opacity: 0.5;
`;

interface LinkStateProps {
  store_id: number | null;
  token: string | null;
  driverFilterMenu: FilterField[];
  orderFilterMenu: FilterField[];
  deliveryRunFilterMenu: FilterField[];
  deliveryFilterMenu: FilterField[];
}

interface LinkDispatchProps {}

const mapStateToProps = (state: AppState): LinkStateProps => ({
  store_id: state.user.store_id,
  token: state.auth.token,
  driverFilterMenu: state.customDriverFieldFilterMenu,
  orderFilterMenu: state.customOrderFieldFilterMenu,
  deliveryRunFilterMenu: state.customDeliveryRunFieldFilterMenu,
  deliveryFilterMenu: state.customDeliveryFieldFilterMenu,
});

const mapDispatchToProps = (
  dispatch: Dispatch<AppActions>
): LinkDispatchProps => ({});

export default connect(mapStateToProps, mapDispatchToProps)(AddFilterMenu);
