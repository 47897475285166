import {
  AppActions,
  Onboarding,
  SET_ONBOARDING_PREFS,
  SET_ONBOARDING_PAGE,
  CLEAR_ONBOARDING_PREFS
} from "../types";
import { Dispatch } from "redux";
import { AppState } from "../store/configureStore";
import { ONBOARDING_NUM_PAGES } from "../utils";
import { login } from ".";

export const setOnboardingPrefs = (
  onboardingPrefs: Onboarding
): AppActions => ({
  type: SET_ONBOARDING_PREFS,
  onboardingPrefs
});

export const setOnboardingPage = (page: number): AppActions => ({
  type: SET_ONBOARDING_PAGE,
  page
});

export const clearOnboardingPrefs = (): AppActions => ({
  type: CLEAR_ONBOARDING_PREFS
});

export const startSetOnboardingPrefs = (prefs: Object) => {
  return async (dispatch: Dispatch<AppActions>, getState: () => AppState) => {
    const currentPrefs = getState().onboarding;
    dispatch(setOnboardingPrefs({ ...currentPrefs, ...prefs }));
  };
};

export const onboardNextPage = () => {
  return async (dispatch: Dispatch<AppActions>, getState: () => AppState) => {
    const currentPage = getState().onboarding.currentPage;
    // If you are already on the last page, do not advance currentPage counter
    if (currentPage >= ONBOARDING_NUM_PAGES - 1) {
      return;
    }
    // Else, advance page counter
    dispatch(setOnboardingPage(currentPage + 1));
  };
};

export const onboardPrevPage = () => {
  return async (dispatch: Dispatch<AppActions>, getState: () => AppState) => {
    const currentPage = getState().onboarding.currentPage;
    // If you are already on the first page, do not advance currentPage counter
    if (currentPage <= 0) {
      return;
    }
    // Else, advance page counter
    dispatch(setOnboardingPage(currentPage - 1));
  };
};
