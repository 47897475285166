import React, { Component } from "react";
import { Provider } from "react-redux";
import { ApolloProvider } from "react-apollo";
import { createGlobalStyle } from "styled-components";
import { store, persistor } from "./store/configureStore";
import { PersistGate } from "redux-persist/integration/react";
import AppRouter from "../src/routers/AppRouter";
import { ThemeProvider } from "styled-components";
import * as Sentry from "@sentry/browser";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { SENTRY_ID, theme, PRIMARY_FONT_URL } from "./utils";
import { client } from "./apollo/init";

// * Sentry Bug Tracking
Sentry.init({
  dsn: SENTRY_ID,
  environment: process.env.REACT_APP_ENV,
  // Get use feedback after an event occurs
  beforeSend(event) {
    if (event.exception) {
      Sentry.showReportDialog();
    }
    return event;
  },
});

// * Styled Components Global Styles
const GlobalStyles = createGlobalStyle`
  html{
    @import url('https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,300;0,400;0,700;1,300;1,400;1,700&display=swap');
    margin: 0;
    min-height: 100vh;
    /* height: 100vh; */
  }
  body, #root {
    /* min-height: 100%; */
    height: inherit;
    min-height: inherit;
    margin: 0;
  }
  div {
    box-sizing: border-box;
  }
`;

class App extends Component {
  render() {
    return (
      <ApolloProvider client={client}>
        <Provider store={store}>
          <PersistGate loading={null} persistor={persistor}>
            <GlobalStyles />
            <ToastContainer position="top-center" newestOnTop={true} />
            <ThemeProvider theme={theme}>
              <AppRouter />
            </ThemeProvider>
          </PersistGate>
        </Provider>
      </ApolloProvider>
    );
  }
}

export default App;
