import {
  User,
  Consumer,
  Driver,
  LoggedUser,
  CustomFields,
  CustomFilters,
  OrderType,
  ImportedDeliveryRun,
  DeliveryDriver,
  SelectedDashboard,
  DeliveryRuns,
  UpdateModal,
} from ".";
import { Onboarding } from "./onboarding";
import { UserStore } from "./store";
import {
  CustomFieldByEntity,
  CustomFieldFilterMenu,
  FilterField,
} from "./customFields";
import { DeliveryRunResponse } from "../api/responseTypes";

export const FETCH_USER = "FETCH_USER";
export const EDIT_USER = "EDIT_USER";
export const EDIT_USER_PHONE = "EDIT_USER_PHONE";
export const GET_USER = "GET_USER";

export interface FetchUserAction {
  type: typeof FETCH_USER;
  user: User;
}

export interface EditUserAction {
  type: typeof EDIT_USER;
  user: LoggedUser;
}

export interface EditUserPhoneAction {
  type: typeof EDIT_USER_PHONE;
  phoneNumber;
}

export interface GetUserAction {
  type: typeof GET_USER;
  //TODO, confirm user object
  user: LoggedUser;
}

export type UserActionTypes =
  | FetchUserAction
  | EditUserAction
  | EditUserPhoneAction
  | LoginAction
  | LogoutAction
  | GetUserAction;

export const ADD_CONSUMER = "ADD_CONSUMER";
export const EDIT_CONSUMER = "EDIT_CONSUMER";
export const FETCH_CONSUMERS = "FETCH_CONSUMERS";
export const REMOVE_CONSUMER = "REMOVE_CONSUMER";

export interface AddConsumerAction {
  type: typeof ADD_CONSUMER;
  consumer: Consumer;
}

export interface EditConsumerAction {
  type: typeof EDIT_CONSUMER;
  consumer: Consumer;
  user_id: number;
}

export interface FetchConsumersAction {
  type: typeof FETCH_CONSUMERS;
  consumers: Consumer[];
}

export interface RemoveConsumerAction {
  type: typeof REMOVE_CONSUMER;
  user_id: number;
}

export type ConsumerActionTypes =
  | AddConsumerAction
  | EditConsumerAction
  | FetchConsumersAction
  | RemoveConsumerAction
  | LogoutAction;

export const ADD_DRIVER = "ADD_DRIVER";
export const EDIT_DRIVER = "EDIT_DRIVER";
export const FETCH_DRIVER = "FETCH_DRIVER";
export const FETCH_DRIVERS = "FETCH_DRIVERS";
export const REMOVE_DRIVER = "REMOVE_DRIVER";

export interface AddDriverAction {
  type: typeof ADD_DRIVER;
  driver: Driver;
}

export interface EditDriverAction {
  type: typeof EDIT_DRIVER;
  driver: Driver;
  driver_id: number;
}

export interface FetchDriverAction {
  type: typeof FETCH_DRIVER;
  driver: Driver;
}

export interface FetchDriversAction {
  type: typeof FETCH_DRIVERS;
  drivers: Driver[];
}

export interface RemoveDriverAction {
  type: typeof REMOVE_DRIVER;
  driver_id: number;
}

export type DriverActionTypes =
  | AddDriverAction
  | EditDriverAction
  | FetchDriverAction
  | FetchDriversAction
  | RemoveDriverAction
  | LogoutAction;

export const LOGIN = "LOGIN";
export const LOGOUT = "LOGOUT";
export const ERROR = "ERROR";

export interface LoginAction {
  type: typeof LOGIN;
  access_token: string;
  is_registered: number | null;
  store_id?: number;
}

export interface LogoutAction {
  type: typeof LOGOUT;
}

export interface ErrorAction {
  type: typeof ERROR;
  error: string;
}

export type AuthActionTypes = LoginAction | LogoutAction | ErrorAction;

export const TOGGLE_NEW_DRIVER_MODAL = "TOGGLE_NEW_DRIVER_MODAL";
export const TOGGLE_NEW_ORDER_MODAL = "TOGGLE_NEW_ORDER_MODAL";
export const TOGGLE_COLUMN_SWITCH_MODAL = "TOGGLE_COLUMN_SWITCH_MODAL";
export const TOGGLE_DELIVERY_RUN_DETAIL_MODAL =
  "TOGGLE_DELIVERY_RUN_DETAIL_MODAL";

export interface ToggleNewDriverModalAction {
  type: typeof TOGGLE_NEW_DRIVER_MODAL;
}

export interface ToggleNewOrderModalAction {
  type: typeof TOGGLE_NEW_ORDER_MODAL;
}

export interface ToggleColumnSwitchModalAction {
  type: typeof TOGGLE_COLUMN_SWITCH_MODAL;
}

export interface ToggleDeliveryRunDetailModal {
  type: typeof TOGGLE_DELIVERY_RUN_DETAIL_MODAL;
}

export type ModalActionTypes =
  | ToggleNewDriverModalAction
  | ToggleNewOrderModalAction
  | ToggleColumnSwitchModalAction
  | ToggleDeliveryRunDetailModal
  | LogoutAction;

export const SET_TEXT_FILTER = "SET_TEXT_FILTER";
export const SET_FILTER = "SET_FILTER";
export const SET_SELECTED_CHANNEL = "SET_SELECTED_CHANNEL";

export interface SetTextFilterAction {
  type: typeof SET_TEXT_FILTER;
  textFilter: string;
}

export interface SetSelectedChannelAction {
  type: typeof SET_SELECTED_CHANNEL;
  selectedChannel: number;
}

export interface SetFilterAction {
  type: typeof SET_FILTER;
}

export type FilterActionTypes =
  | SetTextFilterAction
  | SetFilterAction
  | SetSelectedChannelAction
  | LogoutAction;

export const SET_ONBOARDING_PAGE = "SET_ONBOARDING_PAGE";
export const SET_ONBOARDING_PREFS = "SET_ONBOARDING_PREFS";
export const CLEAR_ONBOARDING_PREFS = "CLEAR_ONBOARDING_PREFS";

export interface SetOnboardingPrefsAction {
  type: typeof SET_ONBOARDING_PREFS;
  onboardingPrefs: Onboarding;
}

export interface SetOnboardingPageAction {
  type: typeof SET_ONBOARDING_PAGE;
  page: number;
}

export interface ClearOnboardingPrefs {
  type: typeof CLEAR_ONBOARDING_PREFS;
}

export type OnboardingActionTypes =
  | SetOnboardingPrefsAction
  | SetOnboardingPageAction
  | ClearOnboardingPrefs
  | LogoutAction;

//Change 6/27 for store

export const GET_STORE = "GET_STORE";
export const UPDATE_STORE = "UPDATE_STORE";

export interface GetStoreAction {
  type: typeof GET_STORE;
  store: UserStore;
}

export interface UpdateStoreAction {
  type: typeof UPDATE_STORE;
  store: UserStore;
}
export type StoreActionTypes =
  | GetStoreAction
  | UpdateStoreAction
  | LogoutAction;

export const UPDATE_ORDER_LIST = "UPDATE_ORDER_LIST";
export const UPDATE_ORDER_FILTERS = "UPDATE_ORDER_FILTERS";
export const UPDTATE_ORDER_LIST_INITIAL_LOAD = "UPDATE_ORDER_LIST_INITIAL_LOAD";
export const UPDATE_ORDER_LIST_LABEL = "UPDATE_ORDER_LIST_LABEL";
export const UPDATE_DEFAULT_ORDER_ID = "UPDATE_DEFAULT_ORDER_ID";
export const UPDATE_DRIVER_LIST = "UPDATE_DRIVER_LIST";
export const UPDATE_DRIVER_FILTERS = "UPDATE_DRIVER_FILTERS";
export const UPDATE_DRIVER_LIST_INITIAL_LOAD =
  "UPDATE_DRIVER_LIST_INITIAL_LOAD";
export const UPDATE_DRIVER_LIST_LABEL = "UPDATE_DRIVER_LIST_LABEL";
export const UPDATE_DEFAULT_DRIVER_ID = "UPDATE_DEFAULT_DRIVER_ID";
export const UPDATE_DELIVERY_RUN_LIST = "UPDATE_DELIVERY_RUN_LIST";
export const UPDATE_DELIVERY_RUN_FILTERS = "UPDATE_DELIVERY_RUN_FILTERS";
export const UPDATE_DELIVERY_RUN_LIST_INITIAL_LOAD =
  "UPDATE_DELIVERY_RUN_LIST_INITIAL_LOAD";
export const UPDATE_DELIVERY_RUN_LIST_LABEL = "UPDATE_DELIVERY_RUN_LIST_LABEL";
export const UPDATE_DEFAULT_DELIVERY_RUN_ID = "UPDATE_DEFAULT_DELIVERY_RUN_ID";
export const UPDATE_DELIVERY_LIST = "UPDATE_DELIVERY_LIST";
export const UPDATE_DELIVERY_FILTERS = "UPDATE_DELIVERY_FILTERS";
export const UPDATE_DELIVERY_LIST_INITIAL_LOAD =
  "UPDATE_DELIVERY_LIST_INITIAL_LOAD";
export const UPDATE_DELIVERY_LIST_LABEL = "UPDATE_DELIVERY_LIST_LABEL";
export const UPDATE_DEFAULT_DELIVERY_ID = "UPDATE_DEFAULT_DELIVERY_ID";

export interface UpdateOrderList {
  type: typeof UPDATE_ORDER_LIST;
  id: number | null;
}

export interface UpdateOrderFilters {
  type: typeof UPDATE_ORDER_FILTERS;
  filters: string;
}

export interface UpdateOrderListInitialLoad {
  type: typeof UPDTATE_ORDER_LIST_INITIAL_LOAD;
}

export interface UpdateOrderListLabel {
  type: typeof UPDATE_ORDER_LIST_LABEL;
  label: string;
}

export interface UpdateDefaultOrderID {
  type: typeof UPDATE_DEFAULT_ORDER_ID;
  id: number | string;
}

export interface UpdateDriverList {
  type: typeof UPDATE_DRIVER_LIST;
  id: number | null;
}

export interface UpdateDriverFilters {
  type: typeof UPDATE_DRIVER_FILTERS;
  filters: string;
}

export interface UpdateDriverListInitialLoad {
  type: typeof UPDATE_DRIVER_LIST_INITIAL_LOAD;
}

export interface UpdateDriverListLabel {
  type: typeof UPDATE_DRIVER_LIST_LABEL;
  label: string;
}

export interface UpdateDefaultDriverID {
  type: typeof UPDATE_DEFAULT_DRIVER_ID;
  id: number | string;
}
export interface UpdateDeliveryRunList {
  type: typeof UPDATE_DELIVERY_RUN_LIST;
  id: number | null;
}
export interface UpdateDeliveryRunFilters {
  type: typeof UPDATE_DELIVERY_RUN_FILTERS;
  filters: string;
}

export interface UpdateDeliveryRunListInitialLoad {
  type: typeof UPDATE_DELIVERY_RUN_LIST_INITIAL_LOAD;
}

export interface UpdateDeliveryRunListLabel {
  type: typeof UPDATE_DELIVERY_RUN_LIST_LABEL;
  label: string;
}

export interface UpdateDefaultDeliveryRunID {
  type: typeof UPDATE_DEFAULT_DELIVERY_RUN_ID;
  id: number | string;
}

export interface UpdateDeliveryList {
  type: typeof UPDATE_DELIVERY_LIST;
  id: number | null;
}

export interface UpdateDeliveryFilters {
  type: typeof UPDATE_DELIVERY_FILTERS;
  filters: string;
}

export interface UpdateDeliveryListInitialLoad {
  type: typeof UPDATE_DELIVERY_LIST_INITIAL_LOAD;
}

export interface UpdateDeliveryListLabel {
  type: typeof UPDATE_DELIVERY_LIST_LABEL;
  label: string;
}

export interface UpdateDefaultDeliveryID {
  type: typeof UPDATE_DEFAULT_DELIVERY_ID;
  id: number | string;
}

export type ListActionTypes =
  | UpdateDriverList
  | UpdateDriverFilters
  | UpdateDriverListInitialLoad
  | UpdateDriverListLabel
  | UpdateDefaultDriverID
  | UpdateOrderList
  | UpdateOrderFilters
  | UpdateOrderListInitialLoad
  | UpdateOrderListLabel
  | UpdateDefaultOrderID
  | UpdateDeliveryRunList
  | UpdateDeliveryRunFilters
  | UpdateDeliveryRunListInitialLoad
  | UpdateDeliveryRunListLabel
  | UpdateDefaultDeliveryRunID
  | UpdateDeliveryList
  | UpdateDeliveryFilters
  | UpdateDeliveryListInitialLoad
  | UpdateDeliveryListLabel
  | UpdateDefaultDeliveryID
  | LogoutAction;

export const ADD_PROPERTY = "ADD_PROPERTY";
export const EDIT_PROPERTY = "EDIT_PROPERTY";
export const FETCH_PROPERTIES = "FETCH_PROPERTIES";
export const REMOVE_PROPERTY = "REMOVE_PROPERTY";
export const ADD_ORDER_PROPERTY = "ADD_ORDER_PROPERTY";
export const EDIT_ORDER_PROPERTY = "EDIT_ORDER_PROPERTY";
export const FETCH_ORDER_PROPERTIES = "FETCH_ORDER_PROPERTIES";
export const REMOVE_ORDER_PROPERTY = "REMOVE_ORDER_PROPERTY";
export const ADD_DELIVERY_PROPERTY = "ADD_DELIVERY_PROPERTY";
export const EDIT_DELIVERY_PROPERTY = "EDIT_DELIVERY_PROPERTY";
export const FETCH_DELIVERY_PROPERTIES = "FETCH_DELIVERY_PROPERTIES";
export const REMOVE_DELIVERY_PROPERTY = "REMOVE_DELIVERY_PROPERTY";
export const ADD_DELIVERY_RUN_PROPERTY = "ADD_DELIVERY_RUN_PROPERTY";
export const EDIT_DELIVERY_RUN_PROPERTY = "EDIT_DELIVERY_RUN_PROPERTY";
export const FETCH_DELIVERY_RUN_PROPERTIES = "FETCH_DELIVERY_RUN_PROPERTIES";
export const REMOVE_DELIVERY_RUN_PROPERTY = "REMOVE_DELIVERY_RUN_PROPERTY";

export interface AddPropertyAction {
  type: typeof ADD_PROPERTY;
  property: CustomFields;
}

export interface EditPropertyAction {
  type: typeof EDIT_PROPERTY;
  property: CustomFields;
  property_id: number;
}

export interface FetchPropertiesAction {
  type: typeof FETCH_PROPERTIES;
  property: CustomFields[];
}

export interface RemovePropertiesAction {
  type: typeof REMOVE_PROPERTY;
  property_id: number;
}

export interface AddOrderPropertyAction {
  type: typeof ADD_ORDER_PROPERTY;
  property: CustomFields;
}

export interface EditOrderPropertyAction {
  type: typeof EDIT_ORDER_PROPERTY;
  property: CustomFields;
  property_id: number;
}

export interface FetchOrderPropertiesAction {
  type: typeof FETCH_ORDER_PROPERTIES;
  property: CustomFields[];
}

export interface RemoveOrderPropertiesAction {
  type: typeof REMOVE_ORDER_PROPERTY;
  property_id: number;
}

export interface AddDeliveryPropertyAction {
  type: typeof ADD_DELIVERY_PROPERTY;
  property: CustomFields;
}

export interface EditDeliveryPropertyAction {
  type: typeof EDIT_DELIVERY_PROPERTY;
  property: CustomFields;
  property_id: number;
}

export interface FetchDeliveryPropertiesAction {
  type: typeof FETCH_DELIVERY_PROPERTIES;
  property: CustomFields[];
}

export interface RemoveDeliveryPropertiesAction {
  type: typeof REMOVE_DELIVERY_PROPERTY;
  property_id: number;
}
export interface AddDeliveryRunPropertyAction {
  type: typeof ADD_DELIVERY_RUN_PROPERTY;
  property: CustomFields;
}

export interface EditDeliveryRunPropertyAction {
  type: typeof EDIT_DELIVERY_RUN_PROPERTY;
  property: CustomFields;
  property_id: number;
}

export interface FetchDeliveryRunPropertiesAction {
  type: typeof FETCH_DELIVERY_RUN_PROPERTIES;
  property: CustomFields[];
}

export interface RemoveDeliveryRunPropertiesAction {
  type: typeof REMOVE_DELIVERY_RUN_PROPERTY;
  property_id: number;
}
export type PropertiesActionTypes =
  | AddPropertyAction
  | EditPropertyAction
  | FetchPropertiesAction
  | RemovePropertiesAction
  | AddOrderPropertyAction
  | EditOrderPropertyAction
  | FetchOrderPropertiesAction
  | RemoveOrderPropertiesAction
  | AddDeliveryPropertyAction
  | EditDeliveryPropertyAction
  | FetchDeliveryPropertiesAction
  | RemoveDeliveryPropertiesAction
  | AddDeliveryRunPropertyAction
  | EditDeliveryRunPropertyAction
  | FetchDeliveryRunPropertiesAction
  | RemoveDeliveryRunPropertiesAction
  | LogoutAction;

export const ADD_DRIVER_FIELD_FILTER = "ADD_DRIVER_FIELD_FILTER";
export const REMOVE_DRIVER_FIELD_FILTER = "REMOVE_DRIVER_FIELD_FILTER";
export const ADD_DRIVER_CUSTOM_FILTERS = "ADD_DRIVER_CUSTOM_FILTERS";
export const DELETE_DRIVER_FIELD_FILTER = "DELETE_DRIVER_FIELD_FILTER";
export const SET_DRIVER_FIELD_FILTERS = "SET_DRIVER_FIELD_FILTERS";

export interface SetDriverCustomFilters {
  type: typeof SET_DRIVER_FIELD_FILTERS;
  filters: CustomFilters[];
}

export interface AddDriverCustomFilters {
  type: typeof ADD_DRIVER_CUSTOM_FILTERS;
  filters: CustomFilters[];
}
export interface AddDriverFieldFilter {
  type: typeof ADD_DRIVER_FIELD_FILTER;
  filter: string;
}

export interface RemoveDriverFieldFilter {
  type: typeof REMOVE_DRIVER_FIELD_FILTER;
  filter: string;
}
export interface DeleteDriverFieldFilter {
  type: typeof DELETE_DRIVER_FIELD_FILTER;
  filter: string;
}

// to append to field options
export const ADD_DRIVER_FIELD_FILTER_OPTION = "ADD_DRIVER_FIELD_FILTER_OPTION";

export interface AddDriverFieldFilterOption {
  type: typeof ADD_DRIVER_FIELD_FILTER_OPTION;
  filters: FilterField[];
}

export type CustomDriverFieldFilterActionTypes =
  | AddDriverCustomFilters
  | AddDriverFieldFilter
  | RemoveDriverFieldFilter
  | DeleteDriverFieldFilter
  | AddDriverFieldFilterOption
  | SetDriverCustomFilters
  | LogoutAction;

export const UPDATE_DRIVER_CUSTOM_LIST_LOAD = "UPDATE_DRIVER_CUSTOM_LIST_LOAD";
export const RELOAD_DRIVER_CUSTOM_LIST_LOAD = "RELOAD_DRIVER_CUSTOM_LIST_LOAD";

export interface UpdateDriverCustomListLoad {
  type: typeof UPDATE_DRIVER_CUSTOM_LIST_LOAD;
}
export interface ReloadDriverCustomListLoad {
  type: typeof RELOAD_DRIVER_CUSTOM_LIST_LOAD;
}
export type DefaultCustomDriverFilterStateActionTypes =
  | ReloadDriverCustomListLoad
  | UpdateDriverCustomListLoad
  | LogoutAction;

export const UPDATE_ORDER_CUSTOM_LIST_LOAD = "UPDATE_ORDER_CUSTOM_LIST_LOAD";
export const RELOAD_ORDER_CUSTOM_LIST_LOAD = "RELOAD_ORDER_CUSTOM_LIST_LOAD";

export interface UpdateOrderCustomListLoad {
  type: typeof UPDATE_ORDER_CUSTOM_LIST_LOAD;
}
export interface ReloadOrderCustomListLoad {
  type: typeof RELOAD_ORDER_CUSTOM_LIST_LOAD;
}
export type DefaultCustomOrderFilterStateActionTypes =
  | UpdateOrderCustomListLoad
  | ReloadOrderCustomListLoad
  | LogoutAction;

export const ADD_ORDER_FIELD_FILTER = "ADD_ORDER_FIELD_FILTER";
export const REMOVE_ORDER_FIELD_FILTER = "REMOVE_ORDER_FIELD_FILTER";
export const ADD_ORDER_CUSTOM_FILTERS = "ADD_ORDER_CUSTOM_FILTERS";
export const DELETE_ORDER_FIELD_FILTER = "DELETE_ORDER_FIELD_FILTER";
export const SET_ORDER_FIELD_FILTERS = "SET_ORDER_FIELD_FILTERS";

export interface SetOrderFieldFilters {
  type: typeof SET_ORDER_FIELD_FILTERS;
  filters: CustomFilters[];
}

export interface AddOrderCustomFilters {
  type: typeof ADD_ORDER_CUSTOM_FILTERS;
  filters: CustomFilters[];
}
export interface AddOrderFieldFilter {
  type: typeof ADD_ORDER_FIELD_FILTER;
  filter: string;
}

export interface RemoveOrderFieldFilter {
  type: typeof REMOVE_ORDER_FIELD_FILTER;
  filter: string;
}
export interface DeleteOrderFieldFilter {
  type: typeof DELETE_ORDER_FIELD_FILTER;
  filter: string;
}

// to append to field options
export const ADD_ORDER_FIELD_FILTER_OPTION = "ADD_ORDER_FIELD_FILTER_OPTION";

export interface AddOrderFieldFilterOption {
  type: typeof ADD_ORDER_FIELD_FILTER_OPTION;
  filters: FilterField[];
}

export type CustomOrderFieldFilterActionTypes =
  | AddOrderCustomFilters
  | AddOrderFieldFilter
  | RemoveOrderFieldFilter
  | DeleteOrderFieldFilter
  | AddOrderFieldFilterOption
  | SetOrderFieldFilters
  | LogoutAction;

export const ADD_DELIVERY_FIELD_FILTER = "ADD_DELIVERY_FIELD_FILTER";
export const REMOVE_DELIVERY_FIELD_FILTER = "REMOVE_DELIVERY_FIELD_FILTER";
export const ADD_DELIVERY_CUSTOM_FILTERS = "ADD_DELIVERY_CUSTOM_FILTERS";
export const DELETE_DELIVERY_FIELD_FILTER = "DELETE_DELIVERY_FIELD_FILTER";
export const SET_DELIVERY_FIELD_FILTERS = "SET_DELIVERY_FIELD_FILTERS";

export interface SetDeliveryFieldFilters {
  type: typeof SET_DELIVERY_FIELD_FILTERS;
  filters: CustomFilters[];
}

export interface AddDeliveryFieldFilter {
  type: typeof ADD_DELIVERY_FIELD_FILTER;
  filter: string;
}

export interface RemoveDeliveryFieldFilter {
  type: typeof REMOVE_DELIVERY_FIELD_FILTER;
  filter: string;
}

export interface AddDeliveryCustomFilters {
  type: typeof ADD_DELIVERY_CUSTOM_FILTERS;
  filters: CustomFilters[];
}

export interface DeleteDeliveryFieldFilter {
  type: typeof DELETE_DELIVERY_FIELD_FILTER;
  filter: string;
}

// to append to field options
export const ADD_DELIVERY_FIELD_FILTER_OPTION =
  "ADD_DELIVERY_FIELD_FILTER_OPTION";

export interface AddDeliveryFieldFilterOption {
  type: typeof ADD_DELIVERY_FIELD_FILTER_OPTION;
  filters: FilterField[];
}

export type CustomDeliveryFieldFilterActionTypes =
  | AddDeliveryFieldFilter
  | RemoveDeliveryFieldFilter
  | AddDeliveryCustomFilters
  | DeleteDeliveryFieldFilter
  | AddDeliveryFieldFilterOption
  | SetDeliveryFieldFilters
  | LogoutAction;

export const ADD_DELIVERY_RUN_FIELD_FILTER = "ADD_DELIVERY_RUN_FIELD_FILTER";
export const REMOVE_DELIVERY_RUN_FIELD_FILTER =
  "REMOVE_DELIVERY_RUN_FIELD_FILTER";
export const ADD_DELIVERY_RUN_CUSTOM_FILTERS =
  "ADD_DELIVERY_RUN_CUSTOM_FILTERS";
export const DELETE_DELIVERY_RUN_FIELD_FILTER =
  "DELETE_DELIVERY_RUN_FIELD_FILTER";
export const SET_DELIVERY_RUN_FIELD_FILTERS = "SET_DELIVERY_RUN_FIELD_FILTERS";

export interface SetDeliveryRunFieldFilters {
  type: typeof SET_DELIVERY_RUN_FIELD_FILTERS;
  filters: CustomFilters[];
}

export interface AddDeliveryRunFieldFilter {
  type: typeof ADD_DELIVERY_RUN_FIELD_FILTER;
  filter: string;
}

export interface RemoveDeliveryRunFieldFilter {
  type: typeof REMOVE_DELIVERY_RUN_FIELD_FILTER;
  filter: string;
}

export interface AddDeliveryRunCustomFilters {
  type: typeof ADD_DELIVERY_RUN_CUSTOM_FILTERS;
  filters: CustomFilters[];
}

export interface DeleteDeliveryRunFieldFilter {
  type: typeof DELETE_DELIVERY_RUN_FIELD_FILTER;
  filter: string;
}

// to append to field options
export const ADD_DELIVERY_RUN_FIELD_FILTER_OPTION =
  "ADD_DELIVERY_RUN_FIELD_FILTER_OPTION";

export interface AddDeliveryRunFieldFilterOption {
  type: typeof ADD_DELIVERY_RUN_FIELD_FILTER_OPTION;
  filters: FilterField[];
}

export type CustomDeliveryRunFieldFilterActionTypes =
  | AddDeliveryRunFieldFilter
  | RemoveDeliveryRunFieldFilter
  | AddDeliveryRunCustomFilters
  | DeleteDeliveryRunFieldFilter
  | AddDeliveryRunFieldFilterOption
  | SetDeliveryRunFieldFilters
  | LogoutAction;

export const UPDATE_DELIVERY_CUSTOM_LIST_LOAD =
  "UPDATE_DELIVERY_CUSTOM_LIST_LOAD";
export const RELOAD_DELIVERY_CUSTOM_LIST_LOAD =
  "RELOAD_DELIVERY_CUSTOM_LIST_LOAD";

export interface UpdateDeliveryCustomListLoad {
  type: typeof UPDATE_DELIVERY_CUSTOM_LIST_LOAD;
}
export interface ReloadDeliveryCustomListLoad {
  type: typeof RELOAD_DELIVERY_CUSTOM_LIST_LOAD;
}
export type DefaultCustomDeliveryFilterStateActionTypes =
  | UpdateDeliveryCustomListLoad
  | ReloadDeliveryCustomListLoad
  | LogoutAction;

export const UPDATE_DELIVERY_RUN_CUSTOM_LIST_LOAD =
  "UPDATE_DELIVERY_RUN_CUSTOM_LIST_LOAD";
export const RELOAD_DELIVERY_RUN_CUSTOM_LIST_LOAD =
  "RELOAD_DELIVERY_RUN_CUSTOM_LIST_LOAD";

export interface UpdateDeliveryRunCustomListLoad {
  type: typeof UPDATE_DELIVERY_RUN_CUSTOM_LIST_LOAD;
}
export interface ReloadDeliveryRunCustomListLoad {
  type: typeof RELOAD_DELIVERY_RUN_CUSTOM_LIST_LOAD;
}
export type DefaultCustomDeliveryRunFilterStateActionTypes =
  | UpdateDeliveryRunCustomListLoad
  | ReloadDeliveryRunCustomListLoad
  | LogoutAction;

export const GET_DRIVER_CUSTOM_FIELD_VALUES = "GET_CUSTOM_FIELD_VALUES";
export const UPDATE_DRIVER_CUSTOM_FIELD_VALUE = "UPDATE_CUSTOM_FIELD_VALUE";

export interface GetDriverCustomFieldValues {
  type: typeof GET_DRIVER_CUSTOM_FIELD_VALUES;
  customValues: CustomFieldByEntity[];
}

export interface UpdateDriverCustomFieldValue {
  type: typeof UPDATE_DRIVER_CUSTOM_FIELD_VALUE;
  value: string;
  id: number;
  custom_field_id: number;
}

export type DriverCustomFieldValueActionTypes =
  | GetDriverCustomFieldValues
  | UpdateDriverCustomFieldValue
  | LogoutAction;

export const GET_ORDER_CUSTOM_FIELD_VALUES = "GET_ORDER_CUSTOM_FIELD_VALUES";
export const UPDATE_ORDER_CUSTOM_FIELD_VALUE =
  "UPDATE_ORDER_CUSTOM_FIELD_VALUE";

export interface GetOrderCustomFieldValues {
  type: typeof GET_ORDER_CUSTOM_FIELD_VALUES;
  customValues: CustomFieldByEntity[];
}

export interface UpdateOrderCustomFieldValue {
  type: typeof UPDATE_ORDER_CUSTOM_FIELD_VALUE;
  value: string;
  id: number;
  custom_field_id: number;
}

export type OrderCustomFieldValueActionTypes =
  | GetOrderCustomFieldValues
  | UpdateOrderCustomFieldValue
  | LogoutAction;

export const GET_DELIVERY_CUSTOM_FIELD_VALUES =
  "GET_DELIVERY_CUSTOM_FIELD_VALUES";
export const UPDATE_DELIVERY_CUSTOM_FIELD_VALUE =
  "UPDATE_DELIVERY_CUSTOM_FIELD_VALUE";

export interface GetDeliveryCustomFieldValues {
  type: typeof GET_DELIVERY_CUSTOM_FIELD_VALUES;
  customValues: CustomFieldByEntity[];
}

export interface UpdateDeliveryCustomFieldValue {
  type: typeof UPDATE_DELIVERY_CUSTOM_FIELD_VALUE;
  value: string;
  id: number;
  custom_field_id: number;
}

export type DeliveryCustomFieldValueActionTypes =
  | GetDeliveryCustomFieldValues
  | UpdateDeliveryCustomFieldValue
  | LogoutAction;

export const GET_DELIVERY_RUN_CUSTOM_FIELD_VALUES =
  "GET_DELIVERY_RUN_CUSTOM_FIELD_VALUES";
export const UPDATE_DELIVERY_RUN_CUSTOM_FIELD_VALUE =
  "UPDATE_DELIVERY_RUN_CUSTOM_FIELD_VALUE";

export interface GetDeliveryRunCustomFieldValues {
  type: typeof GET_DELIVERY_RUN_CUSTOM_FIELD_VALUES;
  customValues: CustomFieldByEntity[];
}

export interface UpdateDeliveryRunCustomFieldValue {
  type: typeof UPDATE_DELIVERY_RUN_CUSTOM_FIELD_VALUE;
  value: string;
  id: number;
  custom_field_id: number;
}

export type DeliveryRunCustomFieldValueActionTypes =
  | GetDeliveryRunCustomFieldValues
  | UpdateDeliveryRunCustomFieldValue
  | LogoutAction;

export const ADD_DELIVERY_RUN_ORDERS = "ADD_DELIVERY_RUN_ORDERS";
export const ADD_DELIVERY_RUN_DRIVERS = "ADD_DELIVERY_RUN_DRIVERS";
export const REMOVE_DELIVERY_RUN_ORDER = "REMOVE_DELIVERY_RUN_ORDER";
export const CLEAR_DELIVERY_RUN_ORDERS = "CLEAR_DELIVERY_RUN_ORDERS";
export const UPDATE_SELECTED_DELIVERY_RUN_ORDERS =
  "UPDATE_SELECTED_DELIVERY_RUN_ORDERS";
export const ADD_NEW_DELIVERY_RUN = "ADD_NEW_DELIVERY_RUN";
export const ADD_TO_DELIVERY_RUN = "ADD_TO_DELIVERY_RUN";
export const REMOVE_FROM_DELIVERY_RUN = "REMOVE_FROM_DELIVERY_RUN";
export const UPDATE_MAP_VIEW_STATE = "UPDATE_MAP_VIEW_STATE";
export const UPDATE_AVG_LOCATION = "UPDATE_AVG_LOCATION";
export const ADD_TO_SELECTED_ORDERS = "ADD_TO_SELECTED_ORDERS";
export const DELETE_FROM_SELECTED_ORDERS = "DELETE_FROM_SELECTED_ORDERS";
export const UPDATE_SELECT_STATE = "UPDATE_SELECT_STATE";
export const UPDATE_DELIVERY_RUN_SCHEDULE = "UPDATE_DELIVERY_RUN_SCHEDULE";
export const UPDATE_EXISTING_DELIVERY_RUNS = "UPDATE_EXISTING_DELIVERY_RUNS";
export const DELIVERY_RUNS_LOADED = "DELIVERY_RUNS_LOADED";

export interface AddDeliveryRunOrders {
  type: typeof ADD_DELIVERY_RUN_ORDERS;
  orders: OrderType[];
}

export interface AddDeliveryRunDrivers {
  type: typeof ADD_DELIVERY_RUN_DRIVERS;
  drivers: [];
}
export interface UpdateMapViewState {
  type: typeof UPDATE_MAP_VIEW_STATE;
}
export interface ClearDeliveryRunOrders {
  type: typeof CLEAR_DELIVERY_RUN_ORDERS;
}
export interface RemoveDeliveryRunOrders {
  type: typeof REMOVE_DELIVERY_RUN_ORDER;
  orders: number[];
}
export interface AddNewDeliveryRun {
  type: typeof ADD_NEW_DELIVERY_RUN;
  driverID: number;
  orderList: number[];
}
export interface AddToDeliveryRun {
  type: typeof ADD_TO_DELIVERY_RUN;
  driverID: number;
  deliveryRunID: string;

  orderList: number[];
}

export interface RemoveFromDeliveryRun {
  type: typeof REMOVE_FROM_DELIVERY_RUN;
  orders: OrderType[];
  driver_id: number;
  delivery_run_id: string;
}
export interface UpdateSelectedDeliveryRunOrder {
  type: typeof UPDATE_SELECTED_DELIVERY_RUN_ORDERS;
  orderID: number;
}
export interface UpdateAvgLocation {
  type: typeof UPDATE_AVG_LOCATION;
  location: number[];
}
export interface AddToSelectedOrders {
  type: typeof ADD_TO_SELECTED_ORDERS;
  orderID: number;
}

export interface DeleteFromSelectedOrders {
  type: typeof DELETE_FROM_SELECTED_ORDERS;
  orderID: number;
}
export interface UpdateSelectState {
  type: typeof UPDATE_SELECT_STATE;
}
export interface UpdateDeliveryRunSchedule {
  type: typeof UPDATE_DELIVERY_RUN_SCHEDULE;
  date: string;
  time: string;
  delivery_run_id: string;
  driver_id: number;
  inactive: boolean;
}
export interface UpdateExistingDeliveryRuns {
  type: typeof UPDATE_EXISTING_DELIVERY_RUNS;
  delivery_runs: ImportedDeliveryRun;
}
export interface DeliveryRunsLoaded {
  type: typeof DELIVERY_RUNS_LOADED;
}
export type CreateDeliveryRunActionTypes =
  | AddDeliveryRunOrders
  | AddDeliveryRunDrivers
  | AddToSelectedOrders
  | AddNewDeliveryRun
  | DeleteFromSelectedOrders
  | UpdateMapViewState
  | ClearDeliveryRunOrders
  | UpdateAvgLocation
  | RemoveDeliveryRunOrders
  | AddToDeliveryRun
  | RemoveFromDeliveryRun
  | UpdateExistingDeliveryRuns
  | UpdateSelectedDeliveryRunOrder
  | UpdateDeliveryRunSchedule
  | UpdateSelectState
  | DeliveryRunsLoaded
  | LogoutAction;

export const UPDATE_DASHBOARD_DRIVERS = "UPDATE_DASHBOARD_DRIVERS";
export const UPDATE_DASHBOARD_ORDERS = "UPDATE_DASHBOARD_ORDERS";
export const UPDATE_DASHBOARD_ORDER_SELECT = "UPDATE_DASHBOARD_ORDER_SELECT";
export const DELETE_DASHBOARD_ORDER = "DELETE_DASHBOARD_ORDER";
export const UPDATE_DASHBOARD_DELIVERY_RUNS = "UPDATE_DASHBOARD_DELIVERY_RUNS";
export const CLEAR_DASHBOARD_DRIVER_DELIVERY_RUN =
  "CLEAR_DASHBOARD_DRIVER_DELIVERY_RUN";
export const DASHBOARD_LOADED = "DASHBOARD_LOADED";
export const DASHBOARD_DELIVERY_RUNS_LOADED = "DASHBOARD_DELIVERY_RUNS_LOADED";
export const SHOW_DASHBOARD_ORDERS = "SHOW_DASHBOARD_ORDERS";
export const UPDATE_CURRENT_DASHBOARD_DELIVERY_RUN =
  "UPDATE_CURRENT_DASHBOARD_DELIVERY_RUN";
export const SHOW_DASHBOARD_EDIT_DELIVERY_RUN =
  "SHOW_DASHBOARD_EDIT_DELIVERY_RUN";
export const UPDATE_DASHBOARD_MODAL_INFO = "UPDATE_DASHBOARD_MODAL_INFO";
export const FILTER_DASHBOARD_ORDER = "FILTER_DASHBOARD_ORDER";
export const CLEAR_DASHBOARD_STATE = "CLEAR_DASHBOARD_STATE";
export const UPDATE_FROM_DETAILED_DELIVERY_RUN =
  "UPDATE_FROM_DETAILED_DELIVERY_RUN";
export const UPDATE_DETAILED_DELIVERY_RUN = "UPDATE_DETAILED_DELIVERY_RUN";
export const UPDATE_SELECTED_DASHBOARD_ORDERS =
  "UPDATE_SELECTED_DASHBOARD_ORDERS";

export interface UpdateDashboardDrivers {
  type: typeof UPDATE_DASHBOARD_DRIVERS;
  drivers: DeliveryDriver[];
}

export interface ClearDashBoardDriverDeliveryRun {
  type: typeof CLEAR_DASHBOARD_DRIVER_DELIVERY_RUN;
  driverID: number;
}

export interface UpdateDashboardOrders {
  type: typeof UPDATE_DASHBOARD_ORDERS;
  orders: OrderType[];
}

export interface UpdateDashboardOrderSelect {
  type: typeof UPDATE_DASHBOARD_ORDER_SELECT;
  orderID: number;
}

export interface DeleteDashboardOrder {
  type: typeof DELETE_DASHBOARD_ORDER;
  orderID: number;
}

export interface UpdateDashboardDeliveryRuns {
  type: typeof UPDATE_DASHBOARD_DELIVERY_RUNS;
  delivery_runs: ImportedDeliveryRun;
}

export interface DashboardDeliveryRunsLoaded {
  type: typeof DASHBOARD_DELIVERY_RUNS_LOADED;
}
export interface DashboardLoaded {
  type: typeof DASHBOARD_LOADED;
}

export interface ShowDashboardOrders {
  type: typeof SHOW_DASHBOARD_ORDERS;
}

export interface UpdateCurrentDashboardDeliveryRun {
  type: typeof UPDATE_CURRENT_DASHBOARD_DELIVERY_RUN;
  deliveryRun: SelectedDashboard;
}

export interface ShowDashboardEditDeliveryRun {
  type: typeof SHOW_DASHBOARD_EDIT_DELIVERY_RUN;
}

export interface FilterDashboardOrder {
  type: typeof FILTER_DASHBOARD_ORDER;
  orderIDs: number[];
  deliveryRunID: number;
}

export interface UpdateDashboardModalInfo {
  type: typeof UPDATE_DASHBOARD_MODAL_INFO;
  modalInfo: UpdateModal;
}

export interface UpdateFromDetailedDeliveryRun {
  type: typeof UPDATE_FROM_DETAILED_DELIVERY_RUN;
}

export interface UpdateDetailedDeliveryRun {
  type: typeof UPDATE_DETAILED_DELIVERY_RUN;
  id: number;
}
export interface ClearDashBoardState {
  type: typeof CLEAR_DASHBOARD_STATE;
}

export interface UpdateSelectedDashBoardOrders {
  type: typeof UPDATE_SELECTED_DASHBOARD_ORDERS;
  orderID: number;
}
export type DashboardActionTypes =
  | UpdateDashboardDrivers
  | UpdateDashboardOrders
  | UpdateDashboardOrderSelect
  | DeleteDashboardOrder
  | UpdateDashboardDeliveryRuns
  | DashboardLoaded
  | DashboardDeliveryRunsLoaded
  | ShowDashboardOrders
  | UpdateCurrentDashboardDeliveryRun
  | ShowDashboardEditDeliveryRun
  | UpdateDashboardModalInfo
  | FilterDashboardOrder
  | UpdateFromDetailedDeliveryRun
  | UpdateDetailedDeliveryRun
  | ClearDashBoardState
  | UpdateSelectedDashBoardOrders
  | ClearDashBoardDriverDeliveryRun
  | LogoutAction;

export const SUCCESS_API = "SUCCESS_API";
export const ERROR_API = "ERROR_API";

export interface SuccessfulApi {
  type: typeof SUCCESS_API;
}

export interface ErrorApi {
  type: typeof ERROR_API;
  error: string;
}

export type ApiActionTypes = SuccessfulApi | ErrorApi;

export type AppActions =
  | AuthActionTypes
  | UserActionTypes
  | ModalActionTypes
  | FilterActionTypes
  | ConsumerActionTypes
  | DriverActionTypes
  | OnboardingActionTypes
  | ListActionTypes
  | PropertiesActionTypes
  | CustomDriverFieldFilterActionTypes
  | CustomOrderFieldFilterActionTypes
  | CustomDeliveryFieldFilterActionTypes
  | CustomDeliveryRunFieldFilterActionTypes
  | DefaultCustomDriverFilterStateActionTypes
  | DefaultCustomOrderFilterStateActionTypes
  | DefaultCustomDeliveryFilterStateActionTypes
  | DefaultCustomDeliveryRunFilterStateActionTypes
  | DriverCustomFieldValueActionTypes
  | OrderCustomFieldValueActionTypes
  | DeliveryCustomFieldValueActionTypes
  | DeliveryRunCustomFieldValueActionTypes
  | DashboardActionTypes
  | ApiActionTypes;
