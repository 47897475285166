import * as React from "react";
import { connect } from "react-redux";
import { AppState } from "../../../store/configureStore";
import { AppActions } from "../../../types";
import { Formik, FormikProps, Form } from "formik";
import { Dispatch, bindActionCreators } from "redux";
import { withRouter, RouteComponentProps } from "react-router";
import * as Yup from "yup";
import styled from "styled-components";
import { Container, AppText, HR, Button, Collapsible } from "../../UI";
import {
  TABLET_BREAKPOINT,
  PHONE_REG_EXP,
  toastError,
  toastInfo,
} from "../../../utils";
import { AuthButton } from "../../Pages/NewClientSetup";
import { OnboardInputField } from "../../form";
// import { registerAdminThunk } from "../../../api/auth";
import { api } from "../../../api/api";
import { ClientSetupError } from "../../../api/responseTypes";
// const onboardSchema = Yup.object().shape({
//   store_name: Yup.string()
//     .required("* Store Name is required")
//     .max(100, "* name too long"),
//   admin_email: Yup.string()
//     .email("* Invalid email")
//     .required("* Email is required"),
//   store_address: Yup.string()
//     .required("* Store Address is required")
//     .max(250, "* Too Long!"),
//   store_phone_number: Yup.string()
//     .required("Store Phone # is required")
//     .matches(PHONE_REG_EXP, "* Phone number is not valid")
// });
const onboardSchema = Yup.object().shape({
  store_name: Yup.string()
    .required("* Store Name is required")
    .max(100, "* name too long"),
  admin_name: Yup.string()
    .required("* Admin Name is required")
    .max(100, "* name too long"),
  admin_email: Yup.string()
    .email("* Invalid email")
    .required("* Email is required"),
  user_phone: Yup.string().max(10, "*number exceeded"),
  nexmo_number: Yup.string()
    .required("* Nexmo number is required")
    .max(10, "* number exceeded"),
  // ach_number: Yup.string()
  //   .required("* ACH number is required")
  //   .max(10, "* number exceeded"),
  // subscription_price: Yup.string()
  //   .required("* Subscription Price required")
  //   .max(100, "* name too long"),
  // subscription_notes: Yup.string().max(100, "* name too long"),
  // name_on_credit_card: Yup.string()
  //   .required("* Credit Card name is required")
  //   .max(15, "* name  too long"),
  // credit_card_number: Yup.string()
  //   .required("* Credit Card is required")
  //   .max(8, "* Number exceeded"),
  // security_code: Yup.string()
  //   .required("* Security Code is required")
  //   .max(3, "* Number exceeded"),
  // expiration_date: Yup.string().required("* Date is required"),
  // billing_zip: Yup.string().required("* Zip code is required"),
  // subscription_start_date: Yup.string().required("* Security Code is required")
});
interface ClientSetupProps {}

interface ClientSetupState {}
interface MyFormikProps {
  store_name: string;
  admin_name: string;
  admin_email: string;
  user_phone: string;
  nexmo_number: string;
  ach_number: string;
  subcription_price: string;
  subscription_notes: string;
  name_on_credit_card: string;
  credit_card_number: string;
  security_code: string;
  expiration_date: string;
  billing_zip: string;
  subscription_start_date: string;
}

type Props = ClientSetupProps &
  LinkStateProps &
  LinkDispatchProps &
  RouteComponentProps;

class ClientSetup extends React.Component<Props, ClientSetupState> {
  render() {
    return (
      <Formik
        initialValues={{
          store_name: "",
          admin_name: "",
          admin_email: "",
          user_phone: "",
          nexmo_number: "",
          ach_number: "",
          subcription_price: "",
          subscription_notes: "",
          name_on_credit_card: "",
          credit_card_number: "",
          security_code: "",
          expiration_date: "",
          billing_zip: "",
          subscription_start_date: "",
        }}
        validationSchema={onboardSchema}
        onSubmit={async (values, { setFieldError, setStatus }) => {
          const { token } = this.props;
          setStatus({ error: "", loading: true });
          setStatus({ loading: false });
          const response = (await api.registerAdmin(
            {
              first_name: values.admin_name.split(" ")["0"],
              last_name: values.admin_name.split(" ")["1"],
              email: values.admin_email,
              phone: values.user_phone,
              store_name: values.store_name,
              nexmo_num: values.nexmo_number,
            },
            token
          )) as ClientSetupError;
          if (!!response.original) {
            toastError(response.original.error);
          } else {
            const successMessage = "Invite Successfully sent";
            toastInfo(successMessage);
          }
          // this.props.signup({
          //   first_name: values.admin_name.split(" ")["0"],
          //   last_name: values.admin_name.split(" ")["1"],
          //   email: values.admin_email,
          //   store_name: values.store_name,
          //   nexmo_num: values.nexmo_number
          // });
        }}
      >
        {({
          status,
          setStatus,
          submitForm,
          touched,
          errors,
        }: FormikProps<MyFormikProps>) => {
          return (
            <ClientSetupContainer>
              <AccountSetupText>NEW CLIENT SETUP</AccountSetupText>
              <Div>
                <Grid>
                  <LeftGrid>
                    <TitleSetupText>GENERAL INFO</TitleSetupText>
                    <OnboardInputField
                      name="store_name"
                      placeholder=""
                      title="Store Name"
                    />
                    <OnboardInputField
                      name="admin_name"
                      placeholder=""
                      title="Admin Name"
                    />
                    <OnboardInputField
                      name="admin_email"
                      placeholder=""
                      title="Admin Email"
                    />
                    <OnboardInputField
                      style={{ paddingBottom: "40px" }}
                      name="user_phone"
                      placeholder=""
                      title="Phone Number"
                    />
                    <TitleSetupText>TOOL CONFIGURATION</TitleSetupText>
                    <OnboardInputField
                      name="nexmo_number"
                      placeholder=""
                      title="Nexmo Number"
                    />
                    {/* <OnboardInputField
                      name="ach_number"
                      placeholder=""
                      title="ACH Number"
                    /> */}
                    {/* <Collapsible title={"POS"} /> */}
                  </LeftGrid>
                  <RightGrid>
                    <div />
                    {/* <TitleSetupText style={{ marginBottom: "15px" }}>
                      SUBSCRIPTION INFO
                    </TitleSetupText>
                    <SubscriptionGrid>
                      <SubLeft>
                        <StyledOnboardInputField
                          name="subscription_price"
                          placeholder=""
                          title="Subscription Price"
                        />
                        <Collapsible title={"Billing Schedule"} />
                        <OnboardInputField
                          name="subscription_notes"
                          placeholder=""
                          title="Subscription Notes"
                        />
                        <OnboardInputField
                          name="name_on_credit_card"
                          placeholder=""
                          title="Name on Credit Card"
                        />
                        <OnboardInputField
                          name="credit_card_number"
                          placeholder=""
                          title="Credit Card Number"
                        />
                      </SubLeft>
                      <SubRight>
                        <OnboardInputField
                          name="security_code"
                          placeholder=""
                          title="Security Code"
                        />
                        <OnboardInputField
                          name="expiration_date"
                          placeholder=""
                          title="Expiration Date"
                        />
                        <OnboardInputField
                          name="billing_zip"
                          placeholder=""
                          title="Billing Zip"
                        />
                        <OnboardInputField
                          name="subscription_start_date"
                          placeholder=""
                          title="Subscription Start Date"
                        />
                      </SubRight>
                    </SubscriptionGrid> */}
                  </RightGrid>
                </Grid>
              </Div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <AuthButton
                  type="button"
                  style={{ justifySelf: "left" }}
                  onClick={() => this.props.history.push("/dashboard")}
                  // disabled={
                  //   !(Object.keys(touched).length && !Object.keys(errors).length)
                  // }
                >
                  Back to Dashboard
                </AuthButton>
                <AuthButton
                  type="submit"
                  style={{ justifySelf: "right" }}
                  onClick={submitForm}
                  // disabled={
                  //   !(Object.keys(touched).length && !Object.keys(errors).length)
                  // }
                >
                  Send Invite
                </AuthButton>
              </div>
            </ClientSetupContainer>
          );
        }}
      </Formik>
    );
  }
}

const ClientSetupContainer = styled(Container)`
  background-image: linear-gradient(to left, #4ba5eb, #60999e);
  padding: 53px 50px 45px 75px;
  display: grid;
  grid-template-rows: 70px 1fr 1fr;
  background-size: cover;
  background-attachment: fixed;
  background-position: center center;
  overflow-x: auto;
`;
const AccountSetupText = styled(AppText)`
  font-size: 27px;
  color: #fff;
`;

const StyledOnboardInputField = styled(OnboardInputField)`
  border-bottom: transparent;
`;
const TitleSetupText = styled(AppText)`
  font-size: 15px;
  color: #fff;
`;
const LeftGrid = styled.div`
  display: grid;
  grid-column: 1;
  align-content: center;
  justify-content: center;
  padding-bottom: 60px;
  gap: 10px;
`;

const RightGrid = styled.div`
  display: grid;
  align-content: flex-start;
  justify-content: left;
  padding-bottom: 60px;
`;

const Grid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  padding-bottom: 0px;
`;
const Div = styled.div`
  display: grid;
  grid-template-rows: auto min-content;
`;

const SubscriptionGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column: 1;
  /* margin-bottom: 165px; */
`;

const SubLeft = styled.div`
  padding-right: 50px;
`;

const SubRight = styled.div``;

interface LinkStateProps {
  token: string;
}

interface LinkDispatchProps {
  // signup: ({
  //   first_name,
  //   last_name,
  //   email,
  //   store_name,
  //   nexmo_num
  // }: {
  //   first_name: string;
  //   last_name: string;
  //   email: string;
  //   store_name: string;
  //   nexmo_num: string;
  // }) => void;
}

const mapDispatchToProps = (
  dispatch: Dispatch<AppActions>
): LinkDispatchProps => ({
  // signup: bindActionCreators(registerAdminThunk, dispatch)
});

const mapStateToProps = (state: AppState): LinkStateProps => ({
  token: state.auth.token as string,
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(ClientSetup));
