import * as React from "react";
import styled from "styled-components";

interface ColorSelectProps {
  selectedColor: string;
  colors: string[];
  onClick: (color: string) => void;
  error?: boolean;
  inputWidth?: string;
}

interface ColorSelectState {}

class ColorSelect extends React.Component<ColorSelectProps, ColorSelectState> {
  constructor(props: ColorSelectProps) {
    super(props);
  }
  onSelect = (color: string) => this.props.onClick(color);
  render(): JSX.Element {
    const { colors, error, inputWidth, selectedColor } = this.props;
    console.log("colors: ", colors);

    return (
      <Div error={error} inputWidth={inputWidth}>
        {colors.map(color => (
          <ColorItem
            color={color}
            onClick={() => this.onSelect(color)}
            selected={color === selectedColor}
          />
        ))}
      </Div>
    );
  }
}

interface DivProps {
  error?: boolean;
  inputWidth?: string;
}

const Div = styled.div<DivProps>`
  border-radius: 2px;
  border: solid 1px rgba(59, 58, 58, 0.25);
  background-color: #ffffff;
  display: flex;
  flex-direction: horizontal;
  /* justify-content: space-around; */
  align-items: center;
  height: 40px;
  width: ${props => props.inputWidth};
  outline: ${props => (props.error ? "none" : "")};
  border-color: ${props => (props.error ? "red" : "")};
  box-shadow: ${props => (props.error ? "0 0 5px red" : "")};
  padding: 0 5px;
`;

Div.defaultProps = {
  error: false,
  inputWidth: "auto"
};

interface ColorItemProps {
  color: string;
  selected: boolean;
}

const ColorItem = styled.div<ColorItemProps>`
  background-color: ${props => props.color};
  border: ${props => (props.selected ? "solid 3px #000" : "none")};
  width: 28px;
  height: 28px;
  margin: 0 5px;
  /* box-sizing: content-box; */
  :hover {
    cursor: pointer;
  }
`;

export { ColorSelect, ColorItem };
