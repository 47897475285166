import { OrderType } from "../types";
import { Driver } from "../api/responseTypes";

declare global {
  interface Window {
    H: any;
  }
}

// Marker SVG
export const returnMarker = ({
  cases,
  color,
}: {
  cases?: number;
  color: string;
}) => {
  const svg = `<svg width="46" height="46" id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 14.79 16.91"><defs><style>.cls-1{fill:${color};}.cls-2{fill:#fff;}</style></defs>
  <title>ic_pin</title><path id="Shape" class="cls-1" d="M17.28,11.55a6.13,6.13,0,0,0-6.12,6.12c0,4.19,5.48,10.34,5.71,10.6a.56.56,0,0,0,.82,0c.24-.26,5.72-6.41,5.72-10.6A6.13,6.13,0,0,0,17.28,11.55Z" transform="translate(-10.1 -11.55)"/><circle id="Oval-2" class="cls-1" cx="7.4" cy="7.4" r="7.4"/><circle id="Oval-2-Copy" class="cls-2" cx="7.4" cy="7.4" r="4.44"/>
  <text x="7.3" y="9.3" font-size="4pt"
  font-family="Avenir, Lato, sans-serif" font-weight="normal" text-anchor="middle"
  fill="black">${cases ? cases : "N/A"}</text>
  </svg>`;

  const icon = new window.H.map.Icon(svg);
  return icon;
};

export const returnTruckMarker = () => {
  const svg = `<svg width = "46" height="46" id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 53.33 31.99">
  <defs><style>.cls-1{fill:#f52367;}</style></defs>
  <title>ic_truck</title><g id="delivery-truck"><path id="Shape" class="cls-1" d="M50.92,15,47.59,6.13a5.77,5.77,0,0,0-5.48-3.9H39.2A1.55,1.55,0,0,0,37.67,1H3.56A1.55,1.55,0,0,0,2,2.54V26.72a1.55,1.55,0,0,0,1.56,1.55H7.23a6,6,0,0,0,11.66,0H39.8a6,6,0,0,0,11.66,0h2.31a1.55,1.55,0,0,0,1.56-1.54V20.3A5.48,5.48,0,0,0,50.92,15ZM48.45,27.12a2.82,2.82,0,1,1-2.82-2.78A2.8,2.8,0,0,1,48.45,27.12Zm-32.57,0a2.82,2.82,0,1,1-2.82-2.78A2.81,2.81,0,0,1,15.88,27.12ZM39.23,14.84V10.67h6.66l1.64,4.17Z" transform="translate(-2 -1)"/>
  </g></svg>`;

  const icon = new window.H.map.Icon(svg);
  return icon;
};

export const returnStoreMarker = () => {
  const svg = `<svg width="40px" height="39px" viewBox="0 0 40 39" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
  <g id="01-Dashboard" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
      <g id="01-Dashboard/02-Active-Delivery-Run" transform="translate(-650.000000, -530.000000)" fill="#3B3A3A" fill-rule="nonzero">
          <path d="M689.498855,549.028233 C690.223949,548.225102 690.15393,546.994143 689.342518,546.27952 L671.466015,530.526289 C670.654603,529.811666 669.353709,529.826636 668.559377,530.560037 L650.622113,547.119419 C649.827781,547.85282 649.788795,549.082204 650.535795,549.864192 L650.985508,550.335881 C651.731596,551.117869 652.937566,551.211104 653.677395,550.543492 L655.017927,549.334462 L655.017927,567.034851 C655.017927,568.120837 655.891666,569 656.969203,569 L663.961199,569 C665.038736,569 665.912474,568.120837 665.912474,567.034851 L665.912474,554.651723 L674.830841,554.651723 L674.830841,567.034851 C674.815325,568.120049 675.586317,568.999212 676.663854,568.999212 L684.073617,568.999212 C685.151154,568.999212 686.024893,568.120049 686.024893,567.034063 L686.024893,549.583701 C686.024893,549.583701 686.395198,549.910416 686.851952,550.314739 C687.307923,550.718274 688.265633,550.39471 688.990727,549.590792 L689.498855,549.028233 Z" id="Path"></path>
      </g>
  </g>
</svg>`;

  const icon = new window.H.map.Icon(svg);
  return icon;
};

//placeholder for order info bubble
export const orderInfoBubble = (order: OrderType) => {
  console.log(order);
  return `<div style="color:black; height: 100%; width: 250px; background-color: white; border-radius: 5px; font-family: Avenir, Lato, sans-serif; display: flex; flex-direction: column">
  <text style="font-weight: bold; font-size: 18px; margin: 0px 4px;font-family: Avenir, Lato, sans-serif;">${
    order.customer.first_name + " " + order.customer.last_name
  }</text> 
  <div style = "width: 99%; border: solid 1px black;font-family: Avenir, Lato, sans-serif;"></div>
  <div style="display: flex; flex-direction: column">
  <text style ="font-weight: bold; font-size: 16px; margin: 3px 4px;font-family: Avenir, Lato, sans-serif;">Address: ${
    order.address.address_1
  }</text>  
  <text style ="font-weight: bold; font-size: 16px; margin: 3px 4px;font-family: Avenir, Lato, sans-serif;">Phone: ${
    order.customer.phone
  }</text>  
  <text style ="font-weight: bold; font-size: 16px; margin: 3px 4px;font-family: Avenir, Lato, sans-serif;">Email: ${
    order.customer.email
  }</text>  
  </div>

</div>`;
};

//placeholder for driver info bubble
export const driverInfoBubble = (driver: Driver) => {
  return `<div style="color:blue; height: 100px; width: 200px; background-color: red; border-radius: 5px">${driver.first_name}</div>`;
};
