import * as React from "react";
import styled from "styled-components";
import { AppText } from "../UI";
import { ColorItem } from "./ColorSelect";

interface FieldDetailProps {
  title?: string;
  value: string;
  type?: "string" | "image" | "color" | "children";
  style?: React.CSSProperties;
}

const FieldDetail: React.FunctionComponent<FieldDetailProps> = ({
  title,
  type = "string",
  value,
  children,
  style,
}) => {
  const renderValue = () => {
    switch (type) {
      case "string":
        return <ValueText>{value}</ValueText>;
      case "image":
        return (
          <a target="_blank" href={value}>
            <Image src={value} />
          </a>
        );
      case "children":
        return children;
      case "color":
        return (
          <ColorItem color={value} selected={false} style={{ margin: "0" }} />
        );
      default:
        return <ValueText>{value}</ValueText>;
    }
  };
  return (
    <Div style={style}>
      <TitleText>{title}</TitleText>
      {renderValue()}
    </Div>
  );
};

const Div = styled.div`
  display: flex;
  flex-direction: column;
`;

const TitleText = styled(AppText)`
  opacity: 0.5;
  font-size: 14px;
  font-weight: 500;
  color: #3b3a3a;
  margin-bottom: 10px;
`;

const Image = styled.img`
  width: 31px;
  height: 31px;
`;

const ValueText = styled(AppText)`
  font-size: 16px;
  color: #1a1a1a;
`;

export { FieldDetail };
