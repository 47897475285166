import { OrderType } from "../types";

export const avgLocation = (orders: OrderType[]) => {
  let lat = [] as number[];
  let latTotal = 0;
  let lng = [] as number[];
  let lngTotal = 0;
  orders.forEach((order) => {
    lat.push(order.address.latitude);
    lng.push(order.address.longitude);
  });
  lat.forEach((val) => (latTotal += val));
  lng.forEach((val) => (lngTotal += val));

  const latAvg = latTotal / lat.length;
  const lngAvg = lngTotal / lat.length;
  return [latAvg, lngAvg];
};

export const getFilterType = (filterID: number) => {
  switch (filterID) {
    case 1:
      return "text";
    case 2:
      return "email";
    case 3:
      return "dropdown";
    case 4:
      return "date";
    case 5:
      return "numeric";
    case 6:
      return "dropdown";
    default:
      break;
  }
};

export const returnEntity = (type: string) => {
  switch (type) {
    case "Drivers":
      return "Driver";
    case "Orders":
      return "Order";
    case "Delivery Runs":
      return "DeliveryRun";
    case "Deliveries":
      return "Delivery";
    default:
      return "";
  }
};

export const debouncer = (func: Function, delay: number) => {
  let debounceTimer;

  return (function debounce(this: any) {
    const context = this;
    const args = arguments;
    clearTimeout(debounceTimer);
    debounceTimer = setTimeout(() => func.apply(context, args), delay);
  })();
};
