import * as React from "react";
import styled from "styled-components";
import { Spinner } from "./";

const PageLoadSpinner: React.SFC = () => {
  return (
    <Div>
      <Spinner />
    </Div>
  );
};

const Div = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  background-color: white;
  opacity: 0.7;
  justify-content: center;
  align-items: center;
  z-index: 10;
`;

export { PageLoadSpinner };
