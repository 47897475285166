import * as React from "react";
import { connect } from "react-redux";
import * as Yup from "yup";
import { Dispatch, bindActionCreators } from "redux";
import styled from "styled-components";
import { Button, Modal, AppText, HR, SecondaryButton } from "../UI";
import { AppActions } from "../../types";
import { AppState } from "../../store/configureStore";
import { PRIMARY_COLOR } from "../../utils";
import { api } from "../../api/api";
import {
  updateDriverList,
  updateDriverListLabel,
  updateDriverInitialLoad,
  updateDriverFilters,
  updateOrderList,
  updateOrderListLabel,
  updateOrderInitialLoad,
  updateOrderFilters,
  updateDeliveryRunList,
  updateDeliveryRunListLabel,
  updateDeliveryRunInitialLoad,
  updateDeliveryRunFilters,
  updateDeliveryList,
  updateDeliveryListLabel,
  updateDeliveryInitialLoad,
  updateDeliveryFilters,
} from "../../actions";

interface DeleteCustomListProps {
  showModal: boolean;
  toggleModal: () => void;
  entity: string;
}

interface DeleteCustomListState {}

class DeleteCustomList extends React.Component<Props, DeleteCustomListState> {
  deleteList = async (entity: string) => {
    const {
      token,
      toggleModal,
      driverListID,
      orderListID,
      deliveryRunListID,
      deliveryListID,
      updateDriverList,
      updateDriverListLabel,
      updateOrderList,
      updateOrderListLabel,
      updateDriverInitialLoad,
      updateOrderInitialLoad,
      updateDriverFilters,
      updateOrderFilters,
      updateDeliveryRunList,
      updateDeliveryRunListLabel,
      updateDeliveryRunInitialLoad,
      updateDeliveryRunFilters,
      updateDeliveryList,
      updateDeliveryListLabel,
      updateDeliveryInitialLoad,
      updateDeliveryFilters,
      defaultDeliveryListID,
      defaultDeliveryRunListID,
      defaultDriverListID,
      defaultOrderListID,
    } = this.props;

    switch (entity) {
      case "Deliveries":
        await api.deleteItemList(deliveryListID, token);
        updateDeliveryList(defaultDeliveryListID as number);
        updateDeliveryListLabel("All Deliveries");
        updateDeliveryInitialLoad();
        toggleModal();
        break;
      case "Delivery Runs":
        await api.deleteItemList(deliveryRunListID, token);
        updateDeliveryRunList(defaultDeliveryRunListID as number);
        updateDeliveryRunListLabel("All DeliveryRuns");
        updateDeliveryRunInitialLoad();
        toggleModal();

        break;
      case "Orders":
        await api.deleteItemList(orderListID, token);
        updateOrderList(defaultOrderListID as number);
        updateOrderListLabel("All Orders");
        updateOrderInitialLoad();
        toggleModal();
        break;
      case "Drivers":
        await api.deleteItemList(driverListID, token);
        updateDriverList(defaultDriverListID as number);
        updateDriverListLabel("All Drivers");
        updateDriverInitialLoad();
        toggleModal();
        break;
      default:
        return;
    }
  };

  render(): JSX.Element {
    return (
      <Modal
        height="fit-content"
        width="fit-content"
        showModal={this.props.showModal}
        toggleModal={this.props.toggleModal}
        label="Rename Custom List"
      >
        <Div>
          <Header>
            <HeaderText>WARNING</HeaderText>
          </Header>
          <HR />
          <Body>
            <BodyText>
              Please confirm you would like to delete this list.
            </BodyText>
          </Body>
          <Footer>
            <CancelButton onClick={this.props.toggleModal}>Cancel</CancelButton>
            <Button
              width="130px"
              type="submit"
              style={{ justifySelf: "flex-end" }}
              //TODO figure out why submit form submits twice
              onClick={() => this.deleteList(this.props.entity)}
            >
              Delete
            </Button>
          </Footer>
        </Div>
      </Modal>
    );
  }
}

const Div = styled.div`
  display: flex;
  flex-direction: column;
  border-top: solid 1px ${PRIMARY_COLOR};
  border-radius: 2px;
  overflow-x: none;
  width: 507px;
`;

const HeaderText = styled(AppText)`
  font-size: 18px;
  font-weight: 600;
  letter-spacing: 0.3px;
  color: #60999e;
`;

const Header = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height: 57px;
  padding: 0 40px 0 40px;
`;

const Footer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  height: 57px;
  padding: 0 40px 30px 40px;
`;
const Body = styled.div`
  height: 150px;
  display: flex;
  padding-top: 40px;
  padding-right: 50px;
  padding-left: 50px;
`;

const BodyText = styled(AppText)`
  font-size: 15px;
  font-weight: 500;
  line-height: 1.6;
  letter-spacing: normal;
  color: black;
`;
const CancelText = styled(AppText)`
  font-size: 13px;
  font-weight: 500;
  color: #3b3a3a;
  margin-right: 53.5px;
`;

const CancelButton = styled(Button)`
  height: 40px;
  width: 110px;
  border-radius: 4px;
  border: solid 1px rgba(71, 70, 71, 0.26);
  background-color: #fff;
  color: #000;
  margin-right: 21px;
`;

type Props = DeleteCustomListProps & LinkDispatchProps & LinkStateProps;

interface LinkStateProps {
  token: string;
  store_id: number;
  deliveryListID: number;
  deliveryRunListID: number;
  driverListID: number;
  orderListID: number;
  defaultDeliveryListID: string | number | null;
  defaultDeliveryRunListID: string | number | null;
  defaultDriverListID: string | number | null;
  defaultOrderListID: string | number | null;
}

interface LinkDispatchProps {
  updateDriverList: (id: number) => void;
  updateDriverListLabel: (label: string) => void;
  updateDriverInitialLoad: () => void;
  updateDriverFilters: (filters: string[]) => void;
  updateOrderList: (id: number) => void;
  updateOrderListLabel: (label: string) => void;
  updateOrderInitialLoad: () => void;
  updateOrderFilters: (filters: string[]) => void;
  updateDeliveryRunList: (id: number) => void;
  updateDeliveryRunListLabel: (label: string) => void;
  updateDeliveryRunInitialLoad: () => void;
  updateDeliveryRunFilters: (filters: string[]) => void;
  updateDeliveryList: (id: number) => void;
  updateDeliveryListLabel: (label: string) => void;
  updateDeliveryInitialLoad: () => void;
  updateDeliveryFilters: (filters: string[]) => void;
}

const mapStateToProps = (state: AppState): LinkStateProps => ({
  store_id: state.user.store_id as number,
  token: state.auth.token as string,
  deliveryListID: state.listViewIDs.deliveryListID as number,
  deliveryRunListID: state.listViewIDs.deliveryRunListID as number,
  driverListID: state.listViewIDs.driverListID as number,
  orderListID: state.listViewIDs.orderListID as number,
  defaultDeliveryListID: state.listViewIDs.defaultDeliveryID,
  defaultDeliveryRunListID: state.listViewIDs.defaultDeliveryRunID,
  defaultDriverListID: state.listViewIDs.defaultDriverID,
  defaultOrderListID: state.listViewIDs.defaultOrderID,
});

const mapDispatchToProps = (
  dispatch: Dispatch<AppActions>
): LinkDispatchProps => ({
  updateDriverList: bindActionCreators(updateDriverList, dispatch),
  updateDriverListLabel: bindActionCreators(updateDriverListLabel, dispatch),
  updateDriverInitialLoad: bindActionCreators(
    updateDriverInitialLoad,
    dispatch
  ),
  updateDriverFilters: bindActionCreators(updateDriverFilters, dispatch),
  updateOrderList: bindActionCreators(updateOrderList, dispatch),
  updateOrderListLabel: bindActionCreators(updateOrderListLabel, dispatch),
  updateOrderInitialLoad: bindActionCreators(updateOrderInitialLoad, dispatch),
  updateOrderFilters: bindActionCreators(updateOrderFilters, dispatch),
  updateDeliveryRunList: bindActionCreators(updateDeliveryRunList, dispatch),
  updateDeliveryRunListLabel: bindActionCreators(
    updateDeliveryRunListLabel,
    dispatch
  ),
  updateDeliveryRunInitialLoad: bindActionCreators(
    updateDeliveryRunInitialLoad,
    dispatch
  ),
  updateDeliveryRunFilters: bindActionCreators(
    updateDeliveryRunFilters,
    dispatch
  ),
  updateDeliveryList: bindActionCreators(updateDeliveryList, dispatch),
  updateDeliveryListLabel: bindActionCreators(
    updateDeliveryListLabel,
    dispatch
  ),
  updateDeliveryInitialLoad: bindActionCreators(
    updateDeliveryInitialLoad,
    dispatch
  ),
  updateDeliveryFilters: bindActionCreators(updateDeliveryFilters, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(DeleteCustomList);
