import * as React from "react";
import styled from "styled-components";
// import LeftArrow from "react-icons/lib/io/android-arrow-dropleft";
// import RightArrow from "react-icons/lib/io/android-arrow-dropright";
import "semantic-ui-css/semantic.min.css";
import "./DynamicTable.css";
import { AppText, Button, TestCheckbox, ColorBox, Spinner } from "../UI";
import { formatDate, isDefaultNotification } from "../../utils";
// import AddIcon from "react-icons/lib/fa/plus";
// import EditIcon from "react-icons/lib/md/create";
import { IoMdArrowDropright, IoMdArrowDropleft } from "react-icons/io";
import { IoMdDownload } from "react-icons/io";
import {
  MdFirstPage,
  MdLastPage,
  MdChevronLeft,
  MdChevronRight,
} from "react-icons/md";

// import RefreshIcon from "react-icons/lib/fa/refresh";
import {
  FaFilter,
  FaPlus,
  FaSyncAlt,
  FaFileExport,
  FaTrashAlt,
} from "react-icons/fa";
import { MdEdit } from "react-icons/md";
import { PRIMARY_COLOR } from "../../utils";
import { SearchInput } from ".";
import { Dropdown } from "semantic-ui-react";
import FilterMenu from "../Segments/filter/FilterMenu";
import { Filter, MenuItem, CustomFilters } from "../../types";
import ManageColumns from "../modal/ManageColumns";
import { Link } from "react-router-dom";
import EditList from "../modal/EditList";
import NewList from "../modal/newDriverList";
import DeleteCustomList from "../modal/DeleteCustomListModal";
import InplaceDropdown from "./InplaceDropdown/InplaceDropdown";
import { ViewSelect } from "./ViewDropdown";
import TitleReformat from "../../helpers/titleReformat";
import { toast } from "react-toastify";
import { api } from "../../api/api";
import InfiniteScroll from "react-infinite-scroll-component";
export const ROW_COLOR_EVEN = "#fff";
export const ROW_COLOR_ODD = "#f4f4f4";

export type ColumnType = "string" | "number" | "date" | "multiImage";
export type ColumnColors =
  | "blue"
  | "red"
  | "purple"
  | "green"
  | "yellow"
  | "orange"
  | undefined;
type RowDataItem =
  | string
  | number
  | Date
  | string[]
  | { [key: string]: string };

export type RowData = {
  // id: string;
  id: number;
  [key: string]: any;
  // data: RowDataItem[];
};

export type SelectedMenuOption = {
  text: string;
  onClick: (selectedIds: number[] | RowData[]) => void;
};

export interface DynamicTableProps {
  rowData: RowData[];
  totalItemCount?: number;
  currentPageFirstItemNumber?: number;
  columnTitles: string[];
  columnTypes?: ColumnType[];
  columnColors?: ColumnColors[];
  onNextClick?: () => void;
  onPrevClick?: () => void;
  onFirstClick: () => void;
  onLastClick: () => void;
  displayPagination?: boolean;
  displaySelection?: boolean;
  operateOnSelected?: (selectedIds: string[]) => void;
  onSortField?: (title: string) => void;
  onOpenRow?: (id: string) => void;
  hideSelectedMenu?: boolean;
  selectAll?: () => void;
  deselectAll?: () => void;
  selectedMenuOptions?: SelectedMenuOption[];
  selectedMenuLabel?: string;
  showPagination?: boolean;
  searchValue?: string;
  searchOnChange?: (keyword: string) => void;
  customButtonClick?: () => void;
  onRowClickSubpage?: (id: string) => void;
  renderEmptyGrid?: JSX.Element;
  excludedColumns?: string[];
  currentPage?: number;
  from?: number;
  to?: number;
  total?: number;
  renderNewList?: (id: number) => void;
  list: MenuItem[];
  updateList?: () => void;
  changeViewLimit: (limit: string) => void;
  limit: string;
  fieldList?: CustomFilters[];
  customFieldList?: CustomFilters[];
  currentList?: string;
  listID?: number | string;
  basic?: boolean;
  isDefaultList: boolean;
  exportCSVList: () => void;
  changeListRenderState: () => void;
  exporting: boolean;
  onColumnSave: (filters: CustomFilters[]) => void;
  disableViewSelect?: boolean;
  infiniteScrollProp?: boolean;
  onInfiniteScrollFetch?: any;
  hasNextPage?: boolean;
  scrollInverse?: boolean;
}

export interface DynamicTableState {
  selected: (boolean | any)[];
  globalSelected: boolean;
  // searchKeyword: string;
  showFilterMenu: boolean;
  showManageColumns: boolean;
  showNewList: boolean;
  editNewList: boolean;
  deleteList: boolean;
  currentPage: number | undefined;
}

// Items need to be able to be selectable
// ----
// Props
// -----
// [Titles]
// [types]
// [colors] <-- optionally color the rows; If null, use default alternating colors
// [{ id, [data] }]
// onNextClick: () => void
// onPrevClick: () => void
// operateOnSelected: (selectedList) => void
// onSortField: (title) => void
// onOpenItem: (id) => void
// hideSelectedMenu: boolean <-- default false

// State
// -----
// [selected: boolean]

// const filter_stub: Filter[] = [
//   {
//     id: "1",
//     field: "Driver",
//     type: "text",
//     operator: "Equals",
//     value: "Cory"
//   },
//   {
//     id: "2",
//     field: "# of Deliveries",
//     type: "number",
//     operator: "Greater than or equal to",
//     value: "1"
//   },
//   {
//     id: "3",
//     field: "Status",
//     type: "checkbox",
//     operator: "Equals",
//     value: "Active"
//   },
//   {
//     id: "4",
//     field: "Driver",
//     type: "text",
//     operator: "Does not equal",
//     value: "Cory"
//   },
//   {
//     id: "5",
//     field: "# of Deliveries",
//     type: "number",
//     operator: "Less than or equal to",
//     value: "1"
//   },
//   {
//     id: "6",
//     field: "Status",
//     type: "checkbox",
//     operator: "Equals",
//     value: "Active"
//   }
// ];

const field_list_stub = [
  {
    display: "Driver",
    field: "Driver",
    visible: true,
  },
  {
    display: "# of Deliveries",
    field: "# of Deliveries",
    visible: true,
  },
  {
    display: "Status",
    field: "Status",
    visible: true,
  },
  {
    display: "Start time",
    field: "Start time",
    visible: true,
  },
  {
    display: "ETA",
    field: "ETA",
    visible: true,
  },
  {
    display: "Route",
    field: "Route",
    visible: true,
  },
  {
    display: "Date Created",
    field: "Date Created",
    visible: true,
  },
  {
    display: "Date Last Modified",
    field: "Date Last Modified",
    visible: true,
  },
  {
    display: "Custom Field 1",
    field: "Custom Field 1",
    visible: false,
  },
  {
    display: "Custom Field 2",
    field: "Custom Field 2",
    visible: false,
  },
  {
    display: "Time Idle",
    field: "Time Idle",
    visible: false,
  },
  {
    display: "Time Waiting",
    field: "Time Waiting",
    visible: false,
  },
  {
    display: "Score",
    field: "Score",
    visible: false,
  },
  {
    display: "Points",
    field: "Points",
    visible: false,
  },
];

class DynamicTable extends React.Component<
  DynamicTableProps,
  DynamicTableState
> {
  constructor(props: DynamicTableProps) {
    super(props);

    this.state = {
      selected: this.props.rowData.map((row) => {
        return false;
      }),
      globalSelected: false,
      // searchKeyword: "",
      showFilterMenu: false,
      showManageColumns: false,
      showNewList: false,
      editNewList: false,
      deleteList: false,
      currentPage: this.props.currentPage,
    };
    this.validateInput();
  }

  static defaultProps = {};

  componentDidUpdate = async (prevProps, prevState) => {
    if (this.props.currentPage !== prevProps.currentPage) {
      this.setState({
        selected: this.props.rowData.map((row) => false),
        globalSelected: false,
      });
    }
    if (this.props.rowData !== prevProps.rowData) {
      this.setState({
        selected: this.props.rowData.map((row) => false),
        globalSelected: false,
      });
    }
  };

  getRowColor = (rowIndex: number): string | undefined => {
    // If column color is specified, use it. If not, use default alternative colors
    // declared at the top of this component
    const { columnColors } = this.props;
    if (columnColors && columnColors.length && columnColors[rowIndex]) {
      return columnColors[rowIndex];
    }
    return (rowIndex + 1) % 2 === 0 ? ROW_COLOR_EVEN : ROW_COLOR_ODD;
  };

  menuSelectedClick = (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>,
    onClick: SelectedMenuOption["onClick"]
  ) => {
    event.preventDefault();
    const { rowData, selectedMenuLabel } = this.props;
    const { selected } = this.state;
    const ordersList = rowData
      .filter((row, i) => selected[i])
      .map((row) => row);
    const selectedIds = rowData
      .filter((row, i) => selected[i])
      .map((row) => row.id);
    if (selectedMenuLabel === "Orders") {
      onClick(ordersList);
    }
    if (selectedMenuLabel === "Drivers") {
      onClick(selectedIds);
    }
    if (selectedMenuLabel === "Delivery Runs") {
      onClick(selectedIds);
    }
    if (selectedMenuLabel === "Deliveries") {
      onClick(selectedIds);
    }
  };

  toggleSelect = (e: React.ChangeEvent<HTMLInputElement>, i: number) =>
    this.setState((prev) => ({
      selected: prev.selected.map((item, index) => {
        if (i === index) {
          return !item;
        }
        return item;
      }),
    }));

  toggleAll = (
    e: React.ChangeEvent<HTMLInputElement>,
    i: number,
    viewLimit: number,
    currentPage: number | any
  ) => {
    let maxIndex = viewLimit - 1;
    let minIndex = 0;

    this.setState((prev) => ({
      selected: prev.selected.map((item, index) => {
        if (
          i <= maxIndex &&
          i >= minIndex &&
          index <= maxIndex &&
          index >= minIndex &&
          !this.state.globalSelected
        ) {
          return true;
        }
        return false;
      }),
      globalSelected: !prev.globalSelected,
    }));
  };

  onChangeSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    event.preventDefault();
    const { searchOnChange } = this.props;

    // this.setState({ searchKeyword: event.target.value });

    searchOnChange && searchOnChange(event.target.value);
  };

  toggleFilterMenu = () => {
    this.setState((prev) => ({ showFilterMenu: !prev.showFilterMenu }));
  };

  toggleManageColumns = () => {
    this.setState((prev) => ({ showManageColumns: !prev.showManageColumns }));
  };
  toggleNewList = () => {
    this.setState((prev) => ({ showNewList: !prev.showNewList }));
  };

  toggleEditList = () => {
    this.setState((prev) => ({ editNewList: !prev.editNewList }));
  };

  toggleDeleteList = () => {
    this.setState((prev) => ({ deleteList: !prev.deleteList }));
  };

  validateInput = () => {
    // Check to make sure columnColors, columnTitles, and columnTypes is the same
    // length as rowData.data if each exists.
    const { rowData, columnColors, columnTitles, columnTypes } = this.props;
    // Allow differing lengths if rowData is empty
    // rowData.forEach(rowDatum => {
    //   if (columnColors && columnColors.length !== rowDatum.data.length) {
    //     throw new Error(
    //       "ColumnColors array length does not match rowData.data array length"
    //     );
    //   }
    //   if (columnTitles && columnTitles.length !== rowDatum.data.length) {
    //     throw new Error(
    //       "ColumnTitles array length does not match rowData.data array length"
    //     );
    //   }
    //   if (columnTypes && columnTypes.length !== rowDatum.data.length) {
    //     throw new Error(
    //       "columnTypes array length does not match rowData.data array length"
    //     );
    //   }
    // });
  };
  renderRowItem = (
    item: RowDataItem,
    colIndex: number,
    rowIndea,
    id: number,
    columnTitleName: string
  ) => {
    const { columnTypes, selectedMenuLabel } = this.props;
    // TODO: Support epoch dates
    if (typeof item === "object") {
      switch (selectedMenuLabel) {
        case "Orders":
          if (columnTitleName === "address") {
            const { address_1, city, state, zip } = item as {
              [key: string]: string;
            };
            return (
              <Link to={`/order/${id}`}>
                <ItemText>{`${address_1} ${city}, ${state} ${zip}`}</ItemText>
              </Link>
            );
          }
          if (columnTitleName === "customer") {
            const { first_name, last_name } = item as { [key: string]: string };
            return (
              <Link to={`/order/${id}`}>
                <ItemText>{`${first_name} ${last_name}`}</ItemText>
              </Link>
            );
          }
      }
    }
    if (typeof item !== "object")
      switch (columnTypes && columnTypes[colIndex]) {
        case "date":
          return <ItemText>{formatDate(item as number)}</ItemText>;
        case "string" || "number":
          return (
            <ItemText>
              {typeof item === "string" ? TitleReformat(item) : item}
            </ItemText>
          );
        case "multiImage":
          // TODO: Support multiImage row type
          return (
            <ItemText>
              {typeof item === "string" ? TitleReformat(item) : item}
            </ItemText>
          );
        default:
          switch (selectedMenuLabel) {
            case "Drivers":
              if (columnTitleName === "color") {
                return (
                  <ItemDiv>
                    <ColorBox backgroundColor={item} />
                  </ItemDiv>
                );
              }
              return (
                <Link to={`/driver/${id}`}>
                  <ItemText>
                    {typeof item === "string" ? TitleReformat(item) : item}
                  </ItemText>
                </Link>
              );
            case "Orders":
              return (
                <Link to={`/order/${id}`}>
                  <ItemText>{item}</ItemText>
                </Link>
              );
            case "Delivery Runs":
              return (
                <Link to={`/delivery_run/${id}`}>
                  <ItemText>{item}</ItemText>
                </Link>
              );
            case "Deliveries":
              return (
                <Link to={`/deliveries/${id}`}>
                  <ItemText>{item}</ItemText>
                </Link>
              );
          }
        // return (
        //   this.props.selectedMenuLabel === "Drivers" && <Link to={`/driver/${id}`}>
        //     <ItemText>{item}</ItemText>
        //   </Link>
        // );
      }
  };

  renderMainList = () => {
    const {
      rowData,
      columnTitles,
      onInfiniteScrollFetch,
      infiniteScrollProp,
      hasNextPage,
      scrollInverse,
    } = this.props;
    const {
      // searchKeyword,
      selected,
      globalSelected,
    } = this.state;

    const num_columns = !!rowData.length ? Object.keys(rowData[0]).length : 0;

    console.log("rowData.length}", rowData.length + 1);
    if (infiniteScrollProp) {
      return (
        <StyledInfiniteScroll
          length={num_columns}
          dataLength={rowData.length + 1}
          next={onInfiniteScrollFetch}
          inverse={scrollInverse}
          hasMore={!!hasNextPage}
          loader={<h4>Loading...</h4>}
        >
          {columnTitles &&
            columnTitles
              .filter((title) => title !== "id")
              .map((title, i) => {
                return (
                  <>
                    {i === 0 && (
                      <StickyRowItem
                        key={`${title}${i + 1}`}
                        column={i + 1}
                        color={this.getRowColor(0)}
                        style={{
                          width: "min-content",
                          position: "sticky",
                          top: 0,
                        }}
                      >
                        <TestCheckbox
                          checked={globalSelected}
                          onChange={(e) =>
                            this.toggleAll(
                              e,
                              i,
                              this.props.to as number,
                              this.props.currentPage
                            )
                          }
                        />
                      </StickyRowItem>
                    )}
                    <StickyRowItem
                      key={`${title}${i + 1}`}
                      column={i + 2}
                      color={this.getRowColor(0)}
                    >
                      <HeaderText>{TitleReformat(title)}</HeaderText>
                    </StickyRowItem>
                  </>
                );
              })}
          {rowData.map((row, rowIndex) =>
            columnTitles
              .filter((title) => title !== "id")
              .map((columnTitleName, colIndex) => {
                // console.log(`row: ${row} `);

                return (
                  <>
                    {colIndex === 0 && (
                      <RowItem
                        // key={`${rowIndex}${colIndex + 1}`}
                        key={row.id}
                        column={colIndex + 1}
                        color={
                          row.status_color
                            ? row.status_color
                            : this.getRowColor(rowIndex + 1)
                        }
                        // style={{
                        //   width: "min-content",
                        //   position: "sticky",
                        //   top: 0,
                        // }}
                      >
                        <TestCheckbox
                          checked={selected[rowIndex]}
                          onChange={(e) => this.toggleSelect(e, rowIndex)}
                        />
                      </RowItem>
                    )}
                    <RowItem
                      // key={`${rowIndex}${colIndex + 2}`}
                      key={`${row.id}${colIndex}`}
                      column={colIndex + 2}
                      color={
                        row.status_color
                          ? row.status_color
                          : this.getRowColor(rowIndex + 1)
                      }
                    >
                      {this.renderRowItem(
                        row[columnTitleName],
                        colIndex,
                        rowIndex,
                        row.id,
                        columnTitleName
                      )}
                    </RowItem>
                  </>
                );
              })
          )}
        </StyledInfiniteScroll>
      );
    } else {
      return (
        <Div length={num_columns}>
          {columnTitles &&
            columnTitles
              .filter((title) => title !== "id")
              .map((title, i) => {
                return (
                  <>
                    {i === 0 && (
                      <StickyRowItem
                        key={`${title}${i + 1}`}
                        column={i + 1}
                        color={this.getRowColor(0)}
                        style={{
                          width: "min-content",
                          position: "sticky",
                          top: 0,
                        }}
                      >
                        <TestCheckbox
                          checked={globalSelected}
                          onChange={(e) =>
                            this.toggleAll(
                              e,
                              i,
                              this.props.to as number,
                              this.props.currentPage
                            )
                          }
                        />
                      </StickyRowItem>
                    )}
                    <StickyRowItem
                      key={`${title}${i + 1}`}
                      column={i + 2}
                      color={this.getRowColor(0)}
                    >
                      <HeaderText>{TitleReformat(title)}</HeaderText>
                    </StickyRowItem>
                  </>
                );
              })}
          {rowData.map((row, rowIndex) =>
            columnTitles
              .filter((title) => title !== "id")
              .map((columnTitleName, colIndex) => {
                // console.log(`row: ${row} `);

                return (
                  <>
                    {colIndex === 0 && (
                      <RowItem
                        // key={`${rowIndex}${colIndex + 1}`}
                        key={row.id}
                        column={colIndex + 1}
                        color={
                          row.status_color
                            ? row.status_color
                            : this.getRowColor(rowIndex + 1)
                        }
                        // style={{
                        //   width: "min-content",
                        //   position: "sticky",
                        //   top: 0,
                        // }}
                      >
                        <TestCheckbox
                          checked={selected[rowIndex]}
                          onChange={(e) => this.toggleSelect(e, rowIndex)}
                        />
                      </RowItem>
                    )}
                    <RowItem
                      // key={`${rowIndex}${colIndex + 2}`}
                      key={`${row.id}${colIndex}`}
                      column={colIndex + 2}
                      color={
                        row.status_color
                          ? row.status_color
                          : this.getRowColor(rowIndex + 1)
                      }
                    >
                      {this.renderRowItem(
                        row[columnTitleName],
                        colIndex,
                        rowIndex,
                        row.id,
                        columnTitleName
                      )}
                    </RowItem>
                  </>
                );
              })
          )}
        </Div>
      );
    }
  };

  render() {
    const {
      rowData,
      columnTitles,
      selectedMenuOptions,
      selectedMenuLabel,
      totalItemCount,
      currentPageFirstItemNumber,
      showPagination,
      onPrevClick,
      onNextClick,
      onFirstClick,
      onLastClick,
      searchValue,
      isDefaultList,
      disableViewSelect,
      // onSearchChange
    } = this.props;
    const {
      // searchKeyword,
      showFilterMenu,
      showManageColumns,
      selected,
      globalSelected,
    } = this.state;

    const num_rows = rowData.length;
    const num_columns = !!rowData.length ? Object.keys(rowData[0]).length : 0;

    const currentSelection =
      currentPageFirstItemNumber &&
      `${currentPageFirstItemNumber}-${
        currentPageFirstItemNumber + num_rows - 1
      } of ${totalItemCount ? totalItemCount : num_rows}`;
    const currentViewLimit = parseInt(this.props.limit);
    const num_selected = selected.filter((selectedItem) => selectedItem).length;
    return (
      <>
        <FilterMenu
          visible={this.state.showFilterMenu}
          toggleMenu={this.toggleFilterMenu}
          filterType={this.props.selectedMenuLabel}
          updateList={this.props.updateList}
          renderNewList={this.props.renderNewList}
        />
        <ManageColumns
          showModal={showManageColumns}
          toggleModal={this.toggleManageColumns}
          fieldList={
            this.props.fieldList ? this.props.fieldList : field_list_stub
          }
          onSave={this.props.onColumnSave}
          selectedMenuLabel={this.props.selectedMenuLabel}
        />
        <NewList
          list_type={this.props.selectedMenuLabel as string}
          showModal={this.state.showNewList}
          toggleModal={this.toggleNewList}
          renderNewList={this.props.renderNewList}
        />
        <EditList
          showModal={this.state.editNewList}
          toggleModal={this.toggleEditList}
          listID={this.props.listID ? this.props.listID : 0}
          entity={
            this.props.selectedMenuLabel ? this.props.selectedMenuLabel : ""
          }
        />
        <DeleteCustomList
          showModal={this.state.deleteList}
          toggleModal={this.toggleDeleteList}
          entity={
            this.props.selectedMenuLabel ? this.props.selectedMenuLabel : ""
          }
        />
        <HeaderDiv>
          <Spread>
            <HeaderHorizontal>
              <PlusButton onClick={this.toggleNewList}>
                <FaPlus color={PRIMARY_COLOR} size={26} />
              </PlusButton>
              {/* <Dropdown text="RECENTLY VIEWED" /> */}
              <InplaceDropdown
                onChangeSelected={(label) => console.log("label: ", label)}
                list={this.props.list}
                changeListRenderState={this.props.changeListRenderState}
                dropDownType={this.props.selectedMenuLabel}
              />
              {this.props.exporting && (
                <ExportDiv>
                  <SpinnerText>Exporting CSV</SpinnerText>
                  <Spinner />
                </ExportDiv>
              )}
              <ThisButton
                onClick={() =>
                  isDefaultList
                    ? isDefaultNotification()
                    : this.toggleEditList()
                }
                padding="0 8px"
              >
                <MdEdit color={PRIMARY_COLOR} size={22} />
              </ThisButton>
              <ThisButton
                onClick={() =>
                  isDefaultList
                    ? isDefaultNotification()
                    : this.toggleDeleteList()
                }
                padding="0 8px"
              >
                <FaTrashAlt color={PRIMARY_COLOR} size={22} />
              </ThisButton>
              <SearchInput
                placeholder="Search in this list"
                value={searchValue}
                // onChange={onSearchChange}
                onChange={this.onChangeSearch}
              />
            </HeaderHorizontal>
            <HeaderHorizontal>
              <ThisButton
                padding="0 15px"
                style={{ marginLeft: 15 }}
                onClick={this.toggleManageColumns}
              >
                <ButtonText>Manage Columns</ButtonText>
              </ThisButton>
              {/* {this.props.type !== "order" ? (
                <ThisButton
                  padding="0 15px"
                  onClick={this.props.customButtonClick}
                >
                  <ButtonText>Add Driver</ButtonText>
                </ThisButton>
              ) : null} */}
              {!this.props.basic && (
                <ThisButton
                  padding="0 15px"
                  onClick={this.props.customButtonClick}
                >
                  <ButtonText>
                    Add {`${this.props.selectedMenuLabel}`}
                  </ButtonText>
                </ThisButton>
              )}
              <ThisButton padding="0 8px">
                <FaSyncAlt color={PRIMARY_COLOR} size={22} rotate={45} />
              </ThisButton>
              <ThisButton
                padding="0 15px"
                onClick={() => this.props.exportCSVList()}
                style={{
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <IoMdDownload color={PRIMARY_COLOR} size={22} />
                <ButtonText>DOWNLOAD REPORT</ButtonText>
              </ThisButton>
              <ThisButton
                padding="0 8px"
                onClick={() =>
                  isDefaultList
                    ? isDefaultNotification()
                    : this.toggleFilterMenu()
                }
                color={showFilterMenu ? PRIMARY_COLOR : "#fff"}
                disabled={this.state.showFilterMenu}
              >
                <FaFilter
                  color={showFilterMenu ? "#fff" : PRIMARY_COLOR}
                  size={22}
                />
              </ThisButton>
            </HeaderHorizontal>
          </Spread>
          <Main>
            {!rowData.length
              ? this.props.renderEmptyGrid
              : this.renderMainList()}
            <Footer>
              <Dropdown
                additionPosition="bottom"
                text={`${num_selected} ${
                  selectedMenuLabel ? selectedMenuLabel : ""
                } Selected`}
                button
                pointing="top"
                upward
                wrapSelection
                className="dropdown-menu"
              >
                <Dropdown.Menu className="dropdown-menu-text">
                  {selectedMenuOptions &&
                    selectedMenuOptions.map((option) => (
                      <Dropdown.Item
                        key={option.text}
                        className="dropdown-menu-text"
                        text={option.text}
                        onClick={(event) =>
                          this.menuSelectedClick(event, option.onClick)
                        }
                      />
                    ))}
                </Dropdown.Menu>
              </Dropdown>
              <Horizontal>
                {showPagination && !disableViewSelect && (
                  <>
                    {!!onFirstClick && (
                      <ClickHorizontal onClick={onFirstClick}>
                        <MdFirstPage size={24} />
                      </ClickHorizontal>
                    )}
                    {!!onPrevClick && (
                      <ClickHorizontal onClick={onPrevClick}>
                        <MdChevronLeft size={24} />
                        <PaginateText>Previous</PaginateText>
                      </ClickHorizontal>
                    )}
                    {!!this.props.from && (
                      <PaginateSummary>{`${this.props.from}-${this.props.to} of ${this.props.total}`}</PaginateSummary>
                    )}
                    {!this.props.from && (
                      <PaginateSummary>{`No items on this page
                  `}</PaginateSummary>
                    )}
                    {!!onNextClick && (
                      <ClickHorizontal onClick={onNextClick}>
                        <PaginateText>Next</PaginateText>
                        <MdChevronRight size={24} />
                      </ClickHorizontal>
                    )}
                    {!!onLastClick && (
                      <ClickHorizontal onClick={onLastClick}>
                        <MdLastPage size={24} />
                      </ClickHorizontal>
                    )}
                  </>
                )}
                {!disableViewSelect && (
                  <ViewSelect changeViewLimit={this.props.changeViewLimit} />
                )}
              </Horizontal>
              <div style={{ width: "180px" }} />
            </Footer>
          </Main>
        </HeaderDiv>
      </>
    );
  }
}

DynamicTable.defaultProps = {
  displayPagination: true,
  displaySelection: true,
  hideSelectedMenu: false,
  selectedMenuOptions: [],
  showPagination: true,
};

interface DivProps {
  length: number;
}

interface RowItemProps {
  column?: number;
  color?: string;
}

const Div = styled.div<DivProps>`
  border: solid 1px #d8d8d8;
  border-bottom: solid 1px #d8d8d8;
  display: grid;
  grid-template-columns: min-content repeat(${(props) => props.length}, 1fr);
  width: auto;
  overflow-x: scroll;
  position: relative;
  max-height: 550.66px;
`;

const InfiniteScrollDiv = styled.div<DivProps>`
  border: solid 1px #d8d8d8;
  border-bottom: solid 1px #d8d8d8;
  display: grid;
  grid-template-columns: min-content repeat(${(props) => props.length}, 1fr);
  width: auto;
  overflow-x: scroll;
  position: relative;
  max-height: 550.66px;
  max-width: 96vw;
`;

const StyledInfiniteScroll = styled(InfiniteScroll)<DivProps>`
  border: solid 1px #d8d8d8;
  border-bottom: solid 1px #d8d8d8;
  display: grid;
  grid-template-columns: min-content repeat(${(props) => props.length}, 1fr);
  width: auto;
  overflow-x: scroll;
  position: relative;
  max-height: 550.66px;
  max-width: 96vw;
`;

const HeaderDiv = styled.div`
  display: grid;
  grid-template-rows: min-content min-content;
  gap: 30px;
  width: auto;
`;

const Main = styled.div`
  display: grid;
  grid-template-rows: auto min-content;
`;

const Footer = styled.div`
  padding: 10px 0;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`;

const Horizontal = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const ClickHorizontal = styled(Horizontal)`
  cursor: pointer;
  border: 1px solid white;
  :hover {
    border: 1px solid black;
    border-radius: 4px;
  }
`;

const RowItem = styled.div<RowItemProps>`
  padding: 14px 12px;
  /* max-width: 300px; */
  grid-column: ${(props) => props.column} / span 1;
  border-right: solid 0.5px #d8d8d8;
  background-color: ${(props) => props.color};
`;

const StickyRowItem = styled.div<RowItemProps>`
  padding: 14px 12px;
  /* max-width: 300px; */
  grid-column: ${(props) => props.column} / span 1;
  border-right: solid 0.5px #d8d8d8;
  background-color: ${(props) => props.color};
  position: -webkit-sticky;
  position: sticky;
  top: 0;
`;

const PaginateText = styled(AppText)`
  font-size: 16px;
  color: #333333;
  margin: 0px 2px;
`;

const PaginateSummary = styled(AppText)`
  font-size: 10px;
  font-weight: 900;
  letter-spacing: 0.1px;
  color: #3b3a3a;
  margin: 0 15px;
`;

RowItem.defaultProps = {
  color: "#fff",
  column: 1,
};

const ItemText = styled(AppText)`
  font-size: 14px;
  color: #333333;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 200px;
`;

const HeaderText = styled(ItemText)`
  opacity: 0.5;
  font-weight: 900;
`;

const ItemDiv = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
`;
const SelectedMenuText = styled(AppText)`
  font-size: 10px;
  font-weight: 900;
  letter-spacing: 0.1px;
  color: #3b3a3a;
`;

const Spread = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

const HeaderHorizontal = styled.div`
  /* display: flex;
  flex-direction: row; */
  display: grid;
  grid-template-columns: repeat(5, min-content);
  gap: 15px;
  white-space: nowrap;
`;

interface ThisButtonProps {
  color?: string;
}

const ThisButton = styled(Button)`
  height: 40px;
  background-color: ${(props) => props.color};
  width: auto;
  border-radius: 4px;
  border: solid 1px rgba(71, 70, 71, 0.26);
`;

ThisButton.defaultProps = {
  color: "#fff",
};

const PlusButton = styled(ThisButton)`
  border-width: 0;
`;

const ButtonText = styled(AppText)`
  font-size: 10px;
  font-weight: 500;
  letter-spacing: 0.1px;
  color: #3b3a3a;
`;

const ExportDiv = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  position: absolute;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  z-index: 10;
  box-shadow: 2px 1px 3px 0 rgba(0, 0, 0, 0.38);
  width: 220px;
  height: 114px;
  border-radius: 4px;
  background-color: white;
`;

const SpinnerText = styled(AppText)`
  font-size: 14px;
  font-weight: 600;
  color: ${PRIMARY_COLOR};
`;

export { DynamicTable };
