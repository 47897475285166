import * as React from "react";
import { connect } from "react-redux";
import { withRouter, RouteComponentProps } from "react-router";
import styled from "styled-components";
// import BackArrow from "react-icons/lib/md/chevron-left";
import { MdChevronLeft } from "react-icons/md";
import * as Yup from "yup";
import {
  Container,
  SecondaryButton,
  Button,
  HR,
  Spinner,
  AppText,
} from "../UI";
import { FieldDetail, ColorItem } from "../Dumb";
// import { Toggle } from "react-toggle-component";
import { PRIMARY_GRADIENT_FROM } from "../../utils";
import { Formik, FormikProps, FieldArray } from "formik";
import {
  InputField,
  SelectField,
  CheckboxField,
  OnboardCheckboxField,
} from "../form";
import { languages } from "../../static";
import { ColorSelectField } from "../form/ColorSelectField";
import Switch from "react-toggle-switch";
import "react-toggle-switch/dist/css/switch.min.css";
import { api } from "../../api/api";
import { DeliveryResponse } from "../../api/responseTypes";
import { AppState } from "../../store/configureStore";

import { Dispatch, bindActionCreators } from "redux";
import {
  AppActions,
  CustomFieldByEntity,
  CustomFieldsList,
  CustomFields,
  CustomFieldsByEntityListValue,
} from "../../types";
import { getDeliveryCustomFieldValues } from "../../actions";

// TODO: Allow email to be editable

const editDeliverySchema = Yup.object().shape({});

interface FieldArray {
  custom_field_id: number;
  field: number | undefined;
  label: string;
  slug: string;
  value: string | null;
  filter_type: number;
  listValues: CustomFieldsByEntityListValue[];
}

interface MyFormikProps {
  custom_fields: FieldArray[];
}

interface RouterProps {
  id: string;
}

interface DeliveryDetailProps {}
interface DeliveryDetailState {
  edit: boolean;
  toggle: boolean;
  delivery: DeliveryResponse;
  loading: boolean;
  custom_fields: FieldArray[];
}

type Props = RouteComponentProps<RouterProps> &
  DeliveryDetailProps &
  LinkStateProps &
  LinkDispatchProps;

class DeliveryDetail extends React.Component<Props, DeliveryDetailState> {
  constructor(props: Props) {
    super(props);

    this.state = {
      edit: false,
      toggle: false,
      loading: true,
      delivery: {
        id: 0,
        order_id: 0,
        store_id: 0,
        customer_id: 0,
        delivery_run_id: 0,
        notes: "",
        delivery_image: "",
        minutes_in_store: 0,
        minutes_in_transit: 0,
        created_at: "",
        updated_at: "",
        deleted_at: "",
        date_added_to_delivery_run: "",
        completed: 0,
        estimated_delivery_time: "",
        actual_delivery_time: "",
        status_id: 0,
        sequence_number: 0,
        status: "",
        order: {
          id: 0,
          store_id: 0,
          driver_id: null,
          customer_id: 0,
          address_id: 0,
          status: null,
          num_boxes: 0,
          amount_to_collect: 0,
          description: "",
          notes: "",
          created_at: "",
          updated_at: "",
          deleted_at: null,
          reference: null,
          date_imported: null,
          address_relevance: 1,
          delivered: null,
          delivery_date: null,
          sort_order: null,
          payment_method: "",
          signature: "",
          delivery_notes: "",
          delivery_image: "",
        },
        customer: {
          id: 0,
          address_id: 0,
          first_name: "",
          last_name: "",
          email: "",
          phone_type: "",
          phone: "",
          subscription_price: "",
          created_at: "",
          updated_at: "",
          deleted_at: null,
          address: {
            id: 0,
            address_1: "",
            address_2: "",
            city: "",
            state: "",
            zip: "",
            created_at: "",
            updated_at: "",
            deleted_at: null,
            latitude: 0,
            longitude: 0,
            notes: null,
          },
        },
        voided_order_id: 0,
      },
      custom_fields: [],
    };
  }

  toggleEdit = () => this.setState((prev) => ({ edit: !prev.edit }));

  toggleToggle = () => {
    console.log("toggle click");
    this.setState((prev) => ({ toggle: !prev.toggle }));
  };

  deleteDelivery = async () => {
    const id = this.props.match.params.id;
    await api.deleteDelivery({ id }, this.props.token);
    this.props.history.goBack();
  };

  componentDidMount = async () => {
    const id = this.props.match.params.id;
    const delivery = await api.getDelivery({ id }, this.props.token);
    const customValues = (await api.getAllCustomProperties(
      id,
      this.props.token,
      "Delivery"
    )) as CustomFieldByEntity[];
    const finalList =
      this.props.allCustomFields && customValues
        ? customValues.map((field) => {
            const slug = this.props.allCustomFields
              .filter(
                (allcustomFields) =>
                  allcustomFields.id === field.custom_field.id
              )
              .map((obj) => {
                return {
                  display: obj.display,
                  slug: obj.slug,
                  filter_id: obj.field_type_id,
                };
              })[0];
            return {
              field: field.id,
              value: field.value,
              custom_field_id: field.custom_field.id,
              label: slug ? slug.display : "",
              slug: slug ? slug.slug : "",
              filter: slug ? slug.filter_id : "",
              listValues: field.custom_field.listValues,
              filter_type: field.custom_field.field_type_id,
            };
          })
        : [];
    this.setState({ delivery, loading: false, custom_fields: finalList });
    this.props.getAllCustomValues(customValues);
  };
  setCustomFieldsState = (customfields: FieldArray[]) => {
    this.setState({
      custom_fields: customfields,
    });
  };
  render() {
    const { edit, loading, custom_fields } = this.state;
    const { customValues, allCustomFields } = this.props;
    const {
      history: { goBack },
    } = this.props;
    if (loading) {
      return <Spinner />;
    }
    return (
      <Container>
        <Div>
          <Horizontal onClick={goBack}>
            <MdChevronLeft color={PRIMARY_GRADIENT_FROM} size={42} />
            <ReturnText>GO BACK</ReturnText>
          </Horizontal>
          <Formik
            initialValues={{
              // need confirmation on what other fields to edit
              custom_fields: this.state.custom_fields,
            }}
            onSubmit={async (values, { setFieldError, setStatus }) => {
              const { token } = this.props;
              setStatus({ error: "", loading: true });
              const response = await api.editDelivery(
                {},
                parseInt(this.props.match.params.id),
                token
              );
              values.custom_fields.forEach((field) => {
                if (!!field.field) {
                  api.updateCustomFieldValue(
                    { id: field.field, value: field.value as string },
                    token
                  );
                }
                if (!field.field && field.value) {
                  api.createCustomFieldValue(
                    {
                      custom_field_id: field.custom_field_id,
                      entity_id: parseInt(this.props.match.params.id),
                      value: field.value,
                    },
                    token
                  );
                }
              });
              this.setState(
                (prev) => ({ delivery: { ...prev.delivery, ...response } }),
                () => {
                  this.toggleEdit();
                  setStatus({ loading: false });
                }
              );
              this.setState({
                custom_fields: values.custom_fields,
              });
            }}
          >
            {({
              status,
              setStatus,
              submitForm,
              values,
            }: FormikProps<MyFormikProps>) => {
              return (
                <Main>
                  <Spread>
                    <Horizontal>
                      <NameText>{`${this.state.delivery.customer.first_name.toUpperCase()} ${this.state.delivery.customer.last_name.toUpperCase()}`}</NameText>
                    </Horizontal>
                    <Horizontal>
                      {edit ? (
                        <>
                          <CancelText clickable onClick={this.toggleEdit}>
                            Cancel
                          </CancelText>
                          <SaveButton type="submit" onClick={submitForm}>
                            Save
                          </SaveButton>
                        </>
                      ) : (
                        <>
                          <SecondaryButton
                            style={{ width: "130px" }}
                            onClick={this.toggleEdit}
                            type="button"
                          >
                            Edit Delivery
                          </SecondaryButton>
                          <DeleteButton
                            onClick={this.deleteDelivery}
                            style={{ width: "130px", marginLeft: "12px" }}
                            type="button"
                          >
                            Delete Delivery
                          </DeleteButton>
                        </>
                      )}
                    </Horizontal>
                  </Spread>
                  <ThisHR />
                  <Grid>
                    <SectionTitle style={{ alignSelf: "center" }}>
                      SYSTEM PROPERTIES
                    </SectionTitle>
                    {this.state.delivery.voided_order_id !== undefined &&
                      this.state.delivery.voided_order_id > 0 && (
                        <SectionTitle
                          style={{ alignSelf: "center", color: "red" }}
                        >
                          VOIDED ORDER
                        </SectionTitle>
                      )}
                    {edit ? (
                      <>
                        {/* <InputField
                          style={{
                            gridRow: "2 / span 1",
                            gridColumn: "1 / span 1"
                          }}
                          name="customer_first_name"
                          placeholder="Customer first name"
                          title="Customer First name"
                        />
                        <InputField
                          style={{
                            gridRow: "2 / span 1",
                            gridColumn: "2 / span 1"
                          }}
                          name="customer_last_name"
                          placeholder="Customer Last name"
                          title="Customer Last name"
                        /> */}
                      </>
                    ) : (
                      <>
                        {/* <FieldDetail
                          style={{
                            gridRow: "2 / span 1",
                            gridColumn: "3 / span 1"
                          }}
                          title="customer name"
                          value={this.state.delivery.customer.first_name}
                        /> */}
                      </>
                    )}

                    <FieldDetail
                      style={{
                        gridRow: "2 / span 1",
                        gridColumn: "1 / span 1",
                      }}
                      title="Customer First name"
                      value={this.state.delivery.customer.first_name}
                    />
                    <FieldDetail
                      style={{
                        gridRow: "2 / span 1",
                        gridColumn: "2 / span 1",
                      }}
                      title="Last name"
                      value={this.state.delivery.customer.last_name}
                    />
                    <FieldDetail
                      style={{
                        gridRow: "2 / span 1",
                        gridColumn: "3 / span 1",
                      }}
                      title="Customer Email"
                      value={this.state.delivery.customer.email}
                    />
                    <FieldDetail
                      style={{
                        gridRow: "3 / span 1",
                        gridColumn: "1 / span 1",
                      }}
                      title="Customer Phone"
                      value={this.state.delivery.customer.phone}
                    />
                    <FieldDetail
                      style={{
                        gridRow: "3 / span 1",
                        gridColumn: "2 / span 1",
                      }}
                      title="Phone Type"
                      value={this.state.delivery.customer.phone_type as string}
                    />
                    <FieldDetail
                      style={{
                        gridRow: "3 / span 1",
                        gridColumn: "3 / span 1",
                      }}
                      title="Order ID"
                      value={`${this.state.delivery.order_id}`}
                    />
                    <FieldDetail
                      style={{
                        gridRow: "4 / span 1",
                        gridColumn: "1 / span 1",
                      }}
                      title="Time in Store"
                      value={`${this.state.delivery.minutes_in_store}`}
                    />
                    <FieldDetail
                      style={{
                        gridRow: "4 / span 1",
                        gridColumn: "2 / span 1",
                      }}
                      title="Time in Transit"
                      value={`${this.state.delivery.minutes_in_transit}`}
                    />
                    <FieldDetail
                      style={{
                        gridRow: "4 / span 1",
                        gridColumn: "3 / span 1",
                      }}
                      title="Date added"
                      value={this.state.delivery.created_at}
                    />
                    <FieldDetail
                      style={{
                        gridRow: "5 / span 1",
                        gridColumn: "1 / span 1",
                      }}
                      title="Delivery Address"
                      value={`${this.state.delivery.customer.address.address_1} \n ${this.state.delivery.customer.address.city}`}
                    />
                    <FieldDetail
                      style={{
                        gridRow: "5 / span 1",
                        gridColumn: "2 / span 1",
                      }}
                      title="Notes"
                      value={this.state.delivery.notes}
                    />
                    {this.state.delivery.order && (
                      <FieldDetail
                        style={{
                          gridRow: "5 / span 1",
                          gridColumn: "3 / span 1",
                        }}
                        type="image"
                        title="Signature"
                        value={this.state.delivery.order.signature}
                      />
                    )}
                    {this.state.delivery.order && (
                      <FieldDetail
                        style={{
                          gridRow: "6 / span 1",
                          gridColumn: "1 / span 1",
                        }}
                        type="image"
                        title="Delivery Picture"
                        value={this.state.delivery.order.delivery_image}
                      />
                    )}
                    <ThisHR
                      style={{
                        gridRow: "7 / span 1",
                        gridColumn: "1 / span 3",
                      }}
                    />
                  </Grid>
                  <Grid>
                    <SectionTitle
                      style={{
                        alignSelf: "center",
                        gridRow: "1 / span 1",
                        gridColumn: "1 / span 1",
                      }}
                    >
                      CUSTOM PROPERTIES
                    </SectionTitle>
                    <EmptyDiv
                      style={{
                        gridRow: "1 / span 1",
                        gridColumn: "2 / span 1",
                      }}
                    />
                    <EmptyDiv
                      style={{
                        gridRow: "1 / span 1",
                        gridColumn: "3 / span 1",
                      }}
                    />
                    {edit ? (
                      <>
                        <FieldArray
                          name="custom_fields"
                          render={(arrayHelpers) => (
                            <>
                              {values.custom_fields.map((fields, index) => {
                                if (fields.filter_type === 3) {
                                  const optionItems = fields.listValues.map(
                                    (values) => {
                                      return {
                                        label: values.value,
                                        data: values.value,
                                        value: values.value,
                                      };
                                    }
                                  );
                                  return (
                                    <div key={fields.custom_field_id}>
                                      <SelectField
                                        name={`custom_fields[${index}].value`}
                                        placeholder={
                                          fields.value ? fields.value : ""
                                        }
                                        options={optionItems}
                                        title={fields.label}
                                      />
                                    </div>
                                  );
                                }
                                if (fields.filter_type === 6) {
                                  return (
                                    <div key={fields.custom_field_id}>
                                      <ValueText>{fields.label}</ValueText>
                                      <CheckboxField
                                        title={``}
                                        name={`custom_fields[${index}].value`}
                                      />
                                    </div>
                                  );
                                }
                                return (
                                  <div key={fields.custom_field_id}>
                                    <InputField
                                      name={`custom_fields[${index}].value`}
                                      placeholder={
                                        fields.value ? fields.value : ""
                                      }
                                      title={fields.label}
                                    />
                                  </div>
                                );
                              })}
                            </>
                          )}
                        />
                      </>
                    ) : (
                      <>
                        {custom_fields.length > 0
                          ? custom_fields.map((values) => {
                              if (values.filter_type === 6) {
                                return (
                                  <FieldDetail
                                    title={values.label}
                                    value={values.value ? "true" : "false"}
                                  />
                                );
                              }
                              return (
                                <FieldDetail
                                  title={values.label}
                                  value={values.value ? values.value : ""}
                                />
                              );
                            })
                          : null}
                      </>
                    )}
                  </Grid>
                </Main>
              );
            }}
          </Formik>
        </Div>
      </Container>
    );
  }
}

const Div = styled.div`
  display: flex;
  flex-direction: column;
`;

const Main = styled(Div)`
  margin-left: 90px;
`;

const Spread = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-end;
`;

const Grid = styled.div`
  display: grid;
  grid-template-rows: min-content 50px 50px 50px min-content;
  grid-template-columns: 400px 400px 400px;
  row-gap: 25px;
`;

const ValueText = styled(AppText)`
  opacity: 0.5;
  font-size: 14px;
  font-weight: 500;
  color: #3b3a3a;
  margin-bottom: 7px;
`;
const Horizontal = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: min-content;
  white-space: nowrap;
  :hover {
    cursor: pointer;
  }
`;

const CancelText = styled(AppText)`
  font-size: 13px;
  font-weight: 500;
  color: #3b3a3a;
  margin-right: 65px;
`;

const DeleteButton = styled(Button)`
  background-color: #c4183c;
`;

const SaveButton = styled(Button)`
  width: 130px;
  height: 40px;
`;

const ThisColorItem = styled(ColorItem)`
  height: 24px;
  width: 24px;
  margin: 0;
`;

const NameText = styled(AppText)`
  opacity: 0.9;
  font-size: 18px;
  font-weight: 500;
  letter-spacing: 0.2px;
  color: #3b3a3a;
  margin-left: 17px;
`;
const EmptyDiv = styled.div``;
const ReturnText = styled(AppText)`
  font-size: 15px;
  font-weight: 600;
  letter-spacing: 0.3px;
  color: ${(props) => props.theme.PRIMARY_GRADIENT_FROM};
  margin-left: 17px;
`;

const SectionTitle = styled(AppText)`
  font-size: 15px;
  font-weight: 900;
  color: #3b3a3a;
`;

const ThisHR = styled(HR)`
  margin: 15px 0 25px 0;
`;

interface LinkStateProps {
  token: string;
  customValues: CustomFieldByEntity[];
  allCustomFields: CustomFields[];
}
interface LinkDispatchProps {
  // toggleNewDriverModal: () => void;
  getAllCustomValues: (customValues: CustomFieldByEntity[]) => void;
}

const mapDispatchToProps = (
  dispatch: Dispatch<AppActions>
): LinkDispatchProps => ({
  // toggleNewDriverModal: bindActionCreators(toggleNewDriverModal, dispatch),
  getAllCustomValues: bindActionCreators(
    getDeliveryCustomFieldValues,
    dispatch
  ),
});
// isAuthenticated is true if user_token exists in redux
const mapStateToProps = (state: AppState): LinkStateProps => ({
  token: state.auth.token as string,
  customValues: state.deliveryCustomValues as CustomFieldByEntity[],
  allCustomFields: state.customDeliveryFields as CustomFields[],
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(DeliveryDetail));
