import {
  UPDATE_DRIVER_LIST,
  UPDATE_ORDER_LIST,
  UPDATE_DRIVER_LIST_INITIAL_LOAD,
  UPDTATE_ORDER_LIST_INITIAL_LOAD,
  UPDATE_DRIVER_LIST_LABEL,
  UPDATE_ORDER_LIST_LABEL,
  UPDATE_DRIVER_FILTERS,
  UPDATE_ORDER_FILTERS,
  UPDATE_DELIVERY_RUN_LIST,
  UPDATE_DELIVERY_RUN_FILTERS,
  UPDATE_DELIVERY_RUN_LIST_INITIAL_LOAD,
  UPDATE_DELIVERY_RUN_LIST_LABEL,
  UPDATE_DELIVERY_LIST,
  UPDATE_DELIVERY_FILTERS,
  UPDATE_DELIVERY_LIST_INITIAL_LOAD,
  UPDATE_DELIVERY_LIST_LABEL,
  UPDATE_DEFAULT_DELIVERY_ID,
  UPDATE_DEFAULT_DELIVERY_RUN_ID,
  UPDATE_DEFAULT_DRIVER_ID,
  UPDATE_DEFAULT_ORDER_ID
} from "../types";

export const updateDriverList = (id: number) => ({
  type: UPDATE_DRIVER_LIST,
  id
});

export const updateDriverFilters = (filters: string[]) => ({
  type: UPDATE_DRIVER_FILTERS,
  filters
});

export const updateDriverInitialLoad = () => ({
  type: UPDATE_DRIVER_LIST_INITIAL_LOAD
});

export const updateDriverListLabel = (label: string) => ({
  type: UPDATE_DRIVER_LIST_LABEL,
  label
});

export const updateDefaultDriverID = (id: number | string) => ({
  type: UPDATE_DEFAULT_DRIVER_ID,
  id
});

export const updateOrderList = (id: number) => ({
  type: UPDATE_ORDER_LIST,
  id
});

export const updateOrderFilters = (filters: string[]) => ({
  type: UPDATE_ORDER_FILTERS,
  filters
});
export const updateOrderInitialLoad = () => ({
  type: UPDTATE_ORDER_LIST_INITIAL_LOAD
});
export const updateOrderListLabel = (label: string) => ({
  type: UPDATE_ORDER_LIST_LABEL,
  label
});

export const updateDefaultOrderID = (id: number | string) => ({
  type: UPDATE_DEFAULT_ORDER_ID,
  id
});

export const updateDeliveryRunList = (id: number) => ({
  type: UPDATE_DELIVERY_RUN_LIST,
  id
});

export const updateDeliveryRunFilters = (filters: string[]) => ({
  type: UPDATE_DELIVERY_RUN_FILTERS,
  filters
});

export const updateDeliveryRunInitialLoad = () => ({
  type: UPDATE_DELIVERY_RUN_LIST_INITIAL_LOAD
});

export const updateDefaultDeliveryRunID = (id: number | string) => ({
  type: UPDATE_DEFAULT_DELIVERY_RUN_ID,
  id
});

export const updateDeliveryRunListLabel = (label: string) => ({
  type: UPDATE_DELIVERY_RUN_LIST_LABEL,
  label
});

export const updateDeliveryList = (id: number) => ({
  type: UPDATE_DELIVERY_LIST,
  id
});

export const updateDeliveryFilters = (filters: string[]) => ({
  type: UPDATE_DELIVERY_FILTERS,
  filters
});

export const updateDeliveryInitialLoad = () => ({
  type: UPDATE_DELIVERY_LIST_INITIAL_LOAD
});

export const updateDeliveryListLabel = (label: string) => ({
  type: UPDATE_DELIVERY_LIST_LABEL,
  label
});

export const updateDefaultDeliveryID = (id: number | string) => ({
  type: UPDATE_DEFAULT_DELIVERY_ID,
  id
});
