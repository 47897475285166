import * as React from "react";
import { connect } from "react-redux";
import { Formik, FormikProps, Form } from "formik";
import * as Yup from "yup";
import { Dispatch, bindActionCreators } from "redux";
import { AppActions, Driver } from "../../types";
import { AppState } from "../../store/configureStore";
import {
  InputField,
  OnboardInputField,
  SwitchField,
  SelectField,
} from "../form";
import styled from "styled-components";
import { Button, Modal, AppText, HR, SecondaryButton } from "../UI";
import { addDriver } from "../../actions";
import { PRIMARY_COLOR } from "../../utils";
import { ColorSelectField } from "../form/ColorSelectField";
import { languages } from "../../static";
import { Input } from "semantic-ui-react";
import { api } from "../../api/api";
import { AuthError } from "../Dumb";

const schema = Yup.object().shape({
  new_password: Yup.string().required("* Password is required"),
  confirm_password: Yup.string().oneOf(
    [Yup.ref("new_password"), null],
    "Passwords must match"
  ),
});

interface MyFormikProps {
  new_password: string;
  confirm_password: string;
}

interface EditPasswordProps {
  showModal: boolean;
  toggleModal: () => void;
  token: string | null;
  user_id: number | null;
}

type Props = EditPasswordProps & LinkDispatchProps & LinkStateProps;

interface EditPasswordState {
  success: boolean;
}

class EditPassword extends React.Component<Props, EditPasswordState> {
  constructor(props: Props) {
    super(props);
    this.state = {
      success: false,
    };
  }
  render(): JSX.Element {
    return (
      <Modal
        height="fit-content"
        width="fit-content"
        showModal={this.props.showModal}
        toggleModal={this.props.toggleModal}
        label="UPDATE PASSWORD"
      >
        <Formik
          initialValues={{
            new_password: "",
            confirm_password: "",
          }}
          validationSchema={schema}
          onSubmit={async (values, { setFieldError, setStatus }) => {
            const { confirm_password } = values;
            setStatus({ error: "", loading: true });
            try {
              api.editUser(
                {
                  user_id: this.props.user_id as number,
                  password: confirm_password,
                },
                this.props.token as string
              );
              this.setState({ success: true });
              this.props.toggleModal;
            } catch (e) {
              console.log("logging error: ", e);
              setStatus({ loading: false });
            }
            console.log("no error");
            setStatus({ loading: false });
            this.props.toggleModal;
          }}
        >
          {({
            status,
            touched,
            submitForm,
            errors,
            setFieldTouched,
          }: FormikProps<MyFormikProps>) => {
            return (
              <Form>
                <Div>
                  <Header>
                    <HeaderText>UPDATE PASSWORD</HeaderText>
                  </Header>
                  <HR />
                  <Grid>
                    <InputField
                      style={{
                        gridColumn: "1 / span 1",
                        gridRow: "1 / span 1",
                      }}
                      name="new_password"
                      placeholder=""
                      title="New Password"
                      inputWidth="418px"
                      type="password"
                    />
                    <InputField
                      style={{
                        gridColumn: "1 / span 1",
                        gridRow: "2 / span 1",
                      }}
                      name="confirm_password"
                      placeholder=""
                      title="Confirm New Password"
                      inputWidth="418px"
                      type="password"
                      // disabled={true}
                    />
                    {this.state.success && (
                      <div
                        style={{
                          gridColumn: "1 / span 1",
                          gridRow: "3 / span 1",
                          alignItems: "center",
                        }}
                      >
                        <SuccessfulText>Success!</SuccessfulText>
                      </div>
                    )}
                    {/* <SuccessfulText
                      style={{
                        gridColumn: "1 / span 1",
                        gridRow: "3 / span 1"
                      }}
                    >
                      Success!
                    </SuccessfulText> */}
                    {/* <AuthError
                      style={{
                        gridColumn: "1 / span 1",
                        gridRow: "3 / span 1"
                      }}
                      status={"Successfull"}
                    /> */}
                  </Grid>
                  <Footer>
                    <CancelButton onClick={this.props.toggleModal}>
                      Cancel
                    </CancelButton>
                    <Button
                      width="130px"
                      type="submit"
                      style={{ justifySelf: "flex-end" }}
                      onClick={submitForm}
                      disabled={
                        !(
                          Object.keys(touched).length &&
                          !Object.keys(errors).length
                        )
                      }
                    >
                      Save
                    </Button>
                  </Footer>
                </Div>
              </Form>
            );
          }}
        </Formik>
      </Modal>
    );
  }
}

const Grid = styled.div`
  display: grid;
  grid-template-columns: min-content min-content auto;
  grid-template-rows: 80px 80px 50px;
  column-gap: 24px;
  padding: 25px 40px 0 40px;
`;

const Div = styled.div`
  display: flex;
  flex-direction: column;
  border-top: solid 1px ${PRIMARY_COLOR};
  border-radius: 2px;
  overflow-x: none;
  width: 500px;
`;

const HeaderText = styled(AppText)`
  font-size: 18px;
  font-weight: 600;
  letter-spacing: 0.3px;
  color: #60999e;
`;

const Header = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height: 57px;
  padding: 0 40px 0 40px;
`;

const Footer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  height: 57px;
  padding: 0 40px 30px 40px;
`;

const CancelText = styled(AppText)`
  font-size: 13px;
  font-weight: 500;
  color: #3b3a3a;
  margin-right: 53.5px;
`;

const CancelButton = styled(Button)`
  height: 40px;
  width: 110px;
  border-radius: 4px;
  border: solid 1px rgba(71, 70, 71, 0.26);
  background-color: #fff;
  color: #000;
  margin-right: 21px;
`;

const StyledAuthError = styled(AuthError)`
  grid-column: 1 / span 1;
  grid-row: 3 / span 1;
`;

const SuccessfulText = styled(AppText)`
  color: #00827f;
  font-size: 15px;
`;

const StyledInputField = styled((props) => <InputField {...props} />)``;

interface LinkStateProps {
  token: string | null;
  user_id: number | null;
  submitStatus: string;
  error: string;
}

interface LinkDispatchProps {
  addDriver: (driver: Driver) => void;
}

const mapStateToProps = (state: AppState): LinkStateProps => ({
  token: state.auth.token,
  user_id: state.user.id,
  submitStatus: state.apiStatus.status,
  error: state.apiStatus.error,
});

const mapDispatchToProps = (
  dispatch: Dispatch<AppActions>
): LinkDispatchProps => ({
  addDriver: bindActionCreators(addDriver, dispatch),
  //TODO CHANGE DRIVER MODAL FUNCTION
});

export default connect(mapStateToProps, mapDispatchToProps)(EditPassword);
