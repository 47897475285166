import * as React from "react";
import { connect } from "react-redux";
import { Formik, FormikProps, Form } from "formik";
import * as Yup from "yup";
import { Dispatch, bindActionCreators } from "redux";
import { AppActions, Suggestion } from "../../types";
import { AppState } from "../../store/configureStore";
import {
  InputField,
  OnboardInputField,
  SwitchField,
  SelectField,
  AddressField,
} from "../form";
import styled from "styled-components";
import { Button, Modal, AppText, HR, SecondaryButton } from "../UI";
import { toggleNewOrderModal } from "../../actions";
import { PRIMARY_COLOR } from "../../utils";
import { ColorSelectField } from "../form/ColorSelectField";
import { api } from "../../api/api";
import { Order } from "../../api/responseTypes";
import { phones } from "../../static/phones";
import { states } from "../../static/states";
import { TextAreaField } from "../form/TextAreaField";
import UploadCSV from "./uploadCSV";

const newOrderSchema = Yup.object().shape({
  first_name: Yup.string()
    .required("* First name is required")
    .max(100, "* Too long!"),
  last_name: Yup.string()
    .required("* Last name is required")
    .max(100, "* Too long!"),
  email: Yup.string().email("* Invalid email").required("* Email is required"),
  phone: Yup.string().required("* Phone number is required"),
  phone_type: Yup.string().required("* Please select a phone type"),
  num_boxes: Yup.number()
    .typeError("* Must be a number")
    .required("* # of Boxes is required"),
  amount_to_collect: Yup.number()
    .typeError("* Must be a number")
    .required("* Amount is required"),
  address_1: Yup.string()
    .required("* Address is required")
    .max(100, "* Too long!"),
  city: Yup.string().required("* City is required"),
  state: Yup.string().required("* State is required"),
  zip: Yup.string().required("* Zip code required"),
  description: Yup.string().required("* Item description is required"),
  notes: Yup.string().required("* Notes Required"),
});

interface NewOrderProps {
  showModal: boolean;
  toggleModal: () => void;
  onComplete: (order: Order) => void;
}

interface NewOrderState {
  showUploadCSV: boolean;
  autoSuggest: Suggestion[];
}

interface MyFormikProps {
  email: string;
  first_name: string;
  last_name: string;
  phone: string;
  phone_type: string;
  num_boxes: string;
  amount_to_collect: string;
  address_1: string;
  unit: string;
  city: string;
  state: string;
  zip: string;
  description: string;
  notes: string;
}

type Props = NewOrderProps & LinkDispatchProps & LinkStateProps;

class NewOrder extends React.Component<Props, NewOrderState> {
  constructor(props: Props) {
    super(props);
    this.state = {
      showUploadCSV: false,
      autoSuggest: [],
    };
  }

  showCSVModal = () => {
    this.setState((prev) => ({
      showUploadCSV: !prev.showUploadCSV,
    }));
  };

  handleAutoSuggest = (suggestion: Suggestion[]) => {
    this.setState({
      autoSuggest: suggestion,
    });
  };

  autoFillsuggestion = ({
    address,
    city,
    state,
    zipcode,
  }: {
    address: string;
    city: string;
    state: string;
    zipcode: string;
  }) => {
    console.log("addressssss", address);
  };

  render(): JSX.Element {
    return (
      <Modal
        height="fit-content"
        width="fit-content"
        showModal={this.props.showModal}
        toggleModal={this.props.toggleModal}
        label="Add Order"
      >
        <Formik
          initialValues={{
            email: "",
            first_name: "",
            last_name: "",
            phone: "",
            phone_type: "",
            num_boxes: "",
            amount_to_collect: "",
            address_1: "",
            unit: "",
            city: "",
            state: "",
            zip: "",
            description: "",
            notes: "",
          }}
          validationSchema={newOrderSchema}
          onSubmit={async (values, { setFieldError, setStatus }) => {
            setStatus({ error: "", loading: true });
            const newOrder = await api.createOrder(
              {
                ...values,
                store_id: this.props.store_id as number,
                //HARDCODE customer id - 0 will create new customer
                id: 0,
              },
              this.props.token as string
            );
            setStatus({ loading: false });
            !!this.props.onComplete && this.props.onComplete(newOrder as Order);
            this.props.toggleModal();
          }}
        >
          {({
            status,
            setStatus,
            submitForm,
            touched,
            errors,
          }: FormikProps<MyFormikProps>) => {
            return (
              <Div>
                <UploadCSV
                  showModal={this.state.showUploadCSV}
                  toggleModal={this.showCSVModal}
                />
                <Header style={{ gridColumn: "1 / span 3" }}>
                  <HeaderText>CREATE NEW ORDER</HeaderText>
                  <SecondaryButton width="111px" onClick={this.showCSVModal}>
                    Mass Import
                  </SecondaryButton>
                </Header>
                <HR />
                <Grid>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-evenly",
                      margin: "5px",
                    }}
                  >
                    <StyledInput
                      style={{
                        gridColumn: "1 / span 1",
                        gridRow: "1 / span 1",
                        margin: "14px",
                      }}
                      name="first_name"
                      placeholder=""
                      title="Customer First Name"
                      inputWidth="250px"
                    />
                    <StyledInput
                      style={{
                        gridColumn: "2 / span 1",
                        gridRow: "1 / span 1",
                        margin: "14px",
                      }}
                      name="last_name"
                      placeholder=""
                      title="Customer Last Name"
                      inputWidth="250px"
                    />
                    <StyledInput
                      style={{
                        gridColumn: "3 / span 1",
                        gridRow: "1 / span 1",
                        margin: "14px",
                      }}
                      name="email"
                      placeholder=""
                      title="Customer Email"
                      inputWidth="250px"
                    />
                  </div>
                  {}
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "flex-start",
                      gridRow: "2 / span 1",
                    }}
                  >
                    <InputField
                      style={{
                        // gridColumn: "1/ span 1",
                        gridRow: "2 / span 1",
                        margin: "14px",
                      }}
                      name="phone"
                      placeholder=""
                      title="Customer Phone"
                      inputWidth="180px"
                    />
                    <SelectField
                      name="phone_type"
                      placeholder=""
                      title="Phone Types"
                      options={phones}
                      style={{
                        // gridColumn: "2 / span 1",
                        gridRow: "2 / span 1",
                        margin: "14px",
                      }}
                      inputWidth={130}
                    />
                    <InputField
                      style={{
                        // gridColumn: "3 / span 1",
                        gridRow: "2 / span 1",
                        margin: "14px",
                      }}
                      name="num_boxes"
                      placeholder=""
                      title="# of Boxes"
                      inputWidth="80px"
                    />
                    <InputField
                      style={{
                        // gridColumn: "4 / span 1",
                        gridRow: "2 / span 1",
                        margin: "14px",
                      }}
                      name="amount_to_collect"
                      placeholder=""
                      title="Amount to Collect"
                      inputWidth="160px"
                    />
                  </div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "flex-start",
                      gridRow: "3 / span 1",
                    }}
                  >
                    <AddressField
                      style={{
                        gridColumn: "1 / span 1",
                        gridRow: "3 / span 1",
                        margin: "14px",
                      }}
                      name="address_1"
                      placeholder=""
                      title="Street Address"
                      inputWidth="250px"
                      latitude={this.props.store_latitude}
                      longitude={this.props.store_longitude}
                      handleAutoSuggest={this.handleAutoSuggest}
                      autoFillsuggestion={this.autoFillsuggestion}
                      suggestions={
                        Array.isArray(this.state.autoSuggest)
                          ? this.state.autoSuggest
                          : []
                      }
                    />
                    <InputField
                      style={{
                        gridColumn: "2 / span 1",
                        gridRow: "3 / span 1",
                        margin: "14px",
                      }}
                      name="unit"
                      placeholder=""
                      title="Unit/Apt/Suite"
                      inputWidth="95px"
                    />
                    <InputField
                      style={{
                        // gridColumn: "3 / span 1",
                        gridRow: "3 / span 1",
                        margin: "14px",
                      }}
                      name="city"
                      placeholder=""
                      title="City"
                      inputWidth="200px"
                    />
                    <SelectField
                      name="state"
                      placeholder=""
                      title="State"
                      options={states}
                      style={{
                        // gridColumn: "2 / span 1",
                        gridRow: "3 / span 1",
                        margin: "14px",
                      }}
                      inputWidth={100}
                    />
                    <InputField
                      style={{
                        // gridColumn: "3 / span 1",
                        gridRow: "3 / span 1",
                        margin: "14px",
                      }}
                      name="zip"
                      placeholder=""
                      title="Zip Code"
                      inputWidth="100px"
                    />
                  </div>

                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "flex-start",
                      gridRow: "4 / span 1",
                    }}
                  >
                    <TextAreaField
                      name="description"
                      placeholder=""
                      title="Item Description"
                      textWidth="280px"
                      textHeight="75px"
                      style={{
                        gridColumn: "1 / span 1",
                        gridRow: "4 / span 1",
                        margin: "14px",
                      }}
                    />
                    <TextAreaField
                      name="notes"
                      placeholder=""
                      title="Notes"
                      textWidth="280px"
                      textHeight="75px"
                      style={{
                        gridColumn: "1 / span 1",
                        gridRow: "4 / span 1",
                        margin: "14px",
                      }}
                    />
                  </div>
                </Grid>
                <HR />
                <Footer>
                  <CancelText
                    clickable
                    onClick={this.props.toggleNewOrderModal}
                  >
                    Cancel
                  </CancelText>
                  <Button
                    width="130px"
                    type="submit"
                    style={{ justifySelf: "flex-end" }}
                    onClick={submitForm}
                    // disabled={
                    //   !(
                    //     Object.keys(touched).length &&
                    //     !Object.keys(errors).length
                    //   )
                    // }
                  >
                    Create Order
                  </Button>
                </Footer>
              </Div>
            );
          }}
        </Formik>
      </Modal>
    );
  }
}

const Grid = styled.div`
  display: grid;
  /* grid-template-columns: min-content min-content auto; */
  grid-template-rows: 100px 100px 100px 100px 200px;
  column-gap: 24px;
  padding: 32px 40px 0 40px;
`;

const Div = styled.div`
  display: flex;
  flex-direction: column;
  border-top: solid 1px ${PRIMARY_COLOR};
  border-radius: 2px;
  overflow-x: none;
`;

const HeaderText = styled(AppText)`
  font-size: 18px;
  font-weight: 600;
  letter-spacing: 0.3px;
  color: #60999e;
`;

const Header = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height: 57px;
  padding: 0 40px 0 40px;
`;

const Footer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  height: 57px;
  padding: 0 40px 0 40px;
`;

const StyledInput = styled(InputField)`
  margin: 10px;
  :focus {
    outline: #7f7f7f;
    border: 1px solid var(--dull-teal);
    border-color: #7f7f7f;
    /* box-shadow: 0px 0px 10px #7f7f7f; */
    background-color: rgba(96, 153, 158, 0.12);
  }
`;

const ThisButton = styled(Button)`
  height: 40px;
  background-color: #fff;
  width: auto;
  border-radius: 4px;
  border: solid 1px rgba(71, 70, 71, 0.26);
`;

const CancelText = styled(AppText)`
  font-size: 13px;
  font-weight: 500;
  color: #3b3a3a;
  margin-right: 53.5px;
`;

interface LinkStateProps {
  token: string | null;
  store_id: number | null;
  customer_id: number | null;
  store_latitude: number;
  store_longitude: number;
}

interface LinkDispatchProps {
  toggleNewOrderModal: () => void;
}

const mapStateToProps = (state: AppState): LinkStateProps => ({
  token: state.auth.token,
  store_id: state.user.store_id,
  customer_id: state.user.store_id,
  store_latitude: state.store.address.latitude,
  store_longitude: state.store.address.longitude,
});

const mapDispatchToProps = (
  dispatch: Dispatch<AppActions>
): LinkDispatchProps => ({
  toggleNewOrderModal: bindActionCreators(toggleNewOrderModal, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(NewOrder);
