import moment from "moment";
import { string } from "prop-types";

export const DATE_FORMAT = "MM/DD/YYYY";
const DATE_FORMAT_CHAT = "DD MM, h:mm a";
const PAYLOAD_DATE = "YYYY-MM-DD";
const SHORT_DATE = "MM/DD";
const PAYLOAD_TIME = "HH:mm:ss";
const RENDER_TIME_FORMAT = "h:mm A";
const DATE_FIELD_FORMAT = "MM-DD-YYYY";
const BASIC_TIME_FORMAT = "h:mm";
const FULL_DATE_FORMAT = "MM/DD/YYYY h:mm A";

export const formatPrice = (price: number): string =>
  `\$${price.toLocaleString()}`;

export const formatMileage = (mileage: number): string =>
  `${mileage.toLocaleString()} miles`;

export const formatDate = (date: number) =>
  moment.unix(date).format(DATE_FORMAT);

export const formatChatDate = (date: number) =>
  moment.unix(date).format(DATE_FORMAT_CHAT);

export const formatFeatureList = (features: string[]) =>
  features && features.join(", ");

export const formatPhoneNumber = (p: string) => {
  const size = p.length;
  switch (true) {
    case size === 0:
      return p;
    case size > 0 && size <= 3:
      return `(${p})`;
    case size > 3 && size <= 6:
      return `(${p.substr(0, 3)}) ${p.substr(4)}`;
    case size > 6:
      return `(${p.substr(0, 3)}) ${p.substr(3, 3)}-${p.substr(6)}`;
    default:
      return p;
  }
};

export const formatSecondsToTime = (seconds: string) => {
  return moment.utc((seconds as any) * 1000).format("HH:mm");
};
export const formatCurrentDate = () => {
  return `${moment().format(PAYLOAD_DATE)}`;
};

export const formatCurrentTime = () => {
  return `${moment().format(PAYLOAD_TIME)}`;
};

export const formatCurrentTimeUSDate = () => {
  return `${moment().format(DATE_FIELD_FORMAT)}`;
};

export const formatGivenDate = (time) => {
  return `${moment(time, [PAYLOAD_DATE]).format(PAYLOAD_DATE)}`;
};

export const formatGivenTime = (time) => {
  return `${moment(time, [RENDER_TIME_FORMAT]).format(PAYLOAD_TIME)}`;
};

export const formatFieldToPayloadDate = (time) => {
  return `${moment(time, [DATE_FIELD_FORMAT]).format(PAYLOAD_DATE)}`;
};

export const formatDefaultTimeToTime = (time) => {
  return moment(time).format(RENDER_TIME_FORMAT);
};

export const shortenDateOrShowToday = (time) => {
  let today = moment(new Date()).format(SHORT_DATE);

  return moment(time).format(SHORT_DATE) === today
    ? "Today"
    : moment(time).format(SHORT_DATE);
};

export const renderBasicTimeFormat = (time) => {
  return moment(time, [PAYLOAD_TIME]).format(BASIC_TIME_FORMAT);
};

export const formatFieldPayloadDateRange = (
  time: string,
  daysToAdd: number
) => {
  return new Date(
    moment(time, [PAYLOAD_DATE]).add(daysToAdd, "days").format(PAYLOAD_DATE)
  );
};

export const formatFullDateToTime = (time: string) => {
  return moment(time).format(RENDER_TIME_FORMAT);
};

export const formatFullDateToUSTime = (time: string) => {
  return moment(time).format(FULL_DATE_FORMAT);
};
