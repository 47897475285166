import * as React from "react";
import styled from "styled-components";
import { AppText } from "../UI";

interface EmptyCardProps {
  primaryText?: string;
  secondaryText?: string;
}

const EmptyCard: React.FunctionComponent<EmptyCardProps> = ({
  primaryText,
  secondaryText,
}) => {
  return (
    <EmptyCardDiv>
      {primaryText && (
        <EmptyCardText>
          All Orders Have Been Assigned To a Delivery Run
        </EmptyCardText>
      )}
      {secondaryText && (
        <EmptyCardText>Click Save Delivery Runs to Proceed</EmptyCardText>
      )}
    </EmptyCardDiv>
  );
};

const EmptyCardDiv = styled.div`
  display: flex;
  flex-direction: column;
  width: 154px;
  background: transparent;
`;

const EmptyCardText = styled(AppText)`
  font-size: 12px;
  font-weight: 500;
  margin-bottom: 20px;
`;

export { EmptyCard };
