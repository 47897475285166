import * as React from "react";
import { connect } from "react-redux";
import { Formik, FormikProps, Form } from "formik";
import { Link } from "react-router-dom";
import * as Yup from "yup";
import styled from "styled-components";
import { Dispatch, bindActionCreators } from "redux";
import { InputField } from "../form";
import {
  AuthContainer,
  Button,
  Spinner,
  Card,
  AppText,
  Icon,
  AppTextRoman,
} from "../UI";
import { AppActions } from "../../types";
import { logo } from "../../img";
import { AuthError } from "../Dumb";
import { PRIMARY_COLOR } from "../../utils";

const signupSchema = Yup.object().shape({
  currentPassword: Yup.string().required("* Current Password is required"),
  newPassword: Yup.string().required("* New Password is required"),
  newPasswordConfirm: Yup.string()
    .oneOf([Yup.ref("newPassword"), null], "* New passwords do not match")
    .required("* You must confirm your new password"),
});

interface ForgotPasswordProps {}

interface ForgotPasswordState {}

type Props = ForgotPasswordProps & LinkDispatchProps;

interface MyFormikProps {
  currentPassword: string;
  newPassword: string;
  newPasswordConfirm: string;
}

class ForgotPassword extends React.Component<Props, ForgotPasswordState> {
  render(): JSX.Element {
    return (
      <Formik
        initialValues={{
          currentPassword: "",
          newPassword: "",
          newPasswordConfirm: "",
        }}
        validationSchema={signupSchema}
        onSubmit={async (values, { setFieldError, setStatus }) => {
          const { currentPassword, newPassword, newPasswordConfirm } = values;
          setStatus({ error: "", loading: true });
          setStatus({ loading: false });
        }}
      >
        {({ status, setStatus }: FormikProps<MyFormikProps>) => {
          console.log("status: ", status);
          return (
            <Div>
              <Form>
                <LoginTitleText>CHANGE PASSWORD</LoginTitleText>
                <ThisCard>
                  <InputField
                    name="currentPassword"
                    placeholder="Current Password"
                    type="password"
                  />
                  <InputField
                    name="newPassword"
                    placeholder="Enter New Password"
                    type="password"
                  />
                  <InputField
                    name="newPasswordConfirm"
                    placeholder="Confirm New Password"
                    type="password"
                  />
                  <div>
                    {status && status.loading ? (
                      <Spinner />
                    ) : (
                      <Button type="submit">Submit</Button>
                    )}
                  </div>
                  <AuthError status={status} />
                  <ForgotPasswordText>Cancel</ForgotPasswordText>
                </ThisCard>
              </Form>
            </Div>
          );
        }}
      </Formik>
    );
  }
}

const Div = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: inherit;
  height: 100%;
  width: 100%;
`;

const ForgotPasswordText = styled(AppText)`
  font-size: 12.6px;
  font-weight: 500;
  color: #989898;
  text-align: center;
`;

const LoginTitleText = styled(AppText)`
  font-size: 26px;
  font-weight: 500;
  text-align: center;
  color: ${PRIMARY_COLOR};
  margin-bottom: 32px;
`;

const ThisCard = styled(Card)`
  height: 294.6px;
  width: 300px;
  display: grid;
  grid-template-rows: 54px 54px 66px 58px auto;
  justify-items: center;
  border: solid 0.9px #d8d8d8;
`;

const ThisAuthContainer = styled(AuthContainer)`
  background: "#fff";
`;

interface LinkDispatchProps {}

const mapDispatchToProps = (
  dispatch: Dispatch<AppActions>
): LinkDispatchProps => ({});

export default connect(null, mapDispatchToProps)(ForgotPassword);
