import * as React from "react";
import styled from "styled-components";
import { AppText } from "../../UI";
import InplaceDropdownMenu from "./InplaceDropdownMenu";
import { AppActions, LoggedUser, ItemList } from "../../../types";
import { AppState } from "../../../store/configureStore";
import { Dispatch, bindActionCreators } from "redux";
import {
  toggleNewDriverModal,
  updateDriverInitialLoad,
  updateDeliveryRunInitialLoad,
  updateDeliveryRunListLabel,
} from "../../../actions";
import { connect } from "react-redux";

interface MenuItem {
  label: string;
  title: boolean;
}

interface InplaceDropdownProps {
  list: MenuItem[];
  onChangeSelected: (label: string) => void;
  dropDownType: string | undefined;
  changeListRenderState: () => void;
}

interface InplaceDropdownState {
  open: boolean;
  selected: number;
}

type Props = InplaceDropdownProps & LinkStateProps & LinkDispatchProps;

class InplaceDropdown extends React.Component<Props, InplaceDropdownState> {
  constructor(props: Props) {
    super(props);

    this.state = {
      open: false,
      selected: 1,
    };
  }

  componentDidMount = () => {
    const {
      driverListLoaded,
      orderListLoaded,
      deliveryRunListLoaded,
      deliveryListLoaded,
      dropDownType,
      changeListRenderState,
      updateDeliveryRunInitialLoad,
      updateDeliveryRunListLabel,
    } = this.props;
    if (dropDownType === "Delivery Runs" && deliveryRunListLoaded) {
      changeListRenderState();
      updateDeliveryRunInitialLoad();
      updateDeliveryRunListLabel("All DeliveryRuns");
      this.onSelect(1);
    }
  };
  openMenu = () => this.setState((prev) => ({ open: !prev.open }));
  onSelect = (index: number) => {
    const { onChangeSelected, changeListRenderState } = this.props;
    this.setState({ selected: index, open: false }, () => {
      onChangeSelected(this.props.list[this.state.selected].label);
    });
  };
  render() {
    const {
      list,
      dropDownType,
      driverListLabel,
      driverListLoaded,
      orderListLabel,
      orderListLoaded,
      deliveryRunListLabel,
      deliveryRunListLoaded,
      deliveryListLabel,
      deliveryListLoaded,
    } = this.props;
    const { open, selected } = this.state;

    return (
      <Div>
        <CurrentListContainer onClick={this.openMenu}>
          {dropDownType === "Drivers" && (
            <CurrentSelection>
              {driverListLoaded && list[selected]
                ? list[selected].label
                : driverListLabel}
            </CurrentSelection>
          )}
          {dropDownType === "Orders" && (
            <CurrentSelection>
              {orderListLoaded && list[selected]
                ? list[selected].label
                : orderListLabel}
            </CurrentSelection>
          )}
          {dropDownType === "Delivery Runs" && (
            <CurrentSelection>
              {deliveryRunListLoaded && list[selected]
                ? list[selected].label
                : deliveryRunListLabel}
            </CurrentSelection>
          )}
          {dropDownType === "Deliveries" && (
            <CurrentSelection>
              {deliveryListLoaded && list[selected]
                ? list[selected].label
                : deliveryListLabel}
            </CurrentSelection>
          )}
          <DownArrow />
        </CurrentListContainer>
        {open && (
          <InplaceDropdownMenu
            handleClickOutside={this.openMenu}
            onSelect={this.onSelect}
            list={list}
            dropDownType={this.props.dropDownType}
            changeListRenderState={this.props.changeListRenderState}
          />
        )}
      </Div>
    );
  }
}

const CurrentSelection = styled(AppText)`
  opacity: 0.9;
  font-size: 18px;
  font-weight: 500;
  letter-spacing: 0.2px;
  color: #3b3a3a;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`;

const CurrentListContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 180px;
  height: 100%;
  :hover {
    cursor: pointer;
  }
`;

const Div = styled.div`
  position: relative;
`;

const DownArrow = styled.div`
  width: 0;
  height: 0;
  border-left: 7px solid transparent;
  border-right: 7px solid transparent;
  border-top: 7px solid rgba(59, 58, 58, 0.9);
  border-radius: 4px;
`;

interface LinkStateProps {
  showNewDriverModal: boolean;
  token: string;
  store_id: number;
  driverListID: number;
  driverListLoaded: boolean;
  driverListLabel: string;
  orderListID: number;
  orderListLoaded: boolean;
  orderListLabel: string;
  deliveryRunListID: number;
  deliveryRunListLoaded: boolean;
  deliveryRunListLabel: string;
  deliveryListID: number;
  deliveryListLoaded: boolean;
  deliveryListLabel: string;
}

interface LinkDispatchProps {
  updateDeliveryRunInitialLoad: () => void;
  updateDeliveryRunListLabel: (label: string) => void;
}

const mapDispatchToProps = (
  dispatch: Dispatch<AppActions>
): LinkDispatchProps => ({
  updateDeliveryRunInitialLoad: bindActionCreators(
    updateDeliveryRunInitialLoad,
    dispatch
  ),
  updateDeliveryRunListLabel: bindActionCreators(
    updateDeliveryRunListLabel,
    dispatch
  ),
});

// isAuthenticated is true if user_token exists in redux
const mapStateToProps = (state: AppState): LinkStateProps => ({
  showNewDriverModal: state.modal.showNewDriverModal,
  //temp
  store_id: state.user.store_id as number,
  token: state.auth.token as string,
  driverListID: state.listViewIDs.driverListID as number,
  driverListLoaded: state.listViewIDs.driverListInitialLoad as boolean,
  driverListLabel: state.listViewIDs.driverLabel as string,
  orderListID: state.listViewIDs.orderListID as number,
  orderListLoaded: state.listViewIDs.orderListInitialLoad as boolean,
  orderListLabel: state.listViewIDs.orderLabel as string,
  deliveryRunListID: state.listViewIDs.deliveryRunListID as number,
  deliveryRunListLoaded: state.listViewIDs
    .deliveryRunListInitialLoad as boolean,
  deliveryRunListLabel: state.listViewIDs.deliveryRunLabel as string,
  deliveryListID: state.listViewIDs.deliveryRunListID as number,
  deliveryListLoaded: state.listViewIDs.deliveryListInitialLoad as boolean,
  deliveryListLabel: state.listViewIDs.deliveryLabel as string,
});
export default connect(mapStateToProps, mapDispatchToProps)(InplaceDropdown);
