import * as React from "react";
import { connect } from "react-redux";
import { AppState } from "../../../../store/configureStore";
import { AppActions, CustomFields } from "../../../../types";
import { Dispatch, bindActionCreators } from "redux";
import styled from "styled-components";
import { Container, AppText, HR, Button, Collapsible } from "../../../UI";
import { AuthButton } from "../../../Pages/NewClientSetup";
import { NavLink } from "react-router-dom";
import { api } from "../../../../api/api";
import CreateCustomProperty from "../../../modal/CustomPrefModals/CreateCustomProperty";
import {
  fetchProperties,
  reloadOrderCustomListLoad,
  reloadDeliveryCustomListLoad,
  reloadDeliveryRunCustomListLoad,
  reloadDriverCustomListLoad,
  fetchOrderProperties,
} from "../../../../actions";
import DynamicCustomFieldTable from "../../../Dumb/DynamicCustomFieldTable";

interface OrderCustomPropertiesProps {
  token?: string;
  store_id?: number;
}
interface OrderCustomPropertiesState {
  createProperty: boolean;
  customFields: CustomFields[];
}

type Props = OrderCustomPropertiesProps & LinkStateProps & LinkDispatchProps;

class OrderCustomProperties extends React.Component<
  Props,
  OrderCustomPropertiesState
> {
  constructor(props: Props) {
    super(props);

    this.state = {
      createProperty: false,
      customFields: [],
    };
  }
  componentDidMount = async () => {
    const { token, store_id, fetchProperties } = this.props;
    const custom_fields = (await api.getCustomField(
      { store_id: store_id as number, entity_name: "Order" },
      token
    )) as any;
    this.setState({
      customFields: custom_fields.data as CustomFields[],
    });
    fetchProperties(custom_fields.data);
  };

  toggleNewCustomProperty = () => {
    this.setState((prev) => ({ createProperty: !prev.createProperty }));
  };

  reloadProperties = () => {
    const {
      reloadDeliveryCustomListLoad,
      reloadDeliveryRunCustomListLoad,
      reloadDriverCustomListLoad,
      reloadOrderCustomListLoad,
    } = this.props;
    reloadDeliveryCustomListLoad();
    reloadDeliveryRunCustomListLoad();
    reloadDriverCustomListLoad();
    reloadOrderCustomListLoad();
  };

  render() {
    const { custom_properties } = this.props;
    return (
      <>
        <CreateCustomProperty
          showModal={this.state.createProperty}
          toggleModal={this.toggleNewCustomProperty}
          type={"Order"}
        />
        {custom_properties.length === 0 && (
          <CustomPrefDiv>
            <NoCustomFiledDiv>
              <DescriptionText>
                YOU DON'T HAVE ANY CUSTOM PROPERTIES ORDERS. WHY DON'T YOU
                CREATE SOME NOW?
              </DescriptionText>
              <div style={{ height: "60px" }} />
              <CreateCustomField onClick={this.toggleNewCustomProperty}>
                CREATE CUSTOM PROPERTY
              </CreateCustomField>
            </NoCustomFiledDiv>
          </CustomPrefDiv>
        )}
        {custom_properties.length > 0 && (
          <CustomDiv>
            <div style={{ height: "60px" }} />
            <DescriptionText>CUSTOM PROPERTIES</DescriptionText>
            <DynamicCustomFieldTable type={"Order"} list={custom_properties} />
          </CustomDiv>
        )}
        <Spread>
          {custom_properties.length > 0 && (
            <AuthButton onClick={this.toggleNewCustomProperty}>
              ADD NEW CUSTOM PROPERTY
            </AuthButton>
          )}
          <NavLink style={{ justifySelf: "right" }} to="/settings">
            <AuthButton onClick={this.reloadProperties}>
              RETURN TO SETTINGS
            </AuthButton>
          </NavLink>
        </Spread>
      </>
    );
  }
}

const CustomPrefDiv = styled.div`
  display: flex;
  align-items: center;
  height: 900px;
`;

const CustomDiv = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  height: 900px;
  width: 70vw;
`;
const DescriptionText = styled(AppText)`
  font-size: 20px;
  line-height: 1.67;
  color: #ffffff;
  font-weight: 500;
  text-align: center;
`;

const NoCustomFiledDiv = styled.div`
  display: flex;
  width: 70vw;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;
const CreateCustomField = styled.button`
  background-color: transparent;
  border: solid 1px white;
  height: 40px;
  width: 200px;
  border-radius: 2px;
  font-size: 13px;
  font-weight: 500;
  font-family: Avenir, Lato, sans-serif;
  color: white;
  :focus {
    outline: none;
  }
  :active {
    box-shadow: 0 3px 0 #dedfe2;
    top: 3px;
    transform: translateY(2px);
    background-color: #ffff;
    border: solid 1px white;
    font-size: 13px;
    font-weight: 500;
    font-family: Avenir, Lato, sans-serif;
    color: gray;
    /* opacity: 0.1; */
  }
`;
const Spread = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  width: 70vw;
`;

const RowTitle = styled.div`
  padding: 14px 12px;
  /* max-width: 300px; */
  grid-column: 1 / span 1;
  border-right: solid 0.5px #d8d8d8;
  background-color: ${(props) => props.color};
`;
interface LinkStateProps {
  custom_properties: CustomFields[];
  token: string;
  user_id: number;
  store_id: number;
}

interface LinkDispatchProps {
  fetchProperties: (property: CustomFields[]) => void;
  reloadOrderCustomListLoad: () => void;
  reloadDeliveryCustomListLoad: () => void;
  reloadDeliveryRunCustomListLoad: () => void;
  reloadDriverCustomListLoad: () => void;
}

const mapDispatchToProps = (
  dispatch: Dispatch<AppActions>
): LinkDispatchProps => ({
  fetchProperties: bindActionCreators(fetchOrderProperties, dispatch),
  reloadOrderCustomListLoad: bindActionCreators(
    reloadOrderCustomListLoad,
    dispatch
  ),
  reloadDeliveryCustomListLoad: bindActionCreators(
    reloadDeliveryCustomListLoad,
    dispatch
  ),
  reloadDeliveryRunCustomListLoad: bindActionCreators(
    reloadDeliveryRunCustomListLoad,
    dispatch
  ),
  reloadDriverCustomListLoad: bindActionCreators(
    reloadDriverCustomListLoad,
    dispatch
  ),
});

const mapStateToProps = (state: AppState): LinkStateProps => ({
  token: state.auth.token as string,
  user_id: state.user.id as number,
  store_id: state.user.store_id as number,
  custom_properties: state.customOrderFields as CustomFields[],
});
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(OrderCustomProperties);
