import {
  createStore,
  combineReducers,
  applyMiddleware,
  compose,
  Store,
  AnyAction
} from "redux";
import thunk, { ThunkMiddleware, ThunkDispatch } from "redux-thunk";
import {
  persistStore,
  persistReducer,
  persistCombineReducers,
  createTransform,
  PersistedState,
  Persistor
} from "redux-persist";
import storage from "redux-persist/lib/storage";
import { AppActions } from "../types";
import {
  consumerReducer,
  authReducer,
  filterReducer,
  userReducer,
  modalReducer,
  driverReducer,
  storeReducer,
  listViewReducer,
  customDriverFieldsReducer,
  customDriverFieldFilterReducer,
  customDriverFilterStateReducer,
  customDriverFieldFilterMenuReducer,
  customDeliveryFieldsReducer,
  customDeliveryFilterStateReducer,
  customDeliveryFieldFilterReducer,
  customDeliveryFieldFilterMenuReducer,
  customDeliveryRunFieldsReducer,
  customDeliveryRunFieldFilterReducer,
  customDeliveryRunFilterStateReducer,
  customDeliveryRunFieldFilterMenuReducer,
  customOrderFieldsReducer,
  customOrderFieldFilterReducer,
  customOrderFilterStateReducer,
  customOrderFieldFilterMenuReducer,
  driverCustomValuesReducer,
  orderCustomValuesReducer,
  deliveryCustomValuesReducer,
  deliveryRunCustomValuesReducer,
  dashboardReducer,
  ApiReducer,
  createDeliveryRunReducer
} from "../reducers";
import { Modal, Auth, Consumer, Driver, Offer, User } from "../types";
import onboardingReducer from "../reducers/onboardingReducer";

// Redux Store
// -----------
// This file is dense main because of the use of Redux-persist
// Redux-persist saves the redux-store to disk, which allows
// the data to persist between browser reloads.
// Firebase is used to persist data between login/logout sessions.

const persistConfig = {
  key: "scanovator",
  storage,
  // Redux-persist converts Moment objects to strings when saving to disk
  // to convert the items back to Moment items upon launch, we use a transform
  // to convert them back to Moment objects.
  transforms: [
    createTransform(
      // The first parameter converts objects when writing to disk
      state => state,
      // The second parameter converts objects when loading into memory
      state => state
      // (outboundState: any) => {
      //   // convert startDate and endDate back into Moment objects
      //   return {
      //     ...outboundState
      //     // startDate: moment(outboundState.startdate),
      //     // endDate: moment(outboundState.endDate)
      //   };
      // }
      // The third parameter denotes which reducers to apply the change to
      // { whitelist: ["filters"] }
    ),
    // Clear error state when persisting to disk
    createTransform(state => ({ ...state, error: "" }), state => state, {
      whitelist: ["auth"]
    })
  ]
};

// Used for Typescript
// -------------------
export const rootReducer = combineReducers({
  auth: authReducer,
  filters: filterReducer,
  user: userReducer,
  modal: modalReducer,
  consumer: consumerReducer,
  driver: driverReducer,
  onboarding: onboardingReducer,
  store: storeReducer,
  listViewIDs: listViewReducer,
  apiStatus: ApiReducer,
  customDriverFields: customDriverFieldsReducer,
  customDriverFieldFilter: customDriverFieldFilterReducer,
  customDriverFilterState: customDriverFilterStateReducer,
  customDriverFieldFilterMenu: customDriverFieldFilterMenuReducer,
  customDeliveryFields: customDeliveryFieldsReducer,
  customDeliveryFilterState: customDeliveryFilterStateReducer,
  customDeliveryFieldFilter: customDeliveryFieldFilterReducer,
  customDeliveryFieldFilterMenu: customDeliveryFieldFilterMenuReducer,
  customDeliveryRunFields: customDeliveryRunFieldsReducer,
  customDeliveryRunFilterState: customDeliveryRunFilterStateReducer,
  customDeliveryRunFieldFilter: customDeliveryRunFieldFilterReducer,
  customDeliveryRunFieldFilterMenu: customDeliveryRunFieldFilterMenuReducer,
  customOrderFields: customOrderFieldsReducer,
  customOrderFieldFilter: customOrderFieldFilterReducer,
  customOrderFilterState: customOrderFilterStateReducer,
  customOrderFieldFilterMenu: customOrderFieldFilterMenuReducer,
  driverCustomValues: driverCustomValuesReducer,
  orderCustomValues: orderCustomValuesReducer,
  deliveryCustomValues: deliveryCustomValuesReducer,
  deliveryRunCustomValues: deliveryRunCustomValuesReducer,
  createDeliveryRun: createDeliveryRunReducer,
  dashboard: dashboardReducer
});

export type AppState = ReturnType<typeof rootReducer>;
// -------------------
// Combine reduces
const reducers = persistCombineReducers(persistConfig, {
  auth: authReducer,
  filters: filterReducer,
  user: userReducer,
  modal: modalReducer,
  consumer: consumerReducer,
  driver: driverReducer,
  onboarding: onboardingReducer,
  store: storeReducer,
  listViewIDs: listViewReducer,
  customDriverFields: customDriverFieldsReducer,
  customDriverFilterState: customDriverFilterStateReducer,
  customDriverFieldFilter: customDriverFieldFilterReducer,
  customDriverFieldFilterMenu: customDriverFieldFilterMenuReducer,
  customDeliveryFields: customDeliveryFieldsReducer,
  customDeliveryFilterState: customDeliveryFilterStateReducer,
  customDeliveryFieldFilter: customDeliveryFieldFilterReducer,
  customDeliveryFieldFilterMenu: customDeliveryFieldFilterMenuReducer,
  customDeliveryRunFields: customDeliveryRunFieldsReducer,
  customDeliveryRunFieldFilter: customDeliveryRunFieldFilterReducer,
  customDeliveryRunFilterState: customDeliveryRunFilterStateReducer,
  customDeliveryRunFieldFilterMenu: customDeliveryRunFieldFilterMenuReducer,
  customOrderFields: customOrderFieldsReducer,
  customOrderFieldFilter: customOrderFieldFilterReducer,
  customOrderFilterState: customOrderFilterStateReducer,
  customOrderFieldFilterMenu: customOrderFieldFilterMenuReducer,
  driverCustomValues: driverCustomValuesReducer,
  orderCustomValues: orderCustomValuesReducer,
  deliveryCustomValues: deliveryCustomValuesReducer,
  deliveryRunCustomValues: deliveryRunCustomValuesReducer,
  apiStatus: ApiReducer,
  createDeliveryRun: createDeliveryRunReducer,
  dashboard: dashboardReducer
});

const composeEnhancers =
  ((window as any).__REDUX_DEVTOOLS_EXTENSION__ &&
    (window as any).__REDUX_DEVTOOLS_EXTENSION__()) ||
  compose;

// create redux store
// const store = createStore(reducers, composeEnhancers(applyMiddleware(thunk)));
const store = createStore(
  reducers,
  applyMiddleware(thunk as ThunkMiddleware<AppState, AppActions>)
);
// Create redux-persist instance
const persistor = persistStore(store);

export { store, persistor };
