import * as React from "react";
import styled from "styled-components";
// import CloseButton from "react-icons/lib/md/close";
import { MdClose } from "react-icons/md";

import { AppText } from "../../UI";
import { defaultProps } from "react-select/lib/Async";
import { OperatorFilter } from "../../../types";

interface FilterItemProps {
  id: number;
  field: string;
  // type?: "text" | "email" | "dropdown" | "checkbox" | "number" | "date";
  operator: string;
  operators: OperatorFilter | null;
  // | "Equals"
  // | "Contains"
  // | "Does not equal"
  // | "Does not contain"
  // | "Equals multiple"
  // | "Does not equal multiple"
  // | "Greater than"
  // | "Less than"
  // | "Greater than or equal to"
  // | "Less than or equal to";
  value: string;
  onDelete: (id: number) => void;
  onClick: (id: number) => void;
  removeFilter: (id: number) => void;
}

const FilterItem: React.FunctionComponent<FilterItemProps> = ({
  id,
  field,
  operator,
  operators,
  value,
  onDelete,
  removeFilter,
}) => {
  // const onClickDelete = () => onDelete(id);
  return (
    <Div>
      <SpreadVertical>
        <FilterText>{`Filter by ${field}`}</FilterText>
        <OperatorText>{`${!!operators ? operators.labels[operator] : ""}: ${
          value === "0" ? "False" : value === "1" ? "True" : value
        }`}</OperatorText>
      </SpreadVertical>
      <Clickable>
        <MdClose size={24} onClick={() => removeFilter(id)} />
      </Clickable>
    </Div>
  );
};

const Div = styled.div`
  padding: 20px;
  margin-bottom: 20px;
  width: 393px;
  height: 80px;
  border-radius: 4px;
  border: solid 1px rgba(71, 70, 71, 0.26);
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

const Clickable = styled.div`
  :hover {
    cursor: pointer;
  }
`;
const FilterText = styled(AppText)`
  font-size: 14px;
  color: #333333;
  margin-bottom: 10px;
`;

const OperatorText = styled(AppText)`
  font-weight: 700;
  font-size: 14px;
  color: #333333;
`;

const SpreadVertical = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

export { FilterItem };
