import React from "react";
import { Field, FieldProps, ErrorMessage } from "formik";
import { AppText } from "../UI";
import styled from "styled-components";
import { ErrorText } from "../Dumb";
import { TextArea } from "../UI/TextArea";

export const TextAreaField = ({
  name,
  title,
  placeholder,
  style,
  textWidth,
  textHeight,
  type = "text"
}: {
  name: string;
  title?: string;
  placeholder: string;
  style?: React.CSSProperties;
  textWidth?: string;
  textHeight?: string;
  type?: string;
}) => (
  <div style={style}>
    <Field
      name={name}
      render={({ field, form: { errors, touched } }: FieldProps) => {
        const error = !!errors[name] && !!touched[name];
        return (
          <FormSegment>
            {/* {title ? (
              <AppTextFormTitle>{title}</AppTextFormTitle>
            ) : (
              <AppTextFormTitle>&nbsp; </AppTextFormTitle>
            )} */}
            {title && <AppTextFormTitle>{title}</AppTextFormTitle>}
            <TextArea
              {...field}
              error={error}
              placeholder={placeholder}
              textWidth={textWidth}
              textHeight={textHeight}
              type={type}
            />
            <ErrorMessage name={field.name} component={ErrorText} />
          </FormSegment>
        );
      }}
    />
  </div>
);

const FormSegment = styled.div``;

const AppTextFormTitle = styled(AppText)`
  opacity: 0.5;
  font-size: 14px;
  font-weight: 500;
  color: #3b3a3a;
  margin-bottom: 2px;
`;
