import * as React from "react";
import { connect } from "react-redux";
import { AppState } from "../../store/configureStore";
import { AppActions, LoggedUser } from "../../types";
import { Dispatch, bindActionCreators } from "redux";
import * as Yup from "yup";
import {
  GeneralInfo,
  MyCommunicationPreferences,
  CustomerCommunicationPreferences,
} from "../Segments/onboarding";
import styled from "styled-components";
import { Container, AppText, HR, Button } from "../UI";
import { TABLET_BREAKPOINT, PHONE_REG_EXP } from "../../utils";
import {
  onboardNextPage,
  onboardPrevPage,
  startSetOnboardingPrefs,
  clearOnboardingPrefs,
} from "../../actions/onboarding";
import { Formik } from "formik";
import { getStoreThunk } from "../../api/store";
import { getStore } from "../../actions/store";
import { UserStore } from "../../types/store";
import NewUserPassword from "./NewUserPassword";

const onboardSchema = Yup.object().shape({
  require_signature_for_deliveries_sms: Yup.boolean().required(),
  require_signature_for_deliveries_email: Yup.boolean().required(),
  require_signature_for_deliveries_phone: Yup.boolean().required(),
  when_delivery_is_scheduled_sms: Yup.boolean().required(),
  when_delivery_is_scheduled_email: Yup.boolean().required(),
  when_delivery_is_scheduled_phone: Yup.boolean().required(),
  when_delivery_will_arrive_soon_sms: Yup.boolean().required(),
  when_delivery_will_arrive_soon_email: Yup.boolean().required(),
  when_delivery_will_arrive_soon_phone: Yup.boolean().required(),
  when_delivery_is_completed_sms: Yup.boolean().required(),
  when_delivery_is_completed_email: Yup.boolean().required(),
  when_delivery_is_completed_phone: Yup.boolean().required(),
  when_delivery_has_been_delayed_sms: Yup.boolean().required(),
  when_delivery_has_been_delayed_email: Yup.boolean().required(),
  when_delivery_has_been_delayed_phone: Yup.boolean().required(),
  limit_customer_comm_to_certain_times_sms_from: Yup.string().notRequired(),
  limit_customer_comm_to_certain_times_sms_to: Yup.string().notRequired(),
  limit_customer_comm_to_certain_times_email_from: Yup.string().notRequired(),
  limit_customer_comm_to_certain_times_email_to: Yup.string().notRequired(),
  limit_customer_comm_to_certain_times_phone_from: Yup.string().notRequired(),
  limit_customer_comm_to_certain_times_phone_to: Yup.string().notRequired(),
});

interface NewClientSetupProps {}

interface NewClientSetupState {}

type Props = NewClientSetupProps & LinkStateProps & LinkDispatchProps;

class NewClientSetup extends React.Component<Props, NewClientSetupState> {
  nextButton = () => (
    <AuthButton onClick={this.props.onboardNextPage}>NEXT</AuthButton>
  );
  prevButton = () => (
    <AuthButton onClick={this.props.onboardPrevPage}>BACK</AuthButton>
  );
  completeButton = () => (
    <AuthButton onClick={this.props.startSetOnboardPrefs}>COMPLETE</AuthButton>
  );
  renderRightButton = (): JSX.Element | null => {
    const { currentPage } = this.props;

    switch (currentPage) {
      case 0:
        return this.nextButton();
      case 1:
        return this.nextButton();
      case 2:
        return this.nextButton();
      case 3:
        return this.completeButton();
      default:
        return null;
    }
  };
  renderLeftButton = (): JSX.Element | null => {
    const { currentPage } = this.props;

    switch (currentPage) {
      case 0:
        return null;
      case 1:
        return null;
      case 2:
        return this.prevButton();
      case 3:
        return this.prevButton();
      default:
        return null;
    }
  };
  renderTracker = (): JSX.Element => {
    const { currentPage } = this.props;
    console.log("newClientSetup props", this.props);
    return (
      <TrackerDiv>
        <FilledCircle>
          <PageTitleName>GENERAL INFO</PageTitleName>
        </FilledCircle>
        <ThisHR />
        {currentPage >= 1 ? (
          <FilledCircle>
            <PageTitleName>MY COMMUNICATION PREFERENCES</PageTitleName>
          </FilledCircle>
        ) : (
          <OpenCircle>
            <PageTitleName>MY COMMUNICATION PREFERENCES</PageTitleName>
          </OpenCircle>
        )}
        <ThisHR />
        {currentPage >= 2 ? (
          <FilledCircle>
            <PageTitleName>CUSTOMER COMMUNICATION SETTINGS</PageTitleName>
          </FilledCircle>
        ) : (
          <OpenCircle>
            <PageTitleName>CUSTOMER COMMUNICATION SETTINGS</PageTitleName>
          </OpenCircle>
        )}
      </TrackerDiv>
    );
  };
  renderPage = (): JSX.Element => {
    const { currentPage } = this.props;
    switch (currentPage) {
      case 1:
        return <GeneralInfo />;
      case 2:
        return <MyCommunicationPreferences />;
      case 3:
        return <CustomerCommunicationPreferences />;
      default:
        return <GeneralInfo />;
    }
  };
  componentDidMount = () => {
    // this.props.clearOnboardingPrefs();
    const { user_store_id } = this.props;
    this.props.getStore(user_store_id);
    //get store info
  };
  // componentDidUpdate = prevProps => {
  //   const { user_store_id } = this.props;
  //   if (prevProps.user_store_id !== user_store_id) {
  //     console.log("MOUNT USER ID", this.props);
  //     this.props.getStore(user_store_id);
  //   }
  // };
  render(): JSX.Element {
    const { currentPage } = this.props;
    return currentPage === 0 ? (
      <NewUserPassword />
    ) : (
      <OnboardingContainer>
        <AccountSetupText>ACCOUNT SETUP</AccountSetupText>
        {this.renderTracker()}
        {this.renderPage()}
      </OnboardingContainer>
    );
  }
}

const OnboardingContainer = styled(Container)`
  background-image: linear-gradient(to left, #4ba5eb, #60999e);
  padding: 53px 50px 45px 75px;
  display: grid;
  grid-template-rows: 70px 100px auto min-content;
  background-size: cover;
  background-attachment: fixed;
  background-position: center center;
  overflow-x: auto;
`;

const TrackerDiv = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin: 0 30px;
`;

const PageTitleName = styled(AppText)`
  font-size: 12.4px;
  text-align: center;
  color: #ffffff;
  position: absolute;
  top: 25px;
  white-space: nowrap;
  @media (max-width: ${TABLET_BREAKPOINT}px) {
    white-space: normal;
  }
`;

export const AuthButton = styled(Button)`
  width: 269px;
  height: 40px;
  background-color: white;
  color: ${(props) => props.theme.PRIMARY_COLOR};
`;

const Spread = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

const AccountSetupText = styled(AppText)`
  font-size: 27px;
  color: #fff;
`;

const OpenCircle = styled.div`
  width: 15px;
  min-width: 15px;
  height: 15px;
  border: solid 2px #ffffff;
  border-radius: 50%;
  /* box-sizing: content-box; */
  position: relative;
  display: flex;
  justify-content: center;
`;

const ThisHR = styled(HR)`
  max-width: 313px;
`;

const FilledCircle = styled(OpenCircle)`
  background-color: #fff;
`;

interface LinkStateProps {
  currentPage: number;
  user_store_id: any;
  store: UserStore;
  user: any;
  auth: any;
}

interface LinkDispatchProps {
  onboardNextPage: () => void;
  onboardPrevPage: () => void;
  startSetOnboardPrefs: (onboardPrefs: Object) => void;
  clearOnboardingPrefs: () => void;
  getStore: (store_id: number) => void;
}

const mapDispatchToProps = (
  dispatch: Dispatch<AppActions>
): LinkDispatchProps => ({
  onboardNextPage: bindActionCreators(onboardNextPage, dispatch),
  onboardPrevPage: bindActionCreators(onboardPrevPage, dispatch),
  startSetOnboardPrefs: bindActionCreators(startSetOnboardingPrefs, dispatch),
  clearOnboardingPrefs: bindActionCreators(clearOnboardingPrefs, dispatch),
  getStore: bindActionCreators(getStoreThunk, dispatch),
});

// isAuthenticated is true if user_token exists in redux
const mapStateToProps = (state: AppState): LinkStateProps => ({
  currentPage: state.onboarding.currentPage,
  user_store_id: state.user.store_id,
  store: state.store,
  user: state.user,
  auth: state.auth,
});

export default connect(mapStateToProps, mapDispatchToProps)(NewClientSetup);
