import { create } from "apisauce";
import {
  Driver,
  Order,
  OrderDetailType,
  DriverList,
  OrderList,
  DeliveryRunResponse,
  DeliveryRunsResponseType,
  ErrorMessage,
  DeliveryResponse,
  DeliveryList,
  ResetPasswordResponse,
  ImportCSVResponse,
  ClientSetupError,
  ClientSetupSuccess,
} from "./responseTypes";
import {
  DeliveryDriver,
  Autocomplete,
  CustomFilters,
  GetCustomFilter,
} from "../types";
import { apiSuccess, apiError } from "../actions/api";
import { string } from "yup";
import { CustomFields } from "../types";
import { logout, dashboardLoaded } from "../actions";
import { store } from "../store/configureStore";
import { formatCurrentTimeUSDate } from "../utils";
import { RowData } from "../Components/Dumb";
import { UserStore } from "../types/store";

const HTTP = create({
  baseURL: "https://stage.scanovator.datech.dev/public/api",
  headers: {
    "Content-Type": "application/json",
    Accept: "application/json",
  },
});

const AUTOCORRECT = create({
  baseURL: "https://autocomplete.geocoder.api.here.com/6.2",
  headers: {
    "Content-Type": "application/json",
    Accept: "application/json",
  },
});

const api = {
  getDrivers: (
    {
      store_id,
      page,
      item_list,
      limit,
      searched_text,
    }: {
      store_id: number;
      page?: number;
      item_list?: number | string;
      limit?: string;
      searched_text?: string;
    },
    access_token: string
  ) => {
    const searchText = searched_text ? `&searched_text=${searched_text}` : "";
    const itemList = item_list ? `&item_list_id=${item_list}` : "";
    return HTTP.get<DriverList>(
      `driver/get-by-store?store_id=${store_id}&limit=${limit}&page=${page}${itemList}${searchText}`,
      {},
      {
        headers: { Authorization: `Bearer ${access_token}` },
      }
    ).then((r) => {
      if (r.status === 401) {
        console.log("401 status-->", r.status);
        store.dispatch(logout());
      }
      return r!.data as DriverList;
    });
  },
  getDriver: ({ id }: { id: string }, access_token: string) =>
    HTTP.get<Driver>(
      `driver/${id}`,
      {},
      {
        headers: { Authorization: `Bearer ${access_token}` },
      }
    ).then((r) => r.data as Driver),
  deleteDriver: ({ id }, access_token: string) =>
    HTTP.delete(
      `driver/${id}`,
      {},
      {
        headers: { Authorization: `Bearer ${access_token}` },
      }
    ).then((r) => r.data),
  editDriver: (
    {
      id,
      first_name,
      last_name,
      email,
      is_active,
      language,
      color,
      transportation_type,
      external_driver_id,
      password,
    }: {
      id: number;
      first_name?: string;
      last_name?: string;
      email?: string;
      is_active?: number | boolean;
      language?: string;
      transportation_type: string;
      color?: string;
      external_driver_id?: string;
      password?: string;
    },
    access_token: string
  ) => {
    const params = new URLSearchParams();
    first_name && params.append("first_name", first_name);
    last_name && params.append("last_name", last_name);
    email && params.append("email", email);
    params.append(
      "is_active",
      `${is_active === true || is_active === 1 ? 1 : 0}`
    );
    language && params.append("language", language);
    params.append("transportation_type", transportation_type);
    color && params.append("color", color);
    password && params.append("password", password);

    external_driver_id &&
      params.append("external_driver_id", external_driver_id);
    store.dispatch(dashboardLoaded());
    return HTTP.put<Driver>(`driver/${id}`, params, {
      headers: {
        Authorization: `Bearer ${access_token}`,
        "Content-Type": "application/x-www-form-urlencoded",
      },
    }).then((r) => r.data);
  },
  makeActiveDrivers: (
    { driverIDs }: { driverIDs: number[] },
    access_token: string
  ) => {
    const bulkIDs = driverIDs.map((id) => `&ids[]=${id}`);
    store.dispatch(dashboardLoaded());
    return HTTP.put(
      `driver/bulk-active?${bulkIDs}`,
      {},
      {
        headers: {
          Authorization: `Bearer ${access_token}`,
          "Content-Type": "application/x-www-form-urlencoded",
        },
      }
    );
  },
  makeInactiveDrivers: (
    { driverIDs }: { driverIDs: number[] },
    access_token: string
  ) => {
    const bulkIDs = driverIDs.map((id) => `&ids[]=${id}`);
    store.dispatch(dashboardLoaded());
    return HTTP.put(
      `driver/bulk-inactive?${bulkIDs}`,
      {},
      {
        headers: {
          Authorization: `Bearer ${access_token}`,
          "Content-Type": "application/x-www-form-urlencoded",
        },
      }
    );
  },
  massDeleteDrivers: (
    { driverIDs }: { driverIDs: number[] },
    access_token: string
  ) => {
    const bulkIDs = driverIDs.map((id) => `&ids[]=${id}`).join("");
    return HTTP.put(
      `driver/bulk-delete?${bulkIDs}`,
      {},
      {
        headers: {
          Authorization: `Bearer ${access_token}`,
          "Content-Type": "application/x-www-form-urlencoded",
        },
      }
    );
  },
  createDriver: (
    field: {
      external_driver_id?: string;
      first_name?: string;
      last_name?: string;
      transportation_type?: string;
      email?: string;
      language?: string;
      color?: string;
      store_id: number;
    },
    access_token: string
  ) => {
    const params = new URLSearchParams();
    Object.keys(field).forEach((key) => params.append(key, field[key]));
    return HTTP.post<Driver | ErrorMessage>(`driver`, params, {
      headers: {
        Authorization: `Bearer ${access_token}`,
        "Content-Type": "application/x-www-form-urlencoded",
      },
    }).then((r) => {
      if (r.status !== 200) {
        return r.data as ErrorMessage;
      } else {
        store.dispatch(dashboardLoaded());
        return r.data as Driver;
      }
    });
  },
  getOrders: (
    {
      store_id,
      page,
      item_list,
      limit,
      searched_text,
    }: {
      store_id: number;
      page?: number;
      item_list?: number | string;
      limit?: string;
      searched_text?: string;
    },
    access_token: string
  ) => {
    const searchText = searched_text ? `&searched_text=${searched_text}` : "";
    const itemList = item_list ? `&item_list_id=${item_list}` : "";
    return HTTP.get<OrderList>(
      `order/get-by-store?store_id=${store_id}&limit=${limit}&page=${page}${itemList}${searchText}`,
      {},
      {
        headers: { Authorization: `Bearer ${access_token}` },
      }
    )
      .then((r) => {
        console.log("RESPONSE ------->", r);
        if (r.status === 401) {
          console.log("401 status", r.status);
          store.dispatch(logout());
        }
        return r!.data as OrderList;
      })
      .catch((error) => {
        console.log("ERROR GET ORDERS------>", error);

        if (error.response.status === 401) {
          logout();
        }
      });
  },
  deleteOrder: ({ id }, access_token: string) =>
    HTTP.delete(
      `order/${id}`,
      {},
      {
        headers: { Authorization: `Bearer ${access_token}` },
      }
    ).then((r) => r.data),
  massDeleteOrders: ({ orderIDs }: { orderIDs: RowData[] }, token) => {
    const bulkIDs = orderIDs.map((id) => `&order_ids[]=${id.id}`).join("");
    return HTTP.delete(
      `order/mass-delete?${bulkIDs}`,
      {},
      {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/x-www-form-urlencoded",
        },
      }
    );
  },
  getOrder: ({ id }: { id: string }, access_token: string) =>
    HTTP.get<OrderDetailType>(
      `order/${id}`,
      {},
      {
        headers: { Authorization: `Bearer ${access_token}` },
      }
    ).then((r) => {
      console.log("r---", r);
      if (!r.ok) {
        return null;
      }
      return r.data as OrderDetailType;
    }),
  // getOrders: (
  //   { store_id,
  //     page,
  //     item_list,
  //     limit }:
  //   { store_id: number;
  //     page?: number;
  //     item_list?: number;
  //     limit: string
  //   }, access_token: string) => {
  //   const itemList = item_list ? `&item_list_id=${item_list}` : "";
  //   return HTTP.get<OrderList>(
  //       `order/get-by-store?store_id=${store_id}`,
  //       {},
  //       {
  //         headers: { Authorization: `Bearer ${access_token}` }
  //       }
  //     ).then(r => r.data as OrderList),
  // },
  createOrder: (
    field: {
      store_id: number;
      email: string;
      first_name: string;
      last_name: string;
      phone: string;
      phone_type?: string;
      num_boxes?: string;
      amount_to_collect?: string;
      address_1?: string;
      unit?: string;
      city?: string;
      state?: string;
      zip?: string;
      description?: string;
      notes?: string;
      id: number;
    },
    access_token: string
  ) => {
    const params = new URLSearchParams();
    Object.keys(field).forEach((key) => params.append(key, field[key]));
    return HTTP.post<Order>(`order`, params, {
      headers: {
        Authorization: `Bearer ${access_token}`,
        "Content-Type": "application/x-www-form-urlencoded",
      },
    }).then((r) => r.data);
  },
  editOrder: (
    field: {
      email: string;
      first_name: string;
      last_name: string;
      phone: string;
      phone_type?: string | null;
      num_boxes?: number | null;
      address_1?: string;
      unit?: string;
      city?: string;
      state?: string;
      zip?: string;
      description?: string | null;
      notes?: string | null;
    },
    id: number,
    access_token: string
  ) => {
    const params = new URLSearchParams();
    Object.keys(field).forEach((key) => params.append(key, field[key]));
    return HTTP.put<Order>(`order/${id}`, params, {
      headers: {
        Authorization: `Bearer ${access_token}`,
        "Content-Type": "application/x-www-form-urlencoded",
      },
    }).then((r) => r.data);
  },
  login: ({ email, password }: { email: string; password: string }) => {
    const formData = new FormData();
    formData.append("email", email);
    formData.append("password", password);
    return HTTP.post(`/auth/login`, formData).then((r) => r);
  },
  forgotEmail: ({ email }: { email: string }) => {
    const formData = new FormData();
    formData.append("email", email);
    return HTTP.post(`/auth/send-reset-pasword`, formData).then((r) => r);
  },
  resetPassword: ({ email }: { email: string }) => {
    const formData = new FormData();
    formData.append("email", email);
    return HTTP.post<ResetPasswordResponse>(
      `/auth/send-reset-password`,
      formData,
      {}
    ).then((r) => r.data);
  },
  logout: () => HTTP.post("/auth/logout"),
  editUser: (
    {
      user_id,
      first_name,
      last_name,
      email,
      password,
      is_registered,
      phone,
    }: {
      user_id: number;
      first_name?: string;
      last_name?: string;
      email?: string;
      password?: string;
      is_registered?: number;
      phone?: string;
    },
    token: string
  ) => {
    let queryString = "?";
    !!first_name &&
      (queryString = queryString.concat(`&first_name=${first_name}`));
    !!last_name &&
      (queryString = queryString.concat(`&last_name=${last_name}`));
    !!email && (queryString = queryString.concat(`&email=${email}`));
    !!password && (queryString = queryString.concat(`&password=${password}`));
    !!phone && (queryString = queryString.concat(`&phone=${phone}`));
    !!is_registered &&
      (queryString = queryString.concat(`is_registered=${is_registered}`));
    return HTTP.put(
      `/user/${user_id}${queryString}`,
      {},
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    ).then((r) => {
      if (r.status === 200) {
        r;
        console.log("Successful");
        // return dispatch => {
        //   dispatch(apiSuccess());
        // };
      }
      // if(r.status !== 200){
      //   return dispatch => {
      //     dispatch(apiError(r.error))
      //   }
      // }
      console.log(r);
    });
  },

  storeUserNotification: (
    { store_id, notification_ids, send_method },
    token: string
  ) => {
    let notificationID = "";
    notification_ids.forEach((element, i) => {
      notificationID += `&notification_ids[${i}]=` + `${element}`;
    });
    return HTTP.post(
      `/notification/store?store_id=${store_id}${notificationID}&send_method=${send_method}&begin_time=${"00:00:00"}&end_time=${"00:00:30"}`,
      {},
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
  },
  storeCustomerNotification: (
    {
      store_id,
      notification_ids,
      send_method,
      begin_time,
      end_time,
    }: {
      store_id: string;
      notification_ids: string[];
      send_method: string;
      begin_time: string;
      end_time: string;
    },
    token: string
  ) => {
    let notificationID = "";
    notification_ids.forEach((element, i) => {
      notificationID += `&notification_ids[${i}]=` + `${element}`;
    });
    return HTTP.post(
      `/notification/store?store_id=${store_id}${notificationID}&send_method=${send_method}&begin_time=${"00:00:00"}&end_time=${"00:00:30"}`,
      {},
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
  },
  getStore: ({ store_id }: { store_id: number }, token) =>
    HTTP.get(
      `/store/${store_id}`,
      {},
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    ).then((r) => r.data),

  editStore: (
    {
      store_id,
      name,
      phone,
      address_1,
      address_2,
      city,
      state,
      zip,
      requires_scanning,
      barcode_type,
    }: {
      store_id: number | null;
      name?: string;
      phone?: string;
      address_1?: string;
      address_2?: string;
      city?: string;
      state?: string;
      zip?: string;
      requires_scanning?: number;
      barcode_type?: string;
    },
    token: string
  ) => {
    // const formData = new FormData();
    // !!name && formData.append("name", name);
    // !!phone && formData.append("phone", phone);
    // !!address_1 && formData.append("address_1", address_1);
    // !!address_2 && formData.append("address_2", address_2);
    // !!city && formData.append("city", city);
    // !!state && formData.append("state", state);
    // !!zip && formData.append("zip", zip);
    // requires_scanning !== undefined &&
    //   formData.append("requires_scanning", `${requires_scanning}`);
    let queryString = "";
    !!name && (queryString = queryString.concat(`&name=${name}`));
    !!barcode_type &&
      (queryString = queryString.concat(`&barcode_type=${barcode_type}`));

    !!phone && (queryString = queryString.concat(`&phone=${phone}`));
    !!address_1 &&
      (queryString = queryString.concat(`&address_1=${address_1}`));
    !!address_2 &&
      (queryString = queryString.concat(`&address_2=${address_2}`));
    !!city && (queryString = queryString.concat(`&city=${city}`));
    !!state && (queryString = queryString.concat(`&state=${state}`));
    !!zip && (queryString = queryString.concat(`&zip=${zip}`));
    requires_scanning !== undefined &&
      (queryString = queryString.concat(
        `&requires_scanning=${requires_scanning}`
      ));
    // console.log("QUERY STRING", queryString);
    return HTTP.put(
      `/store/${store_id}?${queryString}`,
      {},
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    ).then((r) => r.data);
  },
  registerAdmin: (
    {
      first_name,
      last_name,
      email,
      phone,
      store_name,
      nexmo_num,
    }: {
      first_name: string;
      last_name: string;
      email: string;
      phone: string;
      store_name: string;
      nexmo_num: string;
    },
    token: string
  ) => {
    const formData = new FormData();
    formData.append("first_name", first_name);
    formData.append("last_name", last_name);
    formData.append("email", email);
    formData.append("store_name", store_name);
    formData.append("phone", phone);
    formData.append("nexmo_num", nexmo_num);
    return HTTP.post<ClientSetupSuccess | ClientSetupError>(
      "/auth/register-admin",
      formData,
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    ).then((r) => r.data);
  },
  createNewList: (
    {
      store_id,
      entity_name,
      label,
      filter_ids,
    }: {
      store_id: number | null;
      entity_name: string;
      label: string;
      filter_ids?: number[];
    },
    token: string
  ) => {
    const filterIDS = filter_ids
      ? filter_ids.map((filter) => `&filter_ids[]=${filter}`)
      : "";
    return HTTP.post(
      `/item-list?store_id=${store_id}&entity_name=${entity_name}&label=${label}${filterIDS}`,
      {},
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    ).then((r) => r.data);
  },
  editListName: (
    {
      entity,
      list_id,
      label,
      filter_ids,
    }: {
      entity: string;
      list_id: number | string;
      label: string;
      filter_ids: string;
    },
    token: string
  ) => {
    let parsedFilters = !!filter_ids
      ? filter_ids
          .split(",")
          .map((string) => parseInt(string.replace(/[^\d]/g, "")))
      : [];
    let filterIDs = "";
    parsedFilters.forEach((id) => (filterIDs += `&filter_ids[]=${id}`));
    return HTTP.put(
      `item-list/${list_id}?entity_name=${entity}&label=${label}&${filterIDs}`,
      {},
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    ).then((r) => r.data);
  },
  getFilterOperator: (token) => {
    return HTTP.get(
      "/filter/get-operators",
      {},
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    ).then((r) => r.data);
  },

  getFilters: ({ item_list_id }: { item_list_id: number }, token) => {
    return HTTP.get(
      `/item-list/get-filters?item_list_id=${item_list_id}`,
      {},
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    ).then((r) => r.data);
  },
  getDefaultFilters: ({ entity }: { entity: string }, token) => {
    return HTTP.get(
      `/item-list/get-defaults?entity_name=${entity}`,
      {},
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    ).then((r) => r.data);
  },
  getItemLists: (
    { store_id, entity }: { store_id: number; entity: string },
    token: string
  ) => {
    return HTTP.get(
      `/item-list/get-by-store-and-entity?store_id=${store_id}&entity_name=${entity}`,
      {},
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    ).then((r) => r.data);
  },
  createFilter: (
    {
      entity_name,
      slug,
      label,
      operator,
      value,
    }: {
      entity_name: string;
      slug: string;
      label: string;
      operator: string;
      value: string;
    },
    token: string
  ) => {
    return HTTP.post(
      `/filter?entity_name=${entity_name}&slug=${slug}&label=
      ${label}&operator=${operator}&value=${value}`,
      {},
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    ).then((r) => r.data);
  },
  updateList: (
    {
      list_id,
      entity_name,
      label,
      filter_ids,
    }: {
      list_id: number;
      entity_name: string;
      label: string;
      filter_ids?: [];
    },
    token: string
  ) => {
    let filterIDs = "";

    filter_ids
      ? filter_ids.forEach((id) => (filterIDs += `&filter_ids[]=${id}`))
      : "";
    return HTTP.put(
      `/item-list/${list_id}?entity_name=${entity_name}&label=${label}${filterIDs}`,
      {},
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
  },
  getCustomField: (
    {
      store_id,
      entity_name,
    }: {
      store_id: number;
      entity_name: string;
    },
    token: string
  ) => {
    //LIMIT 100 per page
    return HTTP.get(
      `/custom-field/get-by-store-and-entity?store_id=${store_id}&entity_name=${entity_name}&limit=100`,
      {},
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    ).then((r) => r.data);
  },
  createCustomField: (
    {
      store_id,
      field_type_id,
      entity_name,
      display,
      slug,
    }: {
      store_id: number;
      field_type_id: number;
      entity_name: string;
      display: string;
      slug: string;
    },
    token: string
  ) => {
    return HTTP.post(
      `/custom-field?store_id=${store_id}&field_type_id=${field_type_id}&entity_name=${entity_name}&display=${display}&slug=${slug}`,
      {},
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    ).then((r) => r.data);
  },
  updateCustomField: (
    {
      field_id,
      display,
      slug,
    }: {
      field_id: number;
      display: string;
      slug: string;
    },
    token: string
  ) => {
    return HTTP.put(
      `/custom-field/${field_id}?display=${display}&slug=${slug}`,
      {},
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    ).then((r) => r.data);
  },
  deleteCustomField: (field_id: number, token) => {
    HTTP.delete(
      `/custom-field/${field_id}`,
      {},
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
  },
  getCustomFieldListValues: (custom_field_id: number, token: string) => {
    return HTTP.get(
      `custom-field-list-value/get-by-custom-field?custom_field_id=${custom_field_id}`,
      {},
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    ).then((r) => r.data);
  },
  deleteCustomFieldListValue: (id: number, token: string) => {
    HTTP.delete(
      `custom-field-list-value/${id}`,
      {},
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
  },
  editCustomFieldListValue: (
    {
      custom_field_id,
      value,
    }: {
      custom_field_id: number;
      value: string;
    },
    token: string
  ) => {
    HTTP.put(
      `custom-field-list-value/${custom_field_id}?value=${value}&selected=0`,
      {},
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
  },
  createCustomFieldListValue: (
    {
      custom_field_id,
      value,
      selected,
    }: {
      custom_field_id: number;
      value: string;
      selected: number;
    },
    token: string
  ) => {
    HTTP.post(
      `custom-field-list-value?custom_field_id=${custom_field_id}&value=${value}&selected=${selected}`,
      {},
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
  },
  getAllCustomProperties: (
    entity_id: number | string,
    token: string,
    entity: string
  ) => {
    return HTTP.get(
      `/custom-field-value/get-by-entity?entity_name=${entity}&entity_id=${entity_id}`,
      {},
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    ).then((r) => r.data);
  },
  createCustomFieldValue: (
    {
      custom_field_id,
      entity_id,
      value,
    }: {
      custom_field_id: number;
      entity_id: number;
      value: string;
    },
    token: string
  ) => {
    return HTTP.post(
      `/custom-field-value?custom_field_id=${custom_field_id}&entity_id=${entity_id}&value=${value}`,
      {},
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    ).then((r) => r.data);
  },
  updateCustomFieldValue: (
    {
      id,
      value,
    }: {
      id: number;
      value: string;
    },
    token: string
  ) => {
    HTTP.put(
      `/custom-field-value/${id}?value=${value}`,
      {},
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
  },
  getDeliveryRuns: (
    {
      store_id,
      page,
      item_list,
      limit,
      searched_text,
    }: {
      store_id: number;
      page?: number;
      item_list?: number;
      limit?: string;
      searched_text?: string;
    },
    access_token: string
  ) => {
    const searchText = searched_text ? `&searched_text=${searched_text}` : "";

    const itemList = item_list ? `&item_list_id=${item_list}` : "";
    return HTTP.get<DeliveryRunsResponseType>(
      `delivery-run/get-by-store?store_id=${store_id}&limit=${limit}&page=${page}${itemList}${searchText}`,
      {},
      {
        headers: { Authorization: `Bearer ${access_token}` },
      }
    ).then((r) => {
      if (r.status === 401) {
        console.log("401 status -->", r.status);
        store.dispatch(logout());
      }
      return r!.data as DeliveryRunsResponseType;
    });
  },
  createDeliveryRuns: (
    {
      driver_id,
      totalCases,
      route_selection_method,
      startTime,
      status,
      order_ids,
      endTime,
    }: {
      driver_id: number;
      totalCases: number;
      route_selection_method: string;
      startTime: string;
      status: string;
      order_ids: string;
      endTime: string;
    },
    token
  ) => {
    console.log("SUBMIT DELIVERY RUNS RAN");
    HTTP.post(
      `/delivery-run?driver_id=${driver_id}&num_deliveries=${
        totalCases ? totalCases : 0
      }&scheduled_start_time=${startTime}
      &scheduled_end_time=${endTime}&route_selection_method=${route_selection_method}&status=${status}${order_ids}&sequence_num=${0}
      `,
      {},
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    // DeliveryDriver.forEach(driver => {
    //   if (driver.deliveryRuns.length < 0) {
    //     driver.deliveryRuns.forEach(delivery => {
    //       let totalCases = 0;
    //       let order_ids = "";
    //       delivery.deliveries.forEach(order => {
    //         totalCases += order.num_boxes as number;
    //         order_ids += `&order_ids[]=${order.id}`;
    //         console.log("create delivery run RAN");

    //         return HTTP.post(
    //           `/delivery-run/?driver_id=${driver.id}&num_delivery${totalCases}&scheduled_start_time=${startTime}
    //           &scheduled_end_time=${endTime}
    //           }&route_selection_method=${delivery.route_selection_method}&status=${delivery.status}${order_ids}
    //           `,
    //           {},
    //           {
    //             headers: { Authorization: `Bearer ${token}` }
    //           }
    //         );
    //       });
    //     });
    //   }
    // });
  },
  calculateRoute: (order_ids: number[], token) => {
    let orders = "";
    order_ids.forEach((order) => {
      orders += `&order_ids[]=${order}`;
    });
    return HTTP.post(
      `/delivery-run/calculate-route?${orders}`,
      {},
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    ).then((r) => r.data);
  },
  getDeliveryRunByDriver: (driver_id: number, token: string) => {
    return HTTP.get(
      `/delivery-run/get-by-driver?driver_id=${driver_id}`,
      {},
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    ).then((r) => r.data);
  },
  getDeliveryRun: (delivery_run_id: number, token: string) => {
    return HTTP.get(
      `/delivery-run/${delivery_run_id}`,
      {},
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    ).then((r) => r.data);
  },
  //more parameters to be added later
  updateDeliveryRun: (
    {
      order_ids,
      delivery_run_id,
    }: {
      order_ids: string;
      delivery_run_id: number;
    },
    token: string
  ) => {
    return HTTP.put(
      `/delivery-run/${delivery_run_id}?${order_ids}`,
      {},
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    ).then((r) => r.data);
  },
  getDeliveries: (
    {
      store_id,
      page,
      item_list,
      limit,
      searched_text,
    }: {
      store_id: number;
      page?: number;
      item_list?: number;
      limit?: string;
      searched_text?: string;
    },
    access_token: string
  ) => {
    const searchText = searched_text ? `&searched_text=${searched_text}` : "";
    const itemList = item_list ? `&item_list_id=${item_list}` : "";
    return HTTP.get<DeliveryList>(
      `delivery/get-by-store?store_id=${store_id}&limit=${limit}&page=${page}${itemList}${searchText}`,
      {},
      {
        headers: { Authorization: `Bearer ${access_token}` },
      }
    ).then((r) => {
      if (r.status === 401) {
        console.log("401 status -->", r.status);
        store.dispatch(logout());
      }
      return r!.data as DeliveryList;
    });
  },
  editDelivery: (
    field: {
      order_id?: number;
      store_id?: number;
      customer_id?: number;
      delivery_run_id?: number;
      status_id?: number;
      notes?: string;
      delivery_image?: string;
      minutes_in_store?: number;
      minutes_in_transit?: number;
    },
    id: number,
    access_token: string
  ) => {
    const params = new URLSearchParams();
    Object.keys(field).forEach((key) => params.append(key, field[key]));
    return HTTP.put<DeliveryResponse>(`delivery/${id}`, params, {
      headers: {
        Authorization: `Bearer ${access_token}`,
        "Content-Type": "application/x-www-form-urlencoded",
      },
    }).then((r) => r.data);
  },
  deleteDelivery: ({ id }, access_token: string) =>
    HTTP.delete(
      `delivery/${id}`,
      {},
      {
        headers: { Authorization: `Bearer ${access_token}` },
      }
    ).then((r) => r.data),
  massDeleteDeliveries: ({ deliveryIDs }: { deliveryIDs: number[] }, token) => {
    const bulkIDs = deliveryIDs.map((id) => `&delivery_ids[]=${id}`).join("");
    return HTTP.delete(
      `delivery/mass-delete?${bulkIDs}`,
      {},
      {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/x-www-form-urlencoded",
        },
      }
    );
  },
  editDeliveryStatus: (
    {
      delivery_id,
      status_id,
    }: {
      delivery_id: number;
      status_id: number;
    },
    access_token: string
  ) => {
    HTTP.put<DeliveryResponse>(
      `delivery/update-status?delivery_id=${delivery_id}&status_id=${status_id}`,
      {},
      {
        headers: { Authorization: `Bearer ${access_token}` },
      }
    ).then((r) => r.data);
  },
  deleteDeliveryRun: ({ id }, access_token: string) =>
    HTTP.delete(
      `delivery-run/${id}`,
      {},
      {
        headers: { Authorization: `Bearer ${access_token}` },
      }
    ).then((r) => r.data),
  massDeleteDeliveryruns: (
    { deliveryRunIDs }: { deliveryRunIDs: number[] },
    token
  ) => {
    const bulkIDs = deliveryRunIDs
      .map((id) => `&delivery_run_ids[]=${id}`)
      .join("");
    console.log("BULKIDs", bulkIDs);
    return HTTP.delete(
      `delivery-run/mass-delete?${bulkIDs}`,
      {},
      {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/x-www-form-urlencoded",
        },
      }
    );
  },
  getDelivery: ({ id }: { id: string }, access_token: string) =>
    HTTP.get<DeliveryResponse>(
      `delivery/${id}`,
      {},
      {
        headers: { Authorization: `Bearer ${access_token}` },
      }
    ).then((r) => r.data as DeliveryResponse),
  importCSV: (store_id: number, file, token) => {
    const formData = new FormData();
    formData.append("file", file);
    return HTTP.post<ImportCSVResponse>(
      `order/import-from-csv?store_id=${store_id}`,
      formData,
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    ).then((r) => r.data);
  },
  exportDeliveries: (
    id: number,
    item_list_id: number | string,
    token: string
  ) =>
    HTTP.get<File>(
      `export/deliveries?store_id=${id}&item_list_id=${item_list_id}`,

      {},
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    ).then((response) => {
      const url = window.URL.createObjectURL(new Blob([response.data as any]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute(
        "download",
        `deliverylist(${formatCurrentTimeUSDate()}).csv`
      );
      document.body.appendChild(link);
      link.click();
      return response;
    }),
  exportDeliveryRun: (
    id: number,
    item_list_id: number | string,
    token: string
  ) =>
    HTTP.get<File>(
      `export/delivery_runs?store_id=${id}&item_list_id=${item_list_id}`,
      {},
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    ).then((response) => {
      const url = window.URL.createObjectURL(new Blob([response.data as any]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute(
        "download",
        `deliveryrunlist(${formatCurrentTimeUSDate()}).csv`
      );
      document.body.appendChild(link);
      link.click();
      return response;
    }),
  exportDrivers: (id: number, item_list_id: number | string, token: string) => {
    const itemListString = item_list_id ? `&item_list_id=${item_list_id}` : "";
    return HTTP.get<File>(
      `export/drivers?store_id=${id}${itemListString}`,
      {},
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    ).then((response) => {
      const url = window.URL.createObjectURL(new Blob([response.data as any]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute(
        "download",
        `driverlist(${formatCurrentTimeUSDate()}).csv`
      );
      document.body.appendChild(link);
      link.click();
      return response;
    });
  },
  exportOrders: (id: number, item_list_id: number | string, token: string) =>
    HTTP.get<File>(
      `export/orders?store_id=${id}&item_list_id=${item_list_id}`,
      {},
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    ).then((response) => {
      const url = window.URL.createObjectURL(new Blob([response.data as any]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute(
        "download",
        `orderlist(${formatCurrentTimeUSDate()}).csv`
      );
      document.body.appendChild(link);
      link.click();
      return response;
    }),
  createDefaultList: (store_id: number, token: string) =>
    HTTP.post(
      `item-list/create-default-lists?store_id=${store_id}`,
      {},
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    ),
  deleteItemList: (list_id: number | string, token: string) =>
    HTTP.delete(
      `item-list/${list_id}`,
      {},

      {
        headers: { Authorization: `Bearer ${token}` },
      }
    ),
  autoCorrectAddress: ({
    address_query,
    store_latitude,
    store_longitude,
  }: {
    address_query: string;
    store_latitude: number;
    store_longitude: number;
  }) =>
    AUTOCORRECT.get(
      `suggest.json?app_id=p5KSywbaegqL2TI4vufC&app_code=GpzGSU5xOex4QY5hZFQuOw&country=USA&prox=${store_latitude},${store_longitude},40000&query=${address_query}`,
      {}
    ).then((response) => {
      console.log("response. data", response.data);
      return response.data as Autocomplete;
    }),
  getAllDeliveryRunsOnMapView: ({
    store_id,
    token,
  }: {
    store_id: number;
    token: string;
  }) =>
    HTTP.get(
      `driver/fetch-all?store_id=${store_id}`,
      {},

      {
        headers: { Authorization: `Bearer ${token}` },
      }
    ),
  getColumnFilters: async ({
    entity,
    store_id,
    token,
  }: {
    entity: string;
    store_id: number;
    token: string;
  }) => {
    const response = await HTTP.get(
      `column-manager/get-column?entity=${entity}&store_id=${store_id}`,
      {},
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );

    return response.data as GetCustomFilter;
  },
  editColumns: async ({
    entity,
    store_id,
    token,
    filters,
  }: {
    entity: string | undefined;
    store_id: number;
    token: string;
    filters: CustomFilters[];
  }) => {
    const postData = JSON.stringify({
      entity: entity,
      store_id: store_id,
      fields: filters,
    });

    const response = await HTTP.post(`column-manager/data`, postData, {
      headers: { Authorization: `Bearer ${token}` },
    });

    return response.data as GetCustomFilter;
  },
};

export { api };
