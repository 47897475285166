import * as React from "react";
import { connect } from "react-redux";
import { Formik, FormikProps, Form, FieldArray } from "formik";
import * as Yup from "yup";
import { Dispatch, bindActionCreators } from "redux";
import { AppActions, OrderType, DeliveryRuns } from "../../types";
import { AppState } from "../../store/configureStore";
import {
  InputField,
  OnboardInputField,
  SwitchField,
  SelectField,
} from "../form";
import styled from "styled-components";
import { Button, Modal, AppText, HR, SecondaryButton } from "../UI";
import { PRIMARY_COLOR, formatSecondsToTime } from "../../utils";
import { FaRegCalendarAlt, FaRegClock } from "react-icons/fa";
import {
  addProperty,
  editProperty,
  reloadDriverCustomListLoad,
  filterDashboardOrders,
} from "../../actions";
import OrderModalSegment from "../../Components/Segments/OrderModalSegment";
import DateSelectModal from "../../Components/modal/DateSelectModal";
import moment from "moment";
import {
  addCreateDeliveryRunOrders,
  removeFromDeliveryRun,
  updateDeliveryRunSchedule,
} from "../../actions/createDeliveryRun";
import { api } from "../../api/api";

const schema = Yup.object().shape({
  start_day: Yup.string().matches(
    /((1[0-2]|0?[1-9]):([0-5][0-9]) ?([AaPp][Mm]))/,
    "* Time format should be HH:MM AM/PM"
  ),
});

interface DeliveryRunModalProps {
  route_selection_method: string;
  start_day: string;
  start_date: string;
  orders?: OrderType[];
  delivery: DeliveryRuns;
  showModal: boolean;
  toggleModal: () => void;
  driver_id: number;
  travel_time: string;
  fromDashboard?: boolean;
}

interface DeliveryRunModalState {
  selectTimeModal: boolean;
  ordersToBeDeleted: OrderType[];
}

interface MyFormikProps {
  start_day: string;
  start_date: string;
  route_selection_method: string;
  delivery_runs: OrderType[];
  inactive: boolean;
}

class DeliveryRunModal extends React.Component<Props, DeliveryRunModalState> {
  constructor(props: Props) {
    super(props);

    this.state = {
      selectTimeModal: false,
      ordersToBeDeleted: [],
    };
  }

  toggleSelectTime = () => {
    this.setState((prev) => ({
      selectTimeModal: !prev.selectTimeModal,
    }));
  };
  addToOrdersToDelete = (order: OrderType) => {
    this.setState({
      ordersToBeDeleted: [...this.state.ordersToBeDeleted, order],
    });
  };
  componentDidMount = async () => {};
  render(): JSX.Element {
    return (
      <Modal
        height="fit-content"
        width="fit-content"
        zIndex="70"
        showModal={this.props.showModal}
        toggleModal={this.props.toggleModal}
        label={this.props.delivery.label}
      >
        <Formik
          initialValues={{
            start_day: moment(this.props.start_day, ["HH:mm:ss"]).format(
              "h:mm A"
            ),
            start_date: this.props.start_date,
            route_selection_method: this.props.route_selection_method,
            delivery_runs: this.props.delivery.deliveries,
            inactive: this.props.delivery.inactive,
          }}
          validationSchema={schema}
          onSubmit={async (values, { setFieldError, setStatus }) => {
            const { delivery_runs, start_date, start_day, inactive } = values;
            const {
              delivery: { driver_id, id, delivery_id },
              fromDashboard,
              filterDashboardOrders,
              token,
            } = this.props;
            if (!fromDashboard) {
              try {
                //adds delivery orders back to orders
                this.props.addCreateDeliveryRunOrders(
                  this.state.ordersToBeDeleted
                );
                this.props.removeFromDeliveryRun({
                  orders: this.state.ordersToBeDeleted,
                  driver_id: driver_id,
                  delivery_run_id: id,
                });
                this.props.updateDeliveryRunSchedule({
                  date: start_date,
                  time: start_day,
                  driver_id: driver_id,
                  delivery_run_id: id,
                  inactive: inactive,
                });
                this.setState({
                  ordersToBeDeleted: [],
                });
                this.props.toggleModal();
              } catch (error) {
                console.log("logging error", error);
                setStatus({ loading: false });
              }
            } else {
              let order_ids = "";
              delivery_runs.forEach((order) => {
                order_ids += `&order_ids[]=${order.id}`;
              });

              const mappedOrders = delivery_runs.map((order) => {
                return order.id;
              });
              filterDashboardOrders(
                mappedOrders as number[],
                delivery_id as number
              );
              const updatedDeliveryRun = await api.updateDeliveryRun(
                {
                  order_ids: order_ids,
                  delivery_run_id: delivery_id as number,
                },
                token
              );

              this.props.toggleModal();
            }
          }}
        >
          {({
            status,
            touched,
            submitForm,
            errors,
            setFieldTouched,
            values,
            handleReset,
          }: FormikProps<MyFormikProps>) => {
            console.log("values:", values);
            const { start_date, start_day, route_selection_method } = values;
            const {
              delivery: { deliveries },
              travel_time,
              fromDashboard,
            } = this.props;
            return (
              <Form>
                <DateSelectModal
                  showModal={this.state.selectTimeModal}
                  toggleModal={this.toggleSelectTime}
                  name={""}
                  time={start_day}
                  handleReset={handleReset}
                  start_date={start_date}
                />
                <Div>
                  <Header>
                    <HeaderText>{this.props.delivery.label}</HeaderText>
                  </Header>
                  <HR />
                  <DeliveryRunInfo>
                    <DescriptionContainer>
                      <ContainerText
                        style={{
                          marginRight: "5px",
                        }}
                      >
                        Route Optimization:
                      </ContainerText>
                      <ValueText>{route_selection_method}</ValueText>
                    </DescriptionContainer>
                    <DescriptionContainer
                      style={{
                        justifyContent: fromDashboard
                          ? "flex-start"
                          : "space-between",
                      }}
                    >
                      <ContainerText>Scheduled Start Time: </ContainerText>
                      {!fromDashboard && (
                        <>
                          <FaRegCalendarAlt size={20} />
                          <ValueText>{start_date}</ValueText>
                        </>
                      )}

                      <FaRegClock
                        size={20}
                        style={{
                          margin: `${fromDashboard ? "0px 3px" : "0px"}`,
                        }}
                      />
                      <ValueText>{start_day}</ValueText>
                      {!fromDashboard && (
                        <DateButton
                          type="button"
                          onClick={this.toggleSelectTime}
                        >
                          SET CUSTOM TIME
                        </DateButton>
                      )}
                    </DescriptionContainer>
                    <DescriptionContainer>
                      <ContainerText
                        style={{
                          marginRight: "5px",
                        }}
                      >
                        Total Time Estimated:{" "}
                      </ContainerText>
                      <ValueText>
                        {travel_time === "No orders to check"
                          ? travel_time
                          : formatSecondsToTime(travel_time as string)}
                      </ValueText>
                    </DescriptionContainer>
                  </DeliveryRunInfo>
                  <OrderTitle>Delivery Order</OrderTitle>
                  <OrdersContainer>
                    <FieldArray name="delivery_runs">
                      {({ insert, remove, name }) =>
                        values.delivery_runs &&
                        values.delivery_runs.map((order, index) => {
                          return (
                            <OrderModalSegment
                              key={order.id}
                              status={order.status}
                              order={order}
                              index={index}
                              order_id={order.id}
                              address={order.address.address_1}
                              city={order.address.city}
                              zip={order.address.zip}
                              num_boxes={order.num_boxes as number}
                              amount_to_collect={
                                order.amount_to_collect as number
                              }
                              remove={remove}
                              addToOrdersToDelete={this.addToOrdersToDelete}
                            />
                          );
                        })
                      }
                    </FieldArray>
                  </OrdersContainer>
                </Div>
                <Footer>
                  <CancelButton onClick={this.props.toggleModal}>
                    Cancel
                  </CancelButton>
                  <Button
                    width="130px"
                    type="submit"
                    style={{ justifySelf: "flex-end" }}
                    onClick={() => submitForm}
                  >
                    Save
                  </Button>
                </Footer>
              </Form>
            );
          }}
        </Formik>
      </Modal>
    );
  }
}

const Div = styled.div`
  display: flex;
  flex-direction: column;
  border-top: solid 1px ${PRIMARY_COLOR};
  border-radius: 2px;
  overflow-x: none;
  width: 507px;
  z-index: 70px;
  padding: 0 20px 0 20px;
`;

const Header = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height: 57px;
`;

const HeaderText = styled(AppText)`
  font-size: 18px;
  font-weight: 600;
  letter-spacing: 0.3px;
  color: black;
`;
const ContainerText = styled(AppText)`
  font-size: 14px;
  font-weight: 400;
`;

const OrderTitle = styled(AppText)`
  font-size: 15px;
  font-weight: 500;
`;

const ValueText = styled(AppText)`
  font-size: 14px;
  font-weight: 400;
  color: #60999e;
`;
const DeliveryRunInfo = styled.div`
  display: flex;
  flex-direction: column;
`;

const OrdersContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin: 20px 0px;
  height: 400px;
  overflow: auto;
`;

const DescriptionContainer = styled.div`
  display: flex;
  align-items: center;
  margin: 10px 0px;
`;
interface LinkStateProps {
  token: string;
  store_id: number;
}

const DateButton = styled(Button)`
  height: 30px;
  width: 100px;
  border: solid 1px rgba(71, 70, 71, 0.26);
  font-size: 10px;
  font-weight: 500;
  background-color: #fff;
  color: #000;
`;

const CancelButton = styled(Button)`
  height: 40px;
  width: 110px;
  border-radius: 4px;
  border: solid 1px rgba(71, 70, 71, 0.26);
  background-color: #fff;
  color: #000;
  margin-right: 21px;
`;
const Footer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  height: 57px;
  padding: 0 40px 30px 40px;
`;

interface LinkDispatchProps {
  addCreateDeliveryRunOrders: (orders: OrderType[]) => void;
  removeFromDeliveryRun: ({
    orders,
    driver_id,
    delivery_run_id,
  }: {
    orders: OrderType[];
    driver_id: number;
    delivery_run_id: string;
  }) => void;
  updateDeliveryRunSchedule: ({
    date,
    time,
    delivery_run_id,
    driver_id,
    inactive,
  }: {
    date: string;
    time: string;
    delivery_run_id: string;
    driver_id: number;
    inactive;
  }) => void;
  filterDashboardOrders: (orderIDs: number[], deliveryRunID: number) => void;
}

const mapStateToProps = (state: AppState): LinkStateProps => ({
  store_id: state.user.store_id as number,
  token: state.auth.token as string,
});

const mapDispatchToProps = (
  dispatch: Dispatch<AppActions>
): LinkDispatchProps => ({
  addCreateDeliveryRunOrders: bindActionCreators(
    addCreateDeliveryRunOrders,
    dispatch
  ),
  removeFromDeliveryRun: bindActionCreators(removeFromDeliveryRun, dispatch),
  updateDeliveryRunSchedule: bindActionCreators(
    updateDeliveryRunSchedule,
    dispatch
  ),
  filterDashboardOrders: bindActionCreators(filterDashboardOrders, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(DeliveryRunModal);

type Props = DeliveryRunModalProps & LinkDispatchProps & LinkStateProps;
