import styled, { StyledComponentBase } from "styled-components";
import { TABLET_BREAKPOINT } from "../../utils";

export const Container = styled.div`
  padding-left: 27px;
  padding-top: 97px;
  padding-right: 27px;
  padding-bottom: 97px;
  background-color: ${props => props.theme.BACKGROUND_COLOR};
  min-height: inherit;
  height: 100%;:
  @media (max-width: ${props => props.theme.TABLET_BREAKPOINT}) {
    padding-left: 27px;
  }
`;
