import { toast } from "react-toastify";

export const toastError = (errorString: string) => {
  return toast.error(errorString, {
    position: "top-right",
    autoClose: 3000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: false,
    draggable: true
  });
};

export const toastInfo = (note: string) => {
  return toast.info(note, {
    position: "top-right",
    autoClose: 3000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: false,
    draggable: true
  });
};

export const resetPassWord = (success: boolean) => {
  if (success) {
    return toast.success("Email sent to change password", {
      position: "top-right",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: false,
      draggable: true
    });
  } else {
    return toast.error("Something went wrong, please try again", {
      position: "top-right",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: false,
      draggable: true
    });
  }
};

export const isDefaultNotification = () => {
  return toast.error("Cannot edit default list", {
    position: "top-right",
    autoClose: 3000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: false,
    draggable: true
  });
};
