import * as React from "react";
import styled from "styled-components";

// * Reference: https://medium.com/@colebemis/building-a-checkbox-component-with-react-and-styled-components-8d3aa1d826dd

interface TestCheckBoxProps {
  // onChange: (boolean) => void;
  // checked: any;
  error?: boolean;
  checked: boolean;
  checkboxtype?: string;
  onChange?: (event) => void;
  disabled?: boolean;
  name?: string;
}

interface TestCheckboxState {
  checked: boolean;
}

interface StyledCheckboxProps {
  checked: boolean;
}
type Props = TestCheckBoxProps & StyledCheckboxProps;

const CheckBox: React.SFC<Props> = ({ disabled = false, ...props }) => (
  <CheckboxContainer className={props.name}>
    <HiddenCheckbox checked={props.checked} {...props} disabled={disabled} />
    {props.checkboxtype === "Onboarding" ? (
      <OnboardStyledCheckbox checked={props.checked}>
        <Icon viewBox="0 0 24 24">
          <polyline points="20 6 9 17 4 12" />
        </Icon>
      </OnboardStyledCheckbox>
    ) : (
      <DriverStyledCheckbox checked={props.checked} />
    )}
  </CheckboxContainer>
);

class TestCheckbox extends React.Component<Props, TestCheckboxState> {
  constructor(props) {
    super(props);
    this.state = {
      checked: false
    };
  }
  // handleCheckboxChange = event =>
  //   this.setState({ checked: event.target.checked });
  render() {
    return (
      <div>
        <label>
          <CheckBox
            checked={this.state.checked}
            {...this.props}
            // onChange={this.handleCheckboxChange}
          />
        </label>
      </div>
    );
  }
}

const HiddenCheckbox = styled.input.attrs({ type: "checkbox" })`
  /* Hide checkbox visually but remain accessible to screen readers. */
  /* Source: https://polished.js.org/docs/#hidevisually */
  border: 0;
  clip: rect(0 0 0 0);
  clip-path: inset(50%);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  white-space: nowrap;
  width: 1px;
`;
const Icon = styled.svg`
  fill: none;
  stroke: #4ba5eb;
  stroke-width: 3px;
  padding-bottom: 2px;
`;
const OnboardStyledCheckbox = styled.div<StyledCheckboxProps>`
  display: inline-block;
  width: 16px;
  height: 16px;
  background: ${props => (props.checked ? "white" : "transparent")};
  border-radius: 2px;
  transition: all 150ms;
  border: 2px solid white;
  ${Icon} {
    visibility: ${props => (props.checked ? "visible" : "hidden")};
  }
`;

const DriverStyledCheckbox = styled.div<StyledCheckboxProps>`
  display: inline-block;
  width: 13px;
  height: 13px;
  background: ${props => (props.checked ? "#60999e" : "white")};
  border-radius: 2px;
  transition: all 150ms;
  border: 1px solid white;
  outline: 1px solid #dcdcdc;
  ${Icon} {
    visibility: ${props => (props.checked ? "visible" : "hidden")};
  }
`;

const CheckboxContainer = styled.div`
  display: inline-block;
  vertical-align: middle;
`;

export { TestCheckbox };
