import {
  ERROR,
  SignupParams,
  LOGIN,
  LOGOUT,
  GET_USER,
  AppActions
} from "../types";
import { API_URL } from "../utils";
import { getError } from "../firebase/error";
import { auth } from "../firebase/firebase";

// Login takes firebase uid as input
// dispatches fetchUser if successful
// dispatches error if unsuccessful

export const login = ({
  access_token,
  is_registered,
  store_id
}: {
  access_token: string;
  is_registered: number | null;
  store_id?: number;
}): AppActions => ({
  type: LOGIN,
  access_token,
  is_registered,
  store_id
});

export const logout = (): AppActions => ({
  type: LOGOUT
});

export const startError = (error: string): AppActions => ({
  type: ERROR,
  error
});

export const getMe = (user: object) => ({
  type: GET_USER,
  user
});

export const loginAPI = async (uid: string, email: string): Promise<any> => {
  const formData = new FormData();
  formData.append(
    "user_data",
    `{
      "user_firebase_auth_id": "${uid}",
      "user_email": "${email}"
    }`
  );
  let response;
  try {
    const rawData = await fetch(`${API_URL}/user-auth.php`, {
      method: "POST",
      body: formData
    });
    response = await rawData.json();
  } catch (error) {
    return { error: getError(error.message) };
  }

  // error checks
  if (response.status === "error") {
    return { error: getError(response.message) };
  }
  if (!response.user_token) {
    return { error: "Unable to authenticate" };
  }
  if (!response.user_is_active) {
    return { error: "User is not active" };
  }
  return response;
};

export const signupAPI = async ({
  uid,
  email,
  firstName,
  lastName,
  photo = ""
}: SignupParams) => {
  // * For signup, we need to pass in a first name, last name, auth token, and email.
  // * A photo is optional
  const formData = new FormData();
  const body = `{
      "user_email": "${email}",
      "user_firebase_auth_id": "${uid}",
      "user_first_name": "${firstName}",
      "user_last_name": "${lastName}",
      "user_profile_image": "${photo}"
    }`;
  console.log("body: ", body);
  formData.append("user_data", body);
  let response;
  try {
    const rawData = await fetch(`${API_URL}/user-auth.php`, {
      method: "POST",
      body: formData
    });
    response = await rawData.json();
  } catch (e) {
    console.log("error signupAPI: ", e);
    return { error: "Unable to authenticate" };
  }
  // error checks
  if (response.status === "error") {
    return { error: getError(response.message) };
  }
  if (!response.user_token) {
    return { error: "Unable to authenticate" };
  }
  if (!response.user_is_active) {
    return { error: "User is not active" };
  }
  return response;
};

export const startLogout = () => {
  return async (dispatch: any) => {
    await auth.signOut();
    return dispatch(logout());
  };
};
