import React from "react";
import styled from "styled-components";
import DayPickerInput from "react-day-picker/DayPickerInput";
import "react-day-picker/lib/style.css";
import { DayPickerInputProps } from "react-day-picker/types/Props";
import moment from "moment";
import { Input } from ".";
import { DATE_FORMAT } from "../../utils/format";

interface AppDatePickerProps {
  date?: Date;
  title?: string;
  width?: number;
  error?: string;
}

const AppDatePicker = ({
  title,
  width,
  error,
  ...props
}: AppDatePickerProps & DayPickerInputProps) => {
  return (
    <Div>
      <DayPickerInput
        {...props}
        format={DATE_FORMAT}
        formatDate={(date, format) => moment(date).format(format)}
        value={moment(props.value).format(DATE_FORMAT)}
        component={(props) => (
          <Input
            title={!!title ? title.toUpperCase() : undefined}
            width={width}
            {...props}
          />
        )}
      />
      {!!error ? (
        <InputErrorText>{error}</InputErrorText>
      ) : (
        <InputErrorText>&nbsp;</InputErrorText>
      )}
    </Div>
  );
};

const Div = styled.div`
  position: relative;
  width: 250px;
  display: flex;
  flex-direction: column;
`;

const InputErrorText = styled.p`
  font-size: 12px;
  letter-spacing: 2px;
  color: red;
  margin: 0;
`;

export { AppDatePicker };
