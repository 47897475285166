import { ApiActionTypes, ApiResponse } from "../types";

// Auth Reducer
// ------------
// Allows user to login or logout
// Also handle errors in this reducer

const defaultApiReducerState: ApiResponse = {
  status: "",
  error: ""
};

const ApiReducer = (
  state = defaultApiReducerState,
  action: ApiActionTypes
): ApiResponse => {
  console.log("action: ", action);
  switch (action.type) {
    case "SUCCESS_API":
      return {
        ...state,
        status: "Successfull!",
        error: ""
      };
    case "ERROR_API":
      return {
        status: "Error",
        error: action.error
      };

    default:
      return state;
  }
};

export default ApiReducer;
