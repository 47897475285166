import * as React from "react";
import styled from "styled-components";
import { AppText } from "../UI";

export interface AuthErrorProps {
  status: any;
  error?: string;
  style?: any;
}

class AuthError extends React.Component<AuthErrorProps, any> {
  render() {
    const { status, error } = this.props;
    return (
      <>
        {error && (
          <ErrorText style={{ textAlign: "center", marginBottom: "8px" }}>
            {error}
          </ErrorText>
        )}
        {status === "Successfull" && (
          <SuccessfulText style={{ textAlign: "center", marginBottom: "8px" }}>
            Success!
          </SuccessfulText>
        )}
      </>
    );
  }
}

const ErrorText = styled(AppText)`
  color: #f23c5a;
  font-size: 15px;
`;

const SuccessfulText = styled(AppText)`
  color: #00827f;
  font-size: 15px;
`;

export { AuthError };
