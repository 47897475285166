import { CustomFields, PropertiesActionTypes } from "../types";

const customFieldsReducerDefaultState: CustomFields[] = [];

const customDriverFieldsReducer = (
  state = customFieldsReducerDefaultState,
  action: PropertiesActionTypes
): CustomFields[] => {
  switch (action.type) {
    case "FETCH_PROPERTIES":
      if (!!action.property) {
        return [...action.property];
      }
      return state;
    case "ADD_PROPERTY":
      return [...state, action.property];
    case "REMOVE_PROPERTY":
      return state.filter((property) => action.property_id !== property.id);
    case "EDIT_PROPERTY":
      return state.map((property: CustomFields) => {
        if (property.id === action.property_id) {
          return {
            ...property,
            ...action.property,
          };
        }
        return property;
      });
    case "LOGOUT":
      return customFieldsReducerDefaultState;
    default:
      return state;
  }
};

export default customDriverFieldsReducer;
