import * as React from "react";
import { connect } from "react-redux";
import { Formik, FormikProps, Form } from "formik";
import { Link, withRouter, RouteComponentProps } from "react-router-dom";
import * as Yup from "yup";
import styled from "styled-components";
import { Dispatch, bindActionCreators } from "redux";
import { InputField } from "../form";
import {
  AuthContainer,
  Button,
  Spinner,
  Card,
  AppText,
  Icon,
  AppTextRoman,
} from "../UI";
import { AppActions } from "../../types";
import { logo } from "../../img";
import { AuthError } from "../Dumb";
import { PRIMARY_COLOR, API_URL } from "../../utils";
import { onboardNextPage } from "../../actions/onboarding";
import { api } from "../../api/api";
import { AppState } from "../../store/configureStore";
import { logout } from "../../actions";

const signupSchema = Yup.object().shape({
  newPassword: Yup.string()
    .required("* New Password is required")
    .min(8, "* Password must be at least 8 characters"),
  newPasswordConfirm: Yup.string()
    .oneOf([Yup.ref("newPassword"), null], "* New passwords do not match")
    .required("* You must confirm your new password"),
});

interface NewUserPassWordProps {
  token: string;
}

interface NewUserPassWordState {}

type Props = NewUserPassWordProps &
  LinkDispatchProps &
  LinkStateProps &
  RouteComponentProps;

interface MyFormikProps {
  currentPassword: string;
  newPassword: string;
  newPasswordConfirm: string;
}

class NewUserPassWord extends React.Component<Props, NewUserPassWordState> {
  logout = () => {
    this.props.logout();
    this.props.history.push("/signin");
  };

  render(): JSX.Element {
    return (
      <Formik
        initialValues={{
          currentPassword: "",
          newPassword: "",
          newPasswordConfirm: "",
        }}
        validationSchema={signupSchema}
        onSubmit={async (values, { setFieldError, setStatus }) => {
          const { currentPassword, newPassword, newPasswordConfirm } = values;
          const { token, user_id } = this.props;
          setStatus({ error: "", loading: true });
          setStatus({ loading: false });
          this.props.onboardNextPage();
          try {
            await api.editUser(
              {
                user_id: user_id,
                password: values.newPasswordConfirm,
              },
              token
            );
          } catch (err) {
            console.log(err);
          }
        }}
      >
        {({ status, setStatus }: FormikProps<MyFormikProps>) => {
          console.log("status: ", status);
          return (
            <Div>
              <Form>
                <LoginTitleText>CHANGE PASSWORD</LoginTitleText>
                <ThisCard>
                  <InputField
                    name="newPassword"
                    placeholder="Enter New Password"
                    type="password"
                  />
                  <InputField
                    name="newPasswordConfirm"
                    placeholder="Confirm New Password"
                    type="password"
                  />
                  <div>
                    {status && status.loading ? (
                      <Spinner />
                    ) : (
                      <Button type="submit">Submit</Button>
                    )}
                  </div>
                  <AuthError status={status} />
                  <ForgotPasswordText onClick={this.logout}>
                    Back
                  </ForgotPasswordText>
                </ThisCard>
              </Form>
            </Div>
          );
        }}
      </Formik>
    );
  }
}

const Div = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: inherit;
  height: 100%;
  width: 100%;
`;

const ForgotPasswordText = styled(AppText)`
  font-size: 12.6px;
  font-weight: 500;
  color: #989898;
  text-align: center;
`;

const LoginTitleText = styled(AppText)`
  font-size: 26px;
  font-weight: 500;
  text-align: center;
  color: ${PRIMARY_COLOR};
  margin-bottom: 32px;
`;

const ThisCard = styled(Card)`
  height: 294.6px;
  width: 300px;
  display: grid;
  grid-template-rows: 54px 54px 66px 58px auto;
  justify-items: center;
  border: solid 0.9px #d8d8d8;
`;

const ThisAuthContainer = styled(AuthContainer)`
  background: "#fff";
`;

interface LinkDispatchProps {
  onboardNextPage: () => void;
  logout: () => void;
}

interface LinkStateProps {
  token: string;
  user_id: number;
}
const mapDispatchToProps = (
  dispatch: Dispatch<AppActions>
): LinkDispatchProps => ({
  onboardNextPage: bindActionCreators(onboardNextPage, dispatch),
  logout: bindActionCreators(logout, dispatch),
});

const mapStateToProps = (state: AppState): LinkStateProps => ({
  token: state.auth.token as string,
  user_id: state.user.id as number,
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(NewUserPassWord));
