import * as React from "react";
import styled from "styled-components";
import { Icon } from "./Icon";
import { checkboxChecked, checkboxNotChecked } from "../../img";
import { PRIMARY_COLOR } from "../../utils";

// interface CheckboxProps {
//   error?: boolean;
//   checked: boolean;
//   onChange?: any;
//   onBlur?: any;
//   value?: any;
//   name?: string;
// }

// interface CheckboxState {
//   checked;
// }

// class Checkbox extends React.Component<CheckboxProps, CheckboxState> {
//   constructor(props: CheckboxProps) {
//     super(props);

//     this.state = {
//       checked: this.props.checked
//     };
//   }
//   render(): JSX.Element {
//     const { checked, onChange, onBlur, value, name } = this.props;
//     if (checked) {
//       return (
//         <Icon {...this.props} src={checkboxChecked} width={18} height={18} />
//       );
//     }
//     return (
//       <Icon {...this.props} src={checkboxNotChecked} width={18} height={18} />
//     );
//   }
// }

// export { Checkbox };

interface Props {
  error?: boolean;
}

export const Checkbox = styled.input.attrs({ type: "checkbox" })<Props>`
  width: 24px;
  height: 24px;
  border-radius: 4px;
  border: solid 1px #dfe0eb;
  background-color: rgba(255, 255, 255, 0);
  outline: ${props => (props.error ? "none" : "")};
  border-color: ${props => (props.error ? "red" : "")};
  box-shadow: ${props => (props.error ? "0 0 5px red" : "")};
  :checked {
    background-color: black;
  }
`;

// * Reference: https://medium.com/@colebemis/building-a-checkbox-component-with-react-and-styled-components-8d3aa1d826dd

const HiddenCheckbox = styled.input.attrs({ type: "checkbox" })<Props>`
  border: 0;
  clip: rect(0 0 0 0);
  clip-path: inset(50%);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  white-space: nowrap;
  width: 1px;
`;

export const OnboardCheckbox = ({ checked, ...props }) => (
  <HiddenCheckbox checked={checked} {...props}>
    <Icon
      src={checked ? checkboxChecked : checkboxNotChecked}
      height={18}
      width={18}
    />
  </HiddenCheckbox>
);
