import { FilterActionTypes } from "../types";

export interface FilterReducer {
  textFilter: string;
  selectedChannel: number;
}

const filtersReducerDefaultState = {
  textFilter: "",
  selectedChannel: 0
};

const filterReducer = (
  state = filtersReducerDefaultState,
  action: FilterActionTypes
): FilterReducer => {
  switch (action.type) {
    case "SET_FILTER":
      return filtersReducerDefaultState;
    case "SET_TEXT_FILTER":
      return {
        ...state,
        textFilter: action.textFilter
      };
    case "SET_SELECTED_CHANNEL":
      return {
        ...state,
        selectedChannel: action.selectedChannel
      };
    case "LOGOUT":
      return filtersReducerDefaultState;
    default:
      return state;
  }
};

export default filterReducer;
