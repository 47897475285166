import {
  FETCH_USER,
  EDIT_USER,
  EDIT_USER_PHONE,
  User,
  AppActions,
  GET_USER,
  LoggedUser
} from "../types";

export const fetchUser = (user: User): AppActions => ({
  type: FETCH_USER,
  user
});
export const editUser = (user: LoggedUser): AppActions => ({
  type: EDIT_USER,
  user
});

export const editUserPhone = (phoneNumber: string): AppActions => ({
  type: EDIT_USER_PHONE,
  phoneNumber
});

export const getUser = (user: LoggedUser): AppActions => ({
  type: GET_USER,
  user
});
