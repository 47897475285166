import * as React from "react";
import styled from "styled-components";
import { Button, Modal, AppText, HR, SecondaryButton } from "../UI";
import { PRIMARY_COLOR } from "../../utils";

interface ExitOrderModalProps {
  showModal: boolean;
  toggleModal: () => void;
  exitCreateDeliveryRun: () => void;
}

interface ExitOrderModalstate {}

class ExitOrderModal extends React.Component<
  ExitOrderModalProps,
  ExitOrderModalstate
> {
  render() {
    return (
      <Modal
        height="fit-content"
        width="fit-content"
        showModal={this.props.showModal}
        toggleModal={this.props.toggleModal}
        label="Exit Assign Orders"
      >
        <Div>
          <Header>
            <HeaderText>EXIT ASSIGN ORDERS</HeaderText>
          </Header>
          <HR />
          <Body>
            <BodyText>
              Going back to order list will delete your current changes. Do you
              still want to go back to order list?
            </BodyText>
          </Body>
          <Footer>
            <CancelButton onClick={this.props.toggleModal}>Cancel</CancelButton>
            <Button
              width="130px"
              type="button"
              style={{ justifySelf: "flex-end" }}
              //TODO figure out why submit form submits twice
              onClick={this.props.exitCreateDeliveryRun}
            >
              Confirm
            </Button>
          </Footer>
        </Div>
      </Modal>
    );
  }
}
const Div = styled.div`
  display: flex;
  flex-direction: column;
  border-top: solid 1px ${PRIMARY_COLOR};
  border-radius: 2px;
  overflow-x: none;
  width: 507px;
`;
const HeaderText = styled(AppText)`
  font-size: 18px;
  font-weight: 600;
  letter-spacing: 0.3px;
  color: #60999e;
`;

const Header = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height: 57px;
  padding: 0 40px 0 40px;
`;
const Footer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  height: 57px;
  padding: 0 40px 30px 40px;
`;
const Body = styled.div`
  height: 150px;
  display: flex;
  padding-top: 40px;
  padding-right: 50px;
  padding-left: 50px;
`;

const BodyText = styled(AppText)`
  font-size: 15px;
  font-weight: 500;
  line-height: 1.6;
  letter-spacing: normal;
  color: black;
`;
const CancelButton = styled(Button)`
  height: 40px;
  width: 110px;
  border-radius: 4px;
  border: solid 1px rgba(71, 70, 71, 0.26);
  background-color: #fff;
  color: #000;
  margin-right: 21px;
`;
export default ExitOrderModal;
